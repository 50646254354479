import React, { useEffect, useState } from "react";
import Api from "../util/axiosInstance";
import { useAppContext } from "../../hooks/context/tokenStore";
import { DataGrid } from "@mui/x-data-grid";
import { formatDate } from "../util/convertDate";
import { MdDelete } from "react-icons/md";
import { Switch } from "@mui/material";
import { URL } from "../util/authorization/authorizationURL";
import ConfirmationModal from "../util/ConfirmationModal";
import LoadingAnimation from "../util/LoadingAnimation";

const CouponsList = () => {
  const [coupons, setCoupons] = useState([]);
  const [shopId, setShopId] = useState("");
  const userRole = localStorage.getItem("role");
  const [currentRow, setCurrentRow] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const { userData } = useAppContext();

  const handleDeleteCoupon = (id) => {
    setLoading(true);
    Api.delete(`/coupon/${id}`)
      .then((res) => {
        setCoupons(coupons.filter((coupon) => coupon.id !== id));
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const toggleActive = (couponId) => {
    setLoading(true);
    const couponActive = coupons.find((it) => it.id === couponId).active;
    Api.patch(`/coupon/${couponId}`, {
      active: !couponActive,
    })
      .then((res) => {
        setCoupons(
          coupons.map((coupon) => {
            if (coupon.id === couponId) {
              return { ...coupon, active: !coupon.active };
            }
            return coupon;
          })
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    if (userRole !== "vendor") return;
    setLoading(true);
    Api.get("/vendor/" + userData.id)
      .then((res) => {
        setShopId(res.data.id);
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setLoading(false);
      });
  }, [userRole]);
  useEffect(() => {
    const url = URL.coupon.get[userRole];
let filter = ''

if(userRole==='vendor') filter = `shopId=${userData?.Shop?.id}`
  else if(userRole==='professional') filter = `professionalId=${userData.id}`
else  filter = `adminId=${userData.id}`

    if (!filter || !url) return;
    setLoading(true);
    Api.get(`${url}?${filter}`)
      .then((res) => {
        setCoupons(res.data.results);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [shopId, userData.id, userRole]);

  const coloums = [
    {
      // code
      field: "code",
      headerName: "Code",
      width: 150,
    },
    // discount
    {
      field: "discount",
      headerName: "Discount",
      width: 150,
    },
    // type
    {
      field: "type",
      headerName: "Type",
      width: 150,
    },
    //   expiration
    {
      field: "expiration",
      headerName: "Expiration",
      width: 150,
      valueFormatter: (value) => formatDate(value),
    },
    //   status
    {
      field: "active",
      headerName: "Active",
      width: 150,
      renderCell: (params) => {
        return (
          <div className="flex items-center w-full h-full">
            <Switch
              checked={params.row.active}
              color="success"
              onChange={() => {
                toggleActive(params.row.id);
              }}
            />
          </div>
        );
      },
    },
    //   action buttons
    {
      field: "action",
      headerName: "Action",
      width: 150,
      renderCell: (params) => {
        return (
          <div className="flex items-center w-full h-full">
            <MdDelete
              className="text-red-700 cursor-pointer heading-text"
              onClick={() => {
                // handleDeleteCoupon(params.row.id);
                setCurrentRow(params.row);
              }}
            />
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    if (currentRow && currentRow.id) {
      setIsOpen(true);
    }
  }, [currentRow]);

  return (
    <>
      {loading ? (
        <div className=" flex justify-center min-h-[90vh] items-center m-auto">
          <LoadingAnimation />
        </div>
      ) : (
        <div
          style={{
            height: 300,
            width: "80%",
          }}
          className="px-5"
        >
          <DataGrid
            rows={coupons}
            columns={coloums}
            pageSize={5}
            rowsPerPageOptions={[20, 50, 100]}
          />

          <ConfirmationModal
            deleteFuction={handleDeleteCoupon}
            name={currentRow?.code}
            id={currentRow?.id}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
          />
        </div>
      )}
    </>
  );
};

export default CouponsList;
