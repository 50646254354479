import React, { useEffect, useState } from "react";
import Menu from "../sideMenu/Menu";
import Translate from "../util/Translate";
import { useTranslation } from "react-i18next";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Api from "../util/axiosInstance";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { TiDeleteOutline } from "react-icons/ti";

const editorModules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }],
    ["bold", "italic", "underline", "strike"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["clean"],
    ["code-block"],
  ],
};

const EditService = () => {
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const { state } = useLocation();

  const [inputValues, setInputValues] = useState({
    title: "",
    price: "",
    description: "",
    active: true,
    image: "",
    newImage: null,
    categoryId: "",
    subCategoryId: "",
  });
  const { serviceId } = useParams();

  const Navigate = useNavigate();
  const { t } = useTranslation();
  const handleOnInputchange = (e) => {
    const { name, value } = e.target;
    setInputValues({ ...inputValues, [name]: value });
  };

  useEffect(() => {
    Api.get("/category?type=service")
      .then((res) => {
        setCategories(res.data.results);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    Api.get(`/category/${serviceId}`)
      .then((res) => {
        setInputValues({
          title: res.data.data.title,
          price: res.data.data.price,
          description: res.data.data.description,
          active: res.data.data.active,
          image: res.data.data.image,
          categoryId: res.data.data.categoryId,
          subCategoryId: res.data.data.subCategoryId,
        });
        setSelectedCategory(res.data.service.categoryId);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [serviceId]);

  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData();
    for (let key in inputValues) {
      formData.append(key, inputValues[key]);
    }

    Api.post(`/professional/editService/${serviceId}`, formData)
      .then((res) => {
        console.log(res.data);
        setInputValues({
          title: "",
          price: "",
          description: "",
          active: true,
          image: res.data.service.image,
          newImage: "",
          categoryId: "",
          subCategoryId: "",
        });
        Navigate(-1);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <Menu>
      <div>
        <div className="mb-5 heading-text">
          <Translate>Service</Translate>
        </div>
        <div className="w-full flex justify-center">
          <form
            className="w-2/3 flex flex-col space-y-20 my-10"
            onSubmit={handleSubmit}
          >
            <fieldset>
              <legend>
                <Translate>SERVICE DETAILS</Translate>
              </legend>
              <div className="create-form">
                <div>
                  <label htmlFor="title">
                    <Translate>Title</Translate>
                  </label>
                  <input
                    type="text"
                    name="title"
                    id="title"
                    required
                    min={3}
                    max={50}
                    value={inputValues.title}
                    onChange={handleOnInputchange}
                  />
                </div>
                <div>
                  <label htmlFor="price">
                    <Translate>Price</Translate>
                  </label>
                  <input
                    type="number"
                    name="price"
                    id="price"
                    required
                    min={1}
                    value={inputValues.price}
                    onChange={handleOnInputchange}
                  />
                </div>
                <div>
                  <div className="flex flex-row items-center space-x-3">
                    <input
                      type="checkbox"
                      name="active"
                      id="active"
                      checked={inputValues.active}
                      onChange={(e) => {
                        setInputValues({
                          ...inputValues,
                          active: e.target.checked,
                        });
                      }}
                    />
                    <label htmlFor="active">
                      <Translate>Active</Translate>
                    </label>
                  </div>
                </div>

                {inputValues.image ? (
                  <div className="relative">
                    <label htmlFor="newImage">
                      <Translate>Image</Translate>
                    </label>

                    <img
                      src={inputValues.image}
                      alt="service"
                      className="w-40 h-40"
                    />
                    <div
                      className="absolute -top-5 right-0 cursor-pointer"
                      onClick={() => {
                        setInputValues({ ...inputValues, image: "" });
                      }}
                    >
                      <TiDeleteOutline size={20} color="red" />
                    </div>
                  </div>
                ) : (
                  <div>
                    <label htmlFor="image">
                      <Translate>Image</Translate>
                    </label>
                    <input
                      type="file"
                      name="newImage"
                      id="newImage"
                      onChange={(e) => {
                        setInputValues({
                          ...inputValues,
                          newImage: e.target.files[0],
                        });
                      }}
                    />
                  </div>
                )}
                <div>
                  <label htmlFor="categoryId">
                    <Translate>Category</Translate>
                  </label>
                  <select
                    name="categoryId"
                    id="categoryId"
                    onChange={(e) => {
                      handleOnInputchange(e);
                      setSelectedCategory(e.target.value);
                    }}
                  >
                    {categories.map((category) => {
                      return (
                        <option
                          key={category.id}
                          value={category.id}
                          selected={
                            category.id === inputValues.categoryId
                              ? "selected"
                              : ""
                          }
                        >
                          {category.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
                {/* sub category */}
                {selectedCategory && (
                  <div>
                    <label htmlFor="subCategoryId">
                      <Translate>Sub Category</Translate>
                    </label>
                    <select
                      name="subCategoryId"
                      id="subCategoryId"
                      onChange={handleOnInputchange}
                      value={inputValues.subCategoryId}
                    >
                      <option value="">
                        <Translate>Select Sub Category</Translate>
                      </option>
                      {categories
                        ?.find((category) => category.id === selectedCategory)
                        ?.SubCategory?.map((subCategory) => (
                          <option key={subCategory.id} value={subCategory.id}>
                            {subCategory.name}
                          </option>
                        ))}
                    </select>
                  </div>
                )}
              </div>
              <div className="input-group w-[80%] h-72 m-5">
                <label htmlFor="description">
                  <Translate>Description</Translate>
                </label>
                {/* <textarea
                    name="description"
                    id="description"
                    cols="30"
                    rows="10"
                    placeholder={t("Description")}
                    onChange={handleInputChange}
                    value={inputValues.description}
                  ></textarea> */}
                <ReactQuill
                  theme="snow"
                  modules={editorModules}
                  value={inputValues.description}
                  onChange={(e) => {
                    setInputValues({ ...inputValues, description: e });
                  }}
                  className="h-56 "
                />
              </div>
            </fieldset>

            {!state?.disableEdit && (
              <div className="flex justify-center space-x-5">
                <input
                  className="heading-bg-color bg-text-color px-5 py-2 rounded-md cursor-pointer"
                  type="submit"
                  value={t("Save")}
                />

                <div
                  onClick={() => Navigate(-1)}
                  className="heading-bg-color bg-text-color px-5 py-2 rounded-md cursor-pointer"
                >
                  <Translate>Back</Translate>
                </div>
              </div>
            )}
          </form>
        </div>
      </div>
    </Menu>
  );
};

export default EditService;
