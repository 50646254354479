import React, { useEffect, useState } from "react";

import { GiHamburgerMenu, GiBeastEye } from "react-icons/gi";
import { useLocation, useNavigate } from "react-router-dom";
import { RxDashboard } from "react-icons/rx";
import { FiUsers } from "react-icons/fi";
import { BsBagDash, BsBorderStyle } from "react-icons/bs";
import { AiOutlineCar, AiOutlinePlusSquare } from "react-icons/ai";
import { TbReportSearch, TbCategory2 } from "react-icons/tb";
import {
  IoFastFoodOutline,
  IoSettingsOutline,
  IoNotificationsOutline,
} from "react-icons/io5";
import { RiCoupon4Line } from "react-icons/ri";
import { motion } from "framer-motion";

import {
  MdOutlinePayment,
  MdOutlineEmail,
  MdOutlinePolicy,
  MdPolicy,
} from "react-icons/md";
import { PiBowlFood, PiFlagBannerLight } from "react-icons/pi";
import useMenuSwitchStore from "../../store/zustandStore";
import { useTranslation } from "react-i18next";
import Translate from "../util/Translate";
import { useAppContext } from "../../hooks/context/tokenStore";
import { Navigate } from "react-router-dom";
import { FaArrowDown, FaCity } from "react-icons/fa";
import { GrBusinessService } from "react-icons/gr";
import { IoMdNotificationsOutline } from "react-icons/io";

const menuItems = [
  {
    name: "Dashboard",
    path: "/",
    icon: <RxDashboard />,
    allowedRoles: [
      "superadmin",
      "cityadmin",
      "vendor",
      "professional",
      "logisticadmin",
    ],
  },
  {
    name: "Bipeers Eye",
    path: "/map",
    icon: <GiBeastEye />,
    allowedRoles: ["superadmin", "cityadmin"],
  },
  {
    name: "Users / Customer",
    path: "/users",
    icon: <FiUsers />,
    allowedRoles: ["cityadmin", "superadmin"],
  },
  {
    name: "Admins",
    path: "/admins",
    icon: <FiUsers />,
    allowedRoles: ["superadmin"],
  },
  {
    name: "Logistic Admins",
    path: "/logistic/admins",
    icon: <FiUsers />,
    allowedRoles: ["superadmin"],
  },
  // {
  //   name: "Owners / Vendors",
  //   path: "/vendors",
  //   icon: <FiUsers />,
  //   allowedRoles: ["cityadmin", "superadmin"],
  // },
  {
    name: "Professionals",
    path: "/professionals",
    icon: <FiUsers />,
    allowedRoles: ["cityadmin", "superadmin"],
  },
  {
    name: "Services",
    path: "/services",
    icon: <GrBusinessService />,
    allowedRoles: ["cityadmin", "superadmin", "professional"],
  },
  {
    name: "Chats",
    path: "/chats",
    icon: <MdOutlineEmail />,
    allowedRoles: ["professional", "vendor"],
  },
  {
    name: "Cities",
    path: "/cities",
    icon: <FaCity />,
    allowedRoles: ["superadmin"],
  },

  {
    name: "Shops",
    path: "/shops",
    icon: <BsBagDash />,
    allowedRoles: ["cityadmin", "superadmin"],
  },
  // {
  //   name: "Drivers",
  //   path: "/drivers",
  //   icon: <AiOutlineCar />,
  // },
  // {
  //   name: "Reports",
  //   path: "/reports",
  //   icon: <TbReportSearch />,
  // },
  {
    name: "Categories",
    path: "/categories",
    icon: <TbCategory2 />,
    allowedRoles: ["professional", "vendor"],
  },
  {
    name: "Categories",
    path: "/category",
    icon: <TbCategory2 />,
    allowedRoles: ["cityadmin", "superadmin"],
  },
  // {
  //   name: "Sub Categories",
  //   path: "/category/sub",
  //   icon: <TbCategory2 />,
  //   allowedRoles: ["cityadmin", "superadmin"],
  // },
  {
    name: "Taxonomy",
    icon: <TbCategory2 />,
    allowedRoles: ["superadmin"],
    innerLinks: [
      {
        name: "Vendor",
        path: "/taxonomy/vendor",
      },
      {
        name: "Professional",
        path: "/taxonomy/professional",
      },
    ],
  },

  {
    name: "Products",
    path: "/products",
    icon: <IoFastFoodOutline />,
    allowedRoles: ["cityadmin", "superadmin", "vendor"],
  },
  {
    name: "Units",
    path: "/units",
    icon: <PiFlagBannerLight />,
    allowedRoles: ["superadmin", "vendor"],
  },
  {
    name: "Attributes",
    path: "/attributes",
    icon: <AiOutlinePlusSquare />,
    allowedRoles: ["superadmin", "vendor"],
  },
  {
    name: "Orders",
    path: "/orders",
    icon: <BsBorderStyle />,
    allowedRoles: ["superadmin", "cityadmin", "vendor", "professional"],
  },
  {
    name: "Delivery Orders",
    path: "/delivery-orders",
    icon: <BsBorderStyle />,
    allowedRoles: ["logisticadmin"],
  },
  {
    name: "Pickup Orders",
    path: "/pickup-orders",
    icon: <BsBorderStyle />,
    allowedRoles: ["logisticadmin"],
  },
  {
    name: "Orders Completed",
    path: "/completed-orders",
    icon: <BsBorderStyle />,
    allowedRoles: ["logisticadmin"],
  },
  {
    name: "Coupons",
    path: "/coupons",
    icon: <RiCoupon4Line />,
    allowedRoles: ["superadmin", "vendor", "professional"],
  },
  // {
  //   name: "Notifications",
  //   path: "/notifications",
  //   icon: <IoNotificationsOutline />,
  // },
  {
    name: "Subscription",
    icon: <TbCategory2 />,
    allowedRoles: ["superadmin"],
    innerLinks: [
      {
        name: "Vendor",
        path: "/subscription/vendor",
      },
      {
        name: "Professional",
        path: "/subscription/professional",
      },
    ],
  },
  {
    name: "Payments",
    path: "/payments",
    icon: <MdOutlinePayment />,
    allowedRoles: ["superadmin", "cityadmin", "vendor", "professional"],
  },
  {
    name: "Professional Slider",
    path: "/professionalSlider",
    icon: <RxDashboard />,
    allowedRoles: ["cityadmin", "superadmin"],
  },
  {
    name: "Category Slider",
    path: "/categorySlider",
    icon: <RxDashboard />,
    allowedRoles: ["cityadmin", "superadmin"],
  },
  {
    name: "Notifications",
    path: "/notifications",
    allowedRoles: ["superadmin", "cityadmin"],
    icon: <IoMdNotificationsOutline />,
  },
  {
    name: "City Slider",
    path: "/citySlider",
    icon: <RxDashboard />,
    allowedRoles: ["cityadmin", "superadmin"],
  },

  {
    name: "Profile",
    path: "/professional/profile",
    icon: <FiUsers />,
    allowedRoles: ["professional"],
  },
  {
    name: "Profile",
    path: "/vendor/profile",
    icon: <FiUsers />,
    allowedRoles: ["vendor"],
  },
  {
    name: "My Shop",
    path: "/vendor/shop",
    icon: <BsBagDash />,
    allowedRoles: ["vendor"],
  },
  {
    name: "Privay Policy",
    path: "/privacypolicay",
    icon: <MdOutlinePolicy />,
    allowedRoles: ["superadmin"],
  },
  {
    name: "Terms & Conditions",
    path: "/termsandconditions",
    icon: <MdPolicy />,
    allowedRoles: ["superadmin"],
  },
  {
    name: "Tickets",
    path: "/tickets",
    icon: <MdPolicy />,
    allowedRoles: ["superadmin", "customer"],
  },

  // {
  //   name: "Settings",
  //   path: "/settings",
  //   icon: <IoSettingsOutline />,
  // },
];

const Menu = ({ children }) => {
  const { t, i18n } = useTranslation();
  const { userData } = useAppContext();

  const location = useLocation();
  const [currentSelection, setCurrentSelection] = useState();

  const userRole = localStorage.getItem("role");
  console.log("🚀 ~ Menu ~ userRole:", userRole);

  const navigate = useNavigate();
  const menuSwitch = useMenuSwitchStore((state) => state.menuSwitch);
  const invertSwitch = useMenuSwitchStore((state) => state.invertSwitch);

  useEffect(() => {
    const menuItem = menuItems.find((item) => {
      const path = item.path;
      if (location.pathname === path) {
        return true; // Exact match
      }
      if (
        location.pathname.startsWith(path + "/") ||
        location.pathname === "/"
      ) {
        return true; // Match for root path or paths starting with item.path
      }
      return false;
    });

    if (menuItem) {
      setCurrentSelection(menuItem.path);
    } else {
      setCurrentSelection(null); // Set to null if no matching menu item is found
    }
  }, [location.pathname]);

  return (
    <div className="flex h-screen overflow-hidden relative">
      <div
        className={`${
          menuSwitch ? "w-56" : "w-20"
        } max-w-sm sub-heading-text flex flex-col space-y-5 p-2 h-screen overflow-y-scroll menu-scrollbar border-r border-color`}
      >
        <div className="app-text-color px-5 py-3 rounded-md flex items-center space-x-3">
          <div className="text-xl">
            <PiBowlFood />
          </div>
          {menuSwitch && (
            <div>
              <Translate>BIPEERS</Translate>
            </div>
          )}
        </div>
        {menuItems.map((item, index) => {
          return (
            <>
              {item?.allowedRoles &&
                item?.allowedRoles.includes(userRole) &&
                !item.innerLinks && (
                  <div
                    className={`hover:bg-color hover:app-text-color sub-heading-text px-5 py-1 border-b border-color cursor-pointer rounded-md flex items-start space-x-3 ${
                      currentSelection === item.path
                        ? "bg-color app-text-color"
                        : ""
                    }`}
                    onClick={() => {
                      navigate(`${item.path}`);
                    }}
                  >
                    <div className="mt-1">{item.icon}</div>
                    {menuSwitch && (
                      <div>
                        <Translate>{item.name}</Translate>
                      </div>
                    )}
                  </div>
                )}
              {item?.allowedRoles &&
                item?.allowedRoles.includes(userRole) &&
                item.innerLinks && (
                  <div>
                    <div
                      className={`relative group  sub-heading-text px-5 py-1 border-b border-color cursor-pointer rounded-md flex items-center justify-between space-x-3 ${
                        currentSelection === item.path
                          ? "bg-color app-text-color"
                          : ""
                      }`}
                    >
                      <div className="flex items-center space-x-3">
                        <div>{item.icon}</div>
                        {menuSwitch && (
                          <div>
                            <Translate>{item.name}</Translate>
                          </div>
                        )}
                      </div>
                      <div>
                        <FaArrowDown />
                      </div>

                      <div className="absolute top-full right-0 z-40 bg-white py-2 border-2 hidden group-hover:flex flex-col space-y-3 ">
                        {item.innerLinks.map((innerItem, index) => {
                          return (
                            <div
                              className={`hover:bg-color hover:app-text-color sub-heading-text px-5 py-1 border-b border-color cursor-pointer rounded-md flex items-center space-x-3 ${
                                currentSelection === innerItem.path
                                  ? "bg-color app-text-color"
                                  : ""
                              }`}
                              onClick={() => {
                                navigate(`${innerItem.path}`);
                              }}
                            >
                              <div>{item.icon}</div>
                              {menuSwitch && (
                                <div>
                                  <Translate>{innerItem.name}</Translate>
                                </div>
                              )}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                )}
            </>
          );
        })}
      </div>
      <div className="flex-1 overflow-y-scroll">
        <div className="py-5 flex justify-between items-center w-fit">
          <div
            className="heading-text mx-5 cursor-pointer"
            onClick={invertSwitch}
          >
            <GiHamburgerMenu />
          </div>
          <div className="app-text-color">
            <Translate>{localStorage.getItem("role")}</Translate>
          </div>
        </div>
        <div className="p-5 app-bg-color -z-10">
          <motion.dev
            key="page"
            initial={{ y: "50%", opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: "-50%", opacity: 0, transition: { duration: 0.2 } }}
            transition={{ delay: 0, duration: 0.5 }}
          >
            {children}
          </motion.dev>
        </div>
      </div>
    </div>
  );
};

export default Menu;
