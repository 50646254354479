import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Menu from "../sideMenu/Menu";
import Api from "../util/axiosInstance";
import { formatDate } from "../util/convertDate";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../config/Firebase";
import { useAppContext } from "../../hooks/context/tokenStore";

const ViewProductOrder = () => {
  const { orderId } = useParams();
  const { userData } = useAppContext();
  const [order, setOrder] = useState();
  useEffect(() => {
    Api.get(`/order/${orderId}`)
      .then((res) => {
        setOrder(res.data.order);
        console.log("order details: ", res.data.order);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [orderId]);

  const sendNotificationToCustomer = async () => {
    const userDoc = await getDoc(doc(db, "users", order.Customer.id));
    const deviceToken = userDoc.data()?.deviceToken;

    // notification body
    const notification = {
      to: deviceToken,
      priority: "high",
      notification: {
        title: "New Message",
        body: `You have a new message from ${userData.name}`,
      },
      data: {
        click_action: "FLUTTER_NOTIFICATION_CLICK",
      },
    };

    // send message using FCM
    try {
      await fetch("https://fcm.googleapis.com/fcm/send", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `key=${process.env.REACT_APP_FIREBASE_SERVER_KEY}`,
        },
        body: JSON.stringify(notification),
      });
    } catch (error) {
      console.error("Error sending notification: ", error);
    }
  };

  const markReadyForPickup = () => {
    Api.put(`/order/${orderId}/readyForPickup`)
      .then((res) => {
        setOrder({
          ...order,
          availableForPickup: true,
        });
        sendNotificationToCustomer();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Menu>
      <div>
        <div className="flex flex-col space-y-10">
          <h1 className="sub-heading-text py-2 border-b-2 border-b-gray-300 w-full">
            Order
          </h1>
          {/* order Items */}
          <div>
            <fieldset className="rounded-md">
              <legend>
                <h1 className="sub-heading-text">Order Items</h1>
              </legend>

              <div className="flex flex-col space-y-5 w-[90%] md:w-4/5">
                {order &&
                  order.OrderItems.map((item) => (
                    <div
                      key={item.id}
                      className="flex justify-between items-center"
                    >
                      <div className="flex items-center space-x-3">
                        <img
                          src={item.Product.mainImage}
                          alt={item.Product.name}
                          className="w-14 h-14 rounded-md"
                        />
                        <div>
                          <h1 className="flex items-center space-x-3">
                            <div className="sub-heading-text">
                              {item.Product.name}
                            </div>
                            {item.ProductVariation && (
                              <span> - {item.ProductVariation.name}</span>
                            )}
                          </h1>
                          <h1 className="">X {item.quantity}</h1>
                        </div>
                      </div>
                      <div>
                        <p className="text-gray-500">
                          {item.ProductVariation ? (
                            <>${item.ProductVariation.price * item.quantity}</>
                          ) : (
                            <>${item.Product.price * item.quantity}</>
                          )}
                        </p>
                      </div>
                    </div>
                  ))}
              </div>
            </fieldset>
          </div>
          <div>
            <fieldset className="rounded-md">
              <legend>Customer Details</legend>
              <div className="w-[90%] md:w-[50%] mx-auto flex flex-col space-y-3">
                <div className="flex justify-between">
                  <label>Name</label>
                  <div>{order?.Customer.name}</div>
                </div>
                <div className="flex justify-between">
                  <label>Email</label>
                  <div>{order?.Customer.email}</div>
                </div>
                <div className="flex justify-between">
                  <label>Phone</label>
                  <div>{order?.Customer.phone}</div>
                </div>
              </div>
            </fieldset>
          </div>
          {/* other order details */}
          <div>
            <fieldset className="rounded-md">
              <legend>
                <h1 className="sub-heading-text">Order Details</h1>
              </legend>
              <div className="w-[90%] md:w-[50%] mx-auto flex flex-col space-y-3">
                <div className="flex justify-between">
                  <label>Order Date</label>
                  <div>{formatDate(order?.createdAt)}</div>
                </div>
                <div className="flex justify-between">
                  <label>Sub Total</label>
                  <div>${order?.totalAmount}</div>
                </div>
                <div className="flex justify-between">
                  <label>Status From Vendor</label>
                  <div className="flex flex-col space-y-2 items-end">
                    <div>{order?.statusByVendor}</div>
                    <div className="flex items-center space-x-5">
                      <div className="btn">Delivered</div>
                      <div className="btn">Canceled</div>
                    </div>
                  </div>
                </div>
                <div className="flex justify-between">
                  <label>Order Type</label>
                  <div className="flex flex-col space-y-2 items-end">
                    <div>{order?.orderType || "DELIVERY"}</div>

                    {order?.orderType === "PICKUP" && (
                      <div className="flex items-center space-x-5">
                        <div
                          className="btn flex space-x-3 items-center"
                          onClick={markReadyForPickup}
                        >
                          <div>Ready for Pickup</div>
                          {order?.availableForPickup && (
                            <div>
                              <span className="text-orange-500">Yes</span>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="flex justify-between">
                  <label>Status From Customer</label>
                  <div className="flex flex-col space-y-2 items-end">
                    <div>{order?.statusByCustomer}</div>
                  </div>
                </div>
              </div>
            </fieldset>
          </div>

          {/* shipping address */}
          <div>
            <fieldset className="rounded-md">
              <legend>
                <h1 className="sub-heading-text">Shipping Address</h1>
              </legend>
              <div className="w-[90%] md:w-[50%] mx-auto flex flex-col space-y-3">
                <div className="flex justify-between">
                  <label>addressLine1</label>
                  <div>{order?.ShippingAddress?.addressLine1}</div>
                </div>
                <div className="flex justify-between">
                  <label>City</label>
                  <div>{order?.ShippingAddress?.city}</div>
                </div>
                <div className="flex justify-between">
                  <label>State</label>
                  <div>{order?.ShippingAddress?.state}</div>
                </div>
                <div className="flex justify-between">
                  <label>Postal Code</label>
                  <div>{order?.ShippingAddress?.postalCode}</div>
                </div>
              </div>
            </fieldset>
          </div>
          {/* Billing address */}
          <div>
            <fieldset className="rounded-md">
              <legend>
                <h1 className="sub-heading-text">Billing Address</h1>
              </legend>
              <div className="w-[90%] md:w-[50%] mx-auto flex flex-col space-y-3">
                <div className="flex justify-between">
                  <label>addressLine1</label>
                  <div>{order?.BillingAddress?.addressLine1}</div>
                </div>
                <div className="flex justify-between">
                  <label>City</label>
                  <div>{order?.BillingAddress?.city}</div>
                </div>
                <div className="flex justify-between">
                  <label>State</label>
                  <div>{order?.BillingAddress?.state}</div>
                </div>
                <div className="flex justify-between">
                  <label>Postal Code</label>
                  <div>{order?.BillingAddress?.postalCode}</div>
                </div>
              </div>
            </fieldset>
          </div>
        </div>
      </div>
    </Menu>
  );
};

export default ViewProductOrder;
