import React, { useEffect, useState } from "react";
import Api from "../util/axiosInstance";
import { MdDelete } from "react-icons/md";
import LoadingSpinner from "../util/LoadingSpinner";
import DeleteComponent from "../util/DeleteComponent";

const SliderList = () => {
  const [sliders, setSliders] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    Api.get("/category-slider").then((res) => {
      setSliders(res.data?.results);
    });
  }, []);

  const deleteSlider = (id) => {
    setLoading(true);
    Api.delete(`/category-slider/${id}`)
      .then((res) => {
        setSliders(sliders.filter((slider) => slider.id !== id));
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <div>
      <div>
        <table className="table-auto w-full">
          <thead>
            <tr>
              <th>Image</th>
              <th>Category</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {sliders.map((slider) => (
              <tr key={slider._id}>
                <td>
                  <img
                    src={slider.image}
                    alt="slider"
                    className="w-20 h-20 object-cover"
                  />
                </td>
                <td>{slider?.Category?.name}</td>
                <td>
                  <DeleteComponent
                    id={slider.id}
                    deleteFuction={deleteSlider}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default SliderList;
