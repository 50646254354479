import React, { useEffect, useState } from "react";
import { MdDelete, MdEdit } from "react-icons/md";
import CustomModal from "../../../reusable/CustomModal";
import EditSubCategory from "./EditSubCategory";
import Api from "../../../util/axiosInstance";
import Translate from "../../../util/Translate";
import ConfirmationModal from "../../../util/ConfirmationModal";

const SubCategories = ({
  subCategories,
  setSubCategories,
  currentCategory,
  categories,
  setCategories,
  currentSubCategory,
  setCurrentSubCategory,
}) => {
  const [modelIsOpen, setModalIsOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const openModal = () => {
    setModalIsOpen(true);
  };
  const closeModal = () => {
    setModalIsOpen(false);
  };
  const userRole = localStorage.getItem("role");

  useEffect(() => {
    if (currentCategory) {
      const category = categories.find(
        (category) => category.id === currentCategory.id
      );
      if (category?.ShopSubCategory) {
        setSubCategories(category.ShopSubCategory);
      }
    }
  }, [currentCategory]);

  const handleSubCategoryDelete = (id) => {
    Api.delete(`/shop-sub-category/${id}`)
      .then((res) => {
        let tempSubCategories = subCategories.filter((item) => item.id !== id);
        setSubCategories(tempSubCategories);

        let tempCategories = categories.map((item) => {
          if (item.id === currentCategory.id) {
            return {
              ...item,
              ShopSubCategory: tempSubCategories,
            };
          }
          return item;
        });
        setCategories(tempCategories);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="flex flex-col space-y-5 bg-white p-5 w-fit rounded-md">
      <div className="sub-heading-text">
        <Translate>Sub Categories</Translate>
      </div>
      <div className="flex flex-col space-y-3 h-[40vh] w-[250px] overflow-y-scroll">
        {subCategories.map((category, index) => {
          return (
            <>
              <div
                key={category.id}
                className={`flex justify-between px-3 py-2 hover:heading-bg-color hover:bg-text-color cursor-pointer rounded-md`}
                onClick={() => {
                  setCurrentSubCategory(category);
                }}
              >
                <div>{category.name}</div>
                {(userRole === "admin" || userRole === "superadmin") && (
                  <div className="flex space-x-3 heading-text">
                    <MdEdit
                      className="cursor-pointer "
                      color="green"
                      onClick={openModal}
                    />
                    <MdDelete
                      className="cursor-pointer"
                      color="red"
                      onClick={() => setIsOpen(true)}
                    />
                  </div>
                )}
              </div>
              <ConfirmationModal
                deleteFuction={handleSubCategoryDelete}
                id={category.id}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
              />
            </>
          );
        })}
        {subCategories.length === 0 && (
          <div className="text-center text-gray-400">
            <Translate>No Category Selected/Found</Translate>
          </div>
        )}
      </div>

      <CustomModal
        title="Edit Sub Category"
        openModal={openModal}
        closeModal={closeModal}
        isOpen={modelIsOpen}
        setIsOpen={setModalIsOpen}
      >
        <EditSubCategory
          currentCategory={currentCategory}
          currentSubCategory={currentSubCategory}
          setCategories={setCategories}
          setSubCategories={setSubCategories}
          subCategories={subCategories}
          categories={categories}
          closeModal={closeModal}
        />
      </CustomModal>
    </div>
  );
};

export default SubCategories;
