import React from "react";
import Menu from "../../sideMenu/Menu";
import TaxonomiesData from "./TaxonomiesData";
import { Link } from "react-router-dom";
import Translate from "../../util/Translate";

const VendorTaxonomy = () => {
  return (
    <Menu>
      <div className="flex flex-col space-y-10">
        <h1 className="sub-heading-text py-2 border-b-2 border-b-gray-300 w-full">
          <Translate>Vendor Taxonomy</Translate>
        </h1>
        <Link to={"/taxonomy/create"} className="btn text-center self-start">
          <Translate>Create Taxonomy</Translate>
        </Link>
        <TaxonomiesData />
      </div>
    </Menu>
  );
};

export default VendorTaxonomy;
