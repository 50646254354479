import React, { useEffect, useState } from "react";
import GoogleMapReact from "google-map-react";
import LoadingSpinner from "../util/LoadingSpinner";
import { Picker } from "react-gmap-picker";
import axios from "axios";

const AddressModal = ({ setInputValues, closeModal }) => {
  const [currentLocaiton, setCurrentLocation] = useState({
    latitude: "",
    longitude: "",
  });
  const [address, setAddress] = useState("");
  const [defaultCenter, setDefaultCenter] = useState({
    lat: 31.5872,
    lng: 74.3125,
  });

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(function (position) {
      setCurrentLocation({
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
      });
      if (!currentLocaiton.latitude) {
        setDefaultCenter({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
      }
    });
  }, []);

  // get complete address from lat and longitude
  const getAddress = async (lat, lng) => {
    const result = await axios.get(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${process.env.REACT_APP_MAP_KEY}`
    );

    const address = result.data.results[0].formatted_address;

    setAddress(address);
  };

  const handleAddressSave = () => {
    setInputValues((prev) => ({
      ...prev,
      latitude: currentLocaiton.latitude,
      longitude: currentLocaiton.longitude,
      address: address,
    }));
    closeModal();
  };

  if (!currentLocaiton.latitude) {
    return <LoadingSpinner />;
  }
  return (
    <div className="flex flex-col space-y-3">
      <div className="md:h-[80vh] relative ">
        <div className="absolute top-16 left-0 z-40 bg-white text-black">
          {address && (
            <div className="w-full px-5 py-2 border-2 shadow-lg">{address}</div>
          )}
        </div>
        <Picker
          defaultLocation={defaultCenter}
          zoom={15}
          mapTypeId="roadmap"
          style={{
            height: "80vh",
          }}
          onChangeLocation={(lat, lng) => {
            setCurrentLocation({ latitude: lat, longitude: lng });
            getAddress(lat, lng);
          }}
          apiKey={`${process.env.REACT_APP_MAP_KEY}`}
        />
      </div>
      <div className="btn w-fit" onClick={handleAddressSave}>
        Save
      </div>
    </div>
  );
};

export default AddressModal;
