import React, { useEffect, useState } from "react";
import { MdDelete, MdEdit } from "react-icons/md";
import Translate from "../util/Translate";
import Api from "../util/axiosInstance";
import { useAppContext } from "../../hooks/context/tokenStore";
import CustomModal from "../reusable/CustomModal";
import EditInternalCategory from "./EditInternalCategory";

const InternalCategoriesList = ({ isOpen }) => {
  const [modelIsOpen, setModalIsOpen] = useState(false);
  const [internalCategories, setInternalCategories] = useState([]);
  const [currentCategory, setCurrentCategory] = useState({});
  const userRole = localStorage.getItem("role");

  const { userData } = useAppContext();

  useEffect(() => {
    if (userData) {
      let filter = "";
      if (userRole === "vendor") filter = `vendorId=${userData.id}`;

      if (userData?.Shop?.shopSubCategoryId)
        filter = filter.concat(
          `shopSubCategoryId=${userData?.Shop?.shopSubCategoryId}`
        );

      Api.get(`/product-internal-category?${filter}`).then((response) => {
        setInternalCategories(response.data.results);
      });
    }
  }, [isOpen]);

  const openModal = () => {
    setModalIsOpen(true);
  };
  const closeModal = () => {
    setModalIsOpen(false);
  };

  const handleCategoryDelete = (id) => {
    Api.delete(`/product-internal-category/${id}`)
      .then((response) => {
        console.log(response);
        setInternalCategories(
          internalCategories.filter((category) => category.id !== id)
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="flex flex-col space-y-5 bg-white p-5 w-fit rounded-md">
      <div className="sub-heading-text">
        <Translate>Internal Categories</Translate>
      </div>
      <div className="flex flex-col space-y-3 h-[40vh] w-[250px] overflow-y-scroll">
        {internalCategories.map((category, index) => {
          return (
            <div
              key={category.id}
              className={`flex justify-between px-3 py-2 hover:heading-bg-color hover:bg-text-color cursor-pointer rounded-md
              
              `}
              onClick={() => {
                setCurrentCategory(category);
              }}
            >
              <div>{category.name}</div>
              <div className="flex space-x-3 heading-text">
                <MdEdit
                  className="cursor-pointer "
                  color="green"
                  onClick={openModal}
                />
                <MdDelete
                  className="cursor-pointer"
                  color="red"
                  onClick={() => handleCategoryDelete(category.id)}
                />
              </div>
            </div>
          );
        })}
      </div>
      <CustomModal
        title="Edit Category"
        openModal={openModal}
        closeModal={closeModal}
        isOpen={modelIsOpen}
        setIsOpen={setModalIsOpen}
      >
        <EditInternalCategory
          currentCategory={currentCategory}
          internalCategories={internalCategories}
          setInternalCategories={setInternalCategories}
          closeModal={closeModal}
        />
      </CustomModal>
    </div>
  );
};

export default InternalCategoriesList;
