import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useState } from "react";

const AddWalletAmountModal = ({ isOpen, setIsOpen }) => {
  const [inputVavlues, setInputVavlues] = useState({
    amount: "",
    note: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInputVavlues({
      ...inputVavlues,
      [name]: value,
    });
  };

  const closeModal = () => {
    setIsOpen(false);
  };
  const handleInputSubmit = (e) => {
    e.preventDefault();
    console.log(inputVavlues);
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900 py-2 my-5 border-b border-color"
                >
                  ADD WALLET AMOUNT
                </Dialog.Title>
                <form
                  className="flex flex-col space-y-5"
                  onSubmit={handleInputSubmit}
                >
                  <div className="flex flex-col space-y-3">
                    <label htmlFor="amount">Amount</label>
                    <input
                      type="number"
                      name="amount"
                      id="amount"
                      required
                      placeholder="Enter Amount"
                      value={inputVavlues.amount}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="flex flex-col space-y-3">
                    <label htmlFor="note">Note</label>
                    <input
                      type="text"
                      name="note"
                      id="note"
                      placeholder="Enter Note"
                      value={inputVavlues.note}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="flex justify-end space-x-5">
                    <input
                      type="submit"
                      value="SUBMIT"
                      className="px-3 py-1 bg-color bg-text-color cursor-pointer"
                    />
                    <button
                      className="px-3 py-1 bg-color bg-text-color cursor-pointer"
                      onClick={closeModal}
                    >
                      CLOSE
                    </button>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default AddWalletAmountModal;
