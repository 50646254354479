import React from "react";
import Menu from "../sideMenu/Menu";
import { Link } from "react-router-dom";
import Translate from "../util/Translate";
import CouponsList from "./CouponsList";

const Coupons = () => {
  return (
    <Menu>
      <div className="flex flex-col items-start space-y-5 ">
        <h1 className="sub-heading-text py-2 border-b-2 border-b-gray-300 w-full">
          <Translate>Coupons</Translate>
        </h1>
        <div className="w-full bg-gray-200 flex justify-center items-center">
          <div className="py-2 px-4 bg-color app-text-color cursor-pointer rounded-md">
            <Translate>Coupons List</Translate>
          </div>
          <Link
            className="py-2 px-4 hover:heading-bg-color hover:bg-text-color cursor-pointer rounded-md"
            to={"/coupons/create"}
          >
            <Translate>Create Coupon</Translate>
          </Link>
        </div>
        <CouponsList />
      </div>
    </Menu>
  );
};

export default Coupons;
