import React, { useEffect, useState } from "react";
import Menu from "../sideMenu/Menu";
import Translate from "../util/Translate";
import { useParams } from "react-router-dom";
import Api from "../util/axiosInstance";
import { formatDate } from "../util/convertDate";
import Toast from "../util/CustomToast";

const ViewTicket = () => {
  const { ticketId } = useParams();
  const [ticket, setTicket] = useState({});
  const [status, setStatus] = useState("");

  const userRole = localStorage.getItem("role");

  useEffect(() => {
    Api.get(`/ticket/${ticketId}`)
      .then((res) => {
        setTicket(res.data);
        setStatus(res.data.status);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [ticketId]);

  const handleStatusUpdate = () => {
    Api.patch(`/ticket/${ticketId}`, {
      status,
    })
      .then((res) => {
        console.log(res.data);
        setTicket(res.data.result);
        Toast("success", "Status Updated Successfully");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Menu>
      <div className="flex flex-col space-y-5">
        <h1 className="sub-heading-text py-2 border-b-2 border-b-gray-300 w-full">
          <Translate>Ticket Details</Translate>
        </h1>
        <div className="flex justify-between">
          <div className="flex items-start space-x-5">
            <div className="w-12 h-12">
              <img
                src={ticket?.Customer?.imae || "/logo192.png"}
                alt="profileimg"
                className="w-full h-full object-cover rounded-full"
              />
            </div>
            <div>
              <div className="heading-text app-text-color capitalize">
                {ticket?.title}
              </div>
              <span>by</span>
              <div className="sub-heading-text">{ticket?.Customer?.name}</div>
              <div>{ticket?.City?.name}</div>
            </div>
          </div>
          {/* show date with time */}
          <div className="sub-heading-text">
            {new Date(ticket?.createdAt).toLocaleString()}
          </div>
        </div>
        <fieldset className="bg-white ">
          <legend>Status</legend>
          <div className="flex justify-between">
            <div className="sub-heading-text">{ticket?.status}</div>
            {userRole !== "customer" && (
              <div className="flex space-x-5 items-start">
                <select
                  name="status"
                  id="status"
                  onChange={(e) => setStatus(e.target.value)}
                >
                  <option value="Open">Open</option>
                  <option value="Closed">Closed</option>
                  <option value="Resolved">Resolved</option>
                </select>
                <div>
                  <button className="btn" onClick={handleStatusUpdate}>
                    Update
                  </button>
                </div>
              </div>
            )}
          </div>
        </fieldset>

        <fieldset className="bg-white">
          <legend>Description</legend>
          <div>{ticket?.description}</div>
        </fieldset>
      </div>
    </Menu>
  );
};

export default ViewTicket;
