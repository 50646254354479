import React, { useState } from "react";
import Menu from "../sideMenu/Menu";
import { useNavigate } from "react-router-dom";

const CreateUser = () => {
  const Navigate = useNavigate();

  const [userInputValues, setUserInputValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    phone: "",
    image: "",
    isActive: false,
    addressLine1: "",
    addressLine2: "",
    city: "",
    country: "",
    postalCode: "",
    latitude: "",
    longitude: "",
  });

  const handleOnChange = (e) => {
    const { name, value, type, checked } = e.target;

    console.log({ name, value, type, checked }, "handleOnChange");

    // For checkbox inputs, update the state with the checked value
    if (type === "checkbox") {
      setUserInputValues({
        ...userInputValues,
        [name]: checked,
      });
    } else {
      // For non-checkbox inputs, update the state as before
      setUserInputValues({
        ...userInputValues,
        [name]: value,
      });
    }
  };

  const hangleUserImageUpload = (e) => {
    console.log(e.target.files[0], "uploading...");
  };

  const handleSavingUser = (e) => {
    e.preventDefault();
    // console.log(userInputValues, "saving...");
  };

  return (
    <Menu>
      <div>
        <div className="mb-5 heading-text">Users</div>
        <div className="w-full flex justify-center">
          <form
            className="w-2/3 flex flex-col space-y-20 my-10"
            onClick={handleSavingUser}
          >
            <fieldset>
              <legend>User Details</legend>
              <div className="create-form">
                <div>
                  <label htmlFor="firstName">First Name</label>
                  <input
                    type="text"
                    name="firstName"
                    id="firstName"
                    placeholder="Enter First Name"
                    required
                    onChange={handleOnChange}
                    value={userInputValues.firstName}
                    min={3}
                    max={15}
                  />
                </div>
                <div>
                  <label htmlFor="lastName">Last Name</label>
                  <input
                    type="text"
                    name="lastName"
                    id="lastName"
                    placeholder="Enter Last Name"
                    required
                    value={userInputValues.lastName}
                    onChange={handleOnChange}
                    min={3}
                    max={15}
                  />
                </div>
                <div>
                  <label htmlFor="email">Email</label>
                  <input
                    type="text"
                    name="email"
                    id="email"
                    placeholder="Enter Email"
                    required
                    value={userInputValues.email}
                    onChange={handleOnChange}
                  />
                </div>
                <div>
                  <label htmlFor="password">Passwprd</label>
                  <input
                    type="password"
                    name="password"
                    id="password"
                    placeholder="Enter Password"
                    required
                    value={userInputValues.password}
                    onChange={handleOnChange}
                    min={6}
                    max={15}
                    pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}"
                    title="Must contain at least one number and one uppercase and lowercase letter, and at least 6 or more characters"
                  />
                </div>
                <div>
                  <label htmlFor="phone">Phone</label>
                  <input
                    type="tel"
                    name="phone"
                    id="phone"
                    placeholder="Enter Phone Number"
                    required
                    value={userInputValues.phone}
                    onChange={handleOnChange}
                    min={10}
                  />
                </div>
                <div>
                  <label htmlFor="image">Image</label>
                  <input
                    type="file"
                    name="image"
                    id="image"
                    onChange={hangleUserImageUpload}
                  />
                </div>
              </div>
            </fieldset>

            <fieldset>
              <legend>User (Activate/Deactivate)</legend>
              <div className="create-form">
                <div>
                  <div className="flex items-center space-x-2">
                    <input
                      type="checkbox"
                      name="isActive"
                      id="isActive"
                      value={userInputValues.isActive}
                      required
                      onChange={handleOnChange}
                    />
                    <label htmlFor="isActive">Active</label>
                  </div>
                </div>
              </div>
            </fieldset>

            <fieldset>
              <legend>ADDRESS</legend>
              <div className="create-form">
                <div>
                  <label htmlFor="addressLine1">Address Line 1</label>
                  <input
                    type="text"
                    name="addressLine1"
                    id="addressLine1"
                    placeholder="Enter Address Line 1"
                    required
                    value={userInputValues.addressLine1}
                    onChange={handleOnChange}
                    min={5}
                    max={30}
                  />
                </div>
                <div>
                  <label htmlFor="addressLine2">Address Line 2</label>
                  <input
                    type="text"
                    name="addressLine2"
                    id="addressLine2"
                    placeholder="Enter Address Line 2"
                    value={userInputValues.addressLine2}
                    onChange={handleOnChange}
                    min={5}
                    max={30}
                  />
                </div>
                <div>
                  <label htmlFor="city">City</label>
                  <input
                    type="text"
                    name="city"
                    id="city"
                    placeholder="Enter City"
                    value={userInputValues.city}
                    onChange={handleOnChange}
                    min={3}
                    max={15}
                  />
                </div>
                <div>
                  <label htmlFor="country">Country</label>
                  <input
                    type="text"
                    name="country"
                    id="country"
                    placeholder="Enter Country"
                    value={userInputValues.country}
                    onChange={handleOnChange}
                    min={3}
                    max={15}
                  />
                </div>
                <div>
                  <label htmlFor="postalCode">Postal Code</label>
                  <input
                    type="text"
                    name="postalCode"
                    id="postalCode"
                    placeholder="Enter Postal Code"
                    value={userInputValues.postalCode}
                    onChange={handleOnChange}
                    min={2}
                    max={15}
                  />
                </div>
                <div>
                  <label htmlFor="latitude">Latitude</label>
                  <input
                    type="text"
                    name="latitude"
                    id="latitude"
                    placeholder="Enter Latitude"
                    value={userInputValues.latitude}
                    onChange={handleOnChange}
                    min={3}
                    max={15}
                  />
                </div>
                <div>
                  <label htmlFor="longitude">Longitude</label>
                  <input
                    type="text"
                    name="longitude"
                    id="longitude"
                    placeholder="Enter Longitude"
                    value={userInputValues.longitude}
                    onChange={handleOnChange}
                    min={3}
                    max={15}
                  />
                </div>
              </div>
            </fieldset>

            <div className="flex justify-center space-x-5">
              <input
                type="submit"
                className="bg-color bg-text-color px-5 py-2 rounded-md cursor-pointer"
                value="Save"
              ></input>
              <div
                className="bg-color bg-text-color px-5 py-2 rounded-md cursor-pointer"
                onClick={() => {
                  Navigate(-1);
                }}
              >
                Back
              </div>
            </div>
          </form>
        </div>
      </div>
    </Menu>
  );
};

export default CreateUser;
