import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ShopRow from "./ShopRow";
import Api from "../util/axiosInstance";
import LoadingSpinner from "../util/LoadingSpinner";
import Translate from "../util/Translate";
import { URL } from "../util/authorization/authorizationURL";

const Shop = {
  name: "John",
  image: "https://picsum.photos/800/800",
  createdAt: "2021-01-01",
  productCount: 4,
};

const ShopsList = ({ shopList, setShopList, setLoading }) => {
  const handleShopDelete = (id) => {
    setLoading(true);
    Api.delete(`/shop/${id}`)
      .then((res) => {
        setShopList(shopList.filter((shop) => shop.id !== id));
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="w-full">
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <Translate>Name</Translate>
            </TableCell>
            <TableCell>
              <Translate>Image</Translate>
            </TableCell>
            <TableCell>
              <Translate>Created At</Translate>
            </TableCell>
            <TableCell>
              <Translate>Product Count</Translate>
            </TableCell>
            <TableCell>
              <Translate>Actions</Translate>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {shopList.map((shop) => {
            return (
              <>
                {/* {shop.Shop && ( */}
                <ShopRow
                  key={shop.id}
                  shop={shop?.Shop ? shop.Shop : shop}
                  handleShopDelete={handleShopDelete}
                />
                {/* // )} */}
              </>
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
};

export default ShopsList;
