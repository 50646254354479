import React, { useEffect, useState } from "react";
import Menu from "../sideMenu/Menu";
import Translate from "../util/Translate";
import Api from "../util/axiosInstance";
import { useNavigate } from "react-router-dom";
import { URL } from "../util/authorization/authorizationURL";
import { useAppContext } from "../../hooks/context/tokenStore";

const CreateCoupon = () => {
  const [inputValues, setInputValues] = useState({
    code: "",
    discount: "",
    type: "",
    expiration: "",
  });
  const { userData } = useAppContext();

  const Navigate = useNavigate();
  const [shopId, setShopId] = useState("");

  const handleInputChange = (e) => {
    setInputValues({ ...inputValues, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const userRole = localStorage.getItem("role");
    const filter =
      userRole === "vendor"
        ? {
            shopId: shopId,
          }
        : userRole === "professional"
        ? {
            professionalId: userData.id,
          }
        : {
            adminId: userData.id,
          };
    const url = URL.coupon.add[userRole];

    Api.post(url, {
      ...inputValues,
      ...filter,
    })
      .then((res) => {
        setInputValues({
          code: "",
          discount: "",
          type: "",
          expiration: "",
        });
        Navigate("/coupons");
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    if (userData?.id) {
      setShopId(userData?.Shop?.id);
      // Api.get(`/vendor/${userData?.role === "vendor" ? userData.id : ""}`)
      //   .then((res) => {
      //     if (res.data.results) {
      //       // setShopId(res.data.results?.[0]?.id);
      //     } else setShopId(res.data.id);
      //   })
      //   .catch((err) => console.log(err));
    }
  }, [userData]);

  return (
    <Menu>
      <div>
        <div>
          <h1 className="sub-heading-text py-2 border-b-2 border-b-gray-300 w-full">
            <Translate>Create Coupon</Translate>
          </h1>
        </div>
        <div className="w-full flex justify-center">
          <form
            className="w-2/3 flex flex-col space-y-20 my-10"
            onSubmit={handleSubmit}
          >
            <fieldset>
              <legend>
                <Translate>Coupon Details</Translate>
              </legend>
              <div className="px-5 my-3 flex flex-col space-y-10">
                <div className="input-group">
                  <label htmlFor="code">
                    <Translate>Coupon Code</Translate>
                  </label>
                  <input
                    type="text"
                    name="code"
                    id="code"
                    value={inputValues.code}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="discount">
                    <Translate>Discount</Translate>
                  </label>
                  <input
                    type="number"
                    name="discount"
                    id="discount"
                    value={inputValues.discount}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="type">
                    <Translate>Type</Translate>
                  </label>
                  <select
                    name="type"
                    id="type"
                    value={inputValues.type}
                    onChange={handleInputChange}
                  >
                    <option value="">
                      <Translate>Select Type</Translate>
                    </option>
                    <option value="PERCENTAGE">
                      <Translate>Percentage</Translate>
                    </option>
                    <option value="FIXED">
                      <Translate>Fixed</Translate>
                    </option>
                  </select>
                </div>
                <div className="input-group">
                  <label htmlFor="expiration">
                    <Translate>Expiration</Translate>
                  </label>
                  <input
                    type="date"
                    name="expiration"
                    id="expiration"
                    className="px-3 py-2 border border-gray-300 rounded-md"
                    value={inputValues.expiration}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
            </fieldset>
            <div className="flex justify-center space-x-5">
              <button
                className="heading-bg-color bg-text-color px-5 py-2 rounded-md cursor-pointer"
                type="submit"
              >
                <Translate>Save</Translate>
              </button>
              <div className="heading-bg-color bg-text-color px-5 py-2 rounded-md cursor-pointer">
                <Translate>Back</Translate>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Menu>
  );
};

export default CreateCoupon;
