import React, { useEffect, useState } from "react";
import Menu from "../sideMenu/Menu";
import Translate from "../util/Translate";
import { useAppContext } from "../../hooks/context/tokenStore";
import Api from "../util/axiosInstance";
import TagsInput from "react-tagsinput";
import { TiDeleteOutline } from "react-icons/ti";
import Toast from "../util/CustomToast";
import LoadingSpinner from "../util/LoadingSpinner";
import { Link, useNavigate } from "react-router-dom";
import { cloudinaryUpload } from "../../utils/cloudinaryUpload";

const ProfessionalProfile = () => {
  const { userData } = useAppContext();
  const [loading, setLoading] = useState(false);
  const [cities, setCities] = useState();
  const Navigate = useNavigate();
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [inputValues, setInputValues] = useState({
    name: "",
    email: "",
    profession: "",
    aboutMe: "",
    graduationYear: "",
    skills: [],
    specializations: [],
    workExperiences: [],
    certifications: [],
    cityId: "",
    cityArea: "",
    whatsapp: "",
    phone: "",
    benefitForBipeers: [],
    keywords: [],
    photoLogo: "",
    photoBanner: "",
    professionalSubCategoryId: "",
  });
  useEffect(() => {
    Api.get("/city").then((res) => {
      setCities(res.data.results);
    });
    Api.get("/professional-category")
      .then((res) => {
        setCategories(res.data.results);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    Api.get(`/professional/${userData.id}`).then((res) => {
      setInputValues({
        name: res.data.name,
        email: res.data.email,
        profession: res.data.profession,
        aboutMe: res.data.aboutMe,
        graduationYear: res.data.graduationYear,
        skills: res.data.skills,
        specializations: res.data.specializations,
        workExperiences: res.data.workExperiences,
        certifications: res.data.certifications,
        cityId: res.data.cityId,
        cityArea: res.data.cityArea,
        whatsapp: res.data.whatsapp,
        phone: res.data.phone,
        photoLogo: res.data.photoLogo,
        photoBanner: res.data.photoBanner,
        benefitForBipeers: res.data.benefitForBipeers,
        keywords: res.data.keywords,
        professionalSubCategoryId: res?.data?.ProfessionalSubCategory?.id || "",
      });
      setSelectedCategory(res.data?.ProfessionalSubCategory?.id);
    });
  }, [userData]);

  const handleInputChange = (e) => {
    setInputValues({ ...inputValues, [e.target.name]: e.target.value });
  };
  const handleFileChange = async (e) => {
    if (!e.target.files?.length) return;
    const fileUpload = await cloudinaryUpload(e.target.files[0]);
    setInputValues({ ...inputValues, [e.target.name]: fileUpload });
  };
  const handleSubmitValues = (e) => {
    e.preventDefault();
    setLoading(true);

    const filteredValues = Object.fromEntries(
      Object.entries(inputValues).filter(([key, value]) => {
        return value !== "" && value !== null;
      })
    );

    Api.patch(`/professional/${userData.id}`, filteredValues)
      .then((res) => {
        console.log(res.data);
        setInputValues({
          ...res.data.data,
        });
        Navigate(-1);
        Toast("success", "Profile Updated Successfully");
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <Menu>
      <div>
        <div className="mb-5 heading-text">
          <Translate>Profile</Translate>
        </div>
        <div className="flex justify-center items-center space-x-4">
          <Link to={"/user/resetpassword"} className="btn">
            Password Reset
          </Link>
          <Link to={"/user/sociallinks"} className="btn">
            Social Links
          </Link>
        </div>
        <div className="w-full flex justify-center">
          <form
            className="w-2/3 flex flex-col space-y-20 my-10"
            onSubmit={handleSubmitValues}
          >
            <fieldset>
              <legend>
                <Translate>Profile Details</Translate>
              </legend>
              <div className="create-form">
                <div>
                  <label htmlFor="name">
                    <Translate>Name</Translate>
                  </label>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    value={inputValues.name}
                    onChange={handleInputChange}
                  />
                </div>
                <div>
                  <label htmlFor="email">
                    <Translate>Email</Translate>
                  </label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    value={inputValues.email}
                    onChange={handleInputChange}
                  />
                </div>

                <div>
                  <label htmlFor="profession">
                    <Translate>Profession</Translate>
                  </label>
                  <input
                    type="text"
                    name="profession"
                    id="profession"
                    value={inputValues.profession}
                    onChange={handleInputChange}
                  />
                </div>
                <div>
                  <label htmlFor="aboutMe">
                    <Translate>About Me</Translate>
                  </label>
                  <textarea
                    name="aboutMe"
                    id="aboutMe"
                    value={inputValues.aboutMe}
                    onChange={handleInputChange}
                  />
                </div>
                <div>
                  <label htmlFor="graduationYear">
                    <Translate>Graduation Year</Translate>
                  </label>
                  <input
                    type="text"
                    name="graduationYear"
                    id="graduationYear"
                    value={inputValues.graduationYear}
                    onChange={handleInputChange}
                  />
                </div>
                {/* skills */}
                <div>
                  <label htmlFor="skills">
                    <Translate>Skills</Translate>
                  </label>
                  {/* tags input */}
                  <TagsInput
                    value={inputValues.skills || []}
                    onChange={(skills) =>
                      setInputValues({ ...inputValues, skills })
                    }
                  />
                </div>
                {/* specializations */}
                <div>
                  <label htmlFor="specializations">
                    <Translate>Specializations</Translate>
                  </label>
                  {/* tags input */}
                  <TagsInput
                    value={inputValues.specializations || []}
                    onChange={(specializations) =>
                      setInputValues({ ...inputValues, specializations })
                    }
                  />
                </div>
                {/* work experiences */}
                <div>
                  <label htmlFor="workExperiences">
                    <Translate>Work Experiences</Translate>
                  </label>
                  {/* tags input */}
                  <TagsInput
                    value={inputValues.workExperiences || []}
                    onChange={(workExperiences) =>
                      setInputValues({ ...inputValues, workExperiences })
                    }
                  />
                </div>
                {/* certifications */}
                <div>
                  <label htmlFor="certifications">
                    <Translate>Certifications</Translate>
                  </label>
                  {/* tags input */}
                  <TagsInput
                    value={inputValues.certifications || []}
                    onChange={(certifications) =>
                      setInputValues({ ...inputValues, certifications })
                    }
                  />
                </div>
                {/* city */}
                <div>
                  <label htmlFor="cityId">
                    <Translate>City</Translate>
                  </label>
                  <select
                    name="cityId"
                    id="cityId"
                    value={inputValues.cityId}
                    onChange={handleInputChange}
                  >
                    {cities &&
                      cities.map((city) => (
                        <option key={city.id} value={city.id}>
                          {city.name}
                        </option>
                      ))}
                  </select>
                </div>
                <div>
                  <label htmlFor="cityArea">
                    <Translate>City Area</Translate>
                  </label>
                  <input
                    type="text"
                    name="cityArea"
                    id="cityArea"
                    value={inputValues.cityArea}
                    onChange={handleInputChange}
                  />
                </div>
                <div>
                  <label htmlFor="whatsapp">
                    <Translate>Whatsapp</Translate>
                  </label>
                  <input
                    type="text"
                    name="whatsapp"
                    id="whatsapp"
                    value={inputValues.whatsapp}
                    onChange={handleInputChange}
                  />
                </div>
                <div>
                  <label htmlFor="phone">
                    <Translate>Phone</Translate>
                  </label>
                  <input
                    type="text"
                    name="phone"
                    id="phone"
                    value={inputValues.phone}
                    onChange={handleInputChange}
                  />
                </div>
                <div>
                  <label htmlFor="benefitForBipeers">
                    <Translate>Benefit For Bipeers</Translate>
                  </label>
                  {/* tags input */}
                  <TagsInput
                    value={inputValues.benefitForBipeers || []}
                    onChange={(benefitForBipeers) =>
                      setInputValues({ ...inputValues, benefitForBipeers })
                    }
                  />
                </div>
                <div>
                  <label htmlFor="keywords">
                    <Translate>Keywords</Translate>
                  </label>
                  {/* tags input */}
                  <TagsInput
                    value={inputValues.keywords || []}
                    onChange={(keywords) =>
                      setInputValues({ ...inputValues, keywords })
                    }
                  />
                </div>

                {/* category */}
                <div>
                  <label htmlFor="category">
                    <Translate>Category</Translate>
                  </label>
                  <select
                    name="category"
                    id="category"
                    value={selectedCategory}
                    onChange={(e) => {
                      setSelectedCategory(e.target.value);
                    }}
                  >
                    <option value="">
                      <Translate>Select Category</Translate>
                    </option>
                    {categories.map((category) => {
                      return (
                        <option key={category.id} value={category.id}>
                          {category.name}
                        </option>
                      );
                    })}
                  </select>
                </div>

                {/* sub category */}
                {selectedCategory && (
                  <div>
                    <label htmlFor="professionalSubCategoryId">
                      <Translate>Sub Category</Translate>
                    </label>
                    <select
                      name="professionalSubCategoryId"
                      id="professionalSubCategoryId"
                      value={inputValues.professionalSubCategoryId}
                      onChange={handleInputChange}
                    >
                      <option value="">
                        <Translate>Select Sub Category</Translate>
                      </option>
                      {categories
                        .find((c) => c.id === selectedCategory)
                        ?.ProfessionalSubCategory.map((subCategory) => {
                          return (
                            <option key={subCategory.id} value={subCategory.id}>
                              {subCategory.name}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                )}
              </div>
            </fieldset>

            <fieldset>
              <legend>
                <Translate>Profile Photos</Translate>
              </legend>
              <div className="flex h-40">
                {inputValues.photoLogo ? (
                  <div className="w-1/2 h-auto relative">
                    <img
                      src={inputValues.photoLogo}
                      alt="photoLogo"
                      className="w-full h-full object-contain"
                    />
                    <div
                      className="absolute -top-5 right-0 cursor-pointer"
                      onClick={() => {
                        setInputValues({ ...inputValues, photoLogo: "" });
                      }}
                    >
                      <TiDeleteOutline size={20} color="red" />
                    </div>
                  </div>
                ) : (
                  <div>
                    <label htmlFor="photoLogo">
                      <Translate>Photo Logo</Translate>
                    </label>
                    <input
                      type="file"
                      name="photoLogo"
                      id="photoLogo"
                      onChange={handleFileChange}
                    />
                  </div>
                )}
                {inputValues.photoBanner ? (
                  <div className="w-1/2 h-auto relative">
                    <img
                      src={inputValues.photoBanner}
                      alt="photoBanner"
                      className="w-full h-full object-contain"
                    />
                    <div
                      className="absolute -top-5 right-0 cursor-pointer"
                      onClick={() => {
                        setInputValues({ ...inputValues, photoBanner: "" });
                      }}
                    >
                      <TiDeleteOutline size={20} color="red" />
                    </div>
                  </div>
                ) : (
                  <div>
                    <label htmlFor="photoBanner">
                      <Translate>Photo Banner</Translate>
                    </label>
                    <input
                      type="file"
                      name="photoBanner"
                      id="photoBanner"
                      onChange={handleFileChange}
                    />
                  </div>
                )}
              </div>
            </fieldset>
            <div className="flex justify-center space-x-5">
              <button
                className="heading-bg-color bg-text-color px-5 py-2 rounded-md cursor-pointer"
                type="submit"
              >
                <Translate>Save</Translate>
              </button>
              <div className="heading-bg-color bg-text-color px-5 py-2 rounded-md cursor-pointer">
                <Translate>Back</Translate>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Menu>
  );
};

export default ProfessionalProfile;
