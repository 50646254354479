import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import Translate from "../util/Translate";

const products = [
  {
    id: 1,
    preview: "https://picsum.photos/800/800",
    name: "Product 1",
    price: 100,
    sold: 10,
    revenue: 1000,
  },
  {
    id: 2,
    preview: "https://picsum.photos/800/800",

    name: "Product 2",
    price: 200,
    sold: 20,
    revenue: 4000,
  },
  {
    id: 3,
    preview: "https://picsum.photos/800/800",

    name: "Product 3",
    price: 300,
    sold: 30,
    revenue: 9000,
  },
  {
    id: 4,
    preview: "https://picsum.photos/800/800",

    name: "Product 4",
    price: 400,
    sold: 40,
    revenue: 16000,
  },
  {
    id: 5,
    preview: "https://picsum.photos/800/800",

    name: "Product 5",
    price: 500,
    sold: 50,
    revenue: 25000,
  },
];

const ProductTable = ({ topProducts }) => {
  return (
    <div>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <Translate>Preview</Translate>
            </TableCell>
            <TableCell>
              <Translate>Product</Translate>
            </TableCell>
            <TableCell>
              <Translate>Price</Translate>
            </TableCell>
            <TableCell>
              <Translate>Sold</Translate>
            </TableCell>
            {/* <TableCell>
              <Translate>Revenue</Translate>
            </TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {topProducts.map((product) => (
            <TableRow key={product.id}>
              <TableCell>
                <img
                  src={product.mainImage}
                  alt={product.name}
                  className="h-10 w-10 rounded-lg"
                />
              </TableCell>
              <TableCell>{product.name}</TableCell>
              <TableCell>{product.price}</TableCell>
              <TableCell>{product.totalSoldQuantity}</TableCell>
              {/* <TableCell>{product.revenue}</TableCell> */}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default ProductTable;
