import React, { useState } from "react";
import Translate from "../../util/Translate";

const CityList = ({ cities, currentCity, setCurrentCity }) => {
  return (
    <div className="flex flex-col space-y-5 bg-white p-5 w-fit rounded-md">
      <div className="sub-heading-text">
        <Translate>Cities</Translate>
      </div>
      <div className="flex flex-col space-y-3 h-[40vh] w-[250px] overflow-y-scroll">
        {cities.map((city, index) => {
          return (
            <div
              key={city.id}
              className={`flex space-x-3 px-3 py-2 hover:heading-bg-color hover:bg-text-color cursor-pointer rounded-md
              ${
                currentCity.id === city.id
                  ? "heading-bg-color bg-text-color"
                  : ""
              }
              `}
              onClick={() => {
                setCurrentCity(city);
              }}
            >
              <div>{index + 1}.</div>
              <div>{city.name}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CityList;
