import React, { useEffect, useState } from "react";
import RangeFilter from "./RangeFilter";
import { Tab } from "@headlessui/react";
import ProductTable from "./ProductTable";
import VendorsTable from "./VendorsTable";
import Translate from "../util/Translate";
import useUser from "../../hooks/useUser";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const VendorsSelling = ({ topProducts, vendorsTable }) => {
  const { user } = useUser();
  const [userRole, setUserRole] = useState("");

  useEffect(() => {
    setUserRole(localStorage.getItem("role"));
  }, [user]);

  if (userRole === "vendor") {
    return (
      <div className="bg-white flex flex-col space-y-5 rounded-lg w-full p-3">
        <div className="flex w-full justify-between items-center">
          <div className="flex items-center space-x-2">
            <div className="heading-text">
              <Translate>Top Selling</Translate>
            </div>
            <div>|</div>
            <div>
              <Translate>Monthly</Translate>
            </div>
          </div>
          <div>
            <RangeFilter />
          </div>
        </div>
        <div>
          <Tab.Group>
            <Tab.List className="flex space-x-1 rounded-xl bg-blue-900/20 p-1">
              <Tab
                className={({ selected }) =>
                  classNames(
                    "w-full rounded-lg py-2.5 text-sm font-medium leading-5",
                    "ring-white/60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2",
                    selected
                      ? "bg-white app-text-color shadow"
                      : "text-white hover:bg-white/[0.12] hover:app-text-color"
                  )
                }
              >
                <Translate>Products</Translate>
              </Tab>
            </Tab.List>
            <Tab.Panels>
              <Tab.Panel className="my-5">
                <ProductTable topProducts={topProducts} />
              </Tab.Panel>
              <Tab.Panel className="my-5">
                <VendorsTable vendorsTable={vendorsTable} />
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </div>
      </div>
    );
  }
  return (
    <div className="bg-white flex flex-col space-y-5 rounded-lg w-[530px] p-3">
      <div className="flex w-full justify-between items-center">
        <div className="flex items-center space-x-2">
          <div className="heading-text">
            <Translate>Top Selling</Translate>
          </div>
          <div>|</div>
          <div>
            <Translate>Today</Translate>
          </div>
        </div>
        <div>
          {/* <RangeFilter /> */}
        </div>
      </div>
      <div>
        <Tab.Group>
          <Tab.List className="flex space-x-1 rounded-xl bg-blue-900/20 p-1">
            <Tab
              className={({ selected }) =>
                classNames(
                  "w-full rounded-lg py-2.5 text-sm font-medium leading-5",
                  "ring-white/60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2",
                  selected
                    ? "bg-white app-text-color shadow"
                    : "text-white hover:bg-white/[0.12] hover:app-text-color"
                )
              }
            >
              <Translate>Products</Translate>
            </Tab>
            {user?.role === "superadmin" && (
              <Tab
                className={({ selected }) =>
                  classNames(
                    "w-full rounded-lg py-2.5 text-sm font-medium leading-5",
                    "ring-white/60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2",
                    selected
                      ? "bg-white text-blue-700 shadow"
                      : "text-white hover:bg-white/[0.12] hover:app-text-color"
                  )
                }
              >
                <Translate>Vendors</Translate>
              </Tab>
            )}
          </Tab.List>
          <Tab.Panels>
            <Tab.Panel className="my-5">
              <ProductTable topProducts={topProducts} />
            </Tab.Panel>
            <Tab.Panel className="my-5">
              <VendorsTable vendorsTable={vendorsTable} />
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </div>
    </div>
  );
};

export default VendorsSelling;
