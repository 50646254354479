import React, { useState } from "react";
import Menu from "../sideMenu/Menu";
import Translate from "../util/Translate";
import { useTranslation } from "react-i18next";
import Api from "../util/axiosInstance";
import { useNavigate } from "react-router-dom";

const CreateUnit = () => {
  const [inputValues, setInputValues] = useState({
    name: "",
  });
  const Navigate = useNavigate();
  const { t } = useTranslation();
  const handleInputChange = (e) => {
    setInputValues({ ...inputValues, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    Api.post("/unit", inputValues)
      .then((res) => {
        console.log(res.data);
        Navigate(-1);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Menu>
      <div>
        <div className="mb-5 heading-text">
          <Translate>Create Unit</Translate>
        </div>
        <div className="w-full flex justify-center">
          <form
            className="w-2/3 flex flex-col space-y-20 my-10"
            onSubmit={handleSubmit}
          >
            <fieldset>
              <legend>
                <Translate>Unit Details</Translate>
              </legend>
              <div className="px-5 my-3 flex flex-col space-y-10">
                <div className="input-group">
                  <label htmlFor="name">
                    <Translate>Name</Translate>
                  </label>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    placeholder={t("Name")}
                    value={inputValues.name}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
            </fieldset>

            <div className="flex justify-center space-x-5">
              <button
                className="heading-bg-color bg-text-color px-5 py-2 rounded-md cursor-pointer"
                type="submit"
              >
                <Translate>Save</Translate>
              </button>
              <div className="heading-bg-color bg-text-color px-5 py-2 rounded-md cursor-pointer">
                <Translate>Back</Translate>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Menu>
  );
};

export default CreateUnit;
