import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Api from "../util/axiosInstance";
import ServiceRow from "./ServiceRow";
import { URL } from "../util/authorization/authorizationURL";

const Service = {
  title: "John",
  price: "125",
  createdAt: "2021-01-01",
  description:
    "lorem ipsum dolor sit amet, consectetur adip incididunt ut labore et dolore magna aliqua et aliquip ex ea commodo",
};

const ServicesList = ({ setLoading, services, setServices }) => {
  const handleDelete = (id) => {
    setLoading(true);
    Api.delete("/service/" + id)
      .then((res) => {
        console.log(res.data);
        setServices(services.filter((service) => service.id !== id));
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleActivateToggle = (adminId) => {
    setLoading(true);
    Api.post(`/professional/getallservices/toggleStatus/${adminId}`)
      .then((res) => {
        const updatedAdmins = services.map((admin) => {
          if (admin.id === adminId) {
            admin.active = !admin.active;
          }
          return admin;
        });
        setServices(updatedAdmins);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <div className="w-full">
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>All</TableCell>
            <TableCell>Title</TableCell>
            <TableCell>Price</TableCell>
            <TableCell>Date</TableCell>
            <TableCell>Active</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {services.map((service) => {
            return (
              <ServiceRow
                handleActivateToggle={handleActivateToggle}
                service={service}
                handleDelete={handleDelete}
              />
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
};

export default ServicesList;
