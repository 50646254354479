import { Avatar, Switch, TableCell, TableRow } from "@mui/material";
import React, { useState } from "react";
import { TiEye } from "react-icons/ti";
import { RiEditCircleFill } from "react-icons/ri";
import { MdDelete } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { formatDate } from "../util/convertDate";
import ConfirmationModal from "../util/ConfirmationModal";

const label = { inputProps: { "aria-label": "Switch demo" } };

const UserRow = ({ user, handleDeleteCustomer, handleEditCustomer }) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <TableRow>
      <TableCell>
        <div className="w-5 h-5 border cursor-pointer"></div>
      </TableCell>

      <TableCell>
        <div className="w-12">
          <Avatar src={user.profileImg} alt="user_profile" />
          {/* <img
            src={user.image}
            alt="user_profile"
            className="object-cover rounded-md w-full h-full"
          /> */}
        </div>
      </TableCell>
      <TableCell>{user.name}</TableCell>

      <TableCell>{user.email}</TableCell>
      <TableCell>{user?.City?.name}</TableCell>
      <TableCell>{formatDate(user.createdAt)}</TableCell>
      <TableCell>
        <Switch
          onChange={() => handleEditCustomer(user?.id)}
          defaultChecked={user.active}
          color="success"
        />
      </TableCell>
      <TableCell>
        <div className="heading-text flex items-center space-x-2">
          {/* <div
            className="text-gray-700 cursor-pointer"
            onClick={() => {
              navigate("/users/view/aklsd3497");
            }}
          >
            <TiEye />
          </div> */}
          {/* <div className="text-green-700 cursor-pointer">
            <RiEditCircleFill />
          </div> */}
          <div
            onClick={() => {
              // handleDeleteCustomer(user.id);
              setIsOpen(true);
            }}
          >
            <MdDelete className="text-red-700 cursor-pointer" />
          </div>
        </div>
      </TableCell>
      <ConfirmationModal
        deleteFuction={handleDeleteCustomer}
        id={user.id}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
    </TableRow>
  );
};

export default UserRow;
