import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSSR } from "react-i18next";
import Api from "../util/axiosInstance";
import LoadingSpinner from "../util/LoadingSpinner";
import UnitRow from "./UnitRow";
import Translate from "../util/Translate";

const Category = {
  name: "electronics",
  image: "https://picsum.photos/800/800",
  products: 23,
  publish: true,
};

const UnitsList = ({units,setLoading,setUnits}) => {
 

  const handleDeleteUnit = (id) => {
    setLoading(true);
    Api.delete(`/unit/${id}`)
      .then((res) => {
        console.log(res.data);
        setUnits(units.filter((unit) => unit.id !== id));
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };



  return (
    <div className="w-full">
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>All</TableCell>
            <TableCell>
              <Translate>Name</Translate>
            </TableCell>
            <TableCell>
              <Translate>Actions</Translate>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {units.map((unit) => {
            return <UnitRow unit={unit} handleDeleteUnit={handleDeleteUnit} />;
          })}
        </TableBody>
      </Table>
    </div>
  );
};

export default UnitsList;
