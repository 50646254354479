import { Switch, TableCell, TableRow } from "@mui/material";
import React from "react";
import { MdDelete } from "react-icons/md";
import { RiEditCircleFill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import ConfirmationModal from "../util/ConfirmationModal";

const label = { inputProps: { "aria-label": "Switch demo" } };

const UnitRow = ({ unit, handleDeleteUnit }) => {
  const Navigate = useNavigate();
  const [isOpen, setIsOpen] = React.useState(false);
  return (
    <TableRow>
      <TableCell>
        <div className="w-5 h-5 border border-black cursor-pointer"></div>
      </TableCell>

      <TableCell>{unit.name}</TableCell>

      <TableCell>
        <div className="heading-text flex items-center space-x-2">
          <div
            className="text-green-700 cursor-pointer"
            onClick={() => {
              Navigate(`/units/edit/${unit.id}`);
            }}
          >
            <RiEditCircleFill />
          </div>
          <div
            onClick={() => {
              // handleDeleteUnit(unit.id);
              setIsOpen(true);
            }}
          >
            <MdDelete className="text-red-700 cursor-pointer" />
          </div>
        </div>
      </TableCell>
      <ConfirmationModal
        deleteFuction={handleDeleteUnit}
        name={unit.name}
        id={unit.id}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
    </TableRow>
  );
};

export default UnitRow;
