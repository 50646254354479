import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Translate from "../../util/Translate";
import PricingModal from "../../util/PricingModal";
import Api from "../../util/axiosInstance";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "../../util/LoadingSpinner";
import Toast from "../../util/CustomToast";
import { cloudinaryUpload } from "../../../utils/cloudinaryUpload";

const ProfessionalSignup = () => {
  const [loading, setLoading] = useState(false);
  const [cities, setCities] = useState([]);
  const [categories, setCategories] = useState([]);
  const [cityAreas, setCityAreas] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");

  let [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    Api.get("/city").then((res) => {
      setCities(res.data.results);
    });
    Api.get("/professional-category")
      .then((res) => {
        setCategories(res.data.results);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const [inputValues, setInputValues] = useState({
    name: "",
    email: "",
    password: "",
    profession: "",
    aboutMe: "",
    graduationYear: "",
    skills: "",
    specializations: "",
    workExperiences: "",
    certifications: "",
    cityId: "",
    cityArea: "",
    whatsapp: "",
    phone: "",
    photoLogo: null,
    photoBanner: null,
    benefitForBipeers: "",
    keywords: "",
    subCategoryId: "",
  });

  const Navigate = useNavigate();

  const handleInputChange = (e) => {
    setInputValues({ ...inputValues, [e.target.name]: e.target.value });
  };
  const handleFileChange = async (e) => {
    const url = await cloudinaryUpload(e.target.files[0]);
    setInputValues({ ...inputValues, [e.target.name]: url });
  };

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  const handleLogin = (e) => {
    e.preventDefault();
    const skillsList = inputValues.skills.split(",");
    const specializationsList = inputValues.specializations.split(",");
    const workExperiencesList = inputValues.workExperiences.split(",");
    const certificationsList = inputValues.certifications.split(",");
    const benefitForBipeersList = inputValues.benefitForBipeers.split(",");
    const keywordsList = inputValues.keywords.split(",");

    const requestBody = {
      name: inputValues.name,
      email: inputValues.email,
      password: inputValues.password,
      profession: inputValues.profession,
      aboutMe: inputValues.aboutMe,
      graduationYear: inputValues.graduationYear,
      subCategoryId: inputValues.subCategoryId,
      skills: skillsList,
      specializations: specializationsList,
      workExperiences: workExperiencesList,
      certifications: certificationsList,
      cityId: inputValues.cityId,
      cityArea: inputValues.cityArea,
      whatsapp: inputValues.whatsapp,
      phone: inputValues.phone,
      benefitForBipeers: benefitForBipeersList,
      keywords: keywordsList,
      photoLogo: inputValues.photoLogo,
      photoBanner: inputValues.photoBanner,
    };

    setLoading(true);
    Api.post("/professional", requestBody)
      .then((res) => {
        Navigate("/login/professionaladmin");
      })
      .catch((err) => {
        console.log(err);
        Toast("error", t("Invalid Credentials/Network Error"));
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const { t } = useTranslation();

  useEffect(() => {
    if (!!inputValues.cityId) {
      Api.get(`/city-area?cityId=${inputValues.cityId}`).then((res) => {
        setCityAreas(res.data?.results || []);
      });
    } else {
      setCityAreas([]);
    }
  }, [inputValues.cityId]);

  if (loading) {
    return <LoadingSpinner />;
  }
  return (
    <div className="relative w-full flex justify-center items-center app-bg-color">
      <div
        className="absolute inset-0 w-screen h-screen"
        // style={{
        //   backgroundImage: "url(/login-bg.jpg)",
        //   backgroundSize: "cover",
        //   backgroundPosition: "center",
        //   backgroundRepeat: "no-repeat",
        //   filter: "blur(5px)",
        //   opacity: "0.6",
        //   zIndex: "0",
        // }}
      ></div>
      <div className="flex flex-col justify-center items-center space-y-8 w-[90%] md:w-2/3  z-10">
        <div className="py-2 px-10  rounded-md flex flex-col space-y-2 justify-center items-center">
          <div className="text-5xl app-text-color">Bipeers</div>
          <div>
            <Translate>Professional Signup</Translate>
          </div>
        </div>
        <div className="p-5 shadow-lg w-full border">
          <form
            className="flex flex-col space-y-6 sub-heading-text"
            onSubmit={handleLogin}
          >
            <div className="self-center app-text-color">
              <Translate>SIGNUP</Translate>
            </div>
            <div className="flex flex-wrap items-center justify-center">
              {/* name */}
              <div className="input-group w-[80%] md:w-2/5  m-2">
                <label htmlFor="name">
                  <Translate>Name</Translate>
                </label>
                <input
                  type="text"
                  name="name"
                  id="name"
                  value={inputValues.name}
                  onChange={handleInputChange}
                  placeholder={t("Name")}
                />
              </div>
              {/* email */}
              <div className="input-group w-[80%] md:w-2/5  m-2">
                <label htmlFor="email">
                  <Translate>Email</Translate>
                </label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  value={inputValues.email}
                  onChange={handleInputChange}
                  placeholder={t("Email")}
                />
              </div>
              {/* password */}
              <div className="input-group w-[80%] md:w-2/5  m-2">
                <label htmlFor="password">
                  <Translate>Password</Translate>
                </label>
                <input
                  type="password"
                  name="password"
                  id="password"
                  value={inputValues.password}
                  onChange={handleInputChange}
                  placeholder={t("Password")}
                />
              </div>
              {/* profession */}
              <div className="input-group w-[80%] md:w-2/5  m-2">
                <label htmlFor="profession">
                  <Translate>Profession</Translate>
                </label>
                <select
                  name="profession"
                  id="profession"
                  value={inputValues.profession}
                  onChange={handleInputChange}
                >
                  <option value="">Select Profession</option>
                  <option value="career_professional">
                    Career Professional
                  </option>
                  <option value="profession_by_trade">
                    Profession By Trade
                  </option>
                </select>
              </div>
              {/* about me */}
              <div className="input-group w-[80%] md:w-2/5  m-2">
                <label htmlFor="aboutMe">
                  <Translate>About Me</Translate>
                </label>
                <textarea
                  name="aboutMe"
                  id="aboutMe"
                  value={inputValues.aboutMe}
                  onChange={handleInputChange}
                  placeholder={t("About Me")}
                ></textarea>
              </div>
              {/* graduation year */}
              <div className="input-group w-[80%] md:w-2/5  m-2">
                <label htmlFor="graduationYear">
                  <Translate>Graduation Year</Translate>
                </label>
                <input
                  type="text"
                  name="graduationYear"
                  id="graduationYear"
                  value={inputValues.graduationYear}
                  onChange={handleInputChange}
                  placeholder={t("Graduation Year")}
                />
              </div>
              {/* skills */}
              <div className="input-group w-[80%] md:w-2/5  m-2">
                <label htmlFor="skills">
                  <Translate>Skills</Translate>(
                  <span>
                    <Translate>comma seperated values</Translate>
                  </span>
                  )
                </label>
                <input
                  type="text"
                  name="skills"
                  id="skills"
                  value={inputValues.skills}
                  onChange={handleInputChange}
                  placeholder={t("Skills")}
                />
              </div>
              {/* specializations */}
              <div className="input-group w-[80%] md:w-2/5  m-2">
                <label htmlFor="specializations">
                  <Translate>Specializations</Translate>(
                  <span>
                    <Translate>comma seperated values</Translate>
                  </span>
                  )
                </label>
                <input
                  type="text"
                  name="specializations"
                  id="specializations"
                  value={inputValues.specializations}
                  onChange={handleInputChange}
                  placeholder={t("Specializations")}
                />
              </div>
              {/* work experiences */}
              <div className="input-group w-[80%] md:w-2/5  m-2">
                <label htmlFor="workExperiences">
                  <Translate>Work Experiences</Translate>(
                  <span>
                    <Translate>comma seperated values</Translate>
                  </span>
                  )
                </label>
                <textarea
                  name="workExperiences"
                  id="workExperiences"
                  value={inputValues.workExperiences}
                  onChange={handleInputChange}
                  placeholder={t("Work Experiences")}
                ></textarea>
              </div>
              {/* certifications */}
              <div className="input-group w-[80%] md:w-2/5  m-2">
                <label htmlFor="certifications">
                  <Translate>Certifications</Translate>(
                  <span>
                    <Translate>comma seperated values</Translate>
                  </span>
                  )
                </label>
                <textarea
                  name="certifications"
                  id="certifications"
                  value={inputValues.certifications}
                  onChange={handleInputChange}
                  placeholder={t("Certifications")}
                ></textarea>
              </div>
              {/* city */}
              <div className="input-group w-[80%] md:w-2/5  m-2">
                <label htmlFor="city">
                  <Translate>City</Translate>
                </label>
                <select
                  name="cityId"
                  id="cityId"
                  value={inputValues.cityId}
                  onChange={handleInputChange}
                >
                  <option value="">Select City</option>
                  {cities.map((city) => {
                    return (
                      <option key={city.id} value={city.id}>
                        {city.name}
                      </option>
                    );
                  })}
                </select>
              </div>
              {/* city area */}
              <div className="input-group w-[80%] md:w-2/5  m-2">
                <label htmlFor="cityArea">
                  <Translate>City Area</Translate>
                </label>
                <select
                  type="text"
                  name="cityArea"
                  id="cityArea"
                  value={inputValues.cityArea}
                  onChange={handleInputChange}
                  // placeholder={t("City Area")}
                >
                  <option value="">{t("City Area")}</option>
                  {cityAreas?.map((item) => (
                    <option value={item.id}>{item.name} </option>
                  ))}
                </select>
              </div>
              {/* whatsapp */}
              <div className="input-group w-[80%] md:w-2/5  m-2">
                <label htmlFor="whatsapp">
                  <Translate>Whatsapp</Translate>
                </label>
                <input
                  type="text"
                  name="whatsapp"
                  id="whatsapp"
                  value={inputValues.whatsapp}
                  onChange={handleInputChange}
                  placeholder={t("Whatsapp")}
                />
              </div>
              {/* phone */}
              <div className="input-group w-[80%] md:w-2/5  m-2">
                <label htmlFor="phone">
                  <Translate>Phone</Translate>
                </label>
                <input
                  type="text"
                  name="phone"
                  id="phone"
                  value={inputValues.phone}
                  onChange={handleInputChange}
                  placeholder={t("Phone")}
                />
              </div>
              {/* photo logo */}
              <div className="input-group w-[80%] md:w-2/5  m-2">
                <label htmlFor="photoLogo">
                  <Translate>Photo Logo</Translate>
                </label>
                <input
                  type="file"
                  name="photoLogo"
                  id="photoLogo"
                  onChange={handleFileChange}
                  placeholder={t("Photo Logo")}
                />
              </div>
              {/* photo banner */}
              <div className="input-group w-[80%] md:w-2/5  m-2">
                <label htmlFor="photoBanner">
                  <Translate>Photo Banner</Translate>
                </label>
                <input
                  type="file"
                  name="photoBanner"
                  id="photoBanner"
                  onChange={handleFileChange}
                  placeholder={t("Photo Banner")}
                />
              </div>
              {/* benefit for bipeers */}
              <div className="input-group w-[80%] md:w-2/5  m-2">
                <label htmlFor="benefitForBipeers">
                  <Translate>Benefit For Bipeers</Translate>(
                  <span>
                    <Translate>comma seperated values</Translate>
                  </span>
                  )
                </label>
                <textarea
                  name="benefitForBipeers"
                  id="benefitForBipeers"
                  value={inputValues.benefitForBipeers}
                  onChange={handleInputChange}
                  placeholder={t("Benefit For Bipeers")}
                ></textarea>
              </div>
              {/* keywords */}
              <div className="input-group w-[80%] md:w-2/5  m-2">
                <label htmlFor="keywords">
                  <Translate>Keywords</Translate>(
                  <span>
                    <Translate>comma seperated values</Translate>
                  </span>
                  )
                </label>
                <input
                  type="text"
                  name="keywords"
                  id="keywords"
                  value={inputValues.keywords}
                  onChange={handleInputChange}
                  placeholder={t("Keywords")}
                />
              </div>

              {/* category */}
              <div className="input-group w-[80%] md:w-2/5  m-2">
                <label htmlFor="category">
                  <Translate>Category</Translate>
                </label>
                <select
                  name="category"
                  id="category"
                  value={selectedCategory.id}
                  onChange={(e) => {
                    setSelectedCategory(e.target.value);
                  }}
                >
                  <option value="">Select Category</option>
                  {categories.map((category) => {
                    return (
                      <option key={category.id} value={category.id}>
                        {category.name}
                      </option>
                    );
                  })}
                </select>
              </div>

              {/* sub category */}
              {selectedCategory && (
                <div className="input-group w-[80%] md:w-2/5  m-2">
                  <label htmlFor="subCategoryId">
                    <Translate>Sub Category</Translate>
                  </label>
                  <select
                    name="subCategoryId"
                    id="subCategoryId"
                    value={inputValues.subCategoryId}
                    onChange={handleInputChange}
                  >
                    <option value="">Select Sub Category</option>
                    {categories
                      .find((c) => c.id === selectedCategory)
                      ?.ProfessionalSubCategory.map((subCategory) => {
                        return (
                          <option key={subCategory.id} value={subCategory.id}>
                            {subCategory.name}
                          </option>
                        );
                      })}
                  </select>
                </div>
              )}
            </div>

            {/* pricing options radio buttons, 1 option pay with 20 dollars */}
            <div className="flex justify-between items-center">
              <div className="flex items-center space-x-2">
                <input type="radio" name="pricing" id="pricing" value="20" />
                <label htmlFor="pricing">
                  <Translate>Subscribe</Translate>
                  <small className="ml-2">($10)</small>
                </label>
              </div>
              <div
                className="text-xs underline cursor-pointer"
                onClick={openModal}
              >
                (<Translate>view pricing</Translate>)
              </div>
            </div>

            <input
              type="submit"
              value={t("SIGNUP")}
              className="py-2 w-full text-center heading-bg-color bg-text-color rounded-md cursor-pointer"
            />
          </form>
        </div>
      </div>
      <PricingModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        openModal={openModal}
        closeModal={closeModal}
      />
    </div>
  );
};

export default ProfessionalSignup;
