import React from "react";
import Menu from "../sideMenu/Menu";

const CreateDriver = () => {
  return (
    <Menu>
      <div>
        <div className="mb-5 heading-text">Drivers</div>
        <div className="w-full flex justify-center">
          <div className="w-2/3 flex flex-col space-y-20 my-10">
            <fieldset>
              <legend>Driver Details</legend>
              <div className="create-form">
                <div>
                  <label htmlFor="firstName">First Name</label>
                  <input
                    type="text"
                    name="firstName"
                    id="firstName"
                    placeholder="Enter First Name"
                  />
                </div>
                <div>
                  <label htmlFor="lastName">Last Name</label>
                  <input
                    type="text"
                    name="lastName"
                    id="lastName"
                    placeholder="Enter Last Name"
                  />
                </div>
                <div>
                  <label htmlFor="email">Email</label>
                  <input
                    type="text"
                    name="email"
                    id="email"
                    placeholder="Enter Email"
                  />
                </div>
                <div>
                  <label htmlFor="password">Passwprd</label>
                  <input
                    type="password"
                    name="password"
                    id="password"
                    placeholder="Enter Password"
                  />
                </div>
                <div>
                  <label htmlFor="phone">Phone</label>
                  <input
                    type="tel"
                    name="phone"
                    id="phone"
                    placeholder="Enter Phone Number"
                  />
                </div>
                <div>
                  <label htmlFor="image">Profile Image</label>
                  <input type="file" name="image" id="image" />
                </div>
                <div>
                  <label htmlFor="latitude">Latitude</label>
                  <input
                    type="text"
                    name="latitude"
                    id="latitude"
                    placeholder="Enter latitude"
                  />
                </div>
                <div>
                  <label htmlFor="longitude">Longitude</label>
                  <input
                    type="text"
                    name="longitude"
                    id="longitude"
                    placeholder="Enter longitude"
                  />
                </div>
              </div>
            </fieldset>

            <fieldset>
              <legend>Driver (Activate/Deactivate)</legend>
              <div className="create-form">
                <div>
                  <div className="flex items-center space-x-2">
                    <input type="checkbox" name="isActive" id="isActive" />
                    <label htmlFor="isActive">Active</label>
                  </div>
                </div>
              </div>
            </fieldset>

            <fieldset>
              <legend>CAR DETAILS</legend>
              <div className="create-form">
                <div>
                  <label htmlFor="carNumber">Car Number</label>
                  <input
                    type="text"
                    name="carNumber"
                    id="carNumber"
                    placeholder="Enter Car Number"
                  />
                </div>
                <div>
                  <label htmlFor="carName">Car Name</label>
                  <input
                    type="text"
                    name="carName"
                    id="carName"
                    placeholder="Enter Car Name"
                  />
                </div>
                <div>
                  <label htmlFor="carImage">Car Image</label>
                  <input type="file" name="carImage" id="carImage" />
                </div>
              </div>
            </fieldset>

            <div className="flex justify-center space-x-5">
              <div className="bg-color bg-text-color px-5 py-2 rounded-md cursor-pointer">
                Save
              </div>
              <div className="bg-color bg-text-color px-5 py-2 rounded-md cursor-pointer">
                Back
              </div>
            </div>
          </div>
        </div>
      </div>
    </Menu>
  );
};

export default CreateDriver;
