import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Translate from "../../util/Translate";
import PricingModal from "../../util/PricingModal";
import Api from "../../util/axiosInstance";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "../../util/LoadingSpinner";
import Toast from "../../util/CustomToast";
import CustomModal from "../../util/CustomModal";
import AddressModal from "../../shops/AddressModal";
import { cloudinaryUpload } from "../../../utils/cloudinaryUpload";

const VendorSignup = () => {
  const [loading, setLoading] = useState(false);
  const [cities, setCities] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  useEffect(() => {
    Api.get("/city")
      .then((res) => {
        setCities(res.data.results);
      })
      .catch((err) => {
        console.log(err);
      });

    Api.get("/shop-category")
      .then((res) => {
        setCategories(res.data.results);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const [inputValues, setInputValues] = useState({
    name: "",
    email: "",
    password: "",
    cityId: "",
    cityArea: "",
    address: "",
    commercialReference: "",
    cityShoppingCenter: "",
    whatsapp: "",
    phone: "",
    photoLogo: null,
    photoBanner: null,
    subCategoryId: "",
    latitude: "",
    longitude: "",
  });

  const getCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        setInputValues({
          ...inputValues,
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        });
      });
    } else {
      Toast("error", "Geolocation is not supported by this browser.");
    }
  };

  const Navigate = useNavigate();
  const { t } = useTranslation();

  let [isOpenPriceModal, setIsOpenPriceModal] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const openModal = () => {
    setIsOpen(true);
  };
  const closeModal = () => {
    setIsOpen(false);
  };

  // array of strings
  const [benefitForBipeers, setBenefitForBipeers] = useState("");

  // array of strings
  const [keywords, setKeywords] = useState("");

  // handle the generic inputs
  const handleInputChange = (e) => {
    setInputValues({ ...inputValues, [e.target.name]: e.target.value });
  };
  const handleFileChange = async (e) => {
    const { name } = e.target; // get the name of the input (photoLogo or photoBanner)

    // Upload the file to Cloudinary and get the URL
    const url = await cloudinaryUpload(e.target.files[0]);

    // Update only the corresponding field in the inputValues state
    setInputValues((prevValues) => ({
      ...prevValues,
      [name]: url, // Update only the relevant file (photoLogo or photoBanner)
    }));
  };

  function closeModalPriceModal() {
    setIsOpenPriceModal(false);
  }

  function openModalPriceModal() {
    setIsOpenPriceModal(true);
  }

  const handleLogin = (e) => {
    e.preventDefault();
    console.log("login");

    const requestBody = {
      ...inputValues,
    };
    setLoading(true);

    requestBody.longitude = +requestBody.longitude;
    requestBody.latitude = +requestBody.latitude;

    const filteredValues = Object.fromEntries(
      Object.entries(requestBody).filter(([key, value]) => {
        return value !== "" && value !== null;
      })
    );

    Api.post("/vendor", filteredValues)
      .then((response) => {
        console.log(response);
        Navigate("/login/vendoradmin");
      })
      .catch((error) => {
        console.log(error);
        Toast("error", t("Invalid Credentials/Network Error"));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  if (loading) {
    return <LoadingSpinner />;
  }
  return (
    <div className="relative w-full flex justify-center items-center app-bg-color">
      <div
        className="absolute inset-0 w-screen h-screen"
        // style={{
        //   backgroundImage: "url(/login-bg.jpg)",
        //   backgroundSize: "cover",
        //   backgroundPosition: "center",
        //   backgroundRepeat: "no-repeat",
        //   filter: "blur(5px)",
        //   opacity: "0.6",
        //   zIndex: "0",
        // }}
      ></div>
      <div className="flex flex-col justify-center items-center space-y-8 w-[90%] md:w-2/3 z-10">
        <div className="py-2 px-10  rounded-md flex flex-col space-y-2 justify-center items-center">
          <div className="text-5xl app-text-color">Bipeers</div>
          <div>
            <Translate>Vendor Signup</Translate>
          </div>
        </div>
        <div className="p-5 shadow-lg w-full border">
          <form
            className="flex space-y-6 sub-heading-text flex-col"
            onSubmit={handleLogin}
          >
            <div className="self-center app-text-color heading-text">
              <Translate>SIGNUP</Translate>
            </div>
            <div className="flex flex-wrap items-center justify-center">
              {/* name */}
              <div className="input-group w-[80%] md:w-2/5  m-2">
                <label htmlFor="name">
                  <Translate>Shop Name</Translate>
                </label>
                <input
                  type="text"
                  name="name"
                  id="name"
                  value={inputValues.name}
                  onChange={handleInputChange}
                  placeholder={t("Name")}
                />
              </div>
              {/* email */}
              <div className="input-group w-[80%] md:w-2/5  m-2">
                <label htmlFor="email">
                  <Translate>Email</Translate>
                </label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  value={inputValues.email}
                  onChange={handleInputChange}
                  className="w-full p-2 border rounded-md"
                  placeholder={t("Email")}
                />
              </div>

              {/* password */}
              <div className="input-group w-[80%] md:w-2/5  m-2">
                <label htmlFor="password">
                  <Translate>Password</Translate>
                </label>
                <input
                  type="password"
                  name="password"
                  id="password"
                  value={inputValues.password}
                  onChange={handleInputChange}
                  className="w-full p-2 border rounded-md"
                  placeholder={t("Password")}
                />
              </div>

              {/* city */}
              <div className="input-group w-[80%] md:w-2/5  m-2">
                <label htmlFor="city">
                  <Translate>City</Translate>
                </label>
                <select
                  name="cityId"
                  id="cityId"
                  value={inputValues.cityId}
                  onChange={handleInputChange}
                >
                  <option value="">Select City</option>
                  {cities.map((city) => {
                    return (
                      <option key={city.id} value={city.id}>
                        {city.name}
                      </option>
                    );
                  })}
                </select>
              </div>

              {/* address */}
              <div className="input-group w-[80%] md:w-2/5  m-2">
                <label htmlFor="address">
                  <Translate>Address</Translate>
                </label>
                <input
                  type="text"
                  name="address"
                  id="address"
                  value={inputValues.address}
                  onChange={handleInputChange}
                  className="w-full p-2 border rounded-md"
                  placeholder={t("Address")}
                />
              </div>

              {/* phone */}
              <div className="input-group w-[80%] md:w-2/5  m-2">
                <label htmlFor="phone">
                  <Translate>Phone</Translate>
                </label>
                <input
                  type="text"
                  name="phone"
                  id="phone"
                  value={inputValues.phone}
                  onChange={handleInputChange}
                  className="w-full p-2 border rounded-md"
                  placeholder={t("Phone")}
                />
              </div>

              {/* photoLogo */}
              <div className="input-group w-[80%] md:w-2/5  m-2">
                <label htmlFor="photoLogo">
                  <Translate>Photo Logo</Translate>
                </label>
                <input
                  type="file"
                  name="photoLogo"
                  id="photoLogo"
                  required
                  onChange={handleFileChange}
                  className="w-full p-2 border rounded-md"
                  placeholder={t("Photo Logo")}
                />
              </div>

              {/* photoBanner */}
              <div className="input-group w-[80%] md:w-2/5  m-2">
                <label htmlFor="photoBanner">
                  <Translate>Photo Banner</Translate>
                </label>
                <input
                  type="file"
                  name="photoBanner"
                  id="photoBanner"
                  required
                  onChange={handleFileChange}
                  className="w-full p-2 border rounded-md"
                  placeholder={t("Photo Banner")}
                />
              </div>

              {/* category */}
              <div className="input-group w-[80%] md:w-2/5  m-2">
                <label htmlFor="category">
                  <Translate>Category</Translate>
                </label>
                <select
                  name="category"
                  id="category"
                  value={selectedCategory}
                  onChange={(e) => {
                    setSelectedCategory(e.target.value);
                  }}
                >
                  <option value="">
                    <Translate>Select Category</Translate>
                  </option>
                  {categories.map((category) => {
                    return (
                      <option key={category.id} value={category.id}>
                        {category.name}
                      </option>
                    );
                  })}
                </select>
              </div>

              {/* sub category */}
              {selectedCategory && (
                <div className="input-group w-[80%] md:w-2/5  m-2">
                  <label htmlFor="subCategoryId">
                    <Translate>Sub Category</Translate>
                  </label>
                  <select
                    name="subCategoryId"
                    id="subCategoryId"
                    value={inputValues.subCategoryId}
                    onChange={handleInputChange}
                  >
                    <option value="">
                      <Translate>Select Sub Category</Translate>
                    </option>
                    {categories
                      .find((c) => c.id === selectedCategory)
                      ?.ShopSubCategory.map((subCategory) => {
                        return (
                          <option key={subCategory.id} value={subCategory.id}>
                            {subCategory.name}
                          </option>
                        );
                      })}
                  </select>
                </div>
              )}

              {/* benefit for bipeers list of strings */}

              {/* <div className="input-group w-[80%] md:w-2/5  m-2">
                <label htmlFor="benefitForBipeers">
                  <Translate>Benefit For Bipeers</Translate>(
                  <span>
                    <Translate>comma seperated values</Translate>
                  </span>
                  )
                </label>
                <div>
                  <input
                    type="text"
                    name="benefitForBipeers"
                    id="benefitForBipeers"
                    value={benefitForBipeers}
                    onChange={(e) => setBenefitForBipeers(e.target.value)}
                    className="w-full p-2 border rounded-md"
                    placeholder={t("Benefit For Bipeers")}
                  />
                 
                </div>
              </div> */}

              {/* keywords list of strings */}
              {/* <div className="input-group w-[80%] md:w-2/5  m-2">
                <label htmlFor="keywords">
                  <Translate>Keywords</Translate>(
                  <Translate>comma seperated values</Translate>)
                </label>
                <div>
                  <input
                    type="text"
                    name="keywords"
                    id="keywords"
                    value={keywords}
                    onChange={(e) => setKeywords(e.target.value)}
                    className="w-full p-2 border rounded-md"
                    placeholder={t("Keywords")}
                  />
                
                </div>
              </div> */}
            </div>
            {/* latitude and longitude */}
            <div className="flex flex-col space-y-3 w-fit self-center">
              <div className="flex flex-col space-y-2">
                <label htmlFor="latitude">
                  <Translate>Latitude</Translate>
                </label>
                <input
                  type="text"
                  name="latitude"
                  id="latitude"
                  placeholder={t("Latitude")}
                  onChange={handleInputChange}
                  value={inputValues.latitude}
                />
              </div>
              <div className="flex flex-col space-y-2">
                <label htmlFor="longitude">
                  <Translate>Longitude</Translate>
                </label>
                <input
                  type="text"
                  name="longitude"
                  id="longitude"
                  placeholder={t("Longitude")}
                  onChange={handleInputChange}
                  value={inputValues.longitude}
                />
              </div>
              <div
                className="px-3 py-2 heading-bg-color bg-text-color rounded-md cursor-pointer"
                onClick={getCurrentLocation}
              >
                <Translate>Get Current Location</Translate>
              </div>

              <div
                className="px-3 py-2 heading-bg-color bg-text-color rounded-md cursor-pointer"
                onClick={openModal}
              >
                <Translate>Get Current Address</Translate>
              </div>
            </div>

            {/* pricing options radio buttons, 1 option pay with 20 dollars */}
            <div className="flex justify-between items-center">
              <div className="flex items-center space-x-2">
                <input type="radio" name="pricing" id="pricing" value="20" />
                <label htmlFor="pricing">
                  <Translate>Subscribe</Translate>
                  <small className="ml-2">($20)</small>
                </label>
              </div>
              <div
                className="text-xs underline cursor-pointer"
                onClick={openModalPriceModal}
              >
                (<Translate>view pricing</Translate>)
              </div>
            </div>

            <input
              type="submit"
              value={t("SIGNUP")}
              className="py-2 w-full text-center heading-bg-color bg-text-color rounded-md cursor-pointer"
            />
          </form>
        </div>
      </div>
      <CustomModal
        {...{
          title: "Get Address",
          isOpen,
          setIsOpen,
          openModal,
          closeModal,
        }}
      >
        <AddressModal
          {...{
            setInputValues,
            closeModal,
          }}
        />
      </CustomModal>
      <PricingModal
        isOpen={isOpenPriceModal}
        setIsOpen={setIsOpenPriceModal}
        openModal={openModalPriceModal}
        closeModal={closeModalPriceModal}
      />
    </div>
  );
};

export default VendorSignup;
