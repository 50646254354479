import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ProfessionalRow from "./ProfessionalRow";
import Api from "../util/axiosInstance";
import LoadingSpinner from "../util/LoadingSpinner";

const Professional = {
  name: "John",
  photoLogo: "https://picsum.photos/800/800",
  email: "waleed@yopmail.com",
  createdAt: "2021-01-01",
  phone: "555-555-5555",
};

const ProfessionalsList = ({ professionals, setProfessionals, setLoading }) => {
  const handleProfessionalDelete = (id) => {
    setLoading(true);
    Api.delete(`/professional/${id}`)
      .then((res) => {
        setProfessionals(
          professionals.filter((professional) => professional.id !== id)
        );
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleStatusToggle = (id) => {
    setLoading(true);
    Api.patch(`/professional/${id}`, {
      active: !professionals?.find((it) => it.id === id)?.active,
    })
      .then((res) => {
        setProfessionals(
          professionals.map((professional) => {
            if (professional.id === id) {
              return { ...professional, active: !professional.active };
            }
            return professional;
          })
        );
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // if (loading) {
  //   return <LoadingSpinner />;
  // }

  return (
    <div className="w-full">
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Image</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Profession</TableCell>
            <TableCell>Phone</TableCell>
            <TableCell>Date</TableCell>
            <TableCell>Active</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {professionals.map((professional) => {
            return (
              <ProfessionalRow
                professional={professional}
                handleProfessionalDelete={handleProfessionalDelete}
                handleStatusToggle={handleStatusToggle}
              />
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
};

export default ProfessionalsList;
