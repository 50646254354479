import React, { useEffect } from "react";
import { useAppContext } from "../../hooks/context/tokenStore";
import LoadingSpinner from "../util/LoadingSpinner";
import { useNavigate } from "react-router-dom";

const MyShop = () => {
  const { userData } = useAppContext();
  const Navigate = useNavigate();
  useEffect(() => {
    if (userData && userData.Shop) {
      Navigate(`/shops/edit/${userData.Shop.id}`);
    }
  }, [userData]);
  return <LoadingSpinner />;
};

export default MyShop;
