import React, { useEffect, useState } from "react";
import Api from "../util/axiosInstance";
import { RiChatDeleteLine, RiEditCircleFill } from "react-icons/ri";
import { MdDeleteOutline } from "react-icons/md";
import EditCity from "./EditCity";
import Translate from "../util/Translate";
import ConfirmationModal from "../util/ConfirmationModal";

const CitiesList = ({ cities, setCities, setLoading }) => {
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedDeletedCity, setSelectedDeletedCity] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const handleDeleteCity = (id) => {
    Api.delete(`/city/${id}`)
      .then((response) => {
        setCities(cities.filter((city) => city.id !== id));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="flex flex-col space-y-5">
      <div className="heading-text app-text-color">
        <Translate>Cities List</Translate>
      </div>
      <div className="flex flex-wrap">
        {cities?.map((city) => (
          <>
            <div className="flex items-center space-x-6 sub-heading-text heading-bg-color bg-text-color px-3 py-2 rounded-md hover:app-bg-color hover:app-text-color cursor-pointer border-2">
              <div className="capitalize">{city.name}</div>
              <div
                className="hover:text-green-500"
                onClick={() => {
                  setSelectedCity(city);
                }}
              >
                <RiEditCircleFill size={20} />
              </div>
              <div
                className="hover:text-red-500"
                onClick={() => {
                  setIsOpen(true);
                  setSelectedDeletedCity(city.id);
                }}
              >
                <MdDeleteOutline size={20} />
              </div>
            </div>
          </>
        ))}
      </div>
      <ConfirmationModal
        deleteFuction={handleDeleteCity}
        id={selectedDeletedCity}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
      {selectedCity && (
        <EditCity
          selectedCity={selectedCity}
          setCities={setCities}
          cities={cities}
          setSelectedCity={setSelectedCity}
        />
      )}
    </div>
  );
};

export default CitiesList;
