import React from "react";
import { useTranslation } from "react-i18next";

const Translate = ({ children }) => {
  const { t, i18n } = useTranslation();

  return <>{t(children)}</>;
};

export default Translate;
