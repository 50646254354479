import React, { useEffect, useState } from "react";
import Menu from "../sideMenu/Menu";
import Translate from "../util/Translate";
import { useTranslation } from "react-i18next";
import Api from "../util/axiosInstance";
import { useNavigate, useParams } from "react-router-dom";
import { TiDeleteOutline } from "react-icons/ti";
import LoadingSpinner from "../util/LoadingSpinner";
import { cloudinaryUpload } from "../../utils/cloudinaryUpload";

const EditCategory = () => {
  const [inputValues, setInputValues] = useState({
    name: "",
    image: "",
    publish: false,
    type: "",
  });
  const [loading, setLoading] = useState(false);
  const { categoryId } = useParams();
  useEffect(() => {
    Api.get(`/category/${categoryId}`)
      .then((res) => {
        setInputValues({
          name: res.data.name,
          publish: res.data.publish,
          image: res.data.image,
          type: res.data.type,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }, [categoryId]);
  const Navigate = useNavigate();
  const { t } = useTranslation();
  const handleInputChange = (e) => {
    // handle checkbox input
    if (e.target.type === "checkbox") {
      setInputValues({
        ...inputValues,
        [e.target.name]: e.target.checked,
      });
      return;
    }

    setInputValues({ ...inputValues, [e.target.name]: e.target.value });
  };
  const handleFileChange = (e) => {
    setInputValues({ ...inputValues, [e.target.name]: e.target.files[0] });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (typeof inputValues.image !== "string") {
      const url = await cloudinaryUpload(inputValues.image);
      setInputValues({
        ...inputValues,
        image: url,
      });
    }

    Api.patch(`/category/${categoryId}`, {
      ...inputValues,
    })
      .then((res) => {
        console.log(res.data);
        Navigate(-1);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <Menu>
      <div>
        <div className="mb-5 heading-text">
          <Translate>Edit Category</Translate>
        </div>
        <div className="w-full flex justify-center">
          <form
            className="w-2/3 flex flex-col space-y-20 my-10"
            onSubmit={handleSubmit}
          >
            <fieldset>
              <legend>
                <Translate>Category Details</Translate>
              </legend>
              <div className="px-5 my-3 flex flex-col space-y-10">
                <div className="input-group">
                  <label htmlFor="name">
                    <Translate>Name</Translate>
                  </label>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    placeholder={t("Name")}
                    value={inputValues.name}
                    onChange={handleInputChange}
                  />
                </div>

                <div className="flex space-x-3">
                  <input
                    type="checkbox"
                    name="publish"
                    id="publish"
                    onChange={handleInputChange}
                    checked={inputValues.publish}
                  />
                  <label htmlFor="publish">
                    <Translate>Publish</Translate>
                  </label>
                </div>

                {/* type of category */}
                <div className="input-group">
                  <label htmlFor="type">
                    <Translate>Type</Translate>
                  </label>
                  <select
                    name="type"
                    id="type"
                    onChange={handleInputChange}
                    value={inputValues.type}
                  >
                    <option value="">Select Type</option>
                    <option value="product">Product</option>
                    <option value="service">Service</option>
                  </select>
                </div>

                {inputValues.image ? (
                  <div className="w-1/2 h-auto relative">
                    <img
                      src={inputValues.image}
                      alt="category"
                      className="w-full h-full object-contain"
                    />
                    <div
                      className="absolute -top-5 right-0 cursor-pointer"
                      onClick={() => {
                        setInputValues({ ...inputValues, image: "" });
                      }}
                    >
                      <TiDeleteOutline size={20} color="red" />
                    </div>
                  </div>
                ) : (
                  <div className="flex flex-col space-y-3">
                    <label htmlFor="image">
                      <Translate>Image</Translate>
                    </label>
                    <input
                      required
                      type="file"
                      name="image"
                      id="image"
                      onChange={handleFileChange}
                    />
                  </div>
                )}
              </div>
            </fieldset>

            <div className="flex justify-center space-x-5">
              <button
                className="heading-bg-color bg-text-color px-5 py-2 rounded-md cursor-pointer"
                type="submit"
              >
                <Translate>Save</Translate>
              </button>
              <div
                className="heading-bg-color bg-text-color px-5 py-2 rounded-md cursor-pointer"
                onClick={() => {
                  Navigate(-1);
                }}
              >
                <Translate>Back</Translate>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Menu>
  );
};

export default EditCategory;
