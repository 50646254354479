import React, { useState, useEffect } from "react";
import Menu from "../sideMenu/Menu";
import Translate from "../util/Translate";
import { useTranslation } from "react-i18next";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Api from "../util/axiosInstance";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../../hooks/context/tokenStore";

const editorModules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }],
    ["bold", "italic", "underline", "strike"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["clean"],
    ["code-block"],
  ],
};

const CreateService = () => {
  const [inputValues, setInputValues] = useState({
    title: "",
    price: "",
    description: "",
    active: true,
    image: null,
    categoryId: "",
    subCategoryId: "",
  });
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const Navigate = useNavigate();
  const { userData } = useAppContext();
  const { t } = useTranslation();

  const handleOnInputchange = (e) => {
    const { name, value } = e.target;
    setInputValues({ ...inputValues, [name]: value });
  };

  useEffect(() => {
    Api.get("/category?type=service")
      .then((res) => {
        setCategories(res.data.results);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const uploadImageToCloudinary = async (imageFile) => {
    try {
      const formData = new FormData();
      formData.append("image", imageFile);

      const response = await Api.post("/cloudinary/upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      return response.data.url;
    } catch (error) {
      console.error("Image upload error:", error);
      throw error;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // If there's an image, upload it to Cloudinary
      if (inputValues.image) {
        const imageUrl = await uploadImageToCloudinary(inputValues.image);
        inputValues.image = imageUrl; // Set the image URL from Cloudinary
      }

      if (userData) inputValues.professionalId = userData.id;
      // Now send the service creation request
      await Api.post("/service", { ...inputValues, price: +inputValues.price });
      console.log("Service created successfully");

      setInputValues({
        title: "",
        price: "",
        description: "",
        active: true,
        categoryId: "",
        subCategoryId: "",
        image: "",
      });
      Navigate(-1);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Menu>
      <div>
        <div className="mb-5 heading-text">
          <Translate>Service</Translate>
        </div>
        <div className="w-full flex justify-center">
          <form
            className="w-2/3 flex flex-col space-y-20 my-10"
            onSubmit={handleSubmit}
          >
            <fieldset>
              <legend>
                <Translate>SERVICE DETAILS</Translate>
              </legend>
              <div className="create-form">
                <div>
                  <label htmlFor="title">
                    <Translate>Title</Translate>
                  </label>
                  <input
                    type="text"
                    name="title"
                    id="title"
                    required
                    min={3}
                    max={50}
                    value={inputValues.title}
                    onChange={handleOnInputchange}
                  />
                </div>
                <div>
                  <label htmlFor="price">
                    <Translate>Price</Translate>
                  </label>
                  <input
                    type="number"
                    name="price"
                    id="price"
                    required
                    min={1}
                    value={inputValues.price}
                    onChange={handleOnInputchange}
                  />
                </div>
                <div>
                  <div className="flex flex-row items-center space-x-3">
                    <input
                      type="checkbox"
                      name="active"
                      id="active"
                      checked={inputValues.active}
                      onChange={(e) => {
                        setInputValues({
                          ...inputValues,
                          active: e.target.checked,
                        });
                      }}
                    />
                    <label htmlFor="active">
                      <Translate>Active</Translate>
                    </label>
                  </div>
                </div>
                <div>
                  <label htmlFor="image">
                    <Translate>Image</Translate>
                  </label>
                  <input
                    type="file"
                    name="image"
                    id="image"
                    onChange={(e) => {
                      setInputValues({
                        ...inputValues,
                        image: e.target.files[0], // Update image file
                      });
                    }}
                  />
                </div>
                <div>
                  <label htmlFor="categoryId">
                    <Translate>Category</Translate>
                  </label>
                  <select
                    name="categoryId"
                    id="categoryId"
                    onChange={(e) => {
                      handleOnInputchange(e);
                      setSelectedCategory(e.target.value);
                    }}
                  >
                    <option value="">
                      <Translate>Select Category</Translate>
                    </option>
                    {categories.map((category) => (
                      <option key={category.id} value={category.id}>
                        {category.name}
                      </option>
                    ))}
                  </select>
                </div>
                {/* sub category */}
                {selectedCategory && (
                  <div>
                    <label htmlFor="subCategoryId">
                      <Translate>Sub Category</Translate>
                    </label>
                    <select
                      name="subCategoryId"
                      id="subCategoryId"
                      onChange={handleOnInputchange}
                      value={inputValues.subCategoryId}
                    >
                      <option value="">
                        <Translate>Select Sub Category</Translate>
                      </option>
                      {categories
                        .find((category) => category.id === selectedCategory)
                        .SubCategory.map((subCategory) => (
                          <option key={subCategory.id} value={subCategory.id}>
                            {subCategory.name}
                          </option>
                        ))}
                    </select>
                  </div>
                )}
              </div>
              <div className="input-group w-[80%] h-72 m-5">
                <label htmlFor="description">
                  <Translate>Description</Translate>
                </label>
                <ReactQuill
                  theme="snow"
                  modules={editorModules}
                  value={inputValues.description}
                  onChange={(e) => {
                    setInputValues({ ...inputValues, description: e });
                  }}
                  className="h-56 "
                />
              </div>
            </fieldset>

            <div className="flex justify-center space-x-5">
              <input
                className="heading-bg-color bg-text-color px-5 py-2 rounded-md cursor-pointer"
                type="submit"
                value={t("Save")}
              />

              <div
                className="heading-bg-color bg-text-color px-5 py-2 rounded-md cursor-pointer"
                onClick={() => Navigate(-1)}
              >
                <Translate>Back</Translate>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Menu>
  );
};

export default CreateService;
