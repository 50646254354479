import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Api from "../util/axiosInstance";
import Menu from "../sideMenu/Menu";
import { formatDate } from "../util/convertDate";
import { useAppContext } from "../../hooks/context/tokenStore";

const ViewServiceOrder = () => {
  const { orderId } = useParams();
  const [order, setOrder] = useState();
  const { userData } = useAppContext();

  useEffect(() => {
    Api.get(`/service-order/${orderId}`)
      .then((res) => {
        console.log("order details: ", res?.data);
        setOrder(res?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [orderId]);

  return (
    <Menu>
      <div>
        <div className="flex flex-col space-y-10">
          <h1 className="sub-heading-text py-2 border-b-2 border-b-gray-300 w-full">
            Order
          </h1>

          {/* service purchased */}
          <div>
            <fieldset className="rounded-md">
              <legend>Service Details</legend>
              <div className="flex flex-col space-y-5 w-[90%] md:w-4/5">
                <div className="flex justify-between">
                  <label>Service Name</label>
                  <div>{order?.Service?.title}</div>
                </div>
                <div className="flex justify-between">
                  <label>Service Description</label>
                  <div className="text-right">
                    {order?.Service?.description}
                  </div>
                </div>
                <div className="flex justify-between">
                  <label> Price</label>
                  <div>${order?.Service?.price}</div>
                </div>
              </div>
            </fieldset>
          </div>

          {/* customer details */}
          <div>
            <fieldset className="rounded-md">
              <legend>Customer Details</legend>
              <div className="w-[90%] md:w-[50%] mx-auto flex flex-col space-y-3">
                <div className="flex justify-between">
                  <label>Name</label>
                  <div>{order?.Customer?.name}</div>
                </div>
                <div className="flex justify-between">
                  <label>Email</label>
                  <div>{order?.Customer?.email}</div>
                </div>
                <div className="flex justify-between">
                  <label>Phone</label>
                  <div>{order?.Customer?.phone}</div>
                </div>
              </div>
            </fieldset>
          </div>

          {/* other order details */}
          <div>
            <fieldset className="rounded-md">
              <legend>
                <h1 className="sub-heading-text">Order Details</h1>
              </legend>
              <div className="w-[90%] md:w-[50%] mx-auto flex flex-col space-y-3">
                <div className="flex justify-between">
                  <label>Order Date</label>
                  <div>{formatDate(order?.createdAt)}</div>
                </div>
                <div className="flex justify-between">
                  <label>Sub Total</label>
                  <div>${order?.totalAmount}</div>
                </div>
                <div className="flex justify-between">
                  <label>Status From Vendor</label>
                  <div className="flex flex-col space-y-2 items-end">
                    <div>{order?.statusByProfessional}</div>
                    {order?.Service?.Professional?.id === userData?.id && (
                      <div className="flex items-center space-x-5">
                        <div className="btn">Delivered</div>
                        <div className="btn">Canceled</div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="flex justify-between">
                  <label>Status From Customer</label>
                  <div className="flex flex-col space-y-2 items-end">
                    <div>{order?.statusByCustomer}</div>
                  </div>
                </div>
              </div>
            </fieldset>
          </div>

          {/* payment details */}
          <div>
            <fieldset className="rounded-md">
              <legend>Payment Details</legend>
              <div className="w-[90%] md:w-[50%] mx-auto flex flex-col space-y-3">
                <div className="flex justify-between">
                  <label>Payment Type</label>
                  <div>{order?.Payment?.paymentType}</div>
                </div>
                <div className="flex justify-between">
                  <label>Payment Status</label>
                  <div>{order?.Payment?.status}</div>
                </div>
              </div>
            </fieldset>
          </div>
        </div>
      </div>
    </Menu>
  );
};

export default ViewServiceOrder;
