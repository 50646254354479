import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Api from "../../util/axiosInstance";
import Translate from "../../util/Translate";

const EditCityArea = ({
  currentCity,
  setCities,
  cities,
  closeModal,
  currentCityArea,
  setCityAreas,
  cityAreas,
}) => {
  const [name, setName] = useState("");
  const { t } = useTranslation();

  useEffect(() => {
    if (currentCityArea) {
      setName(currentCityArea.name);
    }
  }, [currentCityArea]);

  const handleSubmit = (e) => {
    e.preventDefault();
    Api.patch(`/city-area/${currentCityArea.id}`, {
      name,
    })
      .then((res) => {
        let tempCityAreas = cityAreas.map((item) => {
          if (item.id === currentCityArea.id) {
            return res.data.data;
          }
          return item;
        });
        setCityAreas(tempCityAreas);

        let tempCities = cities.map((item) => {
          if (item.id === currentCity.id) {
            return {
              ...item,
              CityArea: tempCityAreas,
            };
          }
          return item;
        });
        setCities(tempCities);

        closeModal();
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <div className="my-5">
      <div>
        <form className="flex flex-col space-y-3" onSubmit={handleSubmit}>
          <div className="input-group">
            <label htmlFor="name">
              <Translate>Name</Translate>
            </label>
            <input
              type="text"
              name="name"
              id="name"
              minLength={3}
              maxLength={30}
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
          </div>
          <div>
            <input type="submit" value={t("SAVE")} className="btn" />
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditCityArea;
