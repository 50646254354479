import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import PointerestList from "./PointerestList";
import Menu from "../../sideMenu/Menu";
import Translate from "../../util/Translate";
import CityList from "../cityArea/CityList";
import Api from "../../util/axiosInstance";
import LoadingAnimation from "../../util/LoadingAnimation";

const Pointerest = () => {
  const [cities, setCities] = useState([]);
  const [pointerest, setPointerest] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentCity, setCurrentCity] = useState([]);

  useEffect(() => {
    setLoading(true);
    Api.get("/city")
      .then((response) => {
        setCities(response.data.results);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);
  return (
    <Menu>
      {loading ? (
        <div className=" flex justify-center min-h-[90vh] items-center m-auto">
          <LoadingAnimation />
        </div>
      ) : (
        <div className="flex flex-col space-y-5">
          <div className="sub-heading-text app-text-color">
            <Translate>Point Of Interest Details</Translate>
          </div>
          <Link
            to={"/cities/pointerest/create"}
            className="btn w-fit self-center"
          >
            <Translate>Create Point of Interest</Translate>
          </Link>
          <div className="flex space-x-10 self-center">
            <CityList
              cities={cities}
              setCurrentCity={setCurrentCity}
              currentCity={currentCity}
            />
            <PointerestList
              pointerest={pointerest}
              setPointerest={setPointerest}
              currentCity={currentCity}
              cities={cities}
              setCities={setCities}
            />
          </div>
        </div>
      )}
    </Menu>
  );
};

export default Pointerest;
