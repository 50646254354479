import React, { useState } from "react";
import Api from "../util/axiosInstance";
import Toast from "../util/CustomToast";
import { useNavigate } from "react-router-dom";
import { cloudinaryUpload } from "../../utils/cloudinaryUpload";
import { useAppContext } from "../../hooks/context/tokenStore";

const CreateSlider = () => {
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const Navigate = useNavigate();
  const { userData } = useAppContext();
  const [sliderType, setSliderType] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    let imageUrl;
    if (image) {
      imageUrl = await cloudinaryUpload(image);
    }
    Api.post("/professional-slider", {
      image: imageUrl,
      cityId: !!userData?.City?.id ? userData?.City?.id : undefined,
      sliderType,
    })
      .then((res) => {
        console.log(res.data);
        Toast("success", "Slider Created Successfully");
        setImage(null);
        Navigate(-1);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <div>
      <div>
        <form className="flex flex-col space-y-5" onSubmit={handleSubmit}>
          <fieldset>
            <legend>Create Slider</legend>
            <div>
              <div className="input-group">
                <label htmlFor="image">Image</label>
                <input
                  type="file"
                  id="image"
                  name="image"
                  onChange={(e) => setImage(e.target.files[0])}
                  required
                />
              </div>
              <div className="input-group mt-8 w-fit">
                <label htmlFor="sliderType">Slider Type</label>
                <select
                  name="sliderType"
                  id="sliderType"
                  value={sliderType}
                  onChange={(e) => setSliderType(e.target.value)}
                  required
                >
                  <option value="">Select Slider Type</option>
                  <option value="top">Top</option>
                  <option value="middle">Middle</option>
                  <option value="bottom">Bottom</option>
                </select>
              </div>
            </div>
          </fieldset>
          <div className="flex justify-center">
            <button type="submit" className="btn">
              Create
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateSlider;
