import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Translate from "../util/Translate";

const ProductVariations = ({
  variations,
  variationValues,
  setVariationValues,
}) => {
  const { productId } = useParams();
  useEffect(() => {
    // price and stock change indexes
    if (!productId) {
      const allVariations = [];

      variations.forEach((variation, index) => {
        const obj = {};
        obj[`price-${index}`] = "";
        obj[`stock-${index}`] = "";
        obj["name"] = variation;
        allVariations.push(obj);
      });
      setVariationValues(allVariations);
    } else {
      console.log("already found variation: ", { variationValues });
      // clear the variation values for non selected according to values
      const allVariations = [];
      variations.forEach((variation, index) => {
        console.log("🚀 ~ variations.forEach ~ variation:", variation);
        const obj = {};
        if (Object.entries(variation?.[index]?.length)) {
          obj[`price-${index}`] = variationValues[index][`price-${index}`];
          obj[`stock-${index}`] = variationValues[index][`stock-${index}`];
          obj["name"] = variation;
          allVariations.push(obj);
        }
      });
      setVariationValues(allVariations);
    }
  }, [variations, productId]);

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setVariationValues((prev) => {
      return prev.map((item) => {
        if (item[name] !== undefined) {
          return { ...item, [name]: value };
        }
        return item;
      });
    });
  };

  return (
    <div className="my-5 flex flex-col space-y-5">
      <div className="app-text-color text-center sub-heading-text">
        <Translate>Product Variations</Translate>
      </div>

      {/* each attribute values correspondes with other attribute values and make the unique variation */}
      {/* like one value from size and other from weight make one variation medium-5000 */}
      <div className="flex flex-col space-y-5">
        {variations.map((variation, index) => {
          return (
            <div className="flex justify-between items-center">
              <div className="w-1/4">{variation}</div>
              <div>
                <input
                  type="number"
                  placeholder="Price"
                  name={`price-${index}`}
                  onChange={handleChangeInput}
                  value={variationValues[index]?.[`price-${index}`]}
                />
              </div>
              <div>
                <input
                  type="number"
                  placeholder="Stock"
                  name={`stock-${index}`}
                  onChange={handleChangeInput}
                  value={variationValues[index]?.[`stock-${index}`]}
                  // value={variationValues[`stock-${index}`]}
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ProductVariations;
