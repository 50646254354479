import React, { useTransition } from "react";
import RangeFilter from "./RangeFilter";
import { useTranslation } from "react-i18next";
import Translate from "../util/Translate";

const AnalyticCard = ({ heading, icon, number }) => {
  const { t } = useTranslation();
  return (
    <div className="flex justify-between items-start px-5 py-4 w-64  rounded-lg shadow-lg border mr-5 my-5 border-color flex-col space-y-3">
      <div className="flex items-start justify-between w-full">
        <div className="flex items-start space-x-1 w-32">
          <div className="sub-heading-text">
            <Translate>{heading}</Translate>
          </div>
          {/* <div>|</div>
          <div>
            <Translate>Today</Translate>
          </div> */}
        </div>
        <div>
          {/* <RangeFilter /> */}
        </div>
      </div>
      <div className="flex justify-between  items-center space-x-10">
        <div>{icon}</div>
        <div>
          <div className="heading-text">{number}</div>
          {/* <div className="text-green-400">12% {t("increase")}</div> */}
        </div>
      </div>
    </div>
  );
};

export default AnalyticCard;
