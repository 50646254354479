import React, { useEffect, useState } from "react";
import Menu from "../sideMenu/Menu";
import CreateCity from "./CreateCity";
import CitiesList from "./CitiesList";
import Translate from "../util/Translate";
import { Link } from "react-router-dom";
import Api from "../util/axiosInstance";
import LoadingAnimation from "../util/LoadingAnimation";

const Cities = () => {
  const [cities, setCities] = useState();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    Api.get("/city")
      .then((response) => {
        setCities(response.data.results);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <Menu>
      {loading ? (
        <div className=" flex justify-center min-h-[90vh] items-center m-auto">
          <LoadingAnimation />
        </div>
      ) : (
        <>
          <div className="flex flex-col items-start space-y-5 ">
            <h1 className="sub-heading-text py-2 border-b-2 border-b-gray-300 w-full">
              <Translate>Cities</Translate>
            </h1>
            <div className="w-full bg-gray-200 flex justify-center items-center">
              <div className="py-2 px-4 bg-color app-text-color cursor-pointer rounded-md">
                <Translate>Cities List</Translate>
              </div>
              <Link
                className="py-2 px-4 hover:heading-bg-color hover:bg-text-color cursor-pointer rounded-md"
                to={"/cities/area"}
              >
                <Translate>City Areas</Translate>
              </Link>
              <Link
                className="py-2 px-4 hover:heading-bg-color hover:bg-text-color cursor-pointer rounded-md"
                to={"/cities/pointerest"}
              >
                <Translate>Point of Interest</Translate>
              </Link>
              <Link
                className="py-2 px-4 hover:heading-bg-color hover:bg-text-color cursor-pointer rounded-md"
                to={"/malls"}
              >
                <Translate>Malls</Translate>
              </Link>
            </div>
          </div>
          <div>
            <CreateCity />
            <CitiesList
              cities={cities}
              setCities={setCities}
              setLoading={setLoading}
            />
          </div>
        </>
      )}
    </Menu>
  );
};

export default Cities;
