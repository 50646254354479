import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Menu from "../sideMenu/Menu";
import AttributeInputs from "./AttributeInputs";
import Api from "../util/axiosInstance";
import Translate from "../util/Translate";

const EditProductVariation = () => {
  const { productId } = useParams();
  const Navigate = useNavigate();
  const [attributeValues, setAttributeValues] = useState([]);
  const [variationValues, setVariationValues] = useState([]);

  const [showVariations, setShowVariations] = useState(false);

  useEffect(() => {
    Api.get(`/product/getProductAttributes/${productId}`)
      .then((res) => {
        setAttributeValues([]);

        res.data.attributes.forEach((attribute) => {
          setAttributeValues((attributeValues) => [
            ...attributeValues,
            {
              attributeId: attribute.attributeId,
              attributeName: attribute.Attribute?.name,
              values: attribute.value,
            },
          ]);
          setShowVariations(!showVariations);
        });
      })
      .catch((err) => {
        console.log(err);
      });

    Api.get(`/product/getProductVariations/${productId}`)
      .then((res) => {
        setVariationValues([]);

        const allVariations = [];

        res.data.variations.forEach((variation, index) => {
          const obj = {};
          obj[`price-${index}`] = variation.price;
          obj[`stock-${index}`] = variation.stock;
          obj["name"] = variation.name;
          allVariations.push(obj);
        });
        setVariationValues(allVariations);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [productId]);

  const handleProductVariationUpdate = () => {
    console.log({ variationValues });
    console.log({ attributeValues });
    //   create form data
    const formData = new FormData();
    formData.append("variationValues", JSON.stringify(variationValues));
    formData.append("attributeValues", JSON.stringify(attributeValues));
    Api.post(`/product/updateProductVariations/${productId}`, formData).then(
      (res) => {
        console.log("Product Variation Updated");
        Navigate("/products");
      }
    );
  };

  return (
    <Menu>
      <div className="flex flex-col space-y-5 w-1/2 mx-auto">
        <h1 className="sub-heading-text py-2 border-b-2 border-b-gray-300 w-full">
          <Translate>Edit Product Variation</Translate>
        </h1>
        <div>
          <div>
            <Translate>Attributes</Translate>
          </div>
          <div>
            <AttributeInputs
              attributeValues={attributeValues}
              setAttributeValues={setAttributeValues}
              variationValues={variationValues}
              setVariationValues={setVariationValues}
              showVariations={showVariations}
              setShowVariations={setShowVariations}
            />
          </div>
        </div>
        <div
          className="self-center flex w-fit heading-bg-color bg-text-color px-5 py-2 rounded-md cursor-pointer"
          onClick={handleProductVariationUpdate}
        >
          <Translate>Update</Translate>
        </div>
      </div>
    </Menu>
  );
};

export default EditProductVariation;
