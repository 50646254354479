import React, { useState } from "react";
import Api from "./components/util/axiosInstance";
import axios from "axios";

const Test = () => {
  const [image, setImage] = useState();

  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
    // console.log(e.target.files[0]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    var formData = new FormData();
    formData.append("image", image);

    console.log({ formData });
    Api.post("/test", formData)
      .then((res) => {
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div>
      <form onSubmit={handleSubmit} encType="multipart/form-data">
        <input
          type="file"
          name="image"
          id="image"
          onChange={handleImageChange}
        />
        <button type="submit">Submit</button>
      </form>
    </div>
  );
};

export default Test;
