import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Api from "../util/axiosInstance";
import Translate from "../util/Translate";
import { useTranslation } from "react-i18next";

const EditCity = ({ selectedCity, setCities, setSelectedCity, cities }) => {
  const [city, setCity] = useState("");
  const [deliveryCharge, setDeliveryCharge] = useState(0);
  const { t } = useTranslation();
  useEffect(() => {
    setCity(selectedCity.name);
    setDeliveryCharge(selectedCity.deliveryCharge);
  }, [selectedCity.name]);
  const Navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    Api.patch(`/city/${selectedCity.id}`, {
      name: city,
      deliveryCharge: +deliveryCharge,
    })
      .then((response) => {
        setSelectedCity("");
        setCities(
          cities.map((city) =>
            city.id === selectedCity.id ? response.data.data : city
          )
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <div className="flex justify-center">
      <form
        className="w-2/3 flex flex-col space-y-10 my-10"
        onSubmit={handleSubmit}
      >
        <fieldset>
          <legend className="text-2xl">
            <Translate>City</Translate>
          </legend>
          <div className="create-form">
            <div>
              <label htmlFor="city">
                <Translate>City Name</Translate>
              </label>
              <input
                type="text"
                id="city"
                name="city"
                value={city}
                onChange={(e) => setCity(e.target.value)}
                required
              />
            </div>
            <div>
              <label htmlFor="city">
                <Translate>Delivery Charge</Translate>
              </label>
              <input
                type="number"
                id="deliveryCharge"
                name="deliveryCharge"
                value={deliveryCharge}
                onChange={(e) => setDeliveryCharge(e.target.value)}
                required
              />
            </div>
          </div>
        </fieldset>
        <div className="flex justify-center space-x-5">
          <input
            type="submit"
            className="heading-bg-color bg-text-color px-5 py-2 rounded-md cursor-pointer"
            value={t("Save")}
          ></input>
          <div
            className="heading-bg-color bg-text-color px-5 py-2 rounded-md cursor-pointer"
            onClick={() => {
              Navigate(-1);
            }}
          >
            <Translate>Back</Translate>
          </div>
        </div>
      </form>
    </div>
  );
};

export default EditCity;
