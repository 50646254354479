import React, { useState } from "react";
import { useAppContext } from "../../hooks/context/tokenStore";
import Api from "../util/axiosInstance";

const CreateInternalCategory = ({ currentCategory, closeModal }) => {
  const { userData } = useAppContext();

  const [inputValues, setInputValues] = useState({
    name: "",
  });
  const userRole = localStorage.getItem("role");

  const handleChange = (e) => {
    setInputValues({ ...inputValues, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(inputValues);
    const updatedData = {
      name: inputValues.name,
    };
    if (userData?.Shop?.shopSubCategoryId)
      updatedData.shopSubCategoryId = userData.Shop.shopSubCategoryId;
    if (userData?.Shop?.shopCategoryId)
      updatedData.shopCategoryId = userData.Shop.shopCategoryId;
    if (userRole === "vendor") updatedData.vendorId = userData.id;
    Api.post("/product-internal-category", updatedData)
      .then((response) => {
        console.log(response);
        setInputValues({
          name: "",
        });
        closeModal();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="my-5">
      <form className="flex flex-col space-y-5">
        <div className="input-group">
          <label>Name</label>
          <input
            type="text"
            name="name"
            value={inputValues.name}
            onChange={handleChange}
          />
        </div>

        <div>
          <button
            className="btn bg-color app-text-color"
            onClick={handleSubmit}
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default CreateInternalCategory;
