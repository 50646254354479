import React, { useEffect, useState } from "react";
import Menu from "../sideMenu/Menu";
import AttributesList from "./AttributesList";
import { Link } from "react-router-dom";
import Api from "../util/axiosInstance";
import LoadingAnimation from "../util/LoadingAnimation";

const Attributes = () => {
  const [attributesList, setAttributesList] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    Api.get("/attribute")
      .then((res) => {
        console.log(res);
        setAttributesList(res.data.results);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const handleDeleteAttribute = (id) => {
    setLoading(true);
    Api.delete(`/attribute/${id}`)
      .then((res) => {
        const newAttributesList = attributesList.filter(
          (attribute) => attribute.id !== id
        );
        setAttributesList(newAttributesList);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Menu>
      {loading ? (
        <div className=" flex justify-center min-h-[90vh] items-center m-auto">
          <LoadingAnimation />
        </div>
      ) : (
        <div className="flex flex-col items-start space-y-5 ">
          <h1 className="sub-heading-text py-2 border-b-2 border-b-gray-300 w-full">
            Attributes
          </h1>
          <div className="w-full bg-gray-200 flex justify-center items-center">
            <div className="py-2 px-4 bg-color app-text-color cursor-pointer rounded-md">
              Attributes List
            </div>
            <Link
              className="py-2 px-4 hover:heading-bg-color hover:bg-text-color cursor-pointer rounded-md"
              to={"/attributes/create"}
            >
              Create Attribute
            </Link>
          </div>
          <AttributesList
            attributesList={attributesList}
            handleDeleteAttribute={handleDeleteAttribute}
          />
        </div>
      )}
    </Menu>
  );
};

export default Attributes;
