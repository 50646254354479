import React from "react";
import { PiSmileySadThin } from "react-icons/pi";

const PageNotFound = () => {
  return (
    <div className="w-full h-screen flex justify-center items-center flex-col">
      <h1 className="text-4xl text-center text-red-500 flex space-x-3 items-center">
        <div>
          <PiSmileySadThin size={40} />
        </div>
        <div>404</div>
      </h1>
      <h2 className="text-2xl text-center">Page Not Found</h2>
    </div>
  );
};

export default PageNotFound;
