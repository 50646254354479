import React, { useEffect, useState } from "react";
import Menu from "../sideMenu/Menu";
import UsersList from "./UsersList";
import { Link } from "react-router-dom";
import { TextField } from "@mui/material";
import { FaSearch } from "react-icons/fa";
import { BsSearch } from "react-icons/bs";
import LoadingAnimation from "../util/LoadingAnimation";
import Api from "../util/axiosInstance";
import { useAppContext } from "../../hooks/context/tokenStore";

const Users = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [customers, setCustomers] = useState([]);
  const { userData } = useAppContext();

  useEffect(() => {
    if (!searchQuery) setIsLoading(true);
    // Api.get(`/customer/?search=${searchQuery}`)
    let query = "";
    let userRole = localStorage.getItem("role");
    if (userRole === "cityadmin") query = `cityId=${userData?.City?.id}`;
    Api.get(`/customer?name=${searchQuery}&${query}`)
      .then((response) => {
        setCustomers(response.data.results);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [searchQuery, userData]);

  return (
    <Menu>
      <div className="flex flex-col w-full min-h-[90vh] items-start space-y-5 ">
        {isLoading ? (
          <div className=" flex justify-center items-center m-auto">
            <LoadingAnimation />
          </div>
        ) : (
          <>
            <h1 className="sub-heading-text py-2 border-b-2 border-b-gray-300 w-full">
              Users
            </h1>
            <div className="w-full bg-gray-200 flex justify-center items-center">
              <div className="py-2 px-4 bg-color app-text-color cursor-pointer rounded-md">
                Users List
              </div>

              {/* <Link
            className="py-2 px-4 hover:heading-bg-color hover:bg-text-color cursor-pointer rounded-md"
            to={"/users/create"}
          >
            create User
          </Link> */}
            </div>
            <div className="w-full flex items-end justify-end">
              <TextField
                InputProps={{
                  startAdornment: <BsSearch />,
                }}
                placeholder="Search Here..."
                variant="outlined"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>
            <UsersList
              setIsLoading={setIsLoading}
              searchQuery={searchQuery}
              customers={customers}
              setCustomers={setCustomers}
            />
          </>
        )}
      </div>
    </Menu>
  );
};

export default Users;
