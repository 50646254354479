import React, { useEffect, useState } from "react";
import Menu from "../sideMenu/Menu";
import Api from "../util/axiosInstance";
import Translate from "../util/Translate";
import { useTranslation } from "react-i18next";
import Toast from "../util/CustomToast";
import LoadingSpinner from "../util/LoadingSpinner";

const CreateShop = () => {
  const [cities, setCities] = useState([]);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [malls, setMalls] = useState([]);

  const [inputValues, setInputValues] = useState({
    name: "",
    phone: "",
    address: "",
    latitude: "",
    longitude: "",
    image: null,
    cityId: "",
    mallId: "",
    sundayFrom: "",
    sundayTo: "",
    mondayFrom: "",
    mondayTo: "",
    tuesdayFrom: "",
    tuesdayTo: "",
    wednesdayFrom: "",
    wednesdayTo: "",
    thursdayFrom: "",
    thursdayTo: "",
    fridayFrom: "",
    fridayTo: "",
    saturdayFrom: "",
    saturdayTo: "",
    active: true,
  });

  const handleInputChange = (e) => {
    // handle checkbox input
    if (e.target.type === "checkbox") {
      setInputValues({ ...inputValues, [e.target.name]: e.target.checked });
      return;
    }

    setInputValues({ ...inputValues, [e.target.name]: e.target.value });
  };
  const handleFileChange = (e) => {
    setInputValues({ ...inputValues, [e.target.name]: e.target.files[0] });
  };

  useEffect(() => {
    Api.get("/city/getall")
      .then((res) => {
        setCities(res.data.cities);
      })
      .catch((err) => console.log(err));

    Api.get("/mall/getall")
      .then((res) => {
        setMalls(res.data.result);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  const getCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        setInputValues({
          ...inputValues,
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        });
      });
    } else {
      Toast("error", "Geolocation is not supported by this browser.");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    // create form data
    const formData = new FormData();
    for (let key in inputValues) {
      formData.append(key, inputValues[key]);
    }

    Api.post("/shop/create", formData)
      .then((res) => {
        console.log(res.data);
        Toast("success", res.data.message);
      })
      .catch((err) => {
        console.log(err);
        Toast("error", err.response.data.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <Menu>
      <div>
        <div className="mb-5 heading-text">
          <Translate>Shops</Translate>
        </div>
        <div className="w-full flex justify-center">
          <form
            className="w-2/3 flex flex-col space-y-20 my-10"
            onSubmit={handleSubmit}
          >
            <fieldset>
              <legend>
                <Translate>SHOP DETAILS</Translate>
              </legend>
              <div className="create-form">
                <div>
                  <label htmlFor="name">
                    <Translate>Name</Translate>
                  </label>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    placeholder={t("Name")}
                    onChange={handleInputChange}
                    value={inputValues.name}
                  />
                </div>

                <div>
                  <label htmlFor="phone">
                    <Translate>Phone</Translate>
                  </label>
                  <input
                    type="text"
                    name="phone"
                    id="phone"
                    placeholder={t("Phone")}
                    onChange={handleInputChange}
                    value={inputValues.phone}
                  />
                </div>
                <div>
                  <label htmlFor="address">
                    <Translate>Address</Translate>
                  </label>
                  <input
                    type="text"
                    name="address"
                    id="address"
                    placeholder={t("Address")}
                    onChange={handleInputChange}
                    value={inputValues.address}
                  />
                </div>
                <div>
                  <label htmlFor="image">
                    <Translate>Image</Translate>
                  </label>
                  <input
                    type="file"
                    name="image"
                    id="image"
                    onChange={handleFileChange}
                  />
                </div>
                <div className="flex flex-col space-y-3">
                  <div className="flex flex-col space-y-2">
                    <label htmlFor="latitude">
                      <Translate>Latitude</Translate>
                    </label>
                    <input
                      type="text"
                      name="latitude"
                      id="latitude"
                      placeholder={t("Latitude")}
                      onChange={handleInputChange}
                      value={inputValues.latitude}
                    />
                  </div>
                  <div className="flex flex-col space-y-2">
                    <label htmlFor="longitude">
                      <Translate>Longitude</Translate>
                    </label>
                    <input
                      type="text"
                      name="longitude"
                      id="longitude"
                      placeholder={t("Longitude")}
                      onChange={handleInputChange}
                      value={inputValues.longitude}
                    />
                  </div>
                  <div
                    className="px-3 py-2 heading-bg-color bg-text-color rounded-md cursor-pointer"
                    onClick={getCurrentLocation}
                  >
                    <Translate>Get Current Location</Translate>
                  </div>
                </div>

                <div>
                  <label htmlFor="cityId">
                    <Translate>City</Translate>
                  </label>
                  <select
                    name="cityId"
                    id="cityId"
                    onChange={handleInputChange}
                    value={inputValues.cityId}
                  >
                    <option>
                      <Translate>Select City</Translate>
                    </option>
                    {cities.map((city) => (
                      <option value={city.id}>{city.name}</option>
                    ))}
                  </select>
                </div>
                {/* mall */}
                <div>
                  <label htmlFor="mallId">
                    <Translate>Mall</Translate>-<Translate>optional</Translate>
                  </label>
                  <select
                    name="mallId"
                    id="mallId"
                    onChange={handleInputChange}
                    value={inputValues.mallId}
                  >
                    <option value="">
                      <Translate>Select Mall</Translate>
                    </option>
                    {malls.map((mall) => (
                      <option key={mall.id} value={mall.id}>
                        {mall.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </fieldset>

            <fieldset>
              <legend>
                <Translate>OPERATING HOURS</Translate>
              </legend>
              <div className="px-5">
                <div>
                  <div>
                    <div className="flex flex-col items-start space-y-2 my-2">
                      <label htmlFor="sunday">
                        <Translate>Sunday</Translate>
                      </label>
                    </div>

                    <table>
                      <tbody>
                        <tr>
                          <th>
                            <Translate>From</Translate>
                          </th>
                          <th>
                            <Translate>To</Translate>
                          </th>
                        </tr>
                        <tr>
                          <td className="w-[40%]">
                            <input
                              type="time"
                              name="sundayFrom"
                              id="sundayFrom"
                              onChange={handleInputChange}
                              value={inputValues.sundayFrom}
                            />
                          </td>
                          <td className="w-[40%]">
                            <input
                              type="time"
                              name="sundayTo"
                              id="sundayTo"
                              onChange={handleInputChange}
                              value={inputValues.sundayTo}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div>
                    <div className="flex flex-col items-start space-y-2 my-2">
                      <label htmlFor="monday">
                        <Translate>Monday</Translate>
                      </label>
                    </div>

                    <table>
                      <tbody>
                        <tr>
                          <th>
                            <Translate>From</Translate>
                          </th>
                          <th>
                            <Translate>To</Translate>
                          </th>
                        </tr>
                        <tr>
                          <td className="w-[40%]">
                            <input
                              type="time"
                              name="mondayFrom"
                              id="mondayFrom"
                              onChange={handleInputChange}
                              value={inputValues.mondayFrom}
                            />
                          </td>
                          <td className="w-[40%]">
                            <input
                              type="time"
                              name="mondayTo"
                              id="mondayTo"
                              onChange={handleInputChange}
                              value={inputValues.mondayTo}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div>
                    <div className="flex flex-col items-start space-y-2 my-2">
                      <label htmlFor="tuesday">
                        <Translate>Tuesday</Translate>
                      </label>
                    </div>

                    <table>
                      <tbody>
                        <tr>
                          <th>
                            <Translate>From</Translate>
                          </th>
                          <th>
                            <Translate>To</Translate>
                          </th>
                        </tr>
                        <tr>
                          <td className="w-[40%]">
                            <input
                              type="time"
                              name="tuesdayFrom"
                              id="tuesdayFrom"
                              onChange={handleInputChange}
                              value={inputValues.tuesdayFrom}
                            />
                          </td>
                          <td className="w-[40%]">
                            <input
                              type="time"
                              name="tuesdayTo"
                              id="tuesdayTo"
                              onChange={handleInputChange}
                              value={inputValues.tuesdayTo}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div>
                    <div className="flex flex-col items-start space-y-2 my-2">
                      <label htmlFor="wednesday">
                        <Translate>Wednesday</Translate>
                      </label>
                    </div>

                    <table>
                      <tbody>
                        <tr>
                          <th>
                            <Translate>From</Translate>
                          </th>
                          <th>
                            <Translate>To</Translate>
                          </th>
                        </tr>
                        <tr>
                          <td className="w-[40%]">
                            <input
                              type="time"
                              name="wednesdayFrom"
                              id="wednesdayFrom"
                              onChange={handleInputChange}
                              value={inputValues.wednesdayFrom}
                            />
                          </td>
                          <td className="w-[40%]">
                            <input
                              type="time"
                              name="wednesdayTo"
                              id="wednesdayTo"
                              onChange={handleInputChange}
                              value={inputValues.wednesdayTo}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div>
                    <div className="flex flex-col items-start space-y-2 my-2">
                      <label htmlFor="thursday">
                        <Translate>Thursday</Translate>
                      </label>
                    </div>

                    <table>
                      <tbody>
                        <tr>
                          <th>
                            <Translate>From</Translate>
                          </th>
                          <th>
                            <Translate>To</Translate>
                          </th>
                        </tr>
                        <tr>
                          <td className="w-[40%]">
                            <input
                              type="time"
                              name="thursdayFrom"
                              id="thursdayFrom"
                              onChange={handleInputChange}
                              value={inputValues.thursdayFrom}
                            />
                          </td>
                          <td className="w-[40%]">
                            <input
                              type="time"
                              name="thursdayTo"
                              id="thursdayTo"
                              onChange={handleInputChange}
                              value={inputValues.thursdayTo}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div>
                    <div className="flex flex-col items-start space-y-2 my-2">
                      <label htmlFor="friday">
                        <Translate>Friday</Translate>
                      </label>
                    </div>

                    <table>
                      <tbody>
                        <tr>
                          <th>
                            <Translate>From</Translate>
                          </th>
                          <th>
                            <Translate>To</Translate>
                          </th>
                        </tr>
                        <tr>
                          <td className="w-[40%]">
                            <input
                              type="time"
                              name="fridayFrom"
                              id="fridayFrom"
                              onChange={handleInputChange}
                              value={inputValues.fridayFrom}
                            />
                          </td>
                          <td className="w-[40%]">
                            <input
                              type="time"
                              name="fridayTo"
                              id="fridayTo"
                              onChange={handleInputChange}
                              value={inputValues.fridayTo}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div>
                    <div className="flex flex-col items-start space-y-2 my-2">
                      <label htmlFor="saturday">
                        <Translate>Saturday</Translate>
                      </label>
                    </div>

                    <table>
                      <tbody>
                        <tr>
                          <th>
                            <Translate>From</Translate>
                          </th>
                          <th>
                            <Translate>To</Translate>
                          </th>
                        </tr>
                        <tr>
                          <td className="w-[40%]">
                            <input
                              type="time"
                              name="saturdayFrom"
                              id="saturdayFrom"
                              onChange={handleInputChange}
                              value={inputValues.saturdayFrom}
                            />
                          </td>
                          <td className="w-[40%]">
                            <input
                              type="time"
                              name="saturdayTo"
                              id="saturdayTo"
                              onChange={handleInputChange}
                              value={inputValues.saturdayTo}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </fieldset>
            <fieldset>
              <legend>
                <Translate>SHOP (ACTIVATE/DEACTIVATE)</Translate>
              </legend>
              <div className="px-5">
                <div className="flex space-x-3 items-center">
                  <input
                    type="checkbox"
                    name="active"
                    id="active"
                    onChange={handleInputChange}
                    checked={inputValues.active}
                  />
                  <label htmlFor="active">
                    <Translate>Active</Translate>
                  </label>
                </div>
              </div>
            </fieldset>

            <div className="flex justify-center space-x-5">
              <input
                className="heading-bg-color bg-text-color px-5 py-2 rounded-md cursor-pointer"
                type="submit"
                value={t("Save")}
              />

              <div className="heading-bg-color bg-text-color px-5 py-2 rounded-md cursor-pointer">
                <Translate>Back</Translate>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Menu>
  );
};

export default CreateShop;
