import React, { useState } from "react";
import Menu from "../sideMenu/Menu";
import Translate from "../util/Translate";
import { useTranslation } from "react-i18next";
import Api from "../util/axiosInstance";
import { useNavigate } from "react-router-dom";
import Toast from "../util/CustomToast";
import LoadingSpinner from "../util/LoadingSpinner";
import { cloudinaryUpload } from "../../utils/cloudinaryUpload";

const CreateCategory = () => {
  const [inputValues, setInputValues] = useState({
    name: "",
    image: "",
    publish: true,
    type: "",
  });
  const [loading, setLoading] = useState(false);
  const Navigate = useNavigate();
  const { t } = useTranslation();

  const handleFileChange = (e) => {
    setInputValues({
      ...inputValues,
      image: e.target.files[0],
    });
  };

  const handleInputChange = (e) => {
    // handle checkbox input
    if (e.target.type === "checkbox") {
      setInputValues({
        ...inputValues,
        [e.target.name]: e.target.checked,
      });
      return;
    }

    setInputValues({ ...inputValues, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(false);

    const image = await cloudinaryUpload(inputValues.image);

    Api.post("/category", {
      ...inputValues,
      image,
    })
      .then((res) => {
        console.log(res.data);
        Navigate(-1);
      })
      .catch((err) => {
        console.log(err);
        Toast("error", err.response.data.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <Menu>
      <div>
        <div className="mb-5 heading-text">
          <Translate>Create Category</Translate>
        </div>
        <div className="w-full flex justify-center">
          <form
            className="w-2/3 flex flex-col space-y-20 my-10"
            onSubmit={handleSubmit}
          >
            <fieldset>
              <legend>
                <Translate>Category Details</Translate>
              </legend>
              <div className="px-5 my-3 flex flex-col space-y-10">
                <div className="input-group">
                  <label htmlFor="name">
                    <Translate>Name</Translate>
                  </label>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    required
                    min={3}
                    max={20}
                    placeholder={t("Name")}
                    value={inputValues.name}
                    onChange={handleInputChange}
                  />
                </div>

                {/* type of category */}
                <div className="input-group">
                  <label htmlFor="type">
                    <Translate>Type</Translate>
                  </label>
                  <select
                    name="type"
                    id="type"
                    required
                    onChange={handleInputChange}
                    value={inputValues.type}
                  >
                    <option value="">Select Type</option>
                    <option value="product">Product</option>
                    <option value="service">Service</option>
                  </select>
                </div>

                <div className="flex space-x-3">
                  <input
                    type="checkbox"
                    name="publish"
                    id="publish"
                    onChange={handleInputChange}
                    checked={inputValues.publish}
                  />
                  <label htmlFor="publish">
                    <Translate>Publish</Translate>
                  </label>
                </div>
                <div className="flex flex-col space-y-3">
                  <label htmlFor="image">
                    <Translate>Image</Translate>
                  </label>
                  <input
                    required
                    type="file"
                    name="image"
                    id="image"
                    onChange={handleFileChange}
                  />
                </div>
              </div>
            </fieldset>

            {/* <fieldset>
              <legend>Review Attributes</legend>
              <div className="px-5 my-3 flex flex-col space-y-5">
                <div className="flex space-x-3">
                  <input type="checkbox" name="foodQuality" id="foodQuality" />
                  <label htmlFor="foodQuality">Food Quality</label>
                </div>
                <div className="flex space-x-3">
                  <input
                    type="checkbox"
                    name="politeBehaviour"
                    id="politeBehaviour"
                  />
                  <label htmlFor="politeBehaviour">Polite Behaviour</label>
                </div>
                <div className="flex space-x-3">
                  <input
                    type="checkbox"
                    name="flavoursOfFood"
                    id="flavoursOfFood"
                  />
                  <label htmlFor="flavoursOfFood">Flavours Of Food</label>
                </div>
                <div className="flex space-x-3">
                  <input
                    type="checkbox"
                    name="textureOfFood"
                    id="textureOfFood"
                  />
                  <label htmlFor="textureOfFood">Texture Of Food</label>
                </div>
                <div className="flex space-x-3">
                  <input
                    type="checkbox"
                    name="hygienicFood"
                    id="hygienicFood"
                  />
                  <label htmlFor="hygienicFood">Hygienic Food</label>
                </div>
                <div className="flex space-x-3">
                  <input
                    type="checkbox"
                    name="packingOfFood"
                    id="packingOfFood"
                  />
                  <label htmlFor="packingOfFood">Packing Of Food</label>
                </div>
              </div>
            </fieldset> */}

            <div className="flex justify-center space-x-5">
              <button
                className="heading-bg-color bg-text-color px-5 py-2 rounded-md cursor-pointer"
                type="submit"
              >
                <Translate>Save</Translate>
              </button>
              <div className="heading-bg-color bg-text-color px-5 py-2 rounded-md cursor-pointer">
                <Translate>Back</Translate>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Menu>
  );
};

export default CreateCategory;
