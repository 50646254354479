import React, { useEffect, useState } from "react";
import { useAppContext } from "../../hooks/context/tokenStore";
import { db } from "../../config/Firebase";
import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { Avatar, InputAdornment, TextField } from "@mui/material";
import moment from "moment"; // Import moment.js
import { IoSearchOutline } from "react-icons/io5";
import { generateRandomAvatar } from "../../utils/generateRandomAvatar"; // Import the avatar generator

const AllChats = ({
  currrentChat,
  setCurrentChat,
  userData,
  allChats,
  setAllChats,
}) => {
  const navigate = useNavigate();
  const [chatStyleToggleId, setChatStyleToggleId] = useState(null);
  const [searchTerm, setSearchTerm] = useState(""); // State for search term

  useEffect(() => {
    let targetedChats =
      localStorage.getItem("role") === "vendor" ? "chats" : "serviceChats";

    const q = query(
      collection(db, targetedChats, userData.id, "users"),
      orderBy("updatedAt", "desc")
    );

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const chats = [];
      querySnapshot.forEach((doc) => {
        chats.push(doc.data());
      });
      setAllChats(chats);
    });
  }, [userData.id]);

  // Filter chats based on the search term
  const filteredChats = allChats.filter((chat) =>
    chat.userName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="w-[300px] rounded-tl-2xl rounded-bl-2xl p-3 h-full border-r shadow overflow-y-auto">
      <div className="flex flex-col space-y-3">
        <TextField
          fullWidth
          placeholder="Search..."
          value={searchTerm} // Controlled input
          onChange={(e) => setSearchTerm(e.target.value)} // Update search term state
          InputProps={{
            startAdornment: (
              <InputAdornment>
                <IoSearchOutline />
              </InputAdornment>
            ),
          }}
        />
        {filteredChats.length === 0 && (
          <div className="sub-heading-text app-text-color">No chats found</div>
        )}

        {filteredChats.map((item, index) => {
          console.log("🚀 ~ {filteredChats.map ~ item:", item)
          const { color, initial } = generateRandomAvatar(item.userName);

          return (
            <div
              key={index}
              className={`cursor-pointer capitalize shadow flex flex-col justify-between px-3 py-2 rounded-md transition-colors ${
                item.userId === chatStyleToggleId
                  ? "bg-blue-100"
                  : "bg-white hover:bg-gray-200"
              }`}
              onClick={() => {
                setCurrentChat(item.userId);
                setChatStyleToggleId(item.userId);
              }}
            >
              <div className="flex items-center space-x-3">
                <Avatar sx={{ width: 42, height: 42, bgcolor: color }}>
                  {initial}
                </Avatar>
                <div className="flex flex-col">
                  <div className="text-sm text-gray-800">{item.userName}</div>
                  <div className="text-xs text-gray-500">
                    {/* show 30 characters of the last message */}
                    {item.lastMessage.slice(0, 30)}
                    {item.lastMessage.length > 30 && "..."}
                  </div>
                </div>
              </div>
              <div className="flex flex-col -mt-3 items-end">
                {/* Show last message timestamp using moment */}
                <div className="text-xs text-gray-400">
                  {moment.unix(item.updatedAt.seconds).fromNow()}
                </div>
                <div>
                  {item.vendorUnreadCount > 0 && (
                    <span className="text-xs text-red-500">
                      {item.vendorUnreadCount}
                    </span>
                  )}
                  {item.professionalUnreadCount > 0 && (
                    <span className="text-xs text-red-500">
                      {item.professionalUnreadCount}
                    </span>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default AllChats;
