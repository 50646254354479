import React, { useState } from "react";
import Menu from "../../sideMenu/Menu";
import Translate from "../../util/Translate";
import ReactQuill from "react-quill";
import Api from "../../util/axiosInstance";
import { useNavigate } from "react-router-dom";

const editorModules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }],
    ["bold", "italic", "underline", "strike"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["clean"],
    ["code-block"],
  ],
};

const CreateProfessionalSubscription = () => {
  const [description, setDescription] = useState("");
  const Navigate = useNavigate();
  const [inputValues, setInputValues] = useState({
    title: "",
    monthlyPrice: "",
    yearlyPrice: "",
    type: "",
    description: "",
  });

  const handleInputChange = (e) => {
    setInputValues({ ...inputValues, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    Api.post("/professional-subscription", {
      ...inputValues,
    })
      .then((res) => {
        console.log(res.data);
        Navigate(-1);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Menu>
      <div>
        <div className="mb-5 heading-text">
          <Translate>Professional Subscription</Translate>
        </div>
        <div className="w-full flex justify-center">
          <form
            className="w-2/3 flex flex-col space-y-20 my-10"
            onSubmit={handleSubmit}
          >
            <fieldset className="py-10">
              <legend>
                <Translate>Subscription DETAILS</Translate>
              </legend>
              <div className="create-form">
                <div>
                  <label>
                    <Translate>Title</Translate>
                  </label>
                  <input
                    type="text"
                    name="title"
                    value={inputValues.title}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div>
                  <label>
                    <Translate>Monthly Price</Translate>
                  </label>
                  <input
                    type="number"
                    name="monthlyPrice"
                    value={inputValues.monthlyPrice}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div>
                  <label>
                    <Translate>Yearly Price</Translate>
                  </label>
                  <input
                    type="number"
                    name="yearlyPrice"
                    value={inputValues.yearlyPrice}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div>
                  <label>
                    <Translate>Type</Translate>
                  </label>
                  <select
                    name="type"
                    value={inputValues.type}
                    onChange={handleInputChange}
                    required
                  >
                    <option value="">Select Type</option>
                    <option value="MAIN">Main</option>
                    <option value="CUSTOM">Custom</option>
                  </select>
                </div>
              </div>
              <div className="p-5 flex flex-col space-y-3">
                <label>
                  <Translate>Description</Translate>
                </label>
                <ReactQuill
                  theme="snow"
                  modules={editorModules}
                  value={inputValues.description}
                  onChange={(e) => {
                    setInputValues({ ...inputValues, description: e });
                  }}
                  className="h-56"
                />
              </div>
            </fieldset>
            <div className="flex justify-center space-x-5">
              <button type="submit" className="btn">
                <Translate>Save</Translate>
              </button>
              <div className="btn">
                <Translate>Back</Translate>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Menu>
  );
};

export default CreateProfessionalSubscription;
