import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import AdminRow from "./AdminRow";
import Api from "../util/axiosInstance";
import Translate from "../util/Translate";

const Admin = {
  firstName: "John",
  lastName: "Doe",
  image: "https://picsum.photos/800/800",
  email: "waleed@yopmail.com",
  city: "name",
  createdAt: "2021-01-01",
  active: true,
};

const AdminsList = ({ setAdmins, admins, setLoading }) => {
  const handleActivateToggle = (adminId) => {
    setLoading(true);
    Api.patch(`/admin/${adminId}`, {
      active: !admins.find((admin) => admin.id === adminId)?.active,
    })
      .then((res) => {
        const updatedAdmins = admins.map((admin) => {
          if (admin.id === adminId) {
            admin.active = !admin.active;
          }
          return admin;
        });
        setAdmins(updatedAdmins);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setLoading(false));
  };

  const handleDeleteAdmin = (id) => {
    setLoading(true);
    Api.delete(`/admin/${id}`)
      .then((response) => {
        setAdmins(admins.filter((customer) => customer.id !== id));
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => setLoading(false));
  };
  return (
    <div className="w-full">
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <Translate>All</Translate>
            </TableCell>
            <TableCell>
              <Translate>Image</Translate>
            </TableCell>
            <TableCell>
              <Translate>First Name</Translate>
            </TableCell>
            <TableCell>
              <Translate>Last Name</Translate>
            </TableCell>
            <TableCell>
              <Translate>Email</Translate>
            </TableCell>
            <TableCell>
              <Translate>City</Translate>
            </TableCell>
            <TableCell>
              <Translate>Created At</Translate>
            </TableCell>
            <TableCell>
              <Translate>Active</Translate>
            </TableCell>
            <TableCell>
              <Translate>Actions</Translate>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {admins.map((admin) => (
            <AdminRow
              key={admin.id}
              admin={admin}
              handleActivateToggle={handleActivateToggle}
              handleDeleteAdmin={handleDeleteAdmin}
            />
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default AdminsList;
