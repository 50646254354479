import React from "react";
import { MdDelete } from "react-icons/md";
import ConfirmationModal from "./ConfirmationModal";

const DeleteComponent = ({ id, deleteFuction }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  return (
    <>
      <div
        onClick={() => {
          setIsOpen(true);
        }}
      >
        <MdDelete
          className="text-red-700 cursor-pointer hover:scale-125 transition 
          duration-100 ease-in-out transform 
          "
          size={20}
        />
      </div>
      <ConfirmationModal
        deleteFuction={deleteFuction}
        id={id}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
    </>
  );
};

export default DeleteComponent;
