import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CategoryRow from "./CategoryRow";
import { useSSR } from "react-i18next";
import Api from "../util/axiosInstance";
import LoadingSpinner from "../util/LoadingSpinner";
import Translate from "../util/Translate";

const Category = {
  name: "electronics",
  image: "https://picsum.photos/800/800",
  products: 23,
  publish: true,
};

const CategoryList = () => {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    Api.get("/category")
      .then((res) => {
        setCategories(res.data.results);
        })
        .catch((err) => {
          console.log(err.message);
        });
    }, []);

    const handleDeleteCategory = (id) => {
      setLoading(true);
      Api.delete(`/category/${id}`)
        .then((res) => {
          console.log(res.data);
          setCategories(categories.filter((category) => category.id !== id));
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setLoading(false);
        });
    };
    const handleEditCategory = (id) => {
    setLoading(true);
    const editPublishStatus = categories?.find((it) => it.id === id)?.publish;
    Api.patch(`/category/${id}`, {
      publish: !editPublishStatus,
    })
      .then((res) => {
        const updatedCatgories = categories?.map((item) => {
          if (item.id === id) {
            return {
              ...item,
              publish: !editPublishStatus,
            };
          }
          return item;
        });
        setCategories(updatedCatgories);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <div className="w-full">
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>All</TableCell>
            <TableCell>
              <Translate>Name</Translate>
            </TableCell>
            <TableCell>
              <Translate>Products</Translate>
            </TableCell>
            <TableCell>
              <Translate>Type</Translate>
            </TableCell>
            <TableCell>
              <Translate>Publish</Translate>
            </TableCell>
            <TableCell>
              <Translate>Actions</Translate>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {categories.map((category) => {
            return (
              <CategoryRow
                category={category}
                handleDeleteCategory={handleDeleteCategory}
                handleEditCategory={handleEditCategory}
              />
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
};

export default CategoryList;
