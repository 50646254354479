import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Menu from "../sideMenu/Menu";
import ProductsList from "./ProductsList";
import Translate from "../util/Translate";
import { useAppContext } from "../../hooks/context/tokenStore";
import { URL } from "../util/authorization/authorizationURL";
import Api from "../util/axiosInstance";
import LoadingAnimation from "../util/LoadingAnimation";

const Products = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const { userData } = useAppContext();
  const userRole = localStorage.getItem("role");
  useEffect(() => {
    setLoading(true);
    let url = URL.products[userRole];
    let filter = "";

    if (userRole === "cityadmin") {
      filter = `?cityId=${userData?.City?.id}`;
    }
    if (userRole === "vendor") {
      filter = `?vendorId=${userData?.id}`;
    }

    Api.get(url + (!!filter?.length ? `${filter}&limit=500` : `?limit=500`))
      .then((res) => {
        setProducts(res.data.results);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [userData.id]);
  return (
    <Menu>
      {loading ? (
        <div className=" flex justify-center min-h-[90vh] items-center m-auto">
          <LoadingAnimation />
        </div>
      ) : (
        <div className="flex flex-col items-start space-y-5 ">
          <h1 className="sub-heading-text py-2 border-b-2 border-b-gray-300 w-full">
            <Translate>Products</Translate>
          </h1>
          <div className="w-full bg-gray-200 flex justify-center items-center">
            <div className="py-2 px-4 bg-color app-text-color cursor-pointer rounded-md">
              <Translate>Products List</Translate>
            </div>
            <Link
              className="py-2 px-4 hover:heading-bg-color hover:bg-text-color cursor-pointer rounded-md"
              to={"/products/create"}
            >
              <Translate>Create Product</Translate>
            </Link>
          </div>
          <ProductsList
            products={products}
            setLoading={setLoading}
            setProducts={setProducts}
          />
        </div>
      )}
    </Menu>
  );
};

export default Products;
