import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import OrderRow from "./OrderRow";
import Api from "../util/axiosInstance";
import { URL } from "../util/authorization/authorizationURL";

const Order = {
  id: "L348kajdsfh",
  shop: "Ethad Electronics",
  drivers: "John Doe",
  customer: "John Doe",
  createdAt: "2021-10-05",
  amount: "200",
  orderType: "Delivery",
};

const OrdersList = ({
  orders,
  setOrders,
  filter,
  setFilter,
  setLoading,
  userRole,
}) => {
  const handleDeleteOrder = (orderId) => {
    let url;
    if (filter === "vendor") url = "/product-order/";
    else url = "/service-order/";
    setLoading(true);
    Api.delete(url + orderId)
      .then((res) => {
        console.log(res.data);
        setOrders(orders.filter((order) => order.id !== orderId));
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <div className="w-full">
      <div>
        <div className="flex justify-between items-center">
          <h1 className="sub-heading-text">Orders</h1>
          {(userRole === "superadmin" ||
            userRole === "cityadmin" ||
            userRole === "logisticadmin") && (
            <div>
              <select
                className="border border-gray-300 rounded-md p-1"
                onChange={(e) => {
                  setFilter(e.target.value);
                }}
              >
                <option value="vendor">Vendor</option>
                <option value="professional">Professional</option>
              </select>
            </div>
          )}
        </div>
      </div>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>All</TableCell>
            <TableCell>Order ID</TableCell>
            <TableCell>Amount</TableCell>
            <TableCell>Customer</TableCell>
            <TableCell>Date</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {orders.map((order) => {
            return (
              <OrderRow
                filter={filter}
                order={order}
                handleDeleteOrder={handleDeleteOrder}
              />
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
};

export default OrdersList;
