import React, { useEffect, useState } from "react";
import Menu from "../sideMenu/Menu";
import Translate from "../util/Translate";
import { useAppContext } from "../../hooks/context/tokenStore";
import Api from "../util/axiosInstance";
import { URL } from "../util/authorization/authorizationURL";
import LoadingSpinner from "../util/LoadingSpinner";
import useUser from "../../hooks/useUser";

const SocialLinks = () => {
  const { userData } = useAppContext();
  const [loading, setLoading] = useState(false);
  const [sociallinks, setSocialLinks] = useState({
    instagram: "",
    tiktok: "",
    facebook: "",
    website: "",
  });
  const userRole = localStorage.getItem("role");

  const handleInputChange = (e) => {
    setSocialLinks({ ...sociallinks, [e.target.name]: e.target.value });
  };
  useEffect(() => {
    if (userData) {
      setLoading(true);
      let filter = `vendorId=${userData?.id}`;
      if (userRole === "professional")
        filter = `professionalId=${userData?.id}`;
      // Api.get(`/social-links/${userData.SocialLinks.id}`)
      Api.get(`/social-links?${filter}`)
        .then((res) => {
          const [data] = res?.data?.results;
          setSocialLinks({
            instagram: data?.instagram,
            tiktok: data?.tiktok,
            facebook: data?.facebook,
            website: data?.website,
            id: data?.id,
          });
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [userData]);
  const handleSubmit = (e) => {
    e.preventDefault();
    const updatedSocialLinks = {
      ...sociallinks
    }
    if(!updatedSocialLinks.instagram?.length === 0) delete updatedSocialLinks.instagram
    if(!updatedSocialLinks.tiktok?.length === 0) delete updatedSocialLinks.tiktok
    if(!updatedSocialLinks.facebook?.length === 0) delete updatedSocialLinks.facebook
    if(!updatedSocialLinks.website?.length === 0) delete updatedSocialLinks.website
    if (sociallinks?.id) {
      Api.patch(`/social-links/${sociallinks?.id}`, updatedSocialLinks)
        .then((res) => {
          setSocialLinks({
            instagram: res.data.data.instagram,
            tiktok: res.data.data.tiktok,
            facebook: res.data.data.facebook,
            website: res.data.data.website,
          });
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          window.location.reload();
        });
    } else {
      const url = URL.socialLink[userRole];
      const filter = {};
      if (userRole === "vendor") filter.vendorId = userData.id;
      else if (userRole === "professional") filter.professionalId = userData.id;
      Api.post(url, { ...sociallinks, ...filter })
        .then((res) => {
          setSocialLinks({
            instagram: res.data.data.instagram,
            tiktok: res.data.data.tiktok,
            facebook: res.data.data.facebook,
            website: res.data.data.website,
          });
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          window.location.reload();
        });
    }
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <Menu>
      <div className="heading-text">Social Links</div>
      <div className="w-full flex justify-center">
        <form
          className="w-2/3 flex flex-col space-y-20 my-10"
          onSubmit={handleSubmit}
        >
          <fieldset>
            <legend>Social Links</legend>
            <div className="create-form">
              <div>
                <label htmlFor="instagram">
                  <Translate>Instagram</Translate>
                </label>
                <input
                  type="text"
                  name="instagram"
                  id="instagram"
                  placeholder="Instagram"
                  value={sociallinks.instagram}
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <label htmlFor="tiktok">
                  <Translate>Tiktok</Translate>
                </label>
                <input
                  type="text"
                  name="tiktok"
                  id="tiktok"
                  placeholder="Tiktok"
                  value={sociallinks.tiktok}
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <label htmlFor="facebook">
                  <Translate>Facebook</Translate>
                </label>
                <input
                  type="text"
                  name="facebook"
                  id="facebook"
                  placeholder="Facebook"
                  value={sociallinks.facebook}
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <label htmlFor="website">
                  <Translate>Website</Translate>
                </label>
                <input
                  type="text"
                  name="website"
                  id="website"
                  placeholder="Website"
                  value={sociallinks.website}
                  onChange={handleInputChange}
                />
              </div>
            </div>
          </fieldset>
          <div className="flex justify-center">
            <button type="submit" className="btn">
              Save
            </button>
          </div>
        </form>
      </div>
    </Menu>
  );
};

export default SocialLinks;
