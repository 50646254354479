import React, { useState } from "react";
import { MdDelete, MdEdit } from "react-icons/md";
import CustomModal from "../../../reusable/CustomModal";
import EditMainCategory from "./EditMainCategory";
import Api from "../../../util/axiosInstance";
import Translate from "../../../util/Translate";
import ConfirmationModal from "../../../util/ConfirmationModal";

const MainCategories = ({
  categories,
  currentCategory,
  setCurrentCategory,
  setCategories,
  setSubCategories,
}) => {
  const [modelIsOpen, setModalIsOpen] = useState(false);
  const [selectedCategoryId, setSelectedCategoryId] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const openModal = () => {
    setModalIsOpen(true);
  };
  const closeModal = () => {
    setModalIsOpen(false);
  };

  const handleCategoryDelete = (id) => {
    Api.delete(`/professional-category/${id}`)
      .then((res) => {
        console.log(categories);
        let tempCategories = categories.filter(
          (category) => category.id !== id
        );
        setCategories(tempCategories);
        setSubCategories([]);
        setCurrentCategory({});
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="flex flex-col space-y-5 bg-white p-5 w-fit rounded-md">
      <div className="sub-heading-text">
        <Translate>Main Categories</Translate>
      </div>
      <div className="flex flex-col space-y-3 h-[40vh] w-[250px] overflow-y-scroll">
        {categories.map((category, index) => {
          return (
            <>
              <div
                key={category.id}
                className={`flex justify-between px-3 py-2 hover:heading-bg-color hover:bg-text-color cursor-pointer rounded-md
              ${
                currentCategory.id === category.id
                  ? "heading-bg-color bg-text-color"
                  : ""
              }
              `}
                onClick={() => {
                  setCurrentCategory(category);
                }}
              >
                <div>{category.name}</div>
                <div className="flex space-x-3 heading-text">
                  <MdEdit
                    className="cursor-pointer "
                    color="green"
                    onClick={openModal}
                  />
                  <MdDelete
                    className="cursor-pointer"
                    color="red"
                    onClick={() => {
                      setSelectedCategoryId(category.id);
                      setIsOpen(true);
                    }}
                  />
                </div>
              </div>
            </>
          );
        })}
      </div>
      <ConfirmationModal
        deleteFuction={handleCategoryDelete}
        id={selectedCategoryId}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
      <CustomModal
        title="Edit Main Category"
        openModal={openModal}
        closeModal={closeModal}
        isOpen={modelIsOpen}
        setIsOpen={setModalIsOpen}
      >
        <EditMainCategory
          currentCategory={currentCategory}
          setCategories={setCategories}
          categories={categories}
          closeModal={closeModal}
        />
      </CustomModal>
    </div>
  );
};

export default MainCategories;
