// shops
const URL = {
  shops: {
    vendor: "/shop",
    cityadmin: "/shop",
    superadmin: "/shop",
  },
  products: {
    vendor: "/product",
    cityadmin: "/product",
    superadmin: "/product",
  },
  services: {
    professional: "/professional/services",
    cityadmin: "/professional/getallservices/city",
    superadmin: "/professional/getallservices",
  },
  socialLink: {
    professional: "/social-links",
    vendor: "/social-links",
  },
  password: {
    professional: "/professional/resetPassword",
    vendor: "/vendor/resetPassword",
  },
  order: {
    vendor: "/product-order",
    professional: "/service-order",
    cityadmin: {
      vendor: "/product-order",
      professional: "/service-order",
    },
    logisticadmin: {
      vendor: "/product-order",
      professional: "/service-order",
    },
    superadmin: {
      vendor: "/product-order",
      professional: "/service-order",
    },
    delete: {
      vendor: "/order/delete/",
      professional: "/professional/delete/",
    },
  },
  coupon: {
    get: {
      vendor: "/coupon",
      professional: "/coupon",
      superadmin: "/coupon",
      cityadmin: "/coupon",
      admin: "/coupon",
    },
    add: {
      vendor: "/coupon",
      professional: "/coupon",
      superadmin: "/coupon",
      cityadmin: "/coupon",
      admin: "/coupon",
    },
  },
  ticket: {
    get: {
      superadmin: "/ticket",
      customer: "/ticket",
    },
  },
};

export { URL };
