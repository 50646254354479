export const generateRandomAvatar = (userName) => {
  const darkColorMap = {
    A: "#8B0000", // Dark Red
    B: "#006400", // Dark Green
    C: "#00008B", // Dark Blue
    D: "#8B008B", // Dark Magenta
    E: "#B8860B", // Dark Goldenrod
    F: "#008B8B", // Dark Cyan
    G: "#4B0082", // Indigo
    H: "#2F4F4F", // Dark Slate Gray
    I: "#483D8B", // Dark Slate Blue
    J: "#2E8B57", // Sea Green
    K: "#556B2F", // Dark Olive Green
    L: "#8B4513", // Saddle Brown
    M: "#A0522D", // Sienna
    N: "#2C3E50", // Darker Grayish Blue
    O: "#3D3D3D", // Dark Charcoal Gray
    P: "#6A5ACD", // Slate Blue
    Q: "#4B0082", // Indigo
    R: "#800000", // Maroon
    S: "#654321", // Brown
    T: "#FF8C00", // Dark Orange
    U: "#8B0000", // Dark Red
    V: "#8B4513", // Dark Brown
    W: "#4682B4", // Steel Blue
    X: "#2F4F4F", // Dark Slate Gray
    Y: "#556B2F", // Dark Olive Green
    Z: "#2E8B57", // Sea Green
  };

  const initial = userName?.charAt(0)?.toUpperCase() || "A";

  const color = darkColorMap[initial] || "#333"; // Default dark gray

  return { color, initial };
};
