import React, { useEffect, useState } from "react";
import Menu from "../sideMenu/Menu";
import Translate from "../util/Translate";
import { useTranslation } from "react-i18next";
import Api from "../util/axiosInstance";
import { useNavigate } from "react-router-dom";
import Toast from "../util/CustomToast";
import LoadingSpinner from "../util/LoadingSpinner";

const CreateMall = () => {
  const [inputValues, setInputValues] = useState({
    name: "",
    cityId: "",
    publish: true,
    image: "path/to/static/image.jpg", // Set your static image path here
  });
  const [loading, setLoading] = useState(false);
  const Navigate = useNavigate();
  const { t } = useTranslation();
  const [cities, setCities] = useState([]);

  useEffect(() => {
    Api.get("/city").then((response) => {
      setCities(response.data.results);
    });
  }, []);

  const handleInputChange = (e) => {
    if (e.target.type === "checkbox") {
      setInputValues({
        ...inputValues,
        [e.target.name]: e.target.checked,
      });
      return;
    }
    setInputValues({ ...inputValues, [e.target.name]: e.target.value });
  };

  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append("image", file);

      try {
        const response = await Api.post("/cloudinary/upload", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        setInputValues({ ...inputValues, image: response.data.url });
      } catch (error) {
        console.log(error);
        Toast("error", "Image upload failed.");
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    Api.post("/mall", inputValues)
      .then((res) => {
        console.log(res.data);
        Navigate(-1);
      })
      .catch((err) => {
        console.log(err);
        Toast("error", err.response.data.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <Menu>
      <div>
        <div className="mb-5 heading-text">
          <Translate>Create Mall</Translate>
        </div>
        <div className="w-full flex justify-center">
          <form
            className="w-2/3 flex flex-col space-y-20 my-10"
            onSubmit={handleSubmit}
          >
            <fieldset>
              <legend>
                <Translate>Mall Details</Translate>
              </legend>
              <div className="px-5 my-3 flex flex-col space-y-10">
                <div className="input-group">
                  <label htmlFor="name">
                    <Translate>Name</Translate>
                  </label>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    required
                    min={3}
                    max={20}
                    placeholder={t("Name")}
                    value={inputValues.name}
                    onChange={handleInputChange}
                  />
                </div>

                <div className="input-group">
                  <label htmlFor="cityId">
                    <Translate>City</Translate>
                  </label>
                  <select
                    name="cityId"
                    id="cityId"
                    required
                    onChange={handleInputChange}
                  >
                    <option value="">Select City</option>
                    {cities.map((city) => (
                      <option key={city.id} value={city.id}>
                        {city.name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="flex space-x-3">
                  <input
                    type="checkbox"
                    name="publish"
                    id="publish"
                    onChange={handleInputChange}
                    checked={inputValues.publish}
                  />
                  <label htmlFor="publish">
                    <Translate>Publish</Translate>
                  </label>
                </div>

                <div className="flex flex-col space-y-3">
                  <label htmlFor="image">
                    <Translate>Image</Translate>
                  </label>
                  <input
                    type="file"
                    name="image"
                    id="image"
                    accept="image/*"
                    onChange={handleImageUpload}
                  />
                  {!!inputValues.image && (
                    <img
                      src={inputValues.image}
                      alt="Mall"
                      className="w-full h-48 object-cover"
                    />
                  )}
                </div>
              </div>
            </fieldset>

            <div className="flex justify-center space-x-5">
              <button
                className="heading-bg-color bg-text-color px-5 py-2 rounded-md cursor-pointer"
                type="submit"
              >
                <Translate>Save</Translate>
              </button>
              <div className="heading-bg-color bg-text-color px-5 py-2 rounded-md cursor-pointer">
                <Translate>Back</Translate>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Menu>
  );
};

export default CreateMall;
