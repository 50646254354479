import React, { useEffect, useState } from "react";
import { useAppContext } from "../../hooks/context/tokenStore";
import { Link, Outlet } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import useUser from "../../hooks/useUser";
import Api from "../util/axiosInstance";
import { useLocation } from "react-router-dom";
import LoadingSpinner from "../util/LoadingSpinner";

function LoginLink({ link, text }) {
  return (
    <Link
      to={link}
      className="px-5 py-2 heading-bg-color bg-text-color rounded-md"
    >
      {text}
    </Link>
  );
}

const LoginVerified = () => {
  const { userData, isLoadingUser } = useAppContext();

  const navigate = useNavigate();
  // if authenticated the outlet else go to login
  if (isLoadingUser) {
    return <LoadingSpinner />;
  } else if (!userData) {
    return (
      <div className="flex w-full h-screen justify-center items-center flex-col space-y-5">
        <div className="heading-text app-text-color">
          You are Not Logged In.
        </div>
        <div className="sub-heading-text">Please Login to continue.</div>
        <div className="flex space-x-5">
          <LoginLink link="/login/superadmin" text="Super Admin" />
          <LoginLink link="/login/vendoradmin" text="Vendor Admin" />
          <LoginLink
            link="/login/professionaladmin"
            text="Professional Admin"
          />
          <LoginLink link="/login/cityadmin" text="City Admin" />
          <LoginLink link="/login/logisticadmin" text="Logistic Admin" />
        </div>
      </div>
    );
  }

  return <Outlet />;
};

export default LoginVerified;
