import React from "react";
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import RangeFilter from "./RangeFilter";
import Translate from "../util/Translate";

const MultipleReportChart = ({ reportsData }) => {
  return (
    <div className="bg-white  w-fit flex flex-col space-y-5 rounded-lg py-3">
      <div className="flex w-full justify-between items-center px-5">
        <div className="flex items-center space-x-1">
          <div className="heading-text">
            <Translate>Reports</Translate>
          </div>
          {/* <div>/</div>
          <div>Today</div> */}
        </div>
        {/* <div>
          <RangeFilter />
        </div> */}
      </div>
      <div>
        <LineChart
          width={730}
          height={350}
          data={reportsData}
          margin={{ top: 5, right: 30, left: 0, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line
            type="monotone"
            dataKey="sales"
            stroke="green"
            strokeWidth={2}
            dot={{ stroke: "green", strokeWidth: 2, fill: "green" }}
          />
          <Line
            type="monotone"
            dataKey="revenue"
            stroke="blue"
            strokeWidth={2}
            dot={{ stroke: "blue", strokeWidth: 2, fill: "blue" }}
          />
          <Line
            type="monotone"
            dataKey="customers"
            stroke="orange"
            strokeWidth={2}
            dot={{ stroke: "orange", strokeWidth: 2, fill: "orange" }}
          />
        </LineChart>
      </div>
    </div>
  );
};

export default MultipleReportChart;
