import React, { useEffect, useState } from "react";
import Api from "../components/util/axiosInstance";
import { useAppContext } from "./context/tokenStore";

const useDashboardAnalytics = () => {
  const [totalVendors, setTotalVendors] = useState([]);
  const [totalEarnings, setTotalEarnings] = useState([]);
  const [totalProducts, setTotalProducts] = useState([]);
  const [totalCustomers, setTotalCustomers] = useState([]);
  const [reportsData, setReportsData] = useState([]);
  const [orderStatus, setOrderStatus] = useState([]);
  const [topProducts, setTopProducts] = useState([]);
  const [professionalOrdersStatus, setProfessionalOrdersStatus] = useState([]);
  const [topProfessionals, setTopProfessionals] = useState([]);
  const [professionalReportsData, setProfessionalReportsData] = useState([]);
  const [professionalOrders, setProfessionalOrders] = useState([]);
  const [professionalServices, setProfessionalServices] = useState([]);
  const [vendorProducts, setVendorProducts] = useState([]);
  const [vendorOrders, setVendorOrders] = useState([]);
  const [vendorsTable, setVendorsTable] = useState([]);
  const { userData } = useAppContext();
  useEffect(() => {
    const cityId = userData?.City?.id || "";

    const fetchData = async () => {
      try {
        const role = localStorage.getItem("role");
        const promises = [];

        if (
          role === "superadmin" ||
          role === "cityadmin" ||
          role === "logisticadmin"
        ) {
          promises.push(Api.get(`/shop`));
          promises.push(
            Api.get(`/product-order`, {
              params: { cityId: cityId ? cityId : undefined },
            })
          );
          promises.push(
            Api.get(`/product`, {
              params: { cityId: cityId ? cityId : undefined },
            })
          );
          promises.push(
            Api.get(`/customer`, {
              params: { cityId: cityId ? cityId : undefined },
            })
          );
          promises.push(Api.get(`/product-order/stats`));
          promises.push(
            Api.get(`/product-order/stats-order`, {
              params: { cityId: cityId ? cityId : undefined },
            })
          );
          promises.push(Api.get(`/product/stats/top-ten-month`));
          promises.push(Api.get(`/professional/stats/top-ten`));
          promises.push(Api.get(`/shop/stats/top-ten`));
        } else if (role === "professional") {
          promises.push(
            Api.get(`/service-order?professionalId=${userData?.id}&limit=10000`)
          );
          promises.push(Api.get(`/service-order/stats/order`));
          promises.push(
            Api.get(`/admin/report/stats/service-orders-by-status`)
          );
          promises.push(Api.get(`/admin/report/service/topthismonth`));
        } else if (role === "vendor") {
          promises.push(Api.get(`/product?vendorId=${userData?.id}`));
          promises.push(Api.get(`/product-order?vendorId=${userData?.id}`));
          promises.push(
            Api.get(`/product-order/stats-vendor?vendorId=${userData?.id}`)
          );
          promises.push(
            Api.get(`/product-order/stats-order?vendorId=${userData?.id}`)
          );
          promises.push(Api.get(`/product/stats/top-ten-month`));
        }

        const results = await Promise.allSettled(promises);

        results.forEach((result, index) => {
          if (result.status === "fulfilled") {
            const data = result.value.data;

            // You can map the results to the appropriate setter
            if (
              role === "superadmin" ||
              role === "cityadmin" ||
              "logisticadmin"
            ) {
              switch (index) {
                case 0:
                  setTotalVendors(data);
                  break;
                case 1:
                  setTotalEarnings(data.results);
                  break;
                case 2:
                  setTotalProducts(data);
                  break;
                case 3:
                  setTotalCustomers(data);
                  break;
                case 4:
                  setReportsData(data.data);
                  break;
                case 5:
                  setOrderStatus(data.data);
                  break;
                case 6:
                  setTopProducts(data.data);
                  break;
                case 7:
                  setTopProfessionals(data.data);
                  break;
                case 8:
                  setVendorsTable(data.data);
                  break;
                default:
                  break;
              }
            } else if (role === "professional") {
              switch (index) {
                case 0:
                  setProfessionalOrders(data);
                  break;
                case 1:
                  setProfessionalReportsData(data.results);
                  break;
                case 2:
                  setProfessionalOrdersStatus(data.result);
                  break;
                case 3:
                  setProfessionalServices(data.result);
                  break;
                default:
                  break;
              }
            } else if (role === "vendor") {
              switch (index) {
                case 0:
                  setVendorProducts(data);
                  break;
                case 1:
                  setVendorOrders(data);
                  break;
                case 2:
                  setReportsData(data.results);
                  break;
                case 3:
                  setOrderStatus(data.data);
                  break;
                case 4:
                  setTopProducts(data.data);
                  break;
                default:
                  break;
              }
            }
          } else if (result.status === "rejected") {
            console.error(
              `Error fetching data for request ${index}:`,
              result.reason
            );
          }
        });
      } catch (error) {
        console.error("Error fetching dashboard analytics:", error);
      }
    };

    fetchData();
  }, [userData]);

  return {
    totalVendors,
    totalEarnings,
    totalProducts,
    totalCustomers,
    reportsData,
    orderStatus,
    topProducts,
    topProfessionals,
    professionalOrders,
    professionalReportsData,
    professionalOrdersStatus,
    professionalServices,
    vendorOrders,
    vendorsTable,
    vendorProducts,
  };
};

export default useDashboardAnalytics;
