import React, { useState } from "react";
import Api from "../../util/axiosInstance";
import Toast from "../../util/CustomToast";
import Translate from "../../util/Translate";

const url = {
  shop: "/shop-category",
  professional: "/professional-category",
};

const CreateMainCategory = ({ selectedRole, setRecheck }) => {
  const [name, setName] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    Api.post(url[selectedRole], {
      name,
    })
      .then((response) => {
        Toast("success", "Main Category Created Successfully");
      })
      .catch((error) => {
        Toast("error", "Error Creating Main Category");
      })
      .finally(() => {
        setRecheck((prev) => !prev);
        window.location.reload();
      });
  };
  return (
    <div className="bg-white p-5 rounded-md">
      <form className="flex flex-col space-y-5" onSubmit={handleSubmit}>
        <fieldset>
          <legend>
            <Translate>Main Category</Translate>
          </legend>
          <div className="create-form">
            <div>
              <label htmlFor="name">
                <Translate>Name</Translate>
              </label>
              <input
                type="text"
                id="name"
                name="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                minLength={3}
                maxLength={30}
              />
            </div>
          </div>
        </fieldset>
        <div>
          <button type="submit" className="btn">
            <Translate>Create</Translate>
          </button>
        </div>
      </form>
    </div>
  );
};

export default CreateMainCategory;
