import React, { useEffect, useState } from "react";
import Api from "../util/axiosInstance";
import Toast from "../util/CustomToast";
import { useNavigate } from "react-router-dom";
import { cloudinaryUpload } from "../../utils/cloudinaryUpload";
import { useAppContext } from "../../hooks/context/tokenStore";

const CreateSlider = () => {
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [categoryId, setCategoryId] = useState("");
  const [categories, setCategories] = useState([]);
  const Navigate = useNavigate();
  const { userData } = useAppContext();

  useEffect(() => {
    let query = "";
    let userRole = localStorage.getItem("role");
    if (userRole === "cityadmin") query = `?cityId=${userData?.City?.id}`;
    Api.get("/category" + query)
      .then((res) => {
        setCategories(res.data?.results);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    let newImageUrl;
    if (image) newImageUrl = await cloudinaryUpload(image);
    Api.post("/category-slider", {
      categoryId: categoryId,
      image: newImageUrl,
    })
      .then((res) => {
        console.log(res.data);
        Toast("success", "Slider Created Successfully");
        setImage(null);
        setCategoryId("");
        Navigate(-1);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <div>
      <div>
        <form
          className="w-2/3 flex flex-col space-y-20 my-10 mx-auto"
          onSubmit={handleSubmit}
        >
          <fieldset>
            <legend>Create Slider</legend>
            <div className="px-5 my-3 flex flex-col space-y-10">
              <div className="input-group">
                <label htmlFor="image">Image</label>
                <input
                  type="file"
                  id="image"
                  name="image"
                  onChange={(e) => setImage(e.target.files[0])}
                  required
                />
              </div>
              <div className="input-group w-fit">
                <label htmlFor="categoryId">Category</label>
                <select
                  name="categoryId"
                  id="categoryId"
                  value={categoryId}
                  onChange={(e) => setCategoryId(e.target.value)}
                  required
                >
                  <option value="">Select Category</option>
                  {categories.map((category) => (
                    <option key={category.id} value={category.id}>
                      {category.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </fieldset>
          <div className="flex justify-center">
            <button type="submit" className="btn">
              Create
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateSlider;
