import React, { useState } from "react";
import Menu from "../sideMenu/Menu";
import { useAppContext } from "../../hooks/context/tokenStore";
import Toast from "../util/CustomToast";
import Api from "../util/axiosInstance";
import { URL } from "../util/authorization/authorizationURL";

const PasswordReset = () => {
  const { userData } = useAppContext();
  const userRole = localStorage.getItem("role");
  const [inputValues, setInputValues] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const handleChange = (e) => {
    setInputValues({ ...inputValues, [e.target.name]: e.target.value });
  };
  const handleSubmitValues = (e) => {
    e.preventDefault();
    console.log(inputValues);
    //   check if the new password and confirm password are the same
    if (inputValues.newPassword !== inputValues.confirmPassword) {
      Toast("error", "Passwords do not match");
      return;
    }
    //   check if the new password is the same as the old password
    if (inputValues.newPassword === inputValues.currentPassword) {
      Toast("error", "New password cannot be the same as the old password");
      return;
    }
    const url = URL.password[userRole];
    Api.post(url, {
      currentPassword: inputValues.currentPassword,
      newPassword: inputValues.newPassword,
    })
      .then((res) => {
        Toast("success", "Password reset successful");
        setInputValues({
          currentPassword: "",
          newPassword: "",
          confirmPassword: "",
        });
      })
      .catch((err) => {
        console.log(err);
        Toast("error", "Password reset failed");
      });
  };
  return (
    <Menu>
      <div>
        <div className="heading-text">Password Reset</div>
        <div className="w-full flex justify-center">
          <form
            className="w-2/3 flex flex-col space-y-20 my-10"
            onSubmit={handleSubmitValues}
          >
            <fieldset>
              <legend>Password</legend>
              <div className="create-form">
                {/* new password */}
                <div>
                  <label htmlFor="newPassword">New Password</label>
                  <input
                    type="password"
                    name="newPassword"
                    id="newPassword"
                    value={inputValues.newPassword}
                    onChange={handleChange}
                    required
                  />
                </div>
                {/* confirm password */}
                <div>
                  <label htmlFor="confirmPassword">Confirm Password</label>
                  <input
                    type="password"
                    name="confirmPassword"
                    id="confirmPassword"
                    value={inputValues.confirmPassword}
                    onChange={handleChange}
                    required
                  />
                </div>
                {/* old password */}
                <div>
                  <label htmlFor="currentPassword">Current Password</label>
                  <input
                    type="password"
                    name="currentPassword"
                    id="currentPassword"
                    value={inputValues.currentPassword}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>
            </fieldset>
            <div className="flex justify-center">
              <button type="submit" className="btn">
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    </Menu>
  );
};

export default PasswordReset;
