import Api from "../components/util/axiosInstance";

export const cloudinaryUpload = async (image) => {
  try {
    const formData = new FormData();
    formData.append("image", image);
    const cloudinary = await Api.post("/cloudinary/upload", formData);
    return cloudinary.data.url;
  } catch (_err) {}
};
