import { Avatar, Switch, TableCell, TableRow } from "@mui/material";
import React, { useState } from "react";
import { TiEye } from "react-icons/ti";
import { RiEditCircleFill } from "react-icons/ri";
import { MdDelete } from "react-icons/md";
import { Navigate, useNavigate } from "react-router-dom";

const AdminRow = ({ admin, handleActivateToggle, handleDeleteAdmin }) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <TableRow>
      <TableCell>
        <div className="w-5 h-5 border cursor-pointer"></div>
      </TableCell>

      <TableCell>
        <div className="w-12">
          <Avatar src={admin.profileImg} />
        </div>
      </TableCell>
      <TableCell>{admin?.name?.split(" ")?.[0]}</TableCell>
      <TableCell>{admin?.name?.split(" ")?.[1]}</TableCell>

      <TableCell>{admin.email}</TableCell>
      <TableCell>{admin?.City?.name}</TableCell>

      <TableCell>{admin.createdAt}</TableCell>
      <TableCell>
        <Switch
          defaultChecked={admin.active}
          color="success"
          onChange={() => {
            handleActivateToggle(admin.id);
          }}
        />
      </TableCell>
      <TableCell>
        <div className="heading-text flex items-center space-x-2">
          {/* <div
            className="text-gray-700 cursor-pointer"
            onClick={() => {
              navigate("/admins/view/aklsd3497");
            }}
          >
            <TiEye />
          </div> */}
          <div
            onClick={() => navigate(admin.id)}
            className="text-green-700 cursor-pointer"
          >
            <RiEditCircleFill />
          </div>
          <div onClick={() => handleDeleteAdmin(admin.id)}>
            <MdDelete className="text-red-700 cursor-pointer" />
          </div>
        </div>
      </TableCell>
    </TableRow>
  );
};

export default AdminRow;
