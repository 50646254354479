import { Switch, TableCell, TableRow } from "@mui/material";
import React from "react";
import { MdDelete } from "react-icons/md";
import { RiEditCircleFill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";

const label = { inputProps: { "aria-label": "Switch demo" } };

const CategoryRow = ({
  category,
  handleDeleteCategory,
  handleEditCategory,
}) => {
  const Navigate = useNavigate();
  return (
    <TableRow>
      <TableCell>
        <div className="w-5 h-5 border border-black cursor-pointer"></div>
      </TableCell>

      <TableCell>{category.name}</TableCell>

      <TableCell>{category.Product.length}</TableCell>
      <TableCell>{category.type}</TableCell>

      <TableCell>
        <Switch
          onChange={() => handleEditCategory(category.id)}
          defaultChecked={category.publish}
          color="success"
        />
      </TableCell>
      <TableCell>
        <div className="heading-text flex items-center space-x-2">
          <div
            className="text-green-700 cursor-pointer"
            onClick={() => {
              Navigate(`/category/edit/${category.id}`);
            }}
          >
            <RiEditCircleFill />
          </div>
          <div
            onClick={() => {
              handleDeleteCategory(category.id);
            }}
          >
            <MdDelete className="text-red-700 cursor-pointer" />
          </div>
        </div>
      </TableCell>
    </TableRow>
  );
};

export default CategoryRow;
