import React, { useEffect, useState } from "react";
import Menu from "../sideMenu/Menu";
import Translate from "../util/Translate";
import { useTranslation } from "react-i18next";
import Api from "../util/axiosInstance";
import { useNavigate, useParams } from "react-router-dom";
import { TiDeleteOutline } from "react-icons/ti";
import LoadingSpinner from "../util/LoadingSpinner";
import Toast from "../util/CustomToast";

const EditMall = () => {
  const [inputValues, setInputValues] = useState({
    name: "",
    image: "",
    cityId: "",
    publish: false,
    newImage: null,
  });
  const [loading, setLoading] = useState(false);
  const { mallId } = useParams();
  const [cities, setCities] = useState([]);

  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    const fetchCities = async () => {
      try {
        const response = await Api.get("/city");
        setCities(response.data.results);
      } catch (error) {
        console.error(error);
        Toast("error", "Failed to load cities.");
      }
    };
    fetchCities();
  }, []);

  useEffect(() => {
    const fetchMallDetails = async () => {
      try {
        const res = await Api.get(`/mall/${mallId}`);
        setInputValues({
          name: res.data.data.name,
          publish: res.data.data.publish,
          image: res.data.data.image,
          cityId: res.data.data.cityId,
        });
      } catch (err) {
        console.error(err);
        Toast("error", "Failed to load mall details.");
      }
    };
    fetchMallDetails();
  }, [mallId]);

  const handleInputChange = (e) => {
    const { name, type, checked, value } = e.target;
    setInputValues((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleFileChange = (e) => {
    setInputValues({ ...inputValues, newImage: e.target.files[0] });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const res = await Api.patch(`/mall/${mallId}`, inputValues);
      Toast("success", "Mall updated successfully.");
      navigate(-1);
    } catch (err) {
      console.error(err);
      Toast("error", err.response?.data?.message || "An error occurred.");
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <Menu>
      <div>
        <div className="mb-5 heading-text">
          <Translate>Edit Mall</Translate>
        </div>
        <div className="w-full flex justify-center">
          <form
            className="w-2/3 flex flex-col space-y-20 my-10"
            onSubmit={handleSubmit}
          >
            <fieldset>
              <legend>
                <Translate>Mall Details</Translate>
              </legend>
              <div className="px-5 my-3 flex flex-col space-y-10">
                <div className="input-group">
                  <label htmlFor="name">
                    <Translate>Name</Translate>
                  </label>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    placeholder={t("Name")}
                    value={inputValues.name}
                    onChange={handleInputChange}
                    required
                  />
                </div>

                <div className="input-group">
                  <label htmlFor="cityId">
                    <Translate>City</Translate>
                  </label>
                  <select
                    name="cityId"
                    id="cityId"
                    value={inputValues.cityId}
                    onChange={handleInputChange}
                    required
                  >
                    <option value="">Select City</option>
                    {cities.map((city) => (
                      <option key={city.id} value={city.id}>
                        {city.name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="flex space-x-3">
                  <input
                    type="checkbox"
                    name="publish"
                    id="publish"
                    onChange={handleInputChange}
                    checked={inputValues.publish}
                  />
                  <label htmlFor="publish">
                    <Translate>Publish</Translate>
                  </label>
                </div>

                {inputValues.image && (
                  <div className="w-1/2 h-auto relative">
                    <img
                      src={inputValues.image}
                      alt="Mall"
                      className="w-full h-full object-contain"
                    />
                    <div
                      className="absolute -top-5 right-0 cursor-pointer"
                      onClick={() => {
                        setInputValues({ ...inputValues, image: "" });
                      }}
                    >
                      <TiDeleteOutline size={20} color="red" />
                    </div>
                  </div>
                )}
                <div className="flex flex-col space-y-3">
                  <label htmlFor="newImage">
                    <Translate>Upload New Image (optional)</Translate>
                  </label>
                  <input
                    type="file"
                    name="newImage"
                    id="newImage"
                    onChange={handleFileChange}
                    accept="image/*"
                  />
                </div>
              </div>
            </fieldset>

            <div className="flex justify-center space-x-5">
              <button
                className="heading-bg-color bg-text-color px-5 py-2 rounded-md cursor-pointer"
                type="submit"
              >
                <Translate>Save</Translate>
              </button>
              <button
                type="button"
                className="heading-bg-color bg-text-color px-5 py-2 rounded-md cursor-pointer"
                onClick={() => {
                  navigate(-1);
                }}
              >
                <Translate>Back</Translate>
              </button>
            </div>
          </form>
        </div>
      </div>
    </Menu>
  );
};

export default EditMall;
