import React, { useEffect, useState } from "react";
import Menu from "../sideMenu/Menu";
import { useAppContext } from "../../hooks/context/tokenStore";
import { useTranslation } from "react-i18next";
import Api from "../util/axiosInstance";
import LoadingSpinner from "../util/LoadingSpinner";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import AttributeInputs from "./AttributeInputs";
import Toast from "../util/CustomToast";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import Translate from "../util/Translate";
import { cloudinaryUpload } from "../../utils/cloudinaryUpload";

const editorModules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }],
    ["bold", "italic", "underline", "strike"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["clean"],
    ["code-block"],
  ],
};

const CreateProduct = () => {
  const { userData } = useAppContext();
  const [loading, setLoading] = useState(false);
  const [vendorShops, setVendorShops] = useState([]);
  const [categories, setCategories] = useState([]);
  const [units, setUnits] = useState([]);
  const [variationValues, setVariationValues] = useState([]);
  const [attributeValues, setAttributeValues] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [internalCategories, setInternalCategories] = useState([]);
  const navigate = useNavigate();

  const [showVariations, setShowVariations] = useState(false);

  const [inputValues, setInputValues] = useState({
    name: "",
    price: 0,
    discountedPrice: 0,
    itemQuantity: 0,
    attributes: ["dummuy", "price"],
    mainImage: null,
    galleryImages: null,
    description: "",
    productInternalCategoryId: "",
    publish: true,
    shopId: "",
    unitId: "",
    subCategoryId: null,
  });

  const { t } = useTranslation();

  const { productId } = useParams();
  useEffect(() => {
    Api.get(`/product/${productId}`)
      .then((res) => {
        console.log(res.data);
        setInputValues({
          name: res.data.name,
          price: res.data.price,
          discountedPrice: res.data.discountedPrice,
          itemQuantity: res.data.itemQuantity,
          attributes: res.data.attributes,

          description: res.data.description,
          publish: res.data.publish,
          productInternalCategoryId: res.data.productInternalCategoryId,

          shopId: res.data.shopId,
          unitId: res.data.unitId,

          mainImage: res.data.mainImage,
          galleryImages: res.data.galleryImages,
          subCategoryId: res.data.subCategoryId,
        });
        setSelectedCategory(res.data.categoryId);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [productId]);

  useEffect(() => {
    Api.get("/shop")
      .then((res) => {
        setVendorShops(res.data.results);
      })
      .catch((e) => {
        console.log(e);
      });
    Api.get("/category")
      .then((res) => {
        setCategories(res.data.results);
      })
      .catch((e) => {
        console.log(e);
      });
    Api.get("/unit")
      .then((res) => {
        setUnits(res.data.results);
      })
      .catch((e) => {
        console.log(e);
      });

    if (userData && userData?.Shop?.shopCategoryId) {
      Api.get(
        `/product-internal-category?shopCategoryId=${userData?.Shop?.shopCategoryId}`
      ).then((response) => {
        setInternalCategories(response.data.results);
      });
    }
  }, []);

  const handleInputChange = (e) => {
    if (e.target.name === "publish") {
      setInputValues({ ...inputValues, [e.target.name]: e.target.checked });
      return;
    } else {
      setInputValues({ ...inputValues, [e.target.name]: e.target.value });
    }
  };

  const handleFileChange = (e) => {
    if (e.target.name === "mainImage") {
      setInputValues({ ...inputValues, [e.target.name]: e.target.files[0] });
      return;
    } else {
      setInputValues({ ...inputValues, [e.target.name]: e.target.files });
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const galleryImages = [];
    for (let i = 0; i < inputValues.galleryImages.length; i++) {
      const galleryImage = await cloudinaryUpload(inputValues.galleryImages[i]);
      if (galleryImage) galleryImages.push(galleryImage);
    }

    const attributes = attributeValues;

    const variations = variationValues.map((variation, indx) => {
      return {
        name: variation.name,
        price: parseFloat(variation[`price-${indx}`]),
        stock: parseInt(variation[`stock-${indx}`], 10),
      };
    });

    const requestBody = {
      name: inputValues.name,
      price: parseFloat(inputValues.price),
      discountedPrice: parseFloat(inputValues.discountedPrice),
      itemQuantity: parseInt(inputValues.itemQuantity),
      attributes: JSON.stringify(inputValues.attributes),
      shopId: inputValues.shopId,
      description: inputValues.description,
      publish: inputValues.publish,
      productInternalCategoryId: inputValues.productInternalCategoryId
        ? inputValues.productInternalCategoryId
        : null,
      unitId: inputValues.unitId,
      attributes,
      variations,
      galleryImages,
      categoryId: selectedCategory,
      subCategoryId: inputValues.subCategoryId,
    };

    const mainImage = await cloudinaryUpload(inputValues.mainImage);
    if (mainImage) requestBody.mainImage = mainImage;
    Api.patch(`/product/${productId}`, requestBody)
      .then((res) => {
        console.log(res.data);
        Toast("success", "Product created successfully!");

        setInputValues({
          name: "",
          price: 0,
          discountedPrice: 0,
          itemQuantity: 0,
          attributes: [],
          mainImage: null,
          galleryImages: null,
          description: "",
          publish: true,
          categoryId: "",
          shopId: "",
          unitId: "",
          subCategoryId: "",
        });
        setAttributeValues([]);
        setVariationValues([]);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <Menu>
      <div>
        <div className="mb-5 heading-text">
          <Translate>Edit Product</Translate>
        </div>
        <div className="w-full flex justify-center">
          <div className="w-2/3 flex flex-col space-y-20 my-10">
            <fieldset>
              <legend>
                <Translate>PRODUCT DETAILS</Translate>
              </legend>
              <form className="create-form" enctype="multipart/form-data">
                {/* name */}
                <div>
                  <label htmlFor="name">
                    <Translate>Name</Translate>
                  </label>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    placeholder={t("Name")}
                    onChange={handleInputChange}
                    value={inputValues.name}
                    required
                  />
                </div>
                {/* price */}
                <div>
                  <label htmlFor="price">
                    <Translate>Price</Translate>
                  </label>
                  <input
                    type="number"
                    name="price"
                    id="price"
                    placeholder={t("Price")}
                    onChange={handleInputChange}
                    value={inputValues.price}
                    required
                  />
                </div>
                {/* discounted price */}
                <div>
                  <label htmlFor="discountedPrice">
                    <Translate>Discounted Price</Translate>
                  </label>
                  <input
                    type="number"
                    name="discountedPrice"
                    id="discountedPrice"
                    placeholder={t("Discounted Price")}
                    onChange={handleInputChange}
                    value={inputValues.discountedPrice}
                    required
                  />
                </div>
                {/* unit */}
                <div>
                  <label htmlFor="unitId">
                    <Translate>Unit</Translate>
                  </label>
                  <select
                    name="unitId"
                    id="unitId"
                    onChange={handleInputChange}
                    value={inputValues.unitId}
                  >
                    <option value="">
                      <Translate>Select Unit</Translate>
                    </option>
                    {units.map((unit) => (
                      <option key={unit.id} value={unit.id}>
                        {unit.name}
                      </option>
                    ))}
                  </select>
                </div>

                {/* internal category */}
                <div>
                  <label htmlFor="productInternalCategoryId">
                    <Translate>Internal Category</Translate>
                  </label>
                  <select
                    name="productInternalCategoryId"
                    id="productInternalCategoryId"
                    onChange={handleInputChange}
                    value={inputValues.productInternalCategoryId}
                    required
                  >
                    <option value="">
                      <Translate>Select Internal Category</Translate>
                    </option>
                    {internalCategories.map((category) => (
                      <option key={category.id} value={category.id}>
                        {category.name}
                      </option>
                    ))}
                  </select>
                </div>

                <div>
                  <label htmlFor="categoryId">
                    <Translate>Category</Translate>
                  </label>
                  <select
                    name="categoryId"
                    id="categoryId"
                    onChange={(e) => {
                      handleInputChange(e);
                      setSelectedCategory(e.target.value);
                    }}
                    value={inputValues.categoryId}
                  >
                    <option value="">
                      <Translate>Select Category</Translate>
                    </option>
                    {categories.map((category) => (
                      <option key={category.id} value={category.id}>
                        {category.name}
                      </option>
                    ))}
                  </select>
                </div>
                {/* sub category */}
                {selectedCategory && (
                  <div>
                    <label htmlFor="subCategoryId">
                      <Translate>Sub Category</Translate>
                    </label>
                    <select
                      name="subCategoryId"
                      id="subCategoryId"
                      onChange={handleInputChange}
                      value={inputValues.subCategoryId}
                    >
                      <option value="">
                        <Translate>Select Sub Category</Translate>
                      </option>
                      {categories
                        .find((category) => category.id === selectedCategory)
                        ?.SubCategory?.map((subCategory) => (
                          <option key={subCategory.id} value={subCategory.id}>
                            {subCategory.name}
                          </option>
                        ))}
                    </select>
                  </div>
                )}

                {/* item quantity */}
                <div>
                  <label htmlFor="itemQuantity">
                    <Translate>Item Quantity</Translate>
                  </label>
                  <input
                    type="number"
                    name="itemQuantity"
                    id="itemQuantity"
                    placeholder={t("Item Quantity")}
                    onChange={handleInputChange}
                    value={inputValues.itemQuantity}
                    required
                  />
                </div>
                {/* food Attribute */}
                {/* <div>
                  <label htmlFor="foodAttribute">Food Attribute</label>
                  <select name="foodAttribute" id="foodAttribute">
                    <option value="">Select Food Attribute</option>
                    <option value="1">Veg</option>
                    <option value="2">Non-Veg</option>
                  </select>
                </div> */}
                {/* vendor shops */}
                <div>
                  <label htmlFor="shopId">
                    <Translate>Vendor Shop</Translate>
                  </label>
                  <select
                    name="shopId"
                    id="shopId"
                    onChange={handleInputChange}
                    value={inputValues.shopId}
                  >
                    <option value="">
                      <Translate>Select Vendor Shop</Translate>
                    </option>
                    {vendorShops.map((shop) => (
                      <option key={shop.id} value={shop.id}>
                        {shop.name}
                      </option>
                    ))}
                  </select>
                </div>
                {/* images */}
                <div>
                  <label htmlFor="mainImage">
                    <Translate>Main Image</Translate>
                  </label>
                  <input
                    type="file"
                    name="mainImage"
                    id="mainImage"
                    onChange={handleFileChange}
                    required
                  />
                </div>
                <div>
                  <label htmlFor="images">
                    <Translate>Gallery Images</Translate>
                  </label>
                  <input
                    type="file"
                    name="galleryImages"
                    id="galleryImages"
                    onChange={handleFileChange}
                    multiple
                    required
                  />
                </div>
              </form>
              {/* description */}
              <div className="px-5 my-3 flex flex-col space-y-5">
                {/* publish */}
                <div className="flex space-x-3">
                  <input
                    type="checkbox"
                    name="publish"
                    id="publish"
                    onChange={handleInputChange}
                    checked={inputValues.publish}
                  />
                  <label htmlFor="publish">
                    <Translate>Publish</Translate>
                  </label>
                </div>
                <div className="input-group w-full h-72">
                  <label htmlFor="description">
                    <Translate>Description</Translate>
                  </label>
                  {/* <textarea
                    name="description"
                    id="description"
                    cols="30"
                    rows="10"
                    placeholder={t("Description")}
                    onChange={handleInputChange}
                    value={inputValues.description}
                  ></textarea> */}
                  <ReactQuill
                    theme="snow"
                    modules={editorModules}
                    value={inputValues.description}
                    onChange={(e) => {
                      setInputValues({ ...inputValues, description: e });
                    }}
                    className="h-56"
                  />
                </div>
              </div>

              <div className="m-5">
                <AttributeInputs
                  attributeValues={attributeValues}
                  setAttributeValues={setAttributeValues}
                  variationValues={variationValues}
                  setVariationValues={setVariationValues}
                  showVariations={showVariations}
                  setShowVariations={setShowVariations}
                />
              </div>
            </fieldset>

            <div className="flex justify-center space-x-5">
              <div
                className="heading-bg-color bg-text-color px-5 py-2 rounded-md cursor-pointer"
                onClick={handleFormSubmit}
              >
                <Translate>Save</Translate>
              </div>
              <div
                onClick={() => navigate(-1)}
                className="heading-bg-color bg-text-color px-5 py-2 rounded-md cursor-pointer"
              >
                <Translate>Back</Translate>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Menu>
  );
};

export default CreateProduct;
