import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Api from "../util/axiosInstance";
import LoadingSpinner from "../util/LoadingSpinner";
import Translate from "../util/Translate";
import MallRow from "./MallRow";

const Mall = {
  name: "electronics",
  image: "https://picsum.photos/800/800",
  products: 23,
  publish: true,
};

const MallList = ({ malls, setMalls, setLoading }) => {
  const handleDeleteMall = (id) => {
    setLoading(true);
    Api.delete(`/mall/${id}`)
      .then((res) => {
        setMalls(malls.filter((mall) => mall.id !== id));
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="w-full">
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <Translate>Number</Translate>
            </TableCell>
            <TableCell>
              <Translate>Name</Translate>
            </TableCell>
            <TableCell>
              <Translate>Shops</Translate>
            </TableCell>
            <TableCell>
              <Translate>City</Translate>
            </TableCell>

            <TableCell>
              <Translate>Publish</Translate>
            </TableCell>
            <TableCell>
              <Translate>Actions</Translate>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {malls.map((mall, index) => {
            return (
              <MallRow
                mall={mall}
                handleDeleteMall={handleDeleteMall}
                index={index}
              />
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
};

export default MallList;
