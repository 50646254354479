import React, { useEffect, useState } from "react";
import Menu from "../sideMenu/Menu";
import Translate from "../util/Translate";
import Api from "../util/axiosInstance";
import { useNavigate } from "react-router-dom";

const CreateTicket = () => {
  const [inputValues, setInputValues] = useState({
    title: "",
    description: "",
    cityId: "",
  });
  const [cities, setCities] = useState([]);
  const Navigate = useNavigate();

  useEffect(() => {
    Api.get("/city/getall")
      .then((response) => {
        setCities(response.data.cities);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleInputChange = (e) => {
    setInputValues({ ...inputValues, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    Api.post("/ticket/create", {
      ...inputValues,
    })
      .then((res) => {
        console.log(res.data);
        Navigate(-1);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <Menu>
      <div>
        <div className="mb-5 heading-text">
          <Translate>Ticket Creation</Translate>
        </div>
        <div className="w-full flex justify-center">
          <form
            className="w-2/3 flex flex-col space-y-20 my-10"
            onSubmit={handleSubmit}
          >
            <fieldset className="py-10">
              <legend>
                <Translate>Ticket Details</Translate>
              </legend>
              <div className="create-form">
                <div>
                  <label>
                    <Translate>Title</Translate>
                  </label>
                  <input
                    type="text"
                    name="title"
                    value={inputValues.title}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div>
                  <label>
                    <Translate>City</Translate>
                  </label>
                  <select
                    name="cityId"
                    value={inputValues.cityId}
                    onChange={handleInputChange}
                    required
                  >
                    <option value="">Select City</option>
                    {cities.map((city) => (
                      <option key={city.id} value={city.id}>
                        {city.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="input-group px-5">
                <label>
                  <Translate>Description</Translate>
                </label>
                <textarea
                  name="description"
                  value={inputValues.description}
                  onChange={handleInputChange}
                  cols={30}
                  rows={10}
                  required
                />
              </div>
            </fieldset>
            <div className="flex justify-center items-center">
              <button className="btn" type="submit">
                <Translate>Submit</Translate>
              </button>
            </div>
          </form>
        </div>
      </div>
    </Menu>
  );
};

export default CreateTicket;
