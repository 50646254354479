import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import AttributeRow from "./AttributeRow";

const AttributesList = ({ attributesList, handleDeleteAttribute }) => {
  return (
    <div className="w-full">
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {attributesList.map((attribute) => (
            <AttributeRow
              key={attribute.id}
              attribute={attribute}
              handleDeleteAttribute={handleDeleteAttribute}
            />
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default AttributesList;
