import { Switch, TableCell, TableRow } from "@mui/material";
import React, { useState } from "react";
import { TiEye } from "react-icons/ti";
import { RiEditCircleFill } from "react-icons/ri";
import { MdDelete } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import ConfirmationModal from "../util/ConfirmationModal";

const label = { inputProps: { "aria-label": "Switch demo" } };

const ServiceRow = ({ service, handleDelete, handleActivateToggle }) => {
  const Navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <TableRow>
      <TableCell>
        <div className="w-5 h-5 border cursor-pointer"></div>
      </TableCell>

      <TableCell>{service.title}</TableCell>
      <TableCell>{service.price}</TableCell>

      <TableCell>{new Date(service.createdAt).toLocaleDateString()}</TableCell>
      <TableCell>
        <Switch
          onChange={() => handleActivateToggle(service.id)}
          defaultChecked={service.active}
          color="success"
        />
      </TableCell>
      <TableCell>
        <div className="heading-text flex items-center space-x-2">
          <div
            onClick={() => {
              Navigate("/services/edit/" + service.id, {
                state: {
                  disableEdit: true,
                },
              });
            }}
            className="text-gray-700 cursor-pointer"
          >
            <TiEye />
          </div>
          <div
            className="text-green-700 cursor-pointer"
            onClick={() => {
              Navigate("/services/edit/" + service.id);
            }}
          >
            <RiEditCircleFill />
          </div>
          <div
            onClick={() => {
              // handleDelete(service.id);
              setIsOpen(true);
            }}
          >
            <MdDelete className="text-red-700 cursor-pointer" />
          </div>
        </div>
      </TableCell>
      <ConfirmationModal
        deleteFuction={handleDelete}
        id={service.id}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
    </TableRow>
  );
};

export default ServiceRow;
