import React, { useState } from "react";
import Menu from "../sideMenu/Menu";
import GoogleMapReact from "google-map-react";
import styled from "styled-components";
import { IoRestaurantSharp } from "react-icons/io5";
import { FaShoppingCart } from "react-icons/fa";

export const restaurants = [
  {
    name: "Gavin's Grill",
    phone: "030238923",
    address: "Samanabad, Lahore",
    lat: 31.5012,
    lng: 74.3365,
  },
  {
    name: "Sophie's Sushi",
    phone: "030238923",
    address: "Shadman, Lahore",
    lat: 31.5237,
    lng: 74.3128,
  },
  {
    name: "Nathan's Noodle House",
    phone: "030238923",
    address: "Gulshan-e-Ravi, Lahore",
    lat: 31.4895,
    lng: 74.2683,
  },
  {
    name: "Olivia's Oyster Bar",
    phone: "030238923",
    address: "Safari Villas, Lahore",
    lat: 31.5089,
    lng: 74.3702,
  },
  {
    name: "Marcus's Mediterranean",
    phone: "030238923",
    address: "Shahdara, Lahore",
    lat: 31.5884,
    lng: 74.3621,
  },
  {
    name: "Isabella's Ice Cream Parlor",
    phone: "030238923",
    address: "Cantt, Lahore",
    lat: 31.5213,
    lng: 74.4036,
  },
  {
    name: "Quinn's Quiche Corner",
    phone: "030238923",
    address: "Green Town, Lahore",
    lat: 31.4701,
    lng: 74.3027,
  },
  {
    name: "Lucas's Lebanese Lounge",
    phone: "030238923",
    address: "Allama Iqbal Town, Lahore",
    lat: 31.5381,
    lng: 74.2864,
  },
  {
    name: "Mia's Mexican Cantina",
    phone: "030238923",
    address: "Raiwind Road, Lahore",
    lat: 31.2495,
    lng: 74.1975,
  },
  {
    name: "Owen's Organic Eatery",
    phone: "030238923",
    address: "Wahdat Road, Lahore",
    lat: 31.532,
    lng: 74.3458,
  },
];

export const ShopLocationIcon = ({ text, shop }) => {
  const [showInfo, setShowInfo] = useState(false);

  const handleClick = () => {
    setShowInfo(!showInfo);
  };
  console.log({ shop });
  return (
    <div
      onMouseEnter={handleClick}
      onMouseLeave={handleClick}
      className="map_marker"
    >
      <span className="text-black heading-text  cursor-pointer">
        <FaShoppingCart size={30} color="red" />
      </span>
      {showInfo && (
        <div className="text-black bg-white p-3 text-xs font-bold w-max absolute top-0 right-0 rounded-md border border-black flex flex-col space-y-1 ">
          {/* Display rider's information here */}
          <div>Shop Name: {shop?.name}</div>
          <div>Phone: {shop?.phone}</div>
          <div>Address: {shop?.address}</div>
          {/* Add more information as needed */}
        </div>
      )}
    </div>
  );
};

// const ShopsMapData = () => {
//   return (
//     <div className="flex flex-col space-y-10">
//       <div className="heading-text">Restaurants Location Data</div>
//       <div className="grid grid-cols-6 gap-3 h-[60vh] overflow-hidden">
//         <div className="col-span-2 overflow-y-scroll">
//           {shopsLocation.map((restaurant) => (
//             <div className="flex flex-col my-4">
//               <div className="flex space-x-3 sub-heading-text">
//                 <div>Restaurant's Name: </div>
//                 <div>{restaurant.name}</div>
//               </div>
//             </div>
//           ))}
//         </div>
//         <div className="h-full col-span-4">
//           <GoogleMapReact
//             bootstrapURLKeys={{
//               key: `${process.env.REACT_APP_MAP_KEY}`,
//             }}
//             defaultCenter={{
//               lat: 31.5892,
//               lng: 74.3125,
//             }}
//             defaultZoom={12}
//           >
//             {restaurants.map((restaurant) => {
//               return (
//                 <ShopLocationIcon
//                   lat={restaurant.lat}
//                   lng={restaurant.lng}
//                   restaurant={restaurant}
//                 />
//               );
//             })}
//           </GoogleMapReact>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ShopsMapData;
