import React, { useEffect, useState } from "react";
import Api from "../../util/axiosInstance";
import Toast from "../../util/CustomToast";
import Translate from "../../util/Translate";

const urls = {
  create: {
    shop: "/shop-sub-category",
    professional: "/professional-sub-category",
  },
  get: {
    shop: "/shop-category",
    professional: "/professional-category",
  },
};

const CreateSubCategory = ({ selectedRole, recheck }) => {
  const [mainCategories, setMainCategories] = useState([]);
  const [inputValues, setInputValues] = useState({
    name: "",
    mainCategoryId: "",
  });
  useEffect(() => {
    Api.get(urls.get[selectedRole])
      .then((response) => {
        setMainCategories(response.data.results);
      })
      .catch((error) => {
        Toast("error", "Error Fetching Main Categories");
        setMainCategories([]);
      });
  }, [selectedRole, recheck]);

  const handleSubmit = (e) => {
    e.preventDefault();
    let data = {
      name: inputValues.name,
      shopCategoryId: inputValues.mainCategoryId,
    };
    if (selectedRole === "professional") {
      data = {
        name: inputValues.name,
        professionalCategoryId: inputValues.mainCategoryId,
      };
    }

    Api.post(urls.create[selectedRole], data)
      .then((response) => {
        Toast("success", "Sub Category Created Successfully");
        window.location.reload();
        // setInputValues({ name: "", shopCategoryId: "" });
      })
      .catch((error) => {
        Toast("error", "Error Creating Sub Category");
      });
  };

  return (
    <div className="bg-white p-5 rounded-md">
      <form className="flex flex-col space-y-5" onSubmit={handleSubmit}>
        <fieldset>
          <legend>
            <Translate>Sub Category</Translate>
          </legend>
          <div className="create-form">
            <div>
              <label htmlFor="name">
                <Translate>Name</Translate>
              </label>
              <input
                type="text"
                id="name"
                name="name"
                value={inputValues.name}
                onChange={(e) =>
                  setInputValues({ ...inputValues, name: e.target.value })
                }
                minLength={3}
                maxLength={30}
              />
            </div>
            <div>
              <label htmlFor="mainCategoryId">
                <Translate>Main Category</Translate>
              </label>
              <select
                id="mainCategoryId"
                name="mainCategoryId"
                value={inputValues.mainCategoryId}
                onChange={(e) =>
                  setInputValues({
                    ...inputValues,
                    mainCategoryId: e.target.value,
                  })
                }
              >
                <option value="">
                  <Translate>Select Main Category</Translate>
                </option>
                {mainCategories.map((category) => (
                  <option key={category.id} value={category.id}>
                    {category.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </fieldset>
        <div>
          <button type="submit" className="btn">
            <Translate>Create</Translate>
          </button>
        </div>
      </form>
    </div>
  );
};

export default CreateSubCategory;
