import React, { useState } from "react";
import Translate from "../../util/Translate";

const MainCategoryList = ({
  categories,
  currentCategory,
  setCurrentCategory,
}) => {
  return (
    <div className="flex flex-col space-y-5 bg-white p-5 w-fit rounded-md">
      <div className="sub-heading-text">
        <Translate>Main Categories</Translate>
      </div>
      <div className="flex flex-col space-y-3 h-[40vh] w-[250px] overflow-y-scroll">
        {categories.map((category, index) => {
          return (
            <div
              key={category.id}
              className={`flex justify-between px-3 py-2 hover:heading-bg-color hover:bg-text-color cursor-pointer rounded-md
              ${
                currentCategory.id === category.id
                  ? "heading-bg-color bg-text-color"
                  : ""
              }
              `}
              onClick={() => {
                setCurrentCategory(category);
              }}
            >
              <div>{category.name}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default MainCategoryList;
