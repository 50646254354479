import React, { useEffect, useState } from "react";
import Api from "../util/axiosInstance";
import { Autocomplete, TextField } from "@mui/material";
import Translate from "../util/Translate";
import TagsInput from "react-tagsinput";

import "react-tagsinput/react-tagsinput.css";
import ProductVariations from "./ProductVariations";
import { GiNewBorn } from "react-icons/gi";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

const AttributeInputs = ({
  variationValues,
  setVariationValues,
  attributeValues,
  setAttributeValues,
  showVariations,
  setShowVariations,
}) => {
  const [attributes, setAttributes] = useState([]);
  const { productId } = useParams();
  const { t } = useTranslation();

  const [selectAttributes, setSelectAttributes] = useState([]);

  const [variations, setVariations] = useState([]);

  function generateVariations(index, currentVariation) {
    if (index === attributeValues.length) {
      setVariations((variations) => [
        ...variations,
        currentVariation.join("-"),
      ]);
      return;
    }

    attributeValues[index].values.forEach((value) => {
      const newVariation = [...currentVariation];
      newVariation.push(value);
      generateVariations(index + 1, newVariation);
    });
  }

  useEffect(() => {
    if (productId) {
      let tempArray = [];
      variationValues.forEach((value) => {
        tempArray.push(value.name);
      });
      setVariations(tempArray);
    }
    if (attributeValues.length > 1) {
      setVariations([]);
      generateVariations(0, []);
    } else if (attributeValues.length === 1) {
      setVariations(attributeValues[0].values);
    }
  }, [showVariations]);

  useEffect(() => {
    Api.get("/attribute")
      .then((res) => {
        setAttributes(res.data.results);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {});
  }, []);

  useEffect(() => {
    // set selected attrubutes according to attributeValues
    if (productId && !selectAttributes.length) {
      setSelectAttributes(
        attributeValues.map((val) => {
          return attributes.find((attr) => attr.id === val.attributeId);
        })
      );
    }
  }, [productId, attributeValues]);

  return (
    <div className="py-5 flex flex-col space-y-5">
      <div className="text-center heading-text app-text-color">
        <Translate>Select Attributes</Translate>
      </div>
      <Autocomplete
        multiple
        id="tags-outlined"
        options={attributes}
        // defaultValue={attributeValues}
        value={selectAttributes}
        getOptionLabel={(option) => option.name}
        filterSelectedOptions
        onChange={(e, value) => {
          setSelectAttributes(value);
          // clear attribute values also for unselected options
          setAttributeValues((prev) => {
            return prev.filter((val) => {
              return value.find((attr) => attr.id === val.attributeId);
            });
          });
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label={t("Select Attributes")}
            placeholder="Attributes"
          />
        )}
      />

      {/* attribute values */}
      <div>
        <div className="app-text-color text-center sub-heading-text">
          <Translate>Choose Attribute Values</Translate>
        </div>
        <div className="flex flex-col space-y-3">
          {selectAttributes.map((attribute) => {
            return (
              <div key={attribute.id} className="flex flex-col space-y-3">
                <div className=" app-text-color capitalize">
                  {attribute.name}
                </div>
                <TagsInput
                  renderInput={(props) => (
                    <input {...props} placeholder="value" />
                  )}
                  value={
                    attributeValues.find(
                      (val) => val.attributeId === attribute.id
                    )?.values || []
                  }
                  onChange={(values) => {
                    //   need to remove values if they are deleted
                    setAttributeValues((prev) => {
                      const index = prev.findIndex(
                        (val) => val.attributeId === attribute.id
                      );
                      if (index === -1) {
                        return [
                          ...prev,
                          {
                            attributeId: attribute.id,
                            attributeName: attribute.name,
                            values,
                          },
                        ];
                      } else {
                        prev[index].values = values;
                        return [...prev];
                      }
                    });

                    // change the variations
                  }}
                />
              </div>
            );
          })}
        </div>
      </div>

      <div
        onClick={() => {
          setShowVariations(!showVariations);
        }}
        className="heading-bg-color bg-text-color text-center py-2 cursor-pointer rounded-md px-5"
      >
        <Translate>GENERATE VARIATIONS</Translate>
      </div>

      {/* product variations */}
      <ProductVariations
        variations={variations}
        variationValues={variationValues}
        setVariationValues={setVariationValues}
      />
    </div>
  );
};

export default AttributeInputs;
