import React from "react";
import Menu from "../sideMenu/Menu";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";

const SalesSlabsDashboard = () => {
  return (
    <Menu>
      <div className="flex flex-col space-y-10">
        <div className="sub-heading-text py-2 border-b-2 border-b-gray-300">
          Sales Slabs Information
        </div>
        <div className="w-1/2 self-center">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Sale Slab</TableCell>
                <TableCell>Cash Sale Commission Charges</TableCell>
                <TableCell>Credit Sale Commission Charges</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>0 - 200,000</TableCell>
                <TableCell>10%</TableCell>
                <TableCell>7%</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>200,001 - 400,000</TableCell>
                <TableCell>15%</TableCell>
                <TableCell>10%</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>400,001 - 600,000</TableCell>
                <TableCell>18%</TableCell>
                <TableCell>13%</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>600,001 - 800,000</TableCell>
                <TableCell>21%</TableCell>
                <TableCell>16%</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>800,001-1,200,000</TableCell>
                <TableCell>23%</TableCell>
                <TableCell>19%</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>1,200,001 - above</TableCell>
                <TableCell>25%</TableCell>
                <TableCell>21%</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>
      </div>
    </Menu>
  );
};

export default SalesSlabsDashboard;
