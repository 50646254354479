import React, { useState } from "react";
import Menu from "../sideMenu/Menu";
import { TabsList } from "../util/resuableComponents";
import AddWalletAmountModal from "./AddWalletAmountModal";

const Tabs = [
  {
    name: "Basic",
  },
  {
    name: "Orders",
  },
  {
    name: "Wallet Transaction",
  },
];

const user = {
  firstName: "John",
  email: "john@example.com",
  phone: "1234567890",
  profileImage: "https://picsum.photos/200",
  address: "123, abc street, xyz city, 123456",
  walletBalance: "1000",
};
function KeyValuePair({ heading, value }) {
  return (
    <div className="flex flex-col space-y-2">
      <div className="sub-heading-text">{heading}</div>
      <div>{value}</div>
    </div>
  );
}

const ViewUser = () => {
  const [walletModal, setWalletModal] = useState(false);
  return (
    <Menu>
      <div>
        <div className="heading-text mb-5">Users</div>
        <TabsList tabsList={Tabs} basic="Basic" />
        <div className="w-2/3 flex flex-col space-y-20 my-10 mx-auto relative">
          <div
            className="absolute top-20 right-10 px-3 py-1 sub-heading-text rounded-md bg-white text-black border-2 border-color cursor-pointer"
            onClick={() => {
              setWalletModal(true);
            }}
          >
            + Add Wallet Amount
          </div>
          <fieldset>
            <legend>USER DETAILS</legend>
            <div className="grid grid-cols-2 gap-y-3">
              <KeyValuePair heading={"First Name"} value={user.firstName} />
              <KeyValuePair heading={"Email"} value={user.email} />
              <KeyValuePair heading={"Phone"} value={user.phone} />
              <KeyValuePair heading={"Address"} value={user.address} />
              <div className="flex flex-col space-y-2">
                <div className="sub-heading-text">Profile Image</div>
                <div className="w-20 h-20">
                  <img
                    src={user.profileImage}
                    alt="profileImage"
                    className="w-full h-full"
                  />
                </div>
              </div>
              <KeyValuePair
                heading={"Wallet Balance"}
                value={user.walletBalance}
              />
            </div>
          </fieldset>
        </div>
      </div>
      <AddWalletAmountModal isOpen={walletModal} setIsOpen={setWalletModal} />
    </Menu>
  );
};

export default ViewUser;
