import {
  addDoc,
  doc,
  getDoc,
  getDocs,
  orderBy,
  updateDoc,
  where,
} from "firebase/firestore";
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { app, db } from "../../config/Firebase";
import { collection, onSnapshot, query } from "firebase/firestore";
import { CiRead, CiUnread } from "react-icons/ci";
import ChatOrderModal from "./inchatOrder.js/ChatOrderModal";
import ProfessionalChatOrder from "./inchatOrder.js/professionalchatorder/ProfessionalChatOrder";
import { Avatar } from "@mui/material";
import { generateRandomAvatar } from "../../utils/generateRandomAvatar";
import moment from "moment";
// import { onSnapshot } from "firebase/firestore";

const ChatPreview = ({
  currentChat,
  setCurrentChat,
  userData,
  allChats,
  setAllChats,
}) => {
  const [chatMessages, setChatMessages] = useState([]);
  const [selectedChat, setSelectedChat] = useState(null);
  const userRole = localStorage.getItem("role");

  const [newMessage, setNewMessage] = useState("");
  const scrollRef = useRef();
  let currentRole = localStorage.getItem("role");

  let [isOpen, setIsOpen] = useState(false);

  function open() {
    setIsOpen(true);
  }

  function close() {
    setIsOpen(false);
  }

  useEffect(() => {
    if (!currentChat || !userData.id) return;
    const item = allChats?.find((item) => item.userId === currentChat);

    setSelectedChat({
      color: generateRandomAvatar(item.userName),
      ...item,
    });

    // latest messages chats first
    let targetedChat =
      localStorage.getItem("role") === "vendor" ? "chats" : "serviceChats";
    const q = query(
      collection(
        db,
        targetedChat,
        userData.id,
        "users",
        currentChat,
        "messages"
      ),
      orderBy("timestamp", "asc")
    );
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const messages = [];
      querySnapshot.forEach((doc) => {
        messages.push(doc.data());
      });

      setChatMessages(messages);
    });

    return () => {
      unsubscribe();
    };
  }, [currentChat, userData]);

  // mark all unread messages as read
  useEffect(() => {
    if (!chatMessages.length) {
      return;
    }

    let targetedChat = currentRole === "vendor" ? "chats" : "serviceChats";

    let unreadCount =
      currentRole === "vendor"
        ? {
            vendorUnreadCount: 0,
          }
        : {
            professionalUnreadCount: 0,
          };

    // make the vendorUnreadCount to 0
    const userDoc = doc(db, targetedChat, userData.id, "users", currentChat);
    updateDoc(userDoc, unreadCount);

    const q = query(
      collection(
        db,
        targetedChat,
        userData.id,
        "users",
        currentChat,
        "messages"
      ),
      where("senderId", "!=", userData.id)
    );
    getDocs(q).then((querySnapshot) => {
      querySnapshot.forEach(async (docu) => {
        await updateDoc(
          doc(
            db,
            targetedChat,
            userData.id,
            "users",
            currentChat,
            "messages",
            docu.id
          ),
          {
            isRead: true,
          }
        );
      });
    });
  }, [chatMessages, currentChat, userData.id]);

  const sendMessage = async () => {
    try {
      let targetedChat = currentRole === "vendor" ? "chats" : "serviceChats";
      const sentMessage = await addDoc(
        collection(
          db,
          targetedChat,
          userData.id,
          "users",
          currentChat,
          "messages"
        ),
        {
          message: newMessage,
          imageUrl: "",
          isRead: false,
          senderId: userData.id,
          senderName: userData.name,
          timestamp: new Date(),
        }
      );
      //  add updatedAt to that user just update that updatedAt field
      const userDoc = doc(db, targetedChat, userData.id, "users", currentChat);
      const userDocSnap = await getDoc(userDoc);
      await updateDoc(userDoc, {
        updatedAt: new Date(),
        lastMessage: newMessage,
        userUnreadCount: userDocSnap.data().userUnreadCount + 1 || 1,
      });

      // add notificaiton to that user
      addDoc(collection(db, "users", currentChat, "notifications"), {
        message: `You have a new message from ${userData.name}`,
        timestamp: new Date(),
      });

      // send notification to the user
      sendNotificationToCustomer();
      setNewMessage("");
    } catch (error) {
      console.error("Error sending message: ", error);
    }
  };

  const sendNotificationToCustomer = async () => {
    const userDoc = await getDoc(doc(db, "users", currentChat));
    const deviceToken = userDoc.data()?.deviceToken;

    // notification body
    const notification = {
      to: deviceToken,
      priority: "high",
      notification: {
        title: "New Message",
        body: `You have a new message from ${userData.name}`,
      },
      data: {
        click_action: "FLUTTER_NOTIFICATION_CLICK",
      },
    };

    // send message using FCM
    try {
      await fetch("https://fcm.googleapis.com/fcm/send", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `key=${process.env.REACT_APP_FIREBASE_SERVER_KEY}`,
        },
        body: JSON.stringify(notification),
      });
    } catch (error) {
      console.error("Error sending notification: ", error);
    }
  };
  const handleOrderSend = async (order) => {
    if (!order) {
      return;
    }

    try {
      let orderValues;
      if (userRole === "vendor") {
        orderValues = {
          ...order,
          shopId: userData.Shop.id,
        };
      } else {
        orderValues = {
          ...order,
          professionalId: userData.id,
        };
      }

      let chatOrderMessage =
        userRole === "vendor" ? "Product_Order_Sent" : "Service_Order_Sent";
      let targetedChat = currentRole === "vendor" ? "chats" : "serviceChats";

      const sentMessage = await addDoc(
        collection(
          db,
          targetedChat,
          userData.id,
          "users",
          currentChat,
          "messages"
        ),
        {
          message: chatOrderMessage,
          imageUrl: "",
          isRead: false,
          senderId: userData.id,
          senderName: userData.name,
          timestamp: new Date(),
          order: orderValues,
        }
      );

      //  add updatedAt to that user just update that updatedAt field
      const userDoc = doc(db, targetedChat, userData.id, "users", currentChat);
      const userDocSnap = await getDoc(userDoc);
      await updateDoc(userDoc, {
        updatedAt: new Date(),
        lastMessage: "Order",
        userUnreadCount: userDocSnap.data().userUnreadCount + 1 || 1,
      });

      // add notificaiton to that user
      addDoc(collection(db, "users", currentChat, "notifications"), {
        message: `You have a new message from ${userData.name}`,
        timestamp: new Date(),
      });

      // send notification to the user
      sendNotificationToCustomer();
      setNewMessage("");
      close();
    } catch (error) {
      console.error("Error sending message: ", error);
    }
  };

  useEffect(() => {
    scrollRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [chatMessages]);

  return (
    <div className="flex-grow   rounded-tr-2xl rounded-br-2xl h-full overflow-y-auto ">
      <div className="flex flex-col min-h-[70vh] space-y-3 mb-3">
        {selectedChat && (
          <div className="flex items-center space-x-2 z-50 sticky top-0 bg-white px-3 border-b py-3">
            <Avatar
              sx={{
                height: 40,
                width: 40,
                bgcolor: selectedChat?.color?.color,
              }}
            >
              {selectedChat?.color?.initial}
            </Avatar>
            <div>
              <div className=" text-sm text-gray-800">
                {selectedChat?.userName}{" "}
              </div>
              <div className=" text-xs text-gray-500">
                {moment.unix(selectedChat?.updatedAt?.seconds).fromNow()}
              </div>
            </div>
          </div>
        )}
        {chatMessages.map((item, index) => {
          const me = userData;
          const secondPerson = selectedChat;
          const isMe = me.id === item.senderId;
          const person = isMe ? me : secondPerson;
          const { color, initial } = isMe
            ? generateRandomAvatar(me?.userName || me?.name || me?.email)
            : secondPerson?.color;

          return (
            <div
              key={index}
              className={`flex flex-col w-full p-3 ${isMe ? "items-end" : ""}`}
            >
              <div
                className={`flex space-x-3 ${
                  isMe ? "flex-row-reverse" : "flex-row"
                }`}
              >
                <Avatar
                  sx={{
                    height: 40,
                    width: 40,
                    bgcolor: color,
                  }}
                >
                  {initial}
                </Avatar>

                <div
                  className={`flex flex-col space-y-1 ${
                    isMe ? "items-end" : "item-start"
                  }`}
                >
                  <div
                    className={`flex mb-2 w-full flex-col ${
                      isMe ? "items-end" : "items-start"
                    }`}
                  >
                    <span className="text-sm  text-gray-500">
                      {person?.name || person?.userName} {isMe && "(Me)"} &nbsp;
                    </span>
                  </div>

                  {/* Message Bubble */}
                  <div>
                    <div
                      className={`${
                        isMe
                          ? "text-end rounded-xl"
                          : "rounded-xl"
                      } bg-white px-3 py-2  inline-block break-words`}
                    >
                      {item.order ? (
                        <div className="flex flex-col items-end">
                          <div className="text-base flex items-center space-x-3 ">
                            <div className="w-10 h-10">
                              {userRole === "vendor" ? (
                                <img
                                  src={item.order.productImage}
                                  alt="productimage"
                                  className="w-full h-full rounded-md"
                                />
                              ) : (
                                <img
                                  src={item.order.serviceImage}
                                  alt="serviceimage"
                                  className="w-full h-full rounded-md"
                                />
                              )}
                            </div>
                            <div>
                              {userRole === "vendor" ? (
                                <>{item?.order?.productName}</>
                              ) : (
                                <>{item?.order?.serviceTitle}</>
                              )}
                            </div>
                          </div>
                          <div className="app-text-color">Order Sent</div>
                        </div>
                      ) : (
                        // Text Message
                        <div className="text-base">{item.message}</div>
                      )}
                    </div>
                  </div>

                  {/* Timestamp and Read Status */}
                  <div className="text-[10px] text-gray-400 flex items-center space-x-2">
                    <div>
                      {new Date(item.timestamp?.toDate()).toLocaleString()}
                    </div>
                    <div className="sub-heading-text">
                      {item.senderId === userData.id ? (
                        <>
                          {item.isRead ? (
                            <CiRead color="blue" />
                          ) : (
                            <CiUnread color="blue" />
                          )}
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}

        <div ref={scrollRef}></div>
      </div>
      {currentChat && (
        <form
          className="flex  justify-between mt-auto items-center space-x-1"
          onSubmit={(e) => {
            e.preventDefault();
            sendMessage();
          }}
        >
          <input
            type="text"
            className="w-full border-2 px-2 py-5"
            placeholder="Type a message"
            required
            value={newMessage}
            onChange={(e) => {
              setNewMessage(e.target.value);
              // if presses enter send the message
              if (e.key === "Enter") {
                sendMessage();
              }
            }}
          />
          <button
            className="my-2 heading-bg-color bg-text-color rounded-md px-5 py-2"
            type="submit"
          >
            Send
          </button>
          <div
            className="btn"
            onClick={() => {
              open();
            }}
          >
            Order
          </div>
        </form>
      )}
      {userRole === "vendor" ? (
        <ChatOrderModal
          open={open}
          close={close}
          isOpen={isOpen}
          handleOrderSend={handleOrderSend}
        />
      ) : (
        <ProfessionalChatOrder
          open={open}
          close={close}
          isOpen={isOpen}
          handleOrderSend={handleOrderSend}
        />
      )}
    </div>
  );
};

export default ChatPreview;
