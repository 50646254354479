import React, { useEffect, useState } from "react";
import Api from "../../util/axiosInstance";
import MainCategories from "./main/MainCategories";
import SubCategories from "./sub/SubCategories";
import Translate from "../../util/Translate";
import LoadingAnimation from "../../util/LoadingAnimation";

const TaxonomiesData = () => {
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [currentCategory, setCurrentCategory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentSubCategory, setCurrentSubCategory] = useState({});

  useEffect(() => {
    setLoading(true);
    Api.get("/shop-category")
      .then((response) => {
        setCategories(response.data.results);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);
  return (
    <div className="flex flex-col space-y-5">
      {loading ? (
        <div className=" flex justify-center min-h-[40vh] items-center m-auto">
          <LoadingAnimation />
        </div>
      ) : (
        <>
          <div className="sub-heading-text app-text-color">
            <Translate>Taxonomies Details</Translate>
          </div>
          <div className="flex space-x-10 self-center">
            <MainCategories
              categories={categories}
              setCategories={setCategories}
              setSubCategories={setSubCategories}
              setCurrentCategory={setCurrentCategory}
              currentCategory={currentCategory}
            />
            <SubCategories
              subCategories={subCategories}
              setSubCategories={setSubCategories}
              currentCategory={currentCategory}
              categories={categories}
              setCategories={setCategories}
              currentSubCategory={currentSubCategory}
              setCurrentSubCategory={setCurrentSubCategory}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default TaxonomiesData;
