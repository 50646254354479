import React, { useEffect, useState } from "react";
import Menu from "../sideMenu/Menu";
import { useNavigate } from "react-router-dom";
import Api from "../util/axiosInstance";
import Translate from "../util/Translate";
import { useTranslation } from "react-i18next";
import Toast from "../util/CustomToast";

const CreateAdmin = () => {
  const Navigate = useNavigate();
  const [cities, setCities] = useState([]);
  const { t } = useTranslation();
  const [userInputValues, setUserInputValues] = useState({
    email: "",
    password: "",
    name: "",
    phone: "",
    active: true,
    profileImg: "",
    addressLine1: "",
    addressLine2: "",
    country: "",
    postalCode: "",
    latitude: 0,
    longitude: 0,
    cityId: "",
  });

  useEffect(() => {
    Api.get("/city")
      .then((response) => {
        setCities(response.data.results);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleOnChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox") {
      setUserInputValues((prevState) => ({
        ...prevState,
        [name]: checked,
      }));
    } else {
      setUserInputValues((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleUserImageUpload = (e) => {
    setUserInputValues((prevState) => ({
      ...prevState,
      profileImg: e.target.files[0],
    }));
  };

  const handleSavingUser = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("image", userInputValues.profileImg);

    Api.post("/cloudinary/upload", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((response) => {
        const { url } = response.data;
        const adminData = {
          ...userInputValues,
          profileImg: url,
          longitude: +userInputValues.longitude,
          latitude: +userInputValues.latitude,
          role:"logisticadmin"
        };

        return Api.post("/admin", adminData);
      })
      .then(() => {
        Toast("success", t("Admin created successfully"));
        setUserInputValues({
          email: "",
          password: "",
          name: "",
          phone: "",
          active: true,
          profileImg: "",
          addressLine1: "",
          addressLine2: "",
          country: "",
          postalCode: "",
          latitude: 0,
          longitude: 0,
          cityId: "",
        });
        Navigate("/logistic/admins");
      })
      .catch((error) => {
        console.log(error, "error");
        Toast("error", t("Invalid Credentials/Network Error"));
      });
  };

  return (
    <Menu>
      <div>
        <div className="mb-5 heading-text">
          <Translate>Admins</Translate>
        </div>
        <div className="w-full flex justify-center">
          <form
            className="w-2/3 flex flex-col space-y-20 my-10"
            onSubmit={handleSavingUser}
          >
            {/* Admin Details Fieldset */}
            <fieldset>
              <legend>
                <Translate>Admin Details</Translate>
              </legend>
              <div className="create-form">
                <div>
                  <label htmlFor="name">
                    <Translate>Name</Translate>
                  </label>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    placeholder={t("Name")}
                    required
                    onChange={handleOnChange}
                    value={userInputValues.name}
                    minLength={3}
                    maxLength={30}
                  />
                </div>
                <div>
                  <label htmlFor="email">
                    <Translate>Email</Translate>
                  </label>
                  <input
                    type="text"
                    name="email"
                    id="email"
                    placeholder={t("Email")}
                    required
                    value={userInputValues.email}
                    onChange={handleOnChange}
                  />
                </div>
                <div>
                  <label htmlFor="password">
                    <Translate>Password</Translate>
                  </label>
                  <input
                    type="password"
                    name="password"
                    id="password"
                    placeholder={t("Password")}
                    required
                    value={userInputValues.password}
                    onChange={handleOnChange}
                    minLength={6}
                    maxLength={15}
                    title="Must contain at least one number and one uppercase and lowercase letter, and at least 6 or more characters"
                  />
                </div>
                <div>
                  <label htmlFor="phone">
                    <Translate>Phone</Translate>
                  </label>
                  <input
                    type="tel"
                    name="phone"
                    id="phone"
                    placeholder={t("Phone")}
                    required
                    value={userInputValues.phone}
                    onChange={handleOnChange}
                    minLength={10}
                  />
                </div>
                <div>
                  <label htmlFor="profileImg">
                    <Translate>Profile Image</Translate>
                  </label>
                  <input
                    type="file"
                    name="profileImg"
                    id="profileImg"
                    accept="image/*"
                    onChange={handleUserImageUpload}
                  />
                </div>
                {/* <div>
                  <label htmlFor="role">
                    <Translate>Role</Translate>
                  </label>
                  <input
                    type="text"
                    name="role"
                    id="role"
                    placeholder={t("Role")}
                    required
                    value={userInputValues.role}
                    onChange={handleOnChange}
                  />
                </div> */}
                <div>
                  <label htmlFor="cityId">
                    <Translate>Admin City</Translate>
                  </label>
                  <select
                    onChange={handleOnChange}
                    name="cityId"
                    id="cityId"
                    required
                  >
                    <option value="">
                      <Translate>Select City</Translate>
                    </option>
                    {cities.map((city) => (
                      <option
                        key={city.id}
                        value={city.id}
                        className="capitalize"
                      >
                        {city.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </fieldset>

            {/* Address Details Fieldset */}
            <fieldset>
              <legend>
                <Translate>Address Details</Translate>
              </legend>
              <div className="create-form">
                <div>
                  <label htmlFor="addressLine1">
                    <Translate>Address Line 1</Translate>
                  </label>
                  <input
                    type="text"
                    name="addressLine1"
                    id="addressLine1"
                    placeholder={t("Address Line 1")}
                    required
                    value={userInputValues.addressLine1}
                    onChange={handleOnChange}
                    minLength={5}
                    maxLength={30}
                  />
                </div>
                <div>
                  <label htmlFor="addressLine2">
                    <Translate>Address Line 2</Translate>
                  </label>
                  <input
                    type="text"
                    name="addressLine2"
                    id="addressLine2"
                    placeholder={t("Address Line 2")}
                    value={userInputValues.addressLine2}
                    onChange={handleOnChange}
                    minLength={5}
                    maxLength={30}
                  />
                </div>
                <div>
                  <label htmlFor="country">
                    <Translate>Country</Translate>
                  </label>
                  <input
                    type="text"
                    name="country"
                    id="country"
                    placeholder={t("Country")}
                    value={userInputValues.country}
                    onChange={handleOnChange}
                    minLength={3}
                    maxLength={15}
                  />
                </div>
                <div>
                  <label htmlFor="postalCode">
                    <Translate>Postal Code</Translate>
                  </label>
                  <input
                    type="text"
                    name="postalCode"
                    id="postalCode"
                    placeholder={t("Postal Code")}
                    value={userInputValues.postalCode}
                    onChange={handleOnChange}
                    minLength={2}
                    maxLength={15}
                  />
                </div>
                <div>
                  <label htmlFor="latitude">
                    <Translate>Latitude</Translate>
                  </label>
                  <input
                    type="text"
                    name="latitude"
                    id="latitude"
                    placeholder={t("Latitude")}
                    value={userInputValues.latitude}
                    onChange={handleOnChange}
                    minLength={3}
                    maxLength={15}
                  />
                </div>
                <div>
                  <label htmlFor="longitude">
                    <Translate>Longitude</Translate>
                  </label>
                  <input
                    type="text"
                    name="longitude"
                    id="longitude"
                    placeholder={t("Longitude")}
                    value={userInputValues.longitude}
                    onChange={handleOnChange}
                    minLength={3}
                    maxLength={15}
                  />
                </div>
              </div>
            </fieldset>

            {/* Action Buttons */}
            <div className="flex justify-end items-center w-full space-x-4 mt-4">
              <button
                type="button"
                onClick={() => Navigate("/admins")}
                className="btn-cancel"
              >
                <Translate>Cancel</Translate>
              </button>
              <button
                type="submit"
                // className="btn-submit"
                className="heading-bg-color bg-text-color px-5 py-2 rounded-md cursor-pointer btn-submit"
              >
                <Translate>Create Admin</Translate>
              </button>
            </div>
          </form>
        </div>
      </div>
    </Menu>
  );
};

export default CreateAdmin;
