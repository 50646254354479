import React from "react";
import { Cell, Legend, Pie, PieChart, Tooltip } from "recharts";
import Translate from "../../util/Translate";

const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042", "#bbff00"];

const UsersReportTable = ({ orderStatus }) => {
  return (
    <div className="bg-white flex flex-col justify-center items-center pt-5 rounded-lg">
      <div className="heading-text">
        <Translate>Orders Report</Translate>
      </div>
      <PieChart width={400} height={400}>
        <Pie
          data={orderStatus}
          cx="50%"
          cy="50%"
          labelLine={false}
          label
          outerRadius={150}
          fill="#8884d8"
          dataKey="value"
        >
          {orderStatus.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Tooltip />
        <Legend verticalAlign="top" />
      </PieChart>
    </div>
  );
};

export default UsersReportTable;
