import React from "react";
import { Link } from "react-router-dom";
import TicketList from "./TicketList";
import Menu from "../sideMenu/Menu";

const VendorSubscription = () => {
  const userRole = localStorage.getItem("role");

  return (
    <Menu>
      <div className="flex flex-col items-start space-y-5 ">
        <h1 className="sub-heading-text py-2 border-b-2 border-b-gray-300 w-full">
          Tickets
        </h1>
        <div className="w-full bg-gray-200 flex justify-center items-center">
          <div className="py-2 px-4 bg-color app-text-color cursor-pointer rounded-md">
            Ticket List
          </div>
          {userRole === "customer" && (
            <Link
              className="py-2 px-4 hover:heading-bg-color hover:bg-text-color cursor-pointer rounded-md"
              to={"/tickets/create"}
            >
              create Ticket
            </Link>
          )}
        </div>
        <TicketList />
      </div>
    </Menu>
  );
};

export default VendorSubscription;
