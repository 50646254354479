import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import DriverRow from "./DriverRow";

const Driver = {
  name: "John",
  image: "https://picsum.photos/800/800",
  email: "waleed@yopmail.com",
  createdAt: "2021-01-01",
  availablity: true,
  totalOrders: 14,
};

const DriversList = () => {
  return (
    <div className="w-full">
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <div className="flex items-center space-x-1">
                <div className="w-5 h-5 border cursor-pointer"></div>
                <div>All</div>
              </div>
            </TableCell>
            <TableCell>Image</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Date</TableCell>
            <TableCell>Foods</TableCell>
            <TableCell>Orders</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <DriverRow driver={Driver} />
          <DriverRow driver={Driver} />
          <DriverRow driver={Driver} />
          <DriverRow driver={Driver} />
          <DriverRow driver={Driver} />
          <DriverRow driver={Driver} />
          <DriverRow driver={Driver} />
          <DriverRow driver={Driver} />
          <DriverRow driver={Driver} />
        </TableBody>
      </Table>
    </div>
  );
};

export default DriversList;
