import axios from "axios";

const baseURL =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_BACKEND_URL
    : process.env.REACT_APP_BACKEND_URL;

const Api = axios.create({
  baseURL: baseURL,
  // withCredentials: true,
});

Api.interceptors.request.use((config) => {
  const token = localStorage.getItem("token");
  console.log({ token }, "token here");

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  console.log(`Request made to: ${config.url}`);
  return config;
});

Api.interceptors.response.use(
  (response) => {
    console.log(`Response received from: ${response.config.url}`);
    return response;
  },
  (error) => {
    console.error(`Error response from: ${error.config?.url}`);
    return Promise.reject(error);
  }
);

export default Api;
