import React, { useEffect, useState } from "react";
import Menu from "../sideMenu/Menu";
import GoogleMapReact from "google-map-react";
import { ShopLocationIcon } from "./VendorsMapData";
import Api from "../util/axiosInstance";
import LoadingAnimation from "../util/LoadingAnimation";

const MapsData = () => {
  const [shopsLocation, setShopsLocation] = useState([]);
  const [loading, setLoading] = useState(false);
  const [mapBounds, setMapBounds] = useState(null);

  useEffect(() => {
    setLoading(true);
    Api.get("/shop")
      .then((response) => {
        const shops = response.data.results.map((shop) => {
          shop.latitude = parseFloat(shop.latitude);
          shop.longitude = parseFloat(shop.longitude);
          return shop;
        });
        setShopsLocation(shops);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const filteredShops = shopsLocation.filter((shop) => {
    if (!mapBounds) return true;
    const { nw, se } = mapBounds;

    return (
      shop.latitude >= se.lat &&
      shop.latitude <= nw.lat &&
      shop.longitude >= nw.lng &&
      shop.longitude <= se.lng
    );
  });

  const handleMapChange = ({ bounds }) => {
    setMapBounds({
      nw: bounds.nw,
      se: bounds.se,
    });
  };

  return (
    <Menu>
      <div className="relative w-full h-[90vh] overflow-hidden">
        {loading && (
          <div className="absolute inset-0 z-50 flex justify-center items-center">
            <LoadingAnimation />
          </div>
        )}
        <GoogleMapReact
          bootstrapURLKeys={{ key: `${process.env.REACT_APP_MAP_KEY}` }}
          defaultCenter={{
            lat: shopsLocation[0]?.latitude || 0,
            lng: shopsLocation[0]?.longitude || 0,
          }}
          defaultZoom={12}
          onChange={handleMapChange} 
        >
          {filteredShops.map((shop) => (
            <ShopLocationIcon
              key={shop.id}
              lat={shop.latitude}
              lng={shop.longitude}
              shop={shop}
            />
          ))}
        </GoogleMapReact>
      </div>
    </Menu>
  );
};

export default MapsData;
