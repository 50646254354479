import React, { useEffect, useState } from "react";
import Menu from "../sideMenu/Menu";
import { Link } from "react-router-dom";
import UnitsList from "./UnitsList";
import Translate from "../util/Translate";
import Api from "../util/axiosInstance";
import LoadingAnimation from "../util/LoadingAnimation";

const Units = () => {
  const [units, setUnits] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    Api.get("/unit")
      .then((res) => {
        setUnits(res.data.results);
      })
      .catch((err) => {
        console.log(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);
  return (
    <Menu>
      {loading ? (
        <div className=" flex justify-center min-h-[90vh] items-center m-auto">
          <LoadingAnimation />
        </div>
      ) : (
        <div className="flex flex-col items-start space-y-5 ">
          <h1 className="sub-heading-text py-2 border-b-2 border-b-gray-300 w-full">
            <Translate>Units</Translate>
          </h1>
          <div className="w-full bg-gray-200 flex justify-center items-center">
            <div className="py-2 px-4 bg-color app-text-color cursor-pointer rounded-md">
              <Translate>Units List</Translate>
            </div>
            <Link
              className="py-2 px-4 hover:heading-bg-color hover:bg-text-color cursor-pointer rounded-md"
              to={"/units/create"}
            >
              <Translate>Create Unit</Translate>
            </Link>
          </div>
          <UnitsList
            setLoading={setLoading}
            setUnits={setUnits}
            units={units}
          />
        </div>
      )}
    </Menu>
  );
};

export default Units;
