import React, { useEffect, useState } from "react";
import Menu from "../sideMenu/Menu";
import ReactQuill from "react-quill";
import Api from "../util/axiosInstance";
import Translate from "../util/Translate";
import Toast from "../util/CustomToast";

const editorModules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }],
    ["bold", "italic", "underline", "strike"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["clean"],
    ["code-block"],
  ],
};

const TermsAndConditions = () => {
  const [termsAndConditions, setTermsAndConditions] = useState("");

  useEffect(() => {
    Api.get("/privacy-policy")
      .then((res) => {
        if (res.data) {
          setTermsAndConditions(res.data.data.termsAndConditions);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleSubmission = () => {
    // This is where you can send the termsAndConditions to the backend
    Api.post("/privacy-policy", { termsAndConditions })
      .then((res) => {
        console.log(res);
        Toast("success", res.data.message);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <Menu>
      <div className=" w-full h-full">
        <div className="sub-heading-text app-text-color my-5">
          <Translate>Terms and Conditions</Translate>
        </div>
        <div className="flex flex-col space-y-20">
          <div className="h-[60vh] w-full">
            <ReactQuill
              theme="snow"
              modules={editorModules}
              value={termsAndConditions}
              onChange={(e) => {
                setTermsAndConditions(e);
              }}
              className="h-full w-full"
            />
          </div>
          <div className="btn self-center" onClick={handleSubmission}>
            <Translate>Finalize</Translate>
          </div>
        </div>
      </div>
    </Menu>
  );
};

export default TermsAndConditions;
