import React, { useEffect, useState } from "react";
import Menu from "../sideMenu/Menu";
import ProfessionalsList from "./NotificationsList";
import Api from "../util/axiosInstance";
import LoadingAnimation from "../util/LoadingAnimation";
import { useAppContext } from "../../hooks/context/tokenStore";
import Translate from "../util/Translate";
import { Link } from "react-router-dom";

const Notifications = () => {
  const [professionals, setProfessionals] = useState([]);
  const [loading, setLoading] = useState(false);
  const { userData } = useAppContext();

  useEffect(() => {
    setLoading(true);
    let query = "";
    let userRole = localStorage.getItem("role");
    if (userRole === "cityadmin") query = `cityId=${userData?.City?.id}`;
    Api.get("/notification?" + query)
      .then((res) => {
        setProfessionals(res.data.results);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <Menu>
      <div className="flex flex-col items-start space-y-5 w-full min-h-[90vh] ">
        {loading ? (
          <div className=" flex justify-center items-center m-auto">
            <LoadingAnimation />
          </div>
        ) : (
          <>
            <h1 className="sub-heading-text py-2 border-b-2 border-b-gray-300 w-full">
              Notifications
            </h1>
            <div className="w-full bg-gray-200 flex justify-center items-center">
              <div className="py-2 px-4 bg-color app-text-color cursor-pointer rounded-md">
                Notifications List
              </div>
              <Link
                className="py-2 px-4 hover:heading-bg-color hover:bg-text-color cursor-pointer rounded-md"
                to={"/notifications/create"}
              >
                <Translate>Create Notification</Translate>
              </Link>
            </div>
            <ProfessionalsList
              professionals={professionals}
              setLoading={setLoading}
              setProfessionals={setProfessionals}
            />
          </>
        )}
      </div>
    </Menu>
  );
};

export default Notifications;
