import React, { useState } from "react";
import Api from "../util/axiosInstance";

const EditInternalCategory = ({
  currentCategory,
  closeModal,
  internalCategories,
  setInternalCategories,
}) => {
  const [inputValues, setInputValues] = useState({
    name: currentCategory.name,
  });

  const handleChange = (e) => {
    setInputValues({ ...inputValues, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(inputValues);
    Api.patch("/product-internal-category/" + currentCategory.id, {
      name: inputValues.name,
    })
      .then((response) => {
        console.log(response);
        setInputValues({
          name: "",
        });

        setInternalCategories(
          internalCategories.map((category) =>
            category.id === currentCategory.id
              ? { ...category, name: inputValues.name }
              : category
          )
        );

        closeModal();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="my-5">
      <form className="flex flex-col space-y-5">
        <div className="input-group">
          <label>Name</label>
          <input
            type="text"
            name="name"
            value={inputValues.name}
            onChange={handleChange}
          />
        </div>

        <div>
          <button
            className="btn bg-color app-text-color"
            onClick={handleSubmit}
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditInternalCategory;
