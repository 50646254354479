import React, { useEffect, useState } from "react";
import Api from "../util/axiosInstance";
import { DataGrid } from "@mui/x-data-grid";
import { RiEditCircleFill } from "react-icons/ri";
import { TiEye } from "react-icons/ti";
import { URL } from "../util/authorization/authorizationURL";
import { useNavigate } from "react-router-dom";
import LoadingAnimation from "../util/LoadingAnimation";

const TicketList = () => {
  const [tickets, setTickets] = useState([]);
  const [loading, setLoading] = useState(false);
  const Navigate = useNavigate();

  const userRole = localStorage.getItem("role");

  useEffect(() => {
    setLoading(true);
    Api.get(URL.ticket.get[userRole])
      .then((res) => {
        setTickets(res.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const columns = [
    {
      field: "title",
      headerName: "Title",
      width: 150,
    },
    {
      field: "status",
      headerName: "Status",
      width: 150,
    },
    {
      field: "city",
      headerName: "City",
      width: 150,
      valueGetter: (value, row) => {
        return row?.City?.name || "";
      },
    },
    {
      field: "customer",
      headerName: "Customer",
      width: 150,
      valueGetter: (value, row) => {
        return row?.Customer?.name || "";
      },
    },
    {
      field: "action",
      headerName: "Action",
      width: 150,
      renderCell: (params) => {
        return (
          <div className="w-full h-full flex items-center">
            <div
              className="text-green-700 cursor-pointer"
              onClick={() => {
                Navigate(`/tickets/view/${params.row.id}`);
              }}
            >
              <TiEye />
            </div>
          </div>
        );
      },
    },
  ];
  return (
    <div className="w-full">
      {loading ? (
        <div className=" flex justify-center min-h-[75vh] items-center m-auto">
          <LoadingAnimation />
        </div>
      ) : (
        <DataGrid
          rows={tickets}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[5]}
        />
      )}
    </div>
  );
};

export default TicketList;
