import { toast, ToastContainer } from "react-toastify";

export default function Toast(type, message) {
  switch (type) {
    case "success":
      return toast.success(message);
    case "error":
      return toast.error(message);
    default:
      break;
  }
}
