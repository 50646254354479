import React, { useEffect, useState } from "react";
import Menu from "../sideMenu/Menu";
import Translate from "../util/Translate";
import MainCategories from "../taxonomy/vendor/main/MainCategories";
import SubCategories from "../taxonomy/vendor/sub/SubCategories";
import Api from "../util/axiosInstance";
import InternalCategoriesList from "./InternalCategoriesList";
import { Link } from "react-router-dom";
import CustomModal from "../reusable/CustomModal";
import CreateInternalCategory from "./CreateInternalCategory";

const InternalCategories = () => {
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [currentCategory, setCurrentCategory] = useState([]);
  const [currentSubCategory, setCurrentSubCategory] = useState({});

  const [modelIsOpen, setModalIsOpen] = useState(false);
  const openModal = () => {
    setModalIsOpen(true);
  };
  const closeModal = () => {
    setModalIsOpen(false);
  };
  useEffect(() => {
    Api.get("/shop-category").then((response) => {
      setCategories(response.data.results);
    });
  }, []);
  return (
    <Menu>
      <div>
        <div className="flex flex-col space-y-5">
          <div className="sub-heading-text app-text-color">
            <Translate>Category Details</Translate>
          </div>
          <div className="btn text-center self-start" onClick={openModal}>
            <Translate>Create Internal Category</Translate>
          </div>
          <div className="flex flex-col space-y-10 lg:space-y-0 lg:flex-row lg:space-x-10 self-center">
            {/* <MainCategories
              categories={categories}
              setCategories={setCategories}
              setSubCategories={setSubCategories}
              setCurrentCategory={setCurrentCategory}
              currentCategory={currentCategory}
            />
            <SubCategories
              subCategories={subCategories}
              setSubCategories={setSubCategories}
              currentCategory={currentCategory}
              categories={categories}
              setCategories={setCategories}
              currentSubCategory={currentSubCategory}
              setCurrentSubCategory={setCurrentSubCategory}
            /> */}
            <InternalCategoriesList isOpen={modelIsOpen} />
          </div>
        </div>
        <CustomModal
          title={"Create Internal Category"}
          isOpen={modelIsOpen}
          closeModal={closeModal}
          setIsOpen={setModalIsOpen}
          openModal={openModal}
        >
          <CreateInternalCategory
            closeModal={closeModal}
            categories={categories}
            currentCategory={currentCategory}
          />
        </CustomModal>
      </div>
    </Menu>
  );
};

export default InternalCategories;
