import React from "react";
import Translate from "../util/Translate";
import CreateSlider from "./CreateSlider";
import Menu from "../sideMenu/Menu";
import SliderList from "./SliderList";

const CitySlider = () => {
  return (
    <Menu>
      <div className="flex flex-col space-y-5">
        <h1 className="sub-heading-text py-2 border-b-2 border-b-gray-300 w-full">
          <Translate>City Slider</Translate>
        </h1>
        <div className="flex flex-col space-y-5">
          <div>
            <CreateSlider />
          </div>
          <div>
            <SliderList />
          </div>
        </div>
      </div>
    </Menu>
  );
};

export default CitySlider;
