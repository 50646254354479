import React, { useEffect, useState } from "react";
import { MdDelete, MdEdit } from "react-icons/md";
import Api from "../../util/axiosInstance";
import Translate from "../../util/Translate";
import CustomModal from "../../reusable/CustomModal";
import EditPointerest from "./EditPointerest";
import ConfirmationModal from "../../util/ConfirmationModal";

const PointerestList = ({
  pointerest,
  setPointerest,
  currentCity,
  cities,
  setCities,
}) => {
  const [modelIsOpen, setModalIsOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [currentPointerest, setCurrentPointerest] = useState({});
  const openModal = () => {
    setModalIsOpen(true);
  };
  const closeModal = () => {
    setModalIsOpen(false);
  };

  useEffect(() => {
    if (currentCity) {
      const city = cities.find((city) => city.id === currentCity.id);
      if (city?.Pointerest) {
        setPointerest(city.Pointerest);
      }
    }
  }, [currentCity]);

  const handlePointerestDelete = (id) => {
    Api.delete(`/pointerest/${id}`)
      .then((res) => {
        let tempPointerest = pointerest.filter((item) => item.id !== id);
        setPointerest(tempPointerest);

        let tempCities = cities.map((item) => {
          if (item.id === currentCity.id) {
            return {
              ...item,
              Pointerest: tempPointerest,
            };
          }
          return item;
        });
        setCities(tempCities);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="flex flex-col space-y-5 bg-white p-5 w-fit rounded-md">
      <div className="sub-heading-text">
        <Translate>Point of Interest</Translate>
      </div>
      <div className="flex flex-col space-y-3 h-[40vh] w-[250px] overflow-y-scroll">
        {pointerest.map((area, index) => {
          return (
            <>
              <div
                key={area.id}
                className={`flex justify-between px-3 py-2 hover:heading-bg-color hover:bg-text-color cursor-pointer rounded-md`}
                onClick={() => {
                  setCurrentPointerest(area);
                }}
              >
                <div className="flex space-x-3">
                  <div>{index + 1}.</div>
                  <div>{area.name}</div>
                </div>
                <div className="flex space-x-3 heading-text">
                  <MdEdit
                    className="cursor-pointer "
                    color="green"
                    onClick={openModal}
                  />
                  <MdDelete
                    className="cursor-pointer"
                    color="red"
                    onClick={() => setIsOpen(true)}
                  />
                </div>
              </div>
              <ConfirmationModal
                deleteFuction={handlePointerestDelete}
                id={area.id}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
              />
            </>
          );
        })}
        {pointerest.length === 0 && (
          <div className="text-center text-gray-400">
            <Translate>No City Selected/Found</Translate>
          </div>
        )}
      </div>

      <CustomModal
        title="Edit Point of Interest"
        openModal={openModal}
        closeModal={closeModal}
        isOpen={modelIsOpen}
        setIsOpen={setModalIsOpen}
      >
        <EditPointerest
          currentCity={currentCity}
          currentPointerest={currentPointerest}
          setCities={setCities}
          setPointerest={setPointerest}
          pointerest={pointerest}
          cities={cities}
          closeModal={closeModal}
        />
      </CustomModal>
    </div>
  );
};

export default PointerestList;
