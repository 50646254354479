import React, { useEffect, useState } from "react";
import Menu from "../sideMenu/Menu";
import { useNavigate, useParams } from "react-router-dom";
import Api from "../util/axiosInstance";
import { useTranslation } from "react-i18next";
import Toast from "../util/CustomToast";
import Translate from "../util/Translate";
import { cloudinaryUpload } from "../../utils/cloudinaryUpload";

const EditAdmin = () => {
  const { id } = useParams(); // Get the admin ID from the URL params
  const Navigate = useNavigate();
  const [cities, setCities] = useState([]);
  const { t } = useTranslation();
  const [userInputValues, setUserInputValues] = useState({
    email: "",
    password: "",
    name: "",
    phone: "",
    active: true,
    profileImg: null,
    addressLine1: "",
    addressLine2: "",
    country: "",
    postalCode: "",
    latitude: "",
    longitude: "",
    cityId: "",
  });

  useEffect(() => {
    // Fetch cities for the city dropdown
    Api.get("/city")
      .then((response) => {
        setCities(response.data.results);
      })
      .catch((error) => {
        console.log(error);
      });

    // Fetch existing admin data
    Api.get(`/admin/${id}`)
      .then((response) => {
        const adminData = response.data;
        setUserInputValues(adminData);
      })
      .catch((error) => {
        console.log(error);
        Toast("error", t("Failed to fetch admin data"));
      });
  }, [id, t]);

  const handleOnChange = (e) => {
    const { name, value, type, checked } = e.target;

    setUserInputValues((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleImageUpload = async (e) => {
    const profileImg = await cloudinaryUpload(e.target.files[0]);
    setUserInputValues((prevState) => ({
      ...prevState,
      profileImg: profileImg,
    }));
  };

  const handleSavingUser = (e) => {
    e.preventDefault();
    Api.patch(`/admin/${id}`, { ...userInputValues })
      .then(() => {
        Toast("success", t("Admin updated successfully"));
        Navigate("/admins");
      })
      .catch((error) => {
        console.log(error, "error");
        Toast("error", t("Update failed"));
      });
  };

  return (
    <Menu>
      <div>
        <div className="mb-5 heading-text">
          <Translate>Edit Admin</Translate>
        </div>
        <div className="w-full flex justify-center">
          <form
            className="w-2/3 flex flex-col space-y-20 my-10"
            onSubmit={handleSavingUser}
          >
            {/* Admin Details Fieldset */}
            <fieldset>
              <legend>
                <Translate>Admin Details</Translate>
              </legend>
              <div className="create-form">
                <div>
                  <label htmlFor="name">
                    <Translate>Name</Translate>
                  </label>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    placeholder={t("Name")}
                    required
                    onChange={handleOnChange}
                    value={userInputValues.name}
                    minLength={3}
                    maxLength={30}
                  />
                </div>
                <div>
                  <label htmlFor="email">
                    <Translate>Email</Translate>
                  </label>
                  <input
                    type="text"
                    name="email"
                    id="email"
                    placeholder={t("Email")}
                    required
                    value={userInputValues.email}
                    onChange={handleOnChange}
                  />
                </div>
                <div>
                  <label htmlFor="phone">
                    <Translate>Phone</Translate>
                  </label>
                  <input
                    type="tel"
                    name="phone"
                    id="phone"
                    placeholder={t("Phone")}
                    required
                    value={userInputValues.phone}
                    onChange={handleOnChange}
                    minLength={10}
                  />
                </div>
                <div>
                  <label htmlFor="profileImg">
                    <Translate>Profile Image</Translate>
                  </label>
                  <input
                    type="file"
                    name="profileImg"
                    id="profileImg"
                    accept="image/*"
                    onChange={handleImageUpload}
                  />
                </div>
                <div>
                  <label htmlFor="cityId">
                    <Translate>Admin City</Translate>
                  </label>
                  <select
                    onChange={handleOnChange}
                    name="cityId"
                    id="cityId"
                    required
                    value={userInputValues.cityId}
                  >
                    <option value="">
                      <Translate>Select City</Translate>
                    </option>
                    {cities.map((city) => (
                      <option
                        key={city.id}
                        value={city.id}
                        className="capitalize"
                      >
                        {city.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </fieldset>

            {/* Address Details Fieldset */}
            <fieldset>
              <legend>
                <Translate>Address Details</Translate>
              </legend>
              <div className="create-form">
                <div>
                  <label htmlFor="addressLine1">
                    <Translate>Address Line 1</Translate>
                  </label>
                  <input
                    type="text"
                    name="addressLine1"
                    id="addressLine1"
                    placeholder={t("Address Line 1")}
                    required
                    value={userInputValues.addressLine1}
                    onChange={handleOnChange}
                    minLength={5}
                    maxLength={30}
                  />
                </div>
                <div>
                  <label htmlFor="addressLine2">
                    <Translate>Address Line 2</Translate>
                  </label>
                  <input
                    type="text"
                    name="addressLine2"
                    id="addressLine2"
                    placeholder={t("Address Line 2")}
                    value={userInputValues.addressLine2}
                    onChange={handleOnChange}
                    minLength={5}
                    maxLength={30}
                  />
                </div>
                <div>
                  <label htmlFor="country">
                    <Translate>Country</Translate>
                  </label>
                  <input
                    type="text"
                    name="country"
                    id="country"
                    placeholder={t("Country")}
                    value={userInputValues.country}
                    onChange={handleOnChange}
                    minLength={3}
                    maxLength={15}
                  />
                </div>
                <div>
                  <label htmlFor="postalCode">
                    <Translate>Postal Code</Translate>
                  </label>
                  <input
                    type="text"
                    name="postalCode"
                    id="postalCode"
                    placeholder={t("Postal Code")}
                    value={userInputValues.postalCode}
                    onChange={handleOnChange}
                    minLength={2}
                    maxLength={15}
                  />
                </div>
                <div>
                  <label htmlFor="latitude">
                    <Translate>Latitude</Translate>
                  </label>
                  <input
                    type="text"
                    name="latitude"
                    id="latitude"
                    placeholder={t("Latitude")}
                    value={userInputValues.latitude}
                    onChange={handleOnChange}
                    minLength={3}
                    maxLength={15}
                  />
                </div>
                <div>
                  <label htmlFor="longitude">
                    <Translate>Longitude</Translate>
                  </label>
                  <input
                    type="text"
                    name="longitude"
                    id="longitude"
                    placeholder={t("Longitude")}
                    value={userInputValues.longitude}
                    onChange={handleOnChange}
                    minLength={3}
                    maxLength={15}
                  />
                </div>
              </div>
            </fieldset>

            {/* Action Buttons */}
            <div className="flex justify-end items-center w-full space-x-4 mt-4">
              <button
                type="button"
                onClick={() => Navigate("/admins")}
                className="btn-cancel"
              >
                <Translate>Cancel</Translate>
              </button>
              <button
                type="submit"
                className="heading-bg-color bg-text-color px-5 py-2 rounded-md cursor-pointer btn-submit"
              >
                <Translate>Save Changes</Translate>
              </button>
            </div>
          </form>
        </div>
      </div>
    </Menu>
  );
};

export default EditAdmin;
