import React, { useEffect, useState } from "react";
import Menu from "../sideMenu/Menu";
import { Link } from "react-router-dom";
import ShopsList from "./ShopsList";
import Translate from "../util/Translate";
import { URL } from "../util/authorization/authorizationURL";
import Api from "../util/axiosInstance";
import LoadingAnimation from "../util/LoadingAnimation";
import { useAppContext } from "../../hooks/context/tokenStore";
import { TextField } from "@mui/material";

const Shops = () => {
  const [shopList, setShopList] = useState([]);
  const [loading, setLoading] = useState(false);
  const userRole = localStorage.getItem("role");
  const [searchValue, setSearchValue] = useState("");
  const { userData } = useAppContext();
  useEffect(() => {
    let url = URL.shops[userRole];
    if (!searchValue) setLoading(true);
    let filter = "";
    if (userRole === "cityadmin")
      filter = `?cityId=${userData?.City?.id}&name=${searchValue}`;
    else filter = `?name=${searchValue}`;
    Api.get(url + filter)
      .then((res) => {
        setShopList(res.data.results);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [searchValue]);
  return (
    <Menu>
      {loading ? (
        <div className=" flex justify-center min-h-[90vh] items-center m-auto">
          <LoadingAnimation />
        </div>
      ) : (
        <div className="flex flex-col items-start space-y-5 ">
          <div className="w-full mb-2 flex items-center justify-between">
            <h1 className="sub-heading-text py-2 border-b-2 border-b-gray-300 w-full">
              <Translate>Shops</Translate>
            </h1>
            <TextField
              onChange={(e) => setSearchValue(e.target.value)}
              value={searchValue}
              size="small"
              placeholder="Search Shops..."
            />
          </div>

          <div className="w-full bg-gray-200 flex justify-center items-center">
            <div className="py-2 px-4 app-text-color  cursor-pointer rounded-md">
              <Translate>Shops List</Translate>
            </div>
            {/* <Link
            className="py-2 px-4 hover:heading-bg-color hover:bg-text-color cursor-pointer rounded-md"
            to={"/shops/create"}
          >
            <Translate>Create Shop</Translate>
          </Link> */}
          </div>
          <ShopsList
            setLoading={setLoading}
            setShopList={setShopList}
            shopList={shopList}
          />
        </div>
      )}
    </Menu>
  );
};

export default Shops;
