import { Menu } from "@headlessui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { SlOptionsVertical } from "react-icons/sl";
import Translate from "../util/Translate";

const filters = [
  { label: "Today" },
  { label: "This Week" },
  { label: "This Month" },
];

const RangeFilter = () => {
  const { t, i18n } = useTranslation();

  return (
    <div className="">
      <Menu as="div" className="relative inline-block text-left">
        <Menu.Button className="inline-flex w-full justify-center rounded-md -z-10">
          <SlOptionsVertical />
        </Menu.Button>
        <Menu.Items className="absolute right-0 mt-2 w-32 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none z-50">
          <div className="py-1">
            {filters.map((link) => (
              <Menu.Item
                className="ui-active:bg-blue-500 ui-active:text-white ui-not-active:bg-white ui-not-active:text-black p-2 cursor-pointer my-1 hover:heading-bg-color hover:bg-text-color"
                as={"div"}
              >
                <Translate>{link.label}</Translate>
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Menu>
    </div>
  );
};

export default RangeFilter;
