import React, { useState } from "react";
import Menu from "../sideMenu/Menu";
import Translate from "../util/Translate";
import { useTranslation } from "react-i18next";
import Api from "../util/axiosInstance";
import { useNavigate } from "react-router-dom";
import Toast from "../util/CustomToast";

const CreateAttribute = () => {
  const [attributeName, setAttributeName] = useState("");
  const { t } = useTranslation();
  const Navigate = useNavigate();

  const handleSubmit = (e) => {
    // handle form submission
    e.preventDefault();
    Api.post("/attribute", { name: attributeName })
      .then((res) => {
        console.log(res);
        setAttributeName("");
        Navigate("/attributes");
      })
      .catch((err) => {
        console.log(err);
        Toast("error", "Network Error");
      })
      .finally(() => {});
  };

  return (
    <Menu>
      <div>
        <div className="mb-5 heading-text">
          <Translate>Attributes</Translate>
        </div>
        <div className="w-full flex justify-center">
          <form
            className="w-2/3 flex flex-col space-y-20 my-10"
            onSubmit={handleSubmit}
          >
            <fieldset>
              <legend>
                <Translate>Attribute DETAILS</Translate>
              </legend>
              <div className="create-form" enctype="multipart/form-data">
                <div>
                  <label htmlFor="attributeName">
                    <Translate>Name</Translate>
                  </label>
                  <input
                    type="text"
                    id="attributeName"
                    required
                    name="attributeName"
                    value={attributeName}
                    onChange={(e) => setAttributeName(e.target.value)}
                  />
                </div>
              </div>
              <div className="m-5">
                <input
                  type="submit"
                  value={t("Save")}
                  className="heading-bg-color bg-text-color px-5 py-2 rounded-md cursor-pointer"
                />
              </div>
            </fieldset>
          </form>
        </div>
      </div>
    </Menu>
  );
};

export default CreateAttribute;
