import React, { useEffect, useState } from "react";
import Api from "../util/axiosInstance";
import Toast from "../util/CustomToast";
import { useNavigate } from "react-router-dom";
import { TextField } from "@mui/material";
import { cloudinaryUpload } from "../../utils/cloudinaryUpload";
import { useAppContext } from "../../hooks/context/tokenStore";

const CreateSlider = () => {
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [cityId, setCityId] = useState("");
  const [shopCategoryId, setShopCategoryId] = useState("");
  const [productCategoryId, setProductCategoryId] = useState("");
  const [externalLink, setExternalLink] = useState("");
  const [cities, setCities] = useState([]);
  const [shopCategories, setShopCategories] = useState([]);
  const [productCategories, setProductCategories] = useState([]);
  const [shops, setShops] = useState([]);
  const [shopId, setShopId] = useState("");
  const [sliderType, setSliderType] = useState("");
  const [selectedOption, setSelectedOption] = useState(""); // Track selected option
  const navigate = useNavigate();
  const { userData } = useAppContext();

  useEffect(() => {
    // Fetch Cities
    let query = "";
    let userRole = localStorage.getItem("role");
    if (userRole === "cityadmin") query = `?id=${userData?.City?.id}`;
    Api.get("/city" + query)
      .then((res) => {
        setCities(res.data.results);
      })
      .catch((e) => {
        console.log(e);
      });

    // Fetch Product Categories
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      let imageUrl = "";
      if (image) {
        imageUrl = await cloudinaryUpload(image);
      }

      const sliderData = {
        image: imageUrl,
        cityId,
        shopCategoryId: shopCategoryId || undefined,
        categoryId: productCategoryId || undefined,
        shopId: shopId || undefined,
        externalLink: externalLink || undefined,
        sliderType: sliderType || undefined,
      };

      await Api.post("/slider", sliderData);
      Toast("success", "Slider Created Successfully");

      setImage(null);
      setCityId("");
      setShopCategoryId("");
      setProductCategoryId("");
      setShopId("");
      setExternalLink("");
      setSliderType("");
      setSelectedOption(""); // Reset selected option
      navigate(-1);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const handleCityChange = (e) => {
    const selectedCityId = e.target.value;
    setCityId(selectedCityId);

    Api.get(`/shop?cityId=${selectedCityId}`)
      .then((res) => {
        setShops(res.data.results);
      })
      .catch((e) => {
        console.log(e);
      });

    Api.get(`/category?cityId=${selectedCityId}`)
      .then((res) => {
        setProductCategories(res.data.results);
      })
      .catch((e) => {
        console.log(e);
      });

    Api.get(`/shop-category?cityId=${selectedCityId}`)
      .then((res) => {
        setShopCategories(res.data.results);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    if (option !== "shop") setShopId("");
    if (option !== "shopCategory") setShopCategoryId("");
    if (option !== "productCategory") setProductCategoryId("");
    if (option !== "externalLink") setExternalLink("");
  };

  const resetSelection = () => {
    setSelectedOption("");
  };

  return (
    <div>
      <div>
        <form
          className="w-2/3 flex flex-col space-y-20 my-10 mx-auto"
          onSubmit={handleSubmit}
        >
          <fieldset>
            <legend>Create Slider</legend>
            <div className="px-5 my-3 flex flex-col space-y-10">
              {/* Image Input */}
              <div className="input-group">
                <label htmlFor="image">Image</label>
                <input
                  type="file"
                  id="image"
                  name="image"
                  onChange={(e) => setImage(e.target.files[0])}
                  required
                />
              </div>

              {/* Slider Type Select Menu */}
              <div className="input-group w-fit">
                <label htmlFor="sliderType">Slider Type</label>
                <select
                  name="sliderType"
                  id="sliderType"
                  value={sliderType}
                  onChange={(e) => setSliderType(e.target.value)}
                  required
                >
                  <option value="">Select Slider Type</option>
                  <option value="top">Top</option>
                  <option value="middle">Middle</option>
                  <option value="bottom">Bottom</option>
                </select>
              </div>

              {/* City Select Menu */}
              <div className="input-group w-fit">
                <label htmlFor="cityId">City</label>
                <select
                  name="cityId"
                  id="cityId"
                  value={cityId}
                  onChange={handleCityChange}
                  required
                >
                  <option value="">Select City</option>
                  {cities.map((city) => (
                    <option key={city.id} value={city.id}>
                      {city.name}
                    </option>
                  ))}
                </select>
              </div>

              {/* Shop Select Menu */}
              <div className="input-group w-fit">
                <label htmlFor="shopId">Shop</label>
                <select
                  name="shopId"
                  id="shopId"
                  value={shopId}
                  onChange={(e) => {
                    setShopId(e.target.value);
                    handleOptionSelect("shop");
                  }}
                  >
                  <option value="">Select Shop</option>
                  {shops.map((shop) => (
                    <option key={shop.id} value={shop.id}>
                      {shop.name}
                    </option>
                  ))}
                </select>
              </div>

              {/* Shop Category Select Menu */}
              <div className="input-group w-fit">
                <label htmlFor="shopCategoryId">Category of Shop</label>
                <select
                  name="shopCategoryId"
                  id="shopCategoryId"
                  value={shopCategoryId}
                  onChange={(e) => {
                    setShopCategoryId(e.target.value);
                    handleOptionSelect("shopCategory");
                  }}
                >
                  <option value="">Select Shop Category</option>
                  {shopCategories.map((category) => (
                    <option key={category.id} value={category.id}>
                      {category.name}
                    </option>
                  ))}
                </select>
              </div>

              {/* Product Category Select Menu */}
              <div className="input-group w-fit">
                <label htmlFor="productCategoryId">Category of Product</label>
                <select
                  name="productCategoryId"
                  id="productCategoryId"
                  value={productCategoryId}
                  onChange={(e) => {
                    setProductCategoryId(e.target.value);
                    handleOptionSelect("productCategory");
                  }}
                >
                  <option value="">Select Product Category</option>
                  {productCategories.map((category) => (
                    <option key={category.id} value={category.id}>
                      {category.name}
                    </option>
                  ))}
                </select>
              </div>

              {/* External Web Link Input */}
              <div className="input-group">
                <label htmlFor="externalLink">External Web Link</label>
                <TextField
                  fullWidth
                  type="url"
                  id="externalLink"
                  name="externalLink"
                  size="small"
                  value={externalLink}
                  onChange={(e) => {
                    setExternalLink(e.target.value);
                    handleOptionSelect("externalLink");
                  }}
                  placeholder="https://example.com"
                />
              </div>

              {/* Reset Option Button */}
              {selectedOption && (
                <div className="flex justify-end">
                  <button
                    type="button"
                    className="btn"
                    onClick={resetSelection}
                  >
                    Change Option
                  </button>
                </div>
              )}
            </div>
          </fieldset>

          <div className="flex justify-center">
            <button type="submit" className="btn" disabled={loading}>
              {loading ? "Creating..." : "Create"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateSlider;
