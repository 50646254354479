import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import Translate from "../../util/Translate";

const services = [
  {
    id: 1,
    name: "Service 1",
    orders: 10,
    revenue: 1000,
  },
  {
    id: 2,
    name: "Service 2",
    orders: 20,
    revenue: 4000,
  },
  {
    id: 3,
    name: "Service 3",
    orders: 30,
    revenue: 9000,
  },
  {
    id: 4,
    name: "Service 4",
    orders: 40,
    revenue: 16000,
  },
  {
    id: 5,
    name: "Service 5",
    orders: 50,
    revenue: 25000,
  },
];

const ServicesTable = ({ professionalServices }) => {
  return (
    <div>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <Translate>Preview</Translate>
            </TableCell>
            <TableCell>
              <Translate>Title</Translate>
            </TableCell>
            <TableCell>
              <Translate>Revenue</Translate>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {professionalServices.map((service) => (
            <TableRow key={service.id}>
              <TableCell>{service.image}</TableCell>
              <TableCell>{service.title}</TableCell>
              <TableCell>{service.price}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default ServicesTable;
