import { useEffect, useLayoutEffect, useState } from "react";
import { useAppContext } from "./context/tokenStore";
import Api from "../components/util/axiosInstance";

export default function useUser() {
  const [user, setUser] = useState(null);
  const userRole = localStorage.getItem("role");
  const [isLoading, setIsLoading] = useState(false);

  useLayoutEffect(() => {
    setIsLoading(true);
    Api.get(`/auth/${userRole}/user/verify`)
      .then((res) => {
        setUser(res.data.user);
      })
      .catch((err) => {
        setUser(null);
        console.log(err.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [userRole]);

  return { user, isLoading };
}
