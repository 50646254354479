import { Switch, TableCell, TableRow } from "@mui/material";
import React, { useState } from "react";
import { MdDelete } from "react-icons/md";
import { RiEditCircleFill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import ConfirmationModal from "../util/ConfirmationModal";

const label = { inputProps: { "aria-label": "Switch demo" } };

const MallRow = ({ mall, handleDeleteMall, index }) => {
  const Navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <TableRow>
      <TableCell>{index + 1}</TableCell>
      <TableCell>{mall.name}</TableCell>

      <TableCell>{mall.Shop?.length}</TableCell>
      <TableCell className="capitalize">{mall.City?.name || ""}</TableCell>

      <TableCell>
        <Switch {...label} checked={mall.publish} color="success" />
      </TableCell>
      <TableCell>
        <div className="heading-text flex items-center space-x-2">
          <div
            className="text-green-700 cursor-pointer"
            onClick={() => {
              Navigate(`/malls/edit/${mall.id}`);
            }}
          >
            <RiEditCircleFill />
          </div>
          <div
            onClick={() => {
              // handleDeleteMall(mall.id);
              setIsOpen(true);
            }}
          >
            <MdDelete className="text-red-700 cursor-pointer" />
          </div>
        </div>
      </TableCell>
      <ConfirmationModal
        deleteFuction={handleDeleteMall}
        id={mall.id}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
    </TableRow>
  );
};

export default MallRow;
