import { Switch, TableCell, TableRow } from "@mui/material";
import React, { useState } from "react";
import { TiEye } from "react-icons/ti";
import { RiEditCircleFill } from "react-icons/ri";
import { MdDelete } from "react-icons/md";
import ConfirmationModal from "../util/ConfirmationModal";

const label = { inputProps: { "aria-label": "Switch demo" } };

const ProfessionalRow = ({
  professional,
  handleProfessionalDelete,
  handleStatusToggle,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <TableRow>
      <TableCell>
        <div className="w-12">
          <img
            src={professional.image}
            alt="professional_profile"
            className="object-cover rounded-md w-full h-full"
          />
        </div>
      </TableCell>
      <TableCell>{professional.title}</TableCell>

      <TableCell>{professional?.Product?.name || "N/A"}</TableCell>
      <TableCell>{professional?.Shop?.name || "N/A"}</TableCell>

      <TableCell>
        {new Date(professional.createdAt).toLocaleDateString()}
      </TableCell>

      <TableCell>
        <div className="heading-text flex items-center space-x-2">
          {/* <div className="text-gray-700 cursor-pointer">
            <TiEye />
          </div>
          <div className="text-green-700 cursor-pointer">
            <RiEditCircleFill />
          </div> */}
          <div
            onClick={() => {
              // handleProfessionalDelete(professional.id);
              setIsOpen(true);
            }}
          >
            <MdDelete className="text-red-700 cursor-pointer" />
          </div>
        </div>
      </TableCell>
      <ConfirmationModal
        deleteFuction={handleProfessionalDelete}
        id={professional.id}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
    </TableRow>
  );
};

export default ProfessionalRow;
