import React from "react";
import LoadingAnimation from "./LoadingAnimation";

const LoadingSpinner = () => {
  return (
    <div className="flex items-center fixed inset-0 w-full bg-black bg-opacity-30 h-full justify-center z-50">
      {/* <div className="fixed inset-0 w-screen h-screen bg-black bg-opacity-30 z-50 flex justify-center items-center"> */}
      <LoadingAnimation />
    </div>
  );
};

export default LoadingSpinner;
