import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Api from "../../util/axiosInstance";
import Translate from "../../util/Translate";

const EditPointerest = ({
  currentCity,
  setCities,
  cities,
  closeModal,
  currentPointerest,
  setPointerest,
  pointerest,
}) => {
  const [name, setName] = useState("");
  const { t } = useTranslation();

  useEffect(() => {
    if (currentPointerest) {
      setName(currentPointerest.name);
    }
  }, [currentPointerest]);

  const handleSubmit = (e) => {
    e.preventDefault();
    Api.patch(`/pointerest/${currentPointerest.id}`, {
      name,
    })
      .then((res) => {
        let tempPointerest = pointerest.map((item) => {
          if (item.id === currentPointerest.id) {
            return res.data.data;
          }
          return item;
        });
        console.log({ tempPointerest });
        setPointerest(tempPointerest);

        let tempCities = cities.map((item) => {
          if (item.id === currentCity.id) {
            return {
              ...item,
              Pointerest: tempPointerest,
            };
          }
          return item;
        });
        setCities(tempCities);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        closeModal();
        setName("");
      });
  };
  return (
    <div className="my-5">
      <div>
        <form className="flex flex-col space-y-3" onSubmit={handleSubmit}>
          <div className="input-group">
            <label htmlFor="name">
              <Translate>Name</Translate>
            </label>
            <input
              type="text"
              name="name"
              id="name"
              minLength={3}
              maxLength={30}
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
          </div>
          <div>
            <input type="submit" value={t("SAVE")} className="btn" />
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditPointerest;
