import { Switch, TableCell, TableRow } from "@mui/material";
import React from "react";
import { TiEye } from "react-icons/ti";
import { RiEditCircleFill } from "react-icons/ri";
import { MdDelete } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import ConfirmationModal from "../util/ConfirmationModal";

const label = { inputProps: { "aria-label": "Switch demo" } };

const AttributeRow = ({ attribute, handleDeleteAttribute }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const Navigate = useNavigate();
  return (
    <TableRow>
      <TableCell>{attribute.name}</TableCell>

      <TableCell>
        <div className="heading-text flex items-center space-x-2">
          <div
            className="text-green-700 cursor-pointer"
            onClick={() => {
              Navigate(`/attributes/edit/${attribute.id}`);
            }}
          >
            <RiEditCircleFill />
          </div>
          <div
            onClick={() => {
              // handleDeleteAttribute(attribute.id);
              setIsOpen(true);
            }}
          >
            {/* <MdDelete className="text-red-700 cursor-pointer" /> */}
          </div>
        </div>
      </TableCell>
      <ConfirmationModal
        deleteFuction={handleDeleteAttribute}
        name={attribute.name}
        id={attribute.id}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
    </TableRow>
  );
};

export default AttributeRow;
