import React, { useEffect, useState } from "react";
import Menu from "../sideMenu/Menu";
import { useNavigate } from "react-router-dom";
import Api from "../util/axiosInstance";
import Translate from "../util/Translate";
import { useTranslation } from "react-i18next";
import Toast from "../util/CustomToast";

const CreateAdmin = () => {
  const Navigate = useNavigate();
  const [cities, setCities] = useState([]);
  const [shops, setShops] = useState([]);
  const [products, setProducts] = useState([]);
  const { t } = useTranslation();
  const [userInputValues, setUserInputValues] = useState({
    title: "",
    content: "",
    productId: "",
    shopId: "",
    image: "",
  });

  useEffect(() => {
    Api.get("/product")
      .then((response) => {
        setProducts(response.data.results);
      })
      .catch((error) => {
        console.log(error);
      });
    Api.get("/shop")
      .then((response) => {
        setShops(response.data.results);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleOnChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox") {
      setUserInputValues((prevState) => ({
        ...prevState,
        [name]: checked,
      }));
    } else {
      setUserInputValues((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleUserImageUpload = (e) => {
    setUserInputValues((prevState) => ({
      ...prevState,
      image: e.target.files[0],
    }));
  };

  const handleSavingUser = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("image", userInputValues.image);

    Api.post("/cloudinary/upload", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((response) => {
        const { url } = response.data;
        const adminData = {
          ...userInputValues,
          image: url,
          shopId: userInputValues.shopId || undefined,
          productId: userInputValues.productId || undefined,
        };

        return Api.post("/notification", adminData);
      })
      .then(() => {
        Toast("success", t("Notification created successfully"));
        setUserInputValues({});
        Navigate("/notifications");
      })
      .catch((error) => {
        console.log(error, "error");
        Toast("error", t("Invalid Credentials/Network Error"));
      });
  };

  return (
    <Menu>
      <div>
        <div className="mb-5 heading-text">
          <Translate>Notifications</Translate>
        </div>
        <div className="w-full flex justify-center">
          <form
            className="w-2/3 flex flex-col space-y-20 my-10"
            onSubmit={handleSavingUser}
          >
            {/* Admin Details Fieldset */}
            <fieldset>
              <legend>
                <Translate>Notification Details</Translate>
              </legend>
              <div className="create-form">
                <div>
                  <label htmlFor="title">
                    <Translate>Title</Translate>
                  </label>
                  <input
                    type="text"
                    name="title"
                    id="title"
                    placeholder={t("Title")}
                    required
                    onChange={handleOnChange}
                    value={userInputValues.title}
                    minLength={3}
                    maxLength={30}
                  />
                </div>
                <div>
                  <label htmlFor="content">
                    <Translate>Content</Translate>
                  </label>
                  <input
                    type="text"
                    name="content"
                    id="content"
                    placeholder={t("Content")}
                    required
                    value={userInputValues.content}
                    onChange={handleOnChange}
                  />
                </div>
                <div>
                  <label htmlFor="image">
                    <Translate>Upload Image</Translate>
                  </label>
                  <input
                    type="file"
                    name="image"
                    id="image"
                    accept="image/*"
                    onChange={handleUserImageUpload}
                  />
                </div>
                {/* <div>
                  <label htmlFor="role">
                    <Translate>Role</Translate>
                  </label>
                  <input
                    type="text"
                    name="role"
                    id="role"
                    placeholder={t("Role")}
                    required
                    value={userInputValues.role}
                    onChange={handleOnChange}
                  />
                </div> */}
                <div>
                  <label htmlFor="cityId">
                    <Translate>Products</Translate>
                  </label>
                  <select
                    onChange={handleOnChange}
                    name="productId"
                    id="productId"
                  >
                    <option value="">
                      <Translate>Select Product</Translate>
                    </option>
                    {products.map((city) => (
                      <option
                        key={city.id}
                        value={city.id}
                        className="capitalize"
                      >
                        {city.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div>
                  <label htmlFor="cityId">
                    <Translate>Shops</Translate>
                  </label>
                  <select onChange={handleOnChange} name="shopId" id="shopId">
                    <option value="">
                      <Translate>Select Shop</Translate>
                    </option>
                    {shops.map((city) => (
                      <option
                        key={city.id}
                        value={city.id}
                        className="capitalize"
                      >
                        {city.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </fieldset>

            {/* Action Buttons */}
            <div className="flex justify-end items-center w-full space-x-4 mt-4">
              <button
                type="button"
                onClick={() => Navigate("/notifications")}
                className="btn-cancel"
              >
                <Translate>Cancel</Translate>
              </button>
              <button
                type="submit"
                // className="btn-submit"
                className="heading-bg-color bg-text-color px-5 py-2 rounded-md cursor-pointer btn-submit"
              >
                <Translate>Create Notification</Translate>
              </button>
            </div>
          </form>
        </div>
      </div>
    </Menu>
  );
};

export default CreateAdmin;
