import { Route, Routes, useLocation } from "react-router-dom";
import "./App.css";
import Login from "./components/auth/login/SuperAdmin";
import Dashboard from "./components/home/Dashboard";
import Users from "./components/users/Users";
import Vendors from "./components/vendors/Vendors";
import CreateUser from "./components/users/CreateUser";
import Drivers from "./components/drivers/Drivers";
import CreateDriver from "./components/drivers/CreateDriver";
import Categories from "./components/categories/Categories";
import CreateCategory from "./components/categories/CreateCategory";
import Foods from "./components/products/Products";
import CreateFood from "./components/products/CreateProduct";
import MapsData from "./components/maps/MapsData";
import SalesSlabsDashboard from "./components/salesSlabs/SalesSlabsDashboard";
import Orders from "./components/orders/Orders";
import CompletedOrders from "./components/completed-order/Orders";
import DeliveryOrders from "./components/delivery-order/Orders";
import PickupOrders from "./components/pickup-order/Orders";
import ViewUser from "./components/users/ViewUser";
import Stores from "./components/shops/Shops";
import CreateStore from "./components/shops/CreateShop";
import Shops from "./components/shops/Shops";
import CreateShop from "./components/shops/CreateShop";
import Products from "./components/products/Products";
import CreateProduct from "./components/products/CreateProduct";
import Professionals from "./components/professionals/Professionals";
import Admins from "./components/admins/Admins";
import CreateAdmin from "./components/admins/CreateAdmin";
import ViewAdmin from "./components/admins/ViewAdmin";
import LogisticAdmins from "./components/logistic-admin/Admins";
import CreateLogisticAdmin from "./components/logistic-admin/CreateAdmin";
import ViewLogisticAdmin from "./components/logistic-admin/ViewAdmin";
import { useTranslation } from "react-i18next";
import SuperAdmin from "./components/auth/login/SuperAdmin";
import CityAdmin from "./components/auth/login/CityAdmin";
import VendorAdmin from "./components/auth/login/VendorAdmin";
import LogisticAdmin from "./components/auth/login/LogisticAdmin";
import ProfessionalLogin from "./components/auth/login/ProfessionalLogin";
import VendorSignup from "./components/auth/signup/VendorSignup";
import ProfessionalSignup from "./components/auth/signup/ProfessionalSignup";
import { useEffect } from "react";
import axios from "axios";
import useUser from "./hooks/useUser";
import { useAppContext } from "./hooks/context/tokenStore";
import LoginVerified from "./components/auth/LoginVerified";
import Cities from "./components/cities/Cities";
import Test from "./Test";
import { ToastContainer } from "react-toastify";
import EditProduct from "./components/products/EditProduct";
import EditShop from "./components/shops/EditShop";
import EditCategory from "./components/categories/EditCategory";
import Attributes from "./components/Attributes/Attributes";
import CreateAttribute from "./components/Attributes/CreateAttribute";
import EditAttribute from "./components/Attributes/EditAttribute";
import Units from "./components/units/Units";
import CreateUnit from "./components/units/CreateUnit";
import EditUnit from "./components/units/EditUnit";
import EditProductVariation from "./components/products/EditProductVariation";
import Chats from "./components/chats/Chats";
import Services from "./components/services/Services";
import CreateService from "./components/services/CreateService";
import EditService from "./components/services/EditService";
import Malls from "./components/malls/Malls";
import CreateMall from "./components/malls/CreateMall";
import EditMall from "./components/malls/EditMall";
import Profile from "./components/profile/ProfessionalProfile";
import PasswordReset from "./components/profile/PasswordReset";
import SocialLinks from "./components/profile/SocialLinks";
import ProfessionalProfile from "./components/profile/ProfessionalProfile";
import VendorProfile from "./components/profile/VendorProfile";
import ViewProductOrder from "./components/orders/ViewProductOrder";
import ViewServiceOrder from "./components/orders/ViewServiceOrder";
import Coupons from "./components/coupons/Coupons";
import CreateCoupon from "./components/coupons/CreateCoupon";
import ProfessionalSlider from "./components/professionalslider/ProfessionalSlider";
import MyShop from "./components/myshop/MyShop";
import VendorTaxonomy from "./components/taxonomy/vendor/VendorTaxonomy";
import CreateTaxonomy from "./components/taxonomy/create/CreateTaxonomy";
import ProfessionalTaxonomy from "./components/taxonomy/professional/ProfessionalTaxonomy";
import SubCategories from "./components/categories/sub/SubCategories";
import CreateSubCategory from "./components/categories/sub/CreateSubCategory";
import Policy from "./components/privacypolicay/Policy";
import TermsAndConditions from "./components/privacypolicay/TermsAndConditions";
import CreateVendorSubscription from "./components/subscription/vendor/CreateVendorSubscription";
import VendorSubscription from "./components/subscription/vendor/VendorSubscription";
import EditVendorSubscription from "./components/subscription/vendor/EditVendorSubscription";
import ProfessionalSubscription from "./components/subscription/professional/ProfessionalSubscription";
import CreateProfessionalSubscription from "./components/subscription/professional/CreateProfessionalSubscription";
import EditProfessionalSubscription from "./components/subscription/professional/EditProfessionalSubscription";
import Tickets from "./components/tickets/Tickets";
import CustomerAdmin from "./components/auth/login/CustomerAdmin";
import CustomerVerification from "./components/auth/CustomerVerification";
import CreateTicket from "./components/tickets/CreateTicket";
import ViewTicket from "./components/tickets/ViewTicket";
import CityAreas from "./components/cities/cityArea/CityAreas";
import CreateCityArea from "./components/cities/cityArea/CreateCityArea";
import Pointerest from "./components/cities/pointerest/Pointerest";
import CreatePointerest from "./components/cities/pointerest/CreatePointerest";
import CategorySlider from "./components/categorySlider/CategorySlider";
import InternalCategories from "./components/internalCategory/InternalCategories";
import CitySlider from "./components/citySlider/CitySlider";
import PageNotFound from "./components/notFound/PageNotFound";
import { AnimatePresence } from "framer-motion";
import LoadingSpinner from "./components/util/LoadingSpinner";
import EditAdmin from "./components/admins/EditAdmin";
import EditLogisticAdmin from "./components/logistic-admin/EditAdmin";
import ViewVendorOrder from "./components/orders/ViewVendorOrder";
import Notifications from "./components/notifications/Notifications";
import CreateNotifications from "./components/notifications/CreateNotifications";

function App() {
  const { t, i18n } = useTranslation();
  const { userData } = useAppContext();
  const location = useLocation();
  console.log("re render");

  useEffect(() => {
    if (localStorage.getItem("language")) {
      i18n.changeLanguage(localStorage.getItem("language"));
    }
  }, []);

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("role");
    window.location.href = "/";
  };

  return (
    <div>
      <AnimatePresence mode="wait">
        <Routes location={location} key={location.pathname}>
          {/* signup routes ---start */}

          <Route path="/imagetesting" element={<Test />} />
          <Route path="/loading" element={<LoadingSpinner />} />

          <Route path="/signup/vendoradmin" element={<VendorSignup />} />
          <Route path="/signup/logisticadmin" element={<LogisticAdmin />} />
          <Route
            path="/signup/professionaladmin"
            element={<ProfessionalSignup />}
          />

          {/* signup routes ---end */}

          {/* login routes ---start */}
          <Route path="/login/customer" element={<CustomerAdmin />} />
          <Route path="/login/superadmin" element={<SuperAdmin />} />
          <Route path="/login/cityadmin" element={<CityAdmin />} />
          <Route path="/login/vendoradmin" element={<VendorAdmin />} />
          <Route path="/login/logisticadmin" element={<LogisticAdmin />} />
          <Route
            path="/login/professionaladmin"
            element={<ProfessionalLogin />}
          />
          {/* login routes ---end */}

          <Route path="/" element={<LoginVerified />}>
            <Route path="/" element={<CustomerVerification />}>
              <Route path="/" element={<Dashboard />} />

              {/* profile section */}
              <Route
                path="/professional/profile"
                element={<ProfessionalProfile />}
              />
              <Route path="/vendor/profile" element={<VendorProfile />} />
              <Route path="/user/resetpassword" element={<PasswordReset />} />
              <Route path="/user/sociallinks" element={<SocialLinks />} />

              <Route path="map" element={<MapsData />} />

              <Route path="users" element={<Users />} />
              <Route path="users/create" element={<CreateUser />} />
              <Route path={`users/view/:userId`} element={<ViewUser />} />

              <Route path="notifications" element={<Notifications />} />
              <Route path="notifications/:notificationId" element={<CreateNotifications />} />

              <Route path="admins" element={<Admins />} />
              <Route path="admins/create" element={<CreateAdmin />} />
              <Route path={`admins/view/:adminId`} element={<ViewAdmin />} />
              <Route path="admins/:id" element={<EditAdmin />} />

              <Route path="logistic/admins" element={<LogisticAdmins />} />
              <Route
                path="logistic/admins/create"
                element={<CreateLogisticAdmin />}
              />
              <Route
                path={`logistic/admins/view/:adminId`}
                element={<ViewLogisticAdmin />}
              />
              <Route
                path="logistic/admins/:id"
                element={<EditLogisticAdmin />}
              />

              {/* <Route path="vendors" element={<Vendors />} /> */}

              <Route path="professionals" element={<Professionals />} />

              <Route path="malls" element={<Malls />} />
              <Route path="malls/create" element={<CreateMall />} />
              <Route path="malls/edit/:mallId" element={<EditMall />} />

              <Route path="services" element={<Services />} />
              <Route path="services/create" element={<CreateService />} />
              <Route
                path="services/edit/:serviceId"
                element={<EditService />}
              />

              <Route path="cities" element={<Cities />} />
              <Route path="cities/area" element={<CityAreas />} />
              <Route path="cities/area/create" element={<CreateCityArea />} />

              <Route path="cities/pointerest" element={<Pointerest />} />
              <Route
                path="cities/pointerest/create"
                element={<CreatePointerest />}
              />

              <Route path="shops" element={<Shops />} />
              <Route path="shops/create" element={<CreateShop />} />
              <Route path="shops/edit/:shopId" element={<EditShop />} />
              <Route path="vendor/shop" element={<MyShop />} />

              <Route path="drivers" element={<Drivers />} />
              <Route path="drivers/create" element={<CreateDriver />} />

              {/* internal categories */}

              <Route path="categories" element={<InternalCategories />} />

              <Route path="category" element={<Categories />} />
              <Route path="category/sub" element={<SubCategories />} />
              <Route
                path="category/sub/create"
                element={<CreateSubCategory />}
              />
              <Route path="category/create" element={<CreateCategory />} />
              <Route
                path="category/edit/:categoryId"
                element={<EditCategory />}
              />

              <Route path="/taxonomy/vendor" element={<VendorTaxonomy />} />
              <Route
                path="/taxonomy/professional"
                element={<ProfessionalTaxonomy />}
              />
              <Route path="/taxonomy/create" element={<CreateTaxonomy />} />

              {/* <Route path="/taxonomy/professional" element={<Foods />} /> */}

              <Route path="products" element={<Products />} />
              <Route path="products/create" element={<CreateProduct />} />
              <Route
                path="products/edit/:productId"
                element={<EditProduct />}
              />
              <Route
                path="products/edit/:productId/editvariation"
                element={<EditProductVariation />}
              />

              <Route path="units" element={<Units />} />
              <Route path="units/create" element={<CreateUnit />} />
              <Route path="units/edit/:unitId" element={<EditUnit />} />

              <Route path="attributes" element={<Attributes />} />
              <Route path="attributes/create" element={<CreateAttribute />} />
              <Route path="attributes/edit/:id" element={<EditAttribute />} />

              <Route path="orders" element={<Orders />} />
              <Route path="completed-orders" element={<CompletedOrders />} />
              <Route path="delivery-orders" element={<DeliveryOrders />} />
              <Route path="pickup-orders" element={<PickupOrders />} />
              <Route
                path="orders/view/product/:orderId"
                element={<ViewProductOrder />}
              />
              <Route
                path="orders/view/professional/:orderId"
                element={<ViewServiceOrder />}
              />
              <Route
                path="orders/view/vendor/:orderId"
                element={<ViewVendorOrder />}
              />

              <Route path="coupons" element={<Coupons />} />
              <Route path="coupons/create" element={<CreateCoupon />} />

              <Route
                path="professionalSlider"
                element={<ProfessionalSlider />}
              />
              <Route path="categorySlider" element={<CategorySlider />} />
              <Route path="citySlider" element={<CitySlider />} />

              <Route
                path="sales-slabs-info"
                element={<SalesSlabsDashboard />}
              />

              <Route path="chats" element={<Chats />} />

              <Route path="privacypolicay" element={<Policy />} />
              <Route
                path="termsandconditions"
                element={<TermsAndConditions />}
              />

              {/* subscription vendor */}
              <Route
                path="subscription/vendor"
                element={<VendorSubscription />}
              />
              <Route
                path="subscription/vendor/create"
                element={<CreateVendorSubscription />}
              />
              <Route
                path="subscription/vendor/edit/:packageId"
                element={<EditVendorSubscription />}
              />

              {/* subscription professional */}
              <Route
                path="subscription/professional"
                element={<ProfessionalSubscription />}
              />
              <Route
                path="subscription/professional/create"
                element={<CreateProfessionalSubscription />}
              />
              <Route
                path="subscription/professional/edit/:packageId"
                element={<EditProfessionalSubscription />}
              />
            </Route>

            {/* customer routes */}

            <Route path="tickets" element={<Tickets />} />
            <Route path="tickets/create" element={<CreateTicket />} />
            <Route path="tickets/view/:ticketId" element={<ViewTicket />} />
          </Route>
          <Route path="about" element={<>hello about</>} />
          <Route path="contact" element={<>hello contact</>} />

          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </AnimatePresence>

      {/* language switcher */}
      <div className="absolute top-2 right-5 flex space-x-5 items-center">
        {userData ? (
          <div
            className="heading-bg-color bg-text-color px-4 py-1 cursor-pointer hover:app-bg-color hover:app-text-color rounded-md border-2"
            onClick={handleLogout}
          >
            Logout
          </div>
        ) : (
          ""
        )}
        <select
          name="languageSwitcher"
          id="languageSwitcher"
          className="p-2"
          onChange={(e) => {
            i18n.changeLanguage(e.target.value);
            localStorage.setItem("language", e.target.value);
          }}
        >
          <option
            value="en"
            selected={
              localStorage.getItem("language") === "en" ? "selected" : ""
            }
          >
            English
          </option>
          <option
            value="sp"
            selected={
              localStorage.getItem("language") === "sp" ? "selected" : ""
            }
          >
            Español
          </option>
        </select>
      </div>
    </div>
  );
}

export default App;
