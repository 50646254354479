import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Api from "../util/axiosInstance";
import Menu from "../sideMenu/Menu";
import { formatDate } from "../util/convertDate";
import { useAppContext } from "../../hooks/context/tokenStore";

const ViewVendorOrder = () => {
  const { orderId } = useParams();
  const [order, setOrder] = useState(null);
  const { userData } = useAppContext();

  useEffect(() => {
    Api.get(`/product-order/${orderId}`)
      .then((res) => {
        setOrder(res?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [orderId]);

  return (
    <Menu>
      <div>
        <div className="flex flex-col space-y-10">
          <h1 className="sub-heading-text py-2 border-b-2 border-b-gray-300 w-full">
            Order Details
          </h1>

          {/* Product Details */}
          <div>
            <fieldset className="rounded-md">
              <legend>Product Details</legend>
              <div className="flex flex-col space-y-5 w-[90%] md:w-4/5">
                {order?.OrderItems?.map((item) => (
                  <div className="space-y-3" key={item?.id}>
                    <div className="flex justify-between">
                      <label>Product Name</label>
                      <div>{item?.Product?.name}</div>
                    </div>
                    <div className="flex justify-between">
                      <label>Product Description</label>
                      <div
                        className="text-right"
                        dangerouslySetInnerHTML={{
                          __html: item?.Product?.description,
                        }}
                      />
                    </div>
                    <div className="flex justify-between">
                      <label>Price</label>
                      <div>
                        $
                        {item?.Product?.discountedPrice || item?.Product?.price}
                      </div>
                    </div>
                    <div className="flex justify-between">
                      <label>Quantity</label>
                      <div>{item?.quantity}</div>
                    </div>
                  </div>
                ))}
              </div>
            </fieldset>
          </div>

          {/* Shop Details */}
          <div>
            <fieldset className="rounded-md">
              <legend>Shop Details</legend>
              <div className="w-[90%] md:w-[50%] mx-auto flex flex-col space-y-3">
                <div className="flex justify-between">
                  <label>Shop Name</label>
                  <div>{order?.Shop?.name}</div>
                </div>
                <div className="flex justify-between">
                  <label>Shop Phone</label>
                  <div>{order?.Shop?.phone}</div>
                </div>
                <div className="flex space-x-3 justify-between">
                  <label>Shop Address</label>
                  <div className="text-left">{order?.Shop?.address}</div>
                </div>
              </div>
            </fieldset>
          </div>

          {/* Customer Details */}
          <div>
            <fieldset className="rounded-md">
              <legend>Customer Details</legend>
              <div className="w-[90%] md:w-[50%] mx-auto flex flex-col space-y-3">
                <div className="flex justify-between">
                  <label>Name</label>
                  <div>{order?.Customer?.name}</div>
                </div>
                <div className="flex justify-between">
                  <label>Email</label>
                  <div>{order?.Customer?.email}</div>
                </div>
                <div className="flex justify-between">
                  <label>Phone</label>
                  <div>{order?.Customer?.phone || "N/A"}</div>
                </div>
              </div>
            </fieldset>
          </div>

          {/* Shipping Address */}
          <div>
            <fieldset className="rounded-md">
              <legend>Shipping Address</legend>
              <div className="w-[90%] md:w-[50%] mx-auto flex flex-col space-y-3">
                <div className="flex justify-between space-x-3">
                  <label>Address</label>
                  <div className="text-left">
                    {order?.ShippingAddress?.addressLine1}
                  </div>
                </div>
                <div className="flex justify-between">
                  <label>City</label>
                  <div>{order?.ShippingAddress?.city}</div>
                </div>
                <div className="flex justify-between">
                  <label>State</label>
                  <div>{order?.ShippingAddress?.state}</div>
                </div>
                <div className="flex justify-between">
                  <label>Postal Code</label>
                  <div>{order?.ShippingAddress?.postalCode}</div>
                </div>
                <div className="flex justify-between">
                  <label>Phone</label>
                  <div>{order?.ShippingAddress?.phone}</div>
                </div>
              </div>
            </fieldset>
          </div>

          {/* Order Details */}
          <div>
            <fieldset className="rounded-md">
              <legend>Order Details</legend>
              <div className="w-[90%] md:w-[50%] mx-auto flex flex-col space-y-3">
                <div className="flex justify-between">
                  <label>Order Date</label>
                  <div>{formatDate(order?.createdAt)}</div>
                </div>
                <div className="flex justify-between">
                  <label>Sub Total</label>
                  <div>${order?.totalAmount}</div>
                </div>
                <div className="flex justify-between">
                  <label>Status From Vendor</label>
                  <div>{order?.statusByVendor}</div>
                </div>
                <div className="flex justify-between">
                  <label>Status From Customer</label>
                  <div>{order?.statusByCustomer}</div>
                </div>
                <div className="flex justify-between">
                  <label>Order Type</label>
                  <div>{order?.orderType}</div>
                </div>
              </div>
            </fieldset>
          </div>

          {/* Payment Details */}
          <div>
            <fieldset className="rounded-md">
              <legend>Payment Details</legend>
              <div className="w-[90%] md:w-[50%] mx-auto flex flex-col space-y-3">
                <div className="flex justify-between">
                  <label>Payment Type</label>
                  <div>{order?.Payment?.paymentType}</div>
                </div>
                <div className="flex justify-between">
                  <label>Payment Status</label>
                  <div>{order?.Payment?.status}</div>
                </div>
              </div>
            </fieldset>
          </div>
        </div>
      </div>
    </Menu>
  );
};

export default ViewVendorOrder;
