import React, { useEffect, useState } from "react";
import { useAppContext } from "../../hooks/context/tokenStore";
import { Link, Outlet } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import useUser from "../../hooks/useUser";
import Api from "../util/axiosInstance";
import { useLocation } from "react-router-dom";
import Menu from "../sideMenu/Menu";

const CustomerVerification = () => {
  // if authenticated the outlet else go to login
  if (localStorage.getItem("role") === "customer") {
    return (
      <Menu>
        <div className="w-full h-full justify-center items-center">
          <h1>Customer Dashboard</h1>
        </div>
      </Menu>
    );
  }

  return <Outlet />;
};

export default CustomerVerification;
