import { createContext, useContext, useEffect, useState, useRef } from "react";
import useUser from "../useUser";

const AppContext = createContext(null);

export function AppWrapper({ children }) {
  const [userData, setUserData] = useState(null);

  const { user, isLoading: isLoadingUser } = useUser();
  useEffect(() => {
    if (user) {
      setUserData(user);
    } else {
      setUserData(null);
    }
  }, [user]);

  const sharedState = {
    userData,
    setUserData,
    isLoadingUser,
  };

  return (
    <AppContext.Provider value={sharedState}>{children}</AppContext.Provider>
  );
}

export function useAppContext() {
  return useContext(AppContext);
}
