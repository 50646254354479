import React, { useEffect, useState } from "react";
import Translate from "../../util/Translate";
import SubCategoryList from "./SubCategoryList";
import MainCategoryList from "./MainCategoryList";
import Api from "../../util/axiosInstance";
import Menu from "../../sideMenu/Menu";
import { Link } from "react-router-dom";

const SubCategories = () => {
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [currentCategory, setCurrentCategory] = useState([]);

  useEffect(() => {
    Api.get("/category").then((response) => {
      setCategories(response.data.results);
    });
  }, []);
  return (
    <Menu>
      <div className="flex flex-col space-y-5">
        <div className="sub-heading-text app-text-color">
          <Translate>Sub Category Details</Translate>
        </div>
        <Link to={"/category/sub/create"} className="btn w-fit self-center">
          Create Sub Category
        </Link>
        <div className="flex space-x-10 self-center">
          <MainCategoryList
            categories={categories}
            setCurrentCategory={setCurrentCategory}
            currentCategory={currentCategory}
          />
          <SubCategoryList
            subCategories={subCategories}
            setSubCategories={setSubCategories}
            currentCategory={currentCategory}
            categories={categories}
            setCategories={setCategories}
          />
        </div>
      </div>
    </Menu>
  );
};

export default SubCategories;
