export const TabsList = ({ tabsList, basic }) => {
  return (
    <div className="sub-heading-text flex space-x-5 py-3">
      {tabsList.map((tab, i) => {
        return (
          <div
            className={`hover:text-blue-400 hover:border-b-2  hover:border-b-blue-400 cursor-pointer ${
              tab.name === basic
                ? "border-b-2 text-blue-400 border-b-blue-400"
                : "border-b-white"
            }`}
          >
            {tab.name}
          </div>
        );
      })}
    </div>
  );
};
