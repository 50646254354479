import React, { useEffect, useState } from "react";
import Menu from "../sideMenu/Menu";
import UsersList from "./AdminsList";
import { Link } from "react-router-dom";
import AdminsList from "./AdminsList";
import Translate from "../util/Translate";
import LoadingAnimation from "../util/LoadingAnimation";
import Api from "../util/axiosInstance";

const Admins = () => {
  const [admins, setAdmins] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    Api.get("/admin?role=logisticadmin")
      .then((res) => {
        setAdmins(res.data.results);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setLoading(false));
  }, []);

  return (
    <Menu>
      <div className="flex flex-col min-h-[90vh] w-full items-start space-y-5 ">
        {loading ? (
          <div className=" flex justify-center items-center m-auto">
            <LoadingAnimation />
          </div>
        ) : (
          <>
            <h1 className="sub-heading-text py-2 border-b-2 border-b-gray-300 w-full">
              <Translate>Admins</Translate>
            </h1>
            <div className="w-full bg-gray-200 flex justify-center items-center">
              <div className="py-2 px-4 bg-color app-text-color cursor-pointer rounded-md">
                <Translate>Admins List</Translate>
              </div>
              <Link
                className="py-2 px-4 hover:heading-bg-color hover:bg-text-color cursor-pointer rounded-md"
                to={"/logistic/admins/create"}
              >
                <Translate>Create Admin</Translate>
              </Link>
            </div>
            <AdminsList
              admins={admins}
              setAdmins={setAdmins}
              setLoading={setLoading}
            />
          </>
        )}
      </div>
    </Menu>
  );
};

export default Admins;
