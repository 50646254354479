import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import ProductRow from "./ProductRow";
import Api from "../util/axiosInstance";
import { useAppContext } from "../../hooks/context/tokenStore";
import LoadingSpinner from "../util/LoadingSpinner";
import Translate from "../util/Translate";
import { URL } from "../util/authorization/authorizationURL";

const Product = {
  name: "USB Cable",
  price: 23,
  discountedPrice: 20,
  itemQuantity: 1,
  vendor: "Ithad Electronics",
  category: "Electronics",
  publish: false,
};

const ProductsList = ({ setLoading, setProducts, products }) => {
  const handleProductDelete = (id) => {
    setLoading(true);
    Api.delete(`/product/${id}`)
      .then((res) => {
        setProducts(products.filter((product) => product.id !== id));
      })
      .catch((err) => {
        console.log(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleActivateToggle = (adminId) => {
    setLoading(true);
    const publishStatus = products.find((it) => it.id === adminId).publish;
    Api.patch(`/product/${adminId}`, {
      publish: !publishStatus,
    })
      .then((res) => {
        const updatedAdmins = products.map((admin) => {
          if (admin.id === adminId) {
            admin.publish = !admin.publish;
          }
          return admin;
        });
        setProducts(updatedAdmins);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setLoading(false));
  };

  return (
    <div className="w-full">
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>All</TableCell>
            <TableCell>
              <Translate>Name</Translate>
            </TableCell>
            <TableCell>
              <Translate>Price</Translate>
            </TableCell>
            <TableCell>
              <Translate>Discounted Price</Translate>
            </TableCell>
            <TableCell>
              <Translate>Quantity</Translate>
            </TableCell>
            <TableCell>
              <Translate>Shop</Translate>
            </TableCell>
            <TableCell>
              <Translate>Category</Translate>
            </TableCell>
            <TableCell>
              <Translate>Publish</Translate>
            </TableCell>
            <TableCell>
              <Translate>Actions</Translate>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {products.map((product) => {
            return (
              <ProductRow
                product={product}
                handleProductDelete={handleProductDelete}
                handleActivateToggle={handleActivateToggle}
              />
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
};

export default ProductsList;
