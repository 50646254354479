import React, { useEffect, useRef, useState } from "react";
import Menu from "../sideMenu/Menu";
import Api from "../util/axiosInstance";
import Translate from "../util/Translate";
import { useTranslation } from "react-i18next";
import Toast from "../util/CustomToast";
import { useNavigate, useParams } from "react-router-dom";
import { TiDeleteOutline } from "react-icons/ti";
import LoadingSpinner from "../util/LoadingSpinner";
import CustomModal from "../util/CustomModal";
import AddressModal from "./AddressModal";
import { MdAdd } from "react-icons/md";
import { cloudinaryUpload } from "../../utils/cloudinaryUpload";

const EditShop = () => {
  const [cities, setCities] = useState([]);
  const [cityAreas, setCityAreas] = useState([]);
  const [pointerests, setPointerests] = useState([]);
  const Navigate = useNavigate();
  //   const [shop, setShop] = useState({});
  const { shopId } = useParams();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [malls, setMalls] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [vendorDetails, setVendorDetails] = useState([]);
  const inputRef = useRef();
  const openModal = () => {
    setIsOpen(true);
  };
  const closeModal = () => {
    setIsOpen(false);
  };

  const [inputValues, setInputValues] = useState({
    name: "",
    phone: "",
    address: "",
    latitude: "",
    longitude: "",
    image: null,
    cityId: "",
    mallId: "",

    sundayFrom: "",
    sundayTo: "",
    mondayFrom: "",
    mondayTo: "",
    tuesdayFrom: "",
    tuesdayTo: "",
    wednesdayFrom: "",
    wednesdayTo: "",
    thursdayFrom: "",
    thursdayTo: "",
    fridayFrom: "",
    fridayTo: "",
    saturdayFrom: "",
    saturdayTo: "",
    newImage: "",
    shopSubCategoryId: "",
    active: true,

    cityAreaId: "",
    pointerestId: "",
    promotionalImages: [],
  });
  console.log("🚀 ~ EditShop ~ inputValues:", inputValues);

  useEffect(() => {
    Api.get(`/shop/${shopId}`)
      .then((res) => {
        setInputValues({
          name: res.data.name,
          phone: res.data.phone,
          address: res.data.address,
          latitude: res.data.latitude,
          longitude: res.data.longitude,
          image: res.data.image,
          cityId: res.data.cityId,
          mallId: res.data.mallId,
          sundayFrom: res.data.sundayFrom,
          sundayTo: res.data.sundayTo,
          mondayFrom: res.data.mondayFrom,
          mondayTo: res.data.mondayTo,
          tuesdayFrom: res.data.tuesdayFrom,
          tuesdayTo: res.data.tuesdayTo,
          wednesdayFrom: res.data.wednesdayFrom,
          wednesdayTo: res.data.wednesdayTo,
          thursdayFrom: res.data.thursdayFrom,

          thursdayTo: res.data.thursdayTo,
          fridayFrom: res.data.fridayFrom,
          fridayTo: res.data.fridayTo,
          saturdayFrom: res.data.saturdayFrom,
          saturdayTo: res.data.saturdayTo,
          active: res.data.active,
          shopSubCategoryId: res.data.shopSubCategoryId || "",
          promotionalImages: res.data.promotionalImages || [],
          cityAreaId: res.data.cityAreaId,
          pointerestId: res.data.pointerestId,
        });

        setVendorDetails(
          res.data.Vendor
            ? res.data.Vendor
            : {
                id: "",
                email: "",
              }
        );
        setSelectedCategory(res.data.ShopCategory.id);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [shopId]);

  const handleInputChange = (e) => {
    // handle checkbox input
    if (e.target.type === "checkbox") {
      setInputValues({ ...inputValues, [e.target.name]: e.target.checked });
      return;
    }

    setInputValues({ ...inputValues, [e.target.name]: e.target.value });
  };
  const handleFileChange = (e) => {
    setInputValues({ ...inputValues, [e.target.name]: e.target.files[0] });
  };
  const handleMediaChange = (e) => {
    if (e.target.files) {
      const files = Array.from(e.target.files);
      setInputValues((prevValues) => ({
        ...prevValues,
        promotionalImages: [...files],
      }));
      e.target.value = null;
    }
  };

  useEffect(() => {
    Api.get("/city")
      .then((res) => {
        setCities(res.data.results);
      })
      .catch((err) => console.log(err));

    Api.get("/mall")
      .then((res) => {
        setMalls(res.data.results);
      })
      .catch((e) => {
        console.log(e);
      });
    Api.get("/shop-category")
      .then((res) => {
        setCategories(res.data.results);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (inputValues.cityId) {
      Api.get(`/city-area?cityId=${inputValues.cityId}`).then((res) => {
        setCityAreas(res.data.results);
      });

      Api.get(`/pointerest?cityId=${inputValues.cityId}`).then((res) => {
        setPointerests(res.data.results);
      });

      Api.get(`/mall?cityId=${inputValues.cityId}`).then((res) => {
        setMalls(res.data.results);
      });
    }
  }, [inputValues.cityId]);

  const getCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        setInputValues({
          ...inputValues,
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        });
      });
    } else {
      Toast("error", "Geolocation is not supported by this browser.");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = { ...inputValues };
    console.log(inputValues);

    setLoading(true);
    // create form data
    // const formData = new FormData();
    // for (let key in data) {
    //   formData.append(key, data[key]);
    // }
    // inputValues.promotionalImages.forEach((file, index) => {
    //   formData.append(`promotionalImages`, file);
    // });

    const promotionalImages = [];
    for (let i = 0; i < inputValues.promotionalImages?.length; i++) {
      const url = await cloudinaryUpload(inputValues.promotionalImages[i]);
      promotionalImages.push(url);
    }
    data.promotionalImages = promotionalImages;
    data.shopCategoryId = selectedCategory;

    const updatedBody = Object.keys(data).reduce((acc, key) => {
      if (data[key] !== "") acc[key] = data[key];
      else acc[key] = null;
      return acc;
    }, {});

    Api.patch(`/shop/${shopId}`, updatedBody)
      .then((res) => {
        console.log(res.data);

        setInputValues({
          name: res.data.name,
          phone: res.data.phone,
          address: res.data.address,
          latitude: res.data.latitude,
          longitude: res.data.longitude,
          image: res.data.image,
          cityId: res.data.cityId,
          mallId: res.data.mallId,
          sundayFrom: res.data.sundayFrom,
          sundayTo: res.data.sundayTo,
          mondayFrom: res.data.mondayFrom,
          mondayTo: res.data.mondayTo,
          tuesdayFrom: res.data.tuesdayFrom,
          tuesdayTo: res.data.tuesdayTo,
          wednesdayFrom: res.data.wednesdayFrom,
          wednesdayTo: res.data.wednesdayTo,
          thursdayFrom: res.data.thursdayFrom,
          thursdayTo: res.data.thursdayTo,
          fridayFrom: res.data.fridayFrom,
          fridayTo: res.data.fridayTo,
          saturdayFrom: res.data.saturdayFrom,
          saturdayTo: res.data.saturdayTo,
          active: res.data.active,
          cityAreaId: res.data.cityAreaId,
          pointerestId: res.data.pointerestId,
          shopSubCategoryId: res.data.shopSubCategoryId || "",
        });

        Toast("success", res.data.message);
        Navigate(-1);
      })
      .catch((err) => {
        console.log(err);
        Toast("error", err.response.data.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleVendorUpdate = (e) => {
    e.preventDefault();
    setLoading(true);
    Api.patch(`/vendor/${vendorDetails.id}`, {
      email: vendorDetails.email,
    })
      .then((res) => {
        Toast("success", res.data.message);
      })
      .catch((err) => {
        Toast("error", err.response.data.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <Menu>
      <div>
        <div className="mb-5 heading-text">
          <Translate>Edit Shop</Translate>
        </div>
        <div className="w-full flex flex-col items-center">
          {/* vendor information */}
          <form
            className="w-2/3 flex flex-col space-y-20 my-10"
            onSubmit={handleVendorUpdate}
          >
            <fieldset>
              <legend>
                <Translate>VENDOR DETAILS</Translate>
              </legend>
              <div className="create-form">
                <div>
                  <label htmlFor="email">
                    <Translate>Email</Translate>
                  </label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    placeholder={t("Email")}
                    onChange={(e) => {
                      setVendorDetails({
                        ...vendorDetails,
                        email: e.target.value,
                      });
                    }}
                    value={vendorDetails.email}
                  />
                </div>
              </div>
            </fieldset>
            <div className="flex justify-center space-x-5">
              <input
                className="heading-bg-color bg-text-color px-5 py-2 rounded-md cursor-pointer"
                type="submit"
                value={t("Update")}
              />

              <div
                className="heading-bg-color bg-text-color px-5 py-2 rounded-md cursor-pointer"
                onClick={() => {
                  Navigate(-1);
                }}
              >
                <Translate>Back</Translate>
              </div>
            </div>
          </form>

          <form
            className="w-2/3 flex flex-col space-y-20 my-10"
            onSubmit={handleSubmit}
          >
            <fieldset>
              <legend>
                <Translate>SHOP DETAILS</Translate>
              </legend>
              <div className="create-form">
                <div>
                  <label htmlFor="name">
                    <Translate>Name</Translate>
                  </label>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    placeholder={t("Name")}
                    onChange={handleInputChange}
                    value={inputValues.name}
                  />
                </div>

                <div>
                  <label htmlFor="phone">
                    <Translate>Phone</Translate>
                  </label>
                  <input
                    type="text"
                    name="phone"
                    id="phone"
                    placeholder={t("Phone")}
                    onChange={handleInputChange}
                    value={inputValues.phone}
                  />
                </div>
                <div>
                  <label htmlFor="address">
                    <Translate>Address</Translate>
                  </label>
                  <input
                    type="text"
                    name="address"
                    id="address"
                    placeholder={t("Address")}
                    onChange={handleInputChange}
                    value={inputValues.address}
                  />
                </div>
                {inputValues.image ? (
                  <div className="relative">
                    <label htmlFor="newImage">
                      <Translate>Image</Translate>
                    </label>

                    <img
                      src={inputValues.image}
                      alt="shop"
                      className="w-40 h-40"
                    />
                    <div
                      className="absolute -top-5 right-0 cursor-pointer"
                      onClick={() => {
                        setInputValues({ ...inputValues, image: "" });
                      }}
                    >
                      <TiDeleteOutline size={20} color="red" />
                    </div>
                  </div>
                ) : (
                  <div>
                    <label htmlFor="image">
                      <Translate>Image</Translate>
                    </label>
                    <input
                      type="file"
                      name="newImage"
                      id="newImage"
                      onChange={handleFileChange}
                    />
                  </div>
                )}

                {/* latitude and longitude */}
                <div className="flex flex-col space-y-3">
                  <div className="flex flex-col space-y-2">
                    <label htmlFor="latitude">
                      <Translate>Latitude</Translate>
                    </label>
                    <input
                      type="text"
                      name="latitude"
                      id="latitude"
                      placeholder={t("Latitude")}
                      onChange={handleInputChange}
                      value={inputValues.latitude}
                    />
                  </div>
                  <div className="flex flex-col space-y-2">
                    <label htmlFor="longitude">
                      <Translate>Longitude</Translate>
                    </label>
                    <input
                      type="text"
                      name="longitude"
                      id="longitude"
                      placeholder={t("Longitude")}
                      onChange={handleInputChange}
                      value={inputValues.longitude}
                    />
                  </div>
                  <div
                    className="px-3 py-2 heading-bg-color bg-text-color rounded-md cursor-pointer"
                    onClick={getCurrentLocation}
                  >
                    <Translate>Get Current Location</Translate>
                  </div>

                  <div
                    className="px-3 py-2 heading-bg-color bg-text-color rounded-md cursor-pointer"
                    onClick={openModal}
                  >
                    <Translate>Get Current Address</Translate>
                  </div>
                </div>

                <div>
                  <label htmlFor="cityId">
                    <Translate>City</Translate>
                  </label>
                  <select
                    name="cityId"
                    id="cityId"
                    onChange={handleInputChange}
                    value={inputValues.cityId}
                  >
                    <option>
                      <Translate>Select City</Translate>
                    </option>
                    {cities.map((city) => (
                      <option value={city.id}>{city.name}</option>
                    ))}
                  </select>
                </div>

                {/* category */}
                <div>
                  <label htmlFor="category">
                    <Translate>Category</Translate>
                  </label>
                  <select
                    name="category"
                    id="category"
                    value={selectedCategory}
                    onChange={(e) => {
                      setSelectedCategory(e.target.value);
                    }}
                  >
                    <option value="">
                      <Translate>Select Category</Translate>
                    </option>
                    {categories.map((category) => {
                      return (
                        <option key={category.id} value={category.id}>
                          {category.name}
                        </option>
                      );
                    })}
                  </select>
                </div>

                {/* sub category */}
                {selectedCategory && (
                  <div>
                    <label htmlFor="shopSubCategoryId">
                      <Translate>Sub Category</Translate>
                    </label>
                    <select
                      name="shopSubCategoryId"
                      id="shopSubCategoryId"
                      value={inputValues.shopSubCategoryId}
                      onChange={handleInputChange}
                    >
                      <option value="">
                        <Translate>Select Sub Category</Translate>
                      </option>
                      {categories
                        .find((c) => c.id === selectedCategory)
                        ?.ShopSubCategory.map((subCategory) => {
                          return (
                            <option key={subCategory.id} value={subCategory.id}>
                              {subCategory.name}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                )}

                {/* mall */}
                <div>
                  <label htmlFor="mallId">
                    <Translate>Mall</Translate>(<Translate>optional</Translate>)
                  </label>
                  <select
                    name="mallId"
                    id="mallId"
                    onChange={handleInputChange}
                    value={inputValues.mallId}
                  >
                    <option value="">
                      <Translate>Select Mall</Translate>
                    </option>
                    {malls.map((mall) => (
                      <option key={mall.id} value={mall.id}>
                        {mall.name}
                      </option>
                    ))}
                  </select>
                </div>

                {/* City Area */}
                <div>
                  <label htmlFor="cityAreaId">
                    <Translate>City Area</Translate>(
                    <Translate>optional</Translate>)
                  </label>
                  <select
                    name="cityAreaId"
                    id="cityAreaId"
                    onChange={handleInputChange}
                    value={inputValues.cityAreaId}
                  >
                    <option value="">
                      <Translate>Select City Area</Translate>
                    </option>
                    {cityAreas.map((area) => (
                      <option key={area.id} value={area.id}>
                        {area.name}
                      </option>
                    ))}
                  </select>
                </div>

                {/* Point of interest */}
                <div>
                  <label htmlFor="pointerestId">
                    <Translate>Point Of Interest</Translate>(
                    <Translate>optional</Translate>)
                  </label>
                  <select
                    name="pointerestId"
                    id="pointerestId"
                    onChange={handleInputChange}
                    value={inputValues.pointerestId}
                  >
                    <option value="">
                      <Translate>Select Pointerest</Translate>
                    </option>
                    {pointerests.map((pointerest) => (
                      <option key={pointerest.id} value={pointerest.id}>
                        {pointerest.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="w-full"></div>
                <div className="w-full">
                  <label htmlFor="promotionalImages">
                    <Translate>Promotional Images</Translate>
                    <input
                      type="file"
                      onClick={(e) => {
                        handleMediaChange(e);
                      }}
                      onBlur={(e) => {
                        handleMediaChange(e);
                      }}
                      multiple
                      name="promotionalImages"
                    />
                  </label>
                  {!!inputValues?.promotionalImages?.length && (
                    <p>
                      Promotional Images Selected :{" "}
                      {inputValues.promotionalImages?.length}{" "}
                    </p>
                  )}
                </div>
              </div>
            </fieldset>

            <fieldset>
              <legend>
                <Translate>OPERATING HOURS</Translate>
              </legend>
              <div className="px-5">
                <div>
                  <div>
                    <div className="flex flex-col items-start space-y-2 my-2">
                      <label htmlFor="sunday">
                        <Translate>Sunday</Translate>
                      </label>
                    </div>

                    <table>
                      <tbody>
                        <tr>
                          <th>
                            <Translate>From</Translate>
                          </th>
                          <th>
                            <Translate>To</Translate>
                          </th>
                        </tr>
                        <tr>
                          <td className="w-[40%]">
                            <input
                              type="time"
                              name="sundayFrom"
                              id="sundayFrom"
                              onChange={handleInputChange}
                              value={inputValues.sundayFrom}
                            />
                          </td>
                          <td className="w-[40%]">
                            <input
                              type="time"
                              name="sundayTo"
                              id="sundayTo"
                              onChange={handleInputChange}
                              value={inputValues.sundayTo}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div>
                    <div className="flex flex-col items-start space-y-2 my-2">
                      <label htmlFor="monday">
                        <Translate>Monday</Translate>
                      </label>
                    </div>

                    <table>
                      <tbody>
                        <tr>
                          <th>
                            <Translate>From</Translate>
                          </th>
                          <th>
                            <Translate>To</Translate>
                          </th>
                        </tr>
                        <tr>
                          <td className="w-[40%]">
                            <input
                              type="time"
                              name="mondayFrom"
                              id="mondayFrom"
                              onChange={handleInputChange}
                              value={inputValues.mondayFrom}
                            />
                          </td>
                          <td className="w-[40%]">
                            <input
                              type="time"
                              name="mondayTo"
                              id="mondayTo"
                              onChange={handleInputChange}
                              value={inputValues.mondayTo}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div>
                    <div className="flex flex-col items-start space-y-2 my-2">
                      <label htmlFor="tuesday">
                        <Translate>Tuesday</Translate>
                      </label>
                    </div>

                    <table>
                      <tbody>
                        <tr>
                          <th>
                            <Translate>From</Translate>
                          </th>
                          <th>
                            <Translate>To</Translate>
                          </th>
                        </tr>
                        <tr>
                          <td className="w-[40%]">
                            <input
                              type="time"
                              name="tuesdayFrom"
                              id="tuesdayFrom"
                              onChange={handleInputChange}
                              value={inputValues.tuesdayFrom}
                            />
                          </td>
                          <td className="w-[40%]">
                            <input
                              type="time"
                              name="tuesdayTo"
                              id="tuesdayTo"
                              onChange={handleInputChange}
                              value={inputValues.tuesdayTo}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div>
                    <div className="flex flex-col items-start space-y-2 my-2">
                      <label htmlFor="wednesday">
                        <Translate>Wednesday</Translate>
                      </label>
                    </div>

                    <table>
                      <tbody>
                        <tr>
                          <th>
                            <Translate>From</Translate>
                          </th>
                          <th>
                            <Translate>To</Translate>
                          </th>
                        </tr>
                        <tr>
                          <td className="w-[40%]">
                            <input
                              type="time"
                              name="wednesdayFrom"
                              id="wednesdayFrom"
                              onChange={handleInputChange}
                              value={inputValues.wednesdayFrom}
                            />
                          </td>
                          <td className="w-[40%]">
                            <input
                              type="time"
                              name="wednesdayTo"
                              id="wednesdayTo"
                              onChange={handleInputChange}
                              value={inputValues.wednesdayTo}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div>
                    <div className="flex flex-col items-start space-y-2 my-2">
                      <label htmlFor="thursday">
                        <Translate>Thursday</Translate>
                      </label>
                    </div>

                    <table>
                      <tbody>
                        <tr>
                          <th>
                            <Translate>From</Translate>
                          </th>
                          <th>
                            <Translate>To</Translate>
                          </th>
                        </tr>
                        <tr>
                          <td className="w-[40%]">
                            <input
                              type="time"
                              name="thursdayFrom"
                              id="thursdayFrom"
                              onChange={handleInputChange}
                              value={inputValues.thursdayFrom}
                            />
                          </td>
                          <td className="w-[40%]">
                            <input
                              type="time"
                              name="thursdayTo"
                              id="thursdayTo"
                              onChange={handleInputChange}
                              value={inputValues.thursdayTo}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div>
                    <div className="flex flex-col items-start space-y-2 my-2">
                      <label htmlFor="friday">
                        <Translate>Friday</Translate>
                      </label>
                    </div>

                    <table>
                      <tbody>
                        <tr>
                          <th>
                            <Translate>From</Translate>
                          </th>
                          <th>
                            <Translate>To</Translate>
                          </th>
                        </tr>
                        <tr>
                          <td className="w-[40%]">
                            <input
                              type="time"
                              name="fridayFrom"
                              id="fridayFrom"
                              onChange={handleInputChange}
                              value={inputValues.fridayFrom}
                            />
                          </td>
                          <td className="w-[40%]">
                            <input
                              type="time"
                              name="fridayTo"
                              id="fridayTo"
                              onChange={handleInputChange}
                              value={inputValues.fridayTo}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div>
                    <div className="flex flex-col items-start space-y-2 my-2">
                      <label htmlFor="saturday">
                        <Translate>Saturday</Translate>
                      </label>
                    </div>

                    <table>
                      <tbody>
                        <tr>
                          <th>
                            <Translate>From</Translate>
                          </th>
                          <th>
                            <Translate>To</Translate>
                          </th>
                        </tr>
                        <tr>
                          <td className="w-[40%]">
                            <input
                              type="time"
                              name="saturdayFrom"
                              id="saturdayFrom"
                              onChange={handleInputChange}
                              value={inputValues.saturdayFrom}
                            />
                          </td>
                          <td className="w-[40%]">
                            <input
                              type="time"
                              name="saturdayTo"
                              id="saturdayTo"
                              onChange={handleInputChange}
                              value={inputValues.saturdayTo}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </fieldset>
            <fieldset>
              <legend>
                <Translate>SHOP (ACTIVATE/DEACTIVATE)</Translate>
              </legend>
              <div className="px-5">
                <div className="flex space-x-3 items-center">
                  <input
                    type="checkbox"
                    name="active"
                    id="active"
                    onChange={handleInputChange}
                    checked={inputValues.active}
                  />
                  <label htmlFor="active">
                    <Translate>Active</Translate>
                  </label>
                </div>
              </div>
            </fieldset>

            <div className="flex justify-center space-x-5">
              <input
                className="heading-bg-color bg-text-color px-5 py-2 rounded-md cursor-pointer"
                type="submit"
                value={t("Update")}
              />

              <div
                className="heading-bg-color bg-text-color px-5 py-2 rounded-md cursor-pointer"
                onClick={() => {
                  Navigate(-1);
                }}
              >
                <Translate>Back</Translate>
              </div>
            </div>
          </form>
        </div>
      </div>
      <CustomModal
        {...{
          title: "Get Address",
          isOpen,
          setIsOpen,
          openModal,
          closeModal,
        }}
      >
        <AddressModal
          {...{
            setInputValues,
            closeModal,
          }}
        />
      </CustomModal>
    </Menu>
  );
};

export default EditShop;
