import React, { useEffect, useState } from "react";
import Menu from "../sideMenu/Menu";
import { Link } from "react-router-dom";
import Translate from "../util/Translate";
import MallList from "./MallList";
import Api from "../util/axiosInstance";
import LoadingAnimation from "../util/LoadingAnimation";

const Malls = () => {
  const [malls, setMalls] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    Api.get("/mall")
      .then((res) => {
        setMalls(res.data.results);
      })
      .catch((err) => {
        console.log(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);
  return (
    <Menu>
      {loading ? (
        <div className=" flex justify-center min-h-[90vh] items-center m-auto">
          <LoadingAnimation />
        </div>
      ) : (
        <div className="flex flex-col items-start space-y-5 ">
          <h1 className="sub-heading-text py-2 border-b-2 border-b-gray-300 w-full">
            <Translate>Malls</Translate>
          </h1>
          <div className="w-full bg-gray-200 flex justify-center items-center">
            <div className="py-2 px-4 bg-color app-text-color cursor-pointer rounded-md">
              <Translate>Malls List</Translate>
            </div>
            <Link
              className="py-2 px-4 hover:heading-bg-color hover:bg-text-color cursor-pointer rounded-md"
              to={"/malls/create"}
            >
              <Translate>Create Mall</Translate>
            </Link>
          </div>
          <MallList malls={malls} setMalls={setMalls} setLoading={setLoading} />
        </div>
      )}
    </Menu>
  );
};

export default Malls;
