import React, { useEffect, useState } from "react";
import Menu from "../sideMenu/Menu";
import ServicesList from "./ServicesList";
import { Link } from "react-router-dom";
import Translate from "../util/Translate";
import Api from "../util/axiosInstance";
import LoadingAnimation from "../util/LoadingAnimation";
import { URL } from "../util/authorization/authorizationURL";
import { useAppContext } from "../../hooks/context/tokenStore";

const Services = () => {
  const [services, setServices] = useState([]);
  const userRole = localStorage.getItem("role");
  const [loading, setLoading] = useState(false);
  const { userData } = useAppContext();

  useEffect(() => {
    let url = "/service";
    let filter = "";
    if (userRole === "professional") {
      filter = `professionalId=${userData.id}&`;
    }
    if (userRole === "cityadmin") {
      filter = filter.concat(`cityId=${userData?.City?.id}`);
    }
    setLoading(true);
    Api.get(url + "?" + filter)
      .then((res) => {
        setServices(res.data?.results);
      })
      .finally(() => setLoading(false));
  }, []);
  return (
    <Menu>
      <div className="flex flex-col items-start space-y-5 w-full min-h-[90vh] ">
        {loading ? (
          <div className=" flex justify-center items-center m-auto">
            <LoadingAnimation />
          </div>
        ) : (
          <>
            <h1 className="sub-heading-text py-2 border-b-2 border-b-gray-300 w-full">
              Professionals Services
            </h1>
            <div className="w-full bg-gray-200 flex justify-center items-center">
              <div className="py-2 px-4 bg-color app-text-color cursor-pointer rounded-md">
                Professionals Services List
              </div>
              <Link
                className="py-2 px-4 hover:heading-bg-color hover:bg-text-color cursor-pointer rounded-md"
                to={"/services/create"}
              >
                <Translate>Create Service</Translate>
              </Link>
            </div>
            <ServicesList
              services={services}
              setLoading={setLoading}
              setServices={setServices}
            />
          </>
        )}
      </div>
    </Menu>
  );
};

export default Services;
