import React, { useState } from "react";
import Menu from "../sideMenu/Menu";
import AllChats from "./AllChats";
import ChatPreview from "./ChatPreview";
import { useAppContext } from "../../hooks/context/tokenStore";

const Chats = () => {
  const [currentChat, setCurrentChat] = useState(null);
  const [allChats, setAllChats] = useState([]);

  const { userData } = useAppContext();

  return (
    <Menu>
      <div className="heading-text app-text-color">Chats</div>
      <div className="w-full rounded-2xl bg-[#FAFCFF] flex h-[82vh] mt-2">
        <AllChats
          currentChat={currentChat}
          setCurrentChat={setCurrentChat}
          userData={userData}
          allChats={allChats}
          setAllChats={setAllChats}
        />
        <ChatPreview
          currentChat={currentChat}
          setCurrentChat={setCurrentChat}
          userData={userData}
          allChats={allChats}
          setAllChats={setAllChats}
        />
      </div>
    </Menu>
  );
};

export default Chats;
