import { TableCell, TableRow } from "@mui/material";
import React from "react";
import { TiEye } from "react-icons/ti";
import { RiEditCircleFill } from "react-icons/ri";
import { MdDelete } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import ConfirmationModal from "../util/ConfirmationModal";

const ShopRow = ({ shop, handleShopDelete }) => {
  const Navigate = useNavigate();
  const [isOpen, setIsOpen] = React.useState(false);
  return (
    <TableRow>
      <TableCell>{shop.name}</TableCell>
      <TableCell>
        <div className="w-12">
          <img
            src={shop.image}
            alt="user_profile"
            className="object-cover rounded-md w-full h-full"
          />
        </div>
      </TableCell>

      <TableCell>{new Date(shop.createdAt).toLocaleDateString()}</TableCell>
      <TableCell>{shop?.Product?.length}</TableCell>

      <TableCell>
        <div className="heading-text flex items-center space-x-2">
          <div
            className="text-green-700 cursor-pointer"
            onClick={() => {
              Navigate(`/shops/edit/${shop.id}`);
            }}
          >
            <RiEditCircleFill />
          </div>
          <div
            onClick={() => {
              // handleShopDelete(shop.id);
              setIsOpen(true);
            }}
          >
            <MdDelete className="text-red-700 cursor-pointer" />
          </div>
        </div>
      </TableCell>
      <ConfirmationModal
        deleteFuction={handleShopDelete}
        id={shop.id}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
    </TableRow>
  );
};

export default ShopRow;
