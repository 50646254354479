import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import Api from "../../util/axiosInstance";
import { useNavigate } from "react-router-dom";
import { RiEditCircleFill } from "react-icons/ri";
import { MdDelete } from "react-icons/md";
import ConfirmationModal from "../../util/ConfirmationModal";
import DeleteComponent from "../../util/DeleteComponent";

const PackagesList = () => {
  const [rows, setRows] = useState([]);
  const Navigate = useNavigate();

  useEffect(() => {
    Api.get("/vendor-subscription").then((res) => {
      setRows(res.data.results);
    });
  }, []);

  const handlePackageDelete = (id) => {
    Api.delete(`/vendor-subscription/${id}`)
      .then((res) => {
        console.log(res.data);
        setRows(rows.filter((row) => row.id !== id));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const columns = [
    {
      field: "title",
      headerName: "Title",
      width: 150,
    },
    {
      field: "monthlyPrice",
      headerName: "Monthly Price",
      width: 150,
    },
    {
      field: "yearlyPrice",
      headerName: "Yearly Price",
      width: 150,
    },
    {
      field: "maxListing",
      headerName: "Max Listing",
      width: 150,
    },
    {
      field: "type",
      headerName: "Type",
      width: 150,
    },

    // calculate the vendors count in this field
    {
      field: "Vendor",
      headerName: "Vendors",
      width: 150,
      valueGetter: (value, row) => row?.Subscription?.length || 0,
    },
    {
      field: "Action",
      headerName: "Action",
      width: 150,
      renderCell: (params) => {
        return (
          <div className="heading-text flex items-center space-x-2 w-full h-full">
            <div
              className="text-green-700 cursor-pointer"
              onClick={() => {
                Navigate(`/subscription/vendor/edit/${params.row.id}`);
              }}
            >
              <RiEditCircleFill />
            </div>
            <DeleteComponent
              id={params.row.id}
              deleteFuction={handlePackageDelete}
            />
          </div>
        );
      },
    },
  ];

  return (
    <div>
      <div>
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5, 10, 20]}
          disableRowSelectionOnClick
        />
      </div>
    </div>
  );
};

export default PackagesList;
