import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Api from "../../util/axiosInstance";
import Translate from "../../util/Translate";

const EditSubCategory = ({
  currentCategory,
  setCategories,
  categories,
  closeModal,
  currentSubCategory,
  setSubCategories,
  subCategories,
}) => {
  const [name, setName] = useState("");
  const { t } = useTranslation();

  useEffect(() => {
    if (currentSubCategory) {
      setName(currentSubCategory.name);
    }
  }, [currentSubCategory]);

  const handleSubmit = (e) => {
    e.preventDefault();
    Api.patch(`/sub-category/${currentSubCategory.id}`, {
      name,
    })
      .then((res) => {
        let tempSubCategories = subCategories.map((item) => {
          if (item.id === currentSubCategory.id) {
            return res.data.data;
          }
          return item;
        });
        setSubCategories(tempSubCategories);

        let tempCategories = categories.map((item) => {
          if (item.id === currentCategory.id) {
            return {
              ...item,
              SubCategory: tempSubCategories,
            };
          }
          return item;
        });
        setCategories(tempCategories);

        closeModal();
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <div className="my-5">
      <div>
        <form className="flex flex-col space-y-3" onSubmit={handleSubmit}>
          <div className="input-group">
            <label htmlFor="name">
              <Translate>Name</Translate>
            </label>
            <input
              type="text"
              name="name"
              id="name"
              minLength={3}
              maxLength={30}
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
          </div>
          <div>
            <input type="submit" value={t("SAVE")} className="btn" />
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditSubCategory;
