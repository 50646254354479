import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import Translate from "../../util/Translate";

const professionals = [
  {
    id: 1,
    name: "Professional 1",
    orders: 10,
    revenue: 1000,
  },
  {
    id: 2,
    name: "Professional 2",
    orders: 20,
    revenue: 4000,
  },
  {
    id: 3,
    name: "Professional 3",
    orders: 30,
    revenue: 9000,
  },
  {
    id: 4,
    name: "Professional 4",
    orders: 40,
    revenue: 16000,
  },
  {
    id: 5,
    name: "Professional 5",
    orders: 50,
    revenue: 25000,
  },
];
const ProfessionalTable = ({topProfessionals}) => {
  return (
    <div>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <Translate>Professional</Translate>
            </TableCell>
            <TableCell>
              <Translate>Total Orders</Translate>
            </TableCell>
            <TableCell>{/* <Translate>Revenue</Translate> */}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {topProfessionals.map((professional) => (
            <TableRow key={professional.id}>
              <TableCell>{professional.name}</TableCell>
              <TableCell>{+professional.totalOrders}</TableCell>
              {/* <TableCell>{professional.revenue}</TableCell> */}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default ProfessionalTable;
