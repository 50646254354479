import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  MenuItem,
  Select,
  TableCell,
  TableRow,
} from "@mui/material";
import React, { useState } from "react";
import { TiEye, TiPencil } from "react-icons/ti";
import { MdDelete } from "react-icons/md";
import { Link } from "react-router-dom";
import ConfirmationModal from "../util/ConfirmationModal";
import Api from "../util/axiosInstance";
import Translate from "../util/Translate";

const OrderRow = ({ order, handleDeleteOrder, filter }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isEditStatus, setIsEditStatus] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(
    order.statusByVendor || ""
  ); // Store the selected status

  const handleStatusChange = (event) => {
    setSelectedStatus(event.target.value);
  };

  const handleSaveStatus = () => {
    if (filter === "vendor") {
      Api.patch(`/product-order/${order.id}`, {
        statusByVendor: selectedStatus,
      });
    } else {
      Api.patch(`/service-order/${order.id}`, {
        statusByProfessional: selectedStatus,
      });
    }
    setSelectedStatus("");
    setIsEditStatus(false); // Close the dialog after saving
  };

  return (
    <TableRow>
      <TableCell>
        <div className="w-5 h-5 border cursor-pointer flex justify-center items-center"></div>
      </TableCell>

      <TableCell>{order.id}</TableCell>

      <TableCell>{order.totalAmount}</TableCell>
      <TableCell>{order.Customer.name}</TableCell>

      <TableCell>
        {new Date(order.createdAt).toDateString().split(" ").slice(1).join(" ")}
      </TableCell>

      <TableCell>
        <div className="heading-text flex items-center space-x-2">
          <div>
            <TiPencil
              onClick={() => setIsEditStatus(true)}
              className="text-blue-500 cursor-pointer"
            />
          </div>
          <Link
            to={`/orders/view/${filter}/${order.id}`}
            className="text-gray-700 cursor-pointer"
          >
            <TiEye />
          </Link>
          <div
            onClick={() => {
              setIsOpen(true);
            }}
          >
            <MdDelete className="text-red-700 cursor-pointer" />
          </div>
        </div>
      </TableCell>

      {/* Dialog for changing status */}
      <Dialog
        maxWidth="sm"
        fullWidth
        open={isEditStatus}
        onClose={() => {
          setIsEditStatus(false);
        }}
      >
        <DialogTitle>
          <Translate>Change Status</Translate>
        </DialogTitle>

        <DialogContent>
          <FormControl fullWidth>
            <Select
              value={selectedStatus} // Bind the select value to the state
              onChange={handleStatusChange} // Handle change event
              fullWidth
            >
              <MenuItem value="">
                <Translate>Seleccionar Estado</Translate>
              </MenuItem>
              <MenuItem value="Pending">
                <Translate>Pendiente</Translate>
              </MenuItem>
              <MenuItem value="OrderConfirmed">
                <Translate>Pedido Confirmado</Translate>
              </MenuItem>
              <MenuItem value="OrderPickedUp">
                <Translate>Pedido Recogido</Translate>
              </MenuItem>
              <MenuItem value="OrderOnTheWay">
                <Translate>Pedido En Camino</Translate>
              </MenuItem>
              <MenuItem value="Delivered">
                <Translate>Entregado</Translate>
              </MenuItem>
              <MenuItem value="Cancelled">
                <Translate>Cancelado</Translate>
              </MenuItem>
            </Select>
          </FormControl>
          <div className="flex justify-end mt-3">
            <Button color="error" onClick={() => setIsEditStatus(false)}>
              <Translate>Cancel</Translate>
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={handleSaveStatus}
            >
              <Translate>Save</Translate>
            </Button>
          </div>
        </DialogContent>
      </Dialog>

      {/* Confirmation Modal for delete */}
      <ConfirmationModal
        deleteFuction={handleDeleteOrder}
        name={"Order"}
        id={order.id}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
    </TableRow>
  );
};

export default OrderRow;
