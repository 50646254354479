import React, { useEffect, useState } from "react";
import Menu from "../sideMenu/Menu";
import AnalyticCard from "./AnalyticCard";
import { CiMoneyCheck1, CiWallet } from "react-icons/ci";
import { IoFastFoodSharp, IoRestaurantSharp } from "react-icons/io5";
import { AiOutlineShoppingCart } from "react-icons/ai";
import { BsPersonCheck, BsPersonGear } from "react-icons/bs";
import AnalyticCardSimple from "./AnalyticCardSimple";
import { CiShoppingCart } from "react-icons/ci";
import { FaCheck, FaProductHunt, FaUserTie } from "react-icons/fa";
import MultipleReportChart from "./MultipleReportChart";
import VendorsSelling from "./VendorsSelling";
import ProfessionalSelling from "./ProfessionalSelling";
import UsersReportTable from "./professionals/UsersReportTable";
import { useTranslation } from "react-i18next";
import Translate from "../util/Translate";
import useUser from "../../hooks/useUser";
import { useAppContext } from "../../hooks/context/tokenStore";
import useDashboardAnalytics from "../../hooks/useDashboardAnalytics";
import { MdOutlinePending } from "react-icons/md";
import { GrDeliver } from "react-icons/gr";

const Dashboard = () => {
  const { userData } = useAppContext();
  const [userRole, setUserRole] = useState("");

  useEffect(() => {
    setUserRole(localStorage.getItem("role"));
  }, [userData]);

  const {
    totalVendors,
    totalEarnings,
    totalProducts,
    totalCustomers,
    reportsData,
    orderStatus,
    topProducts,
    topProfessionals,
    professionalOrders,
    professionalReportsData,
    professionalOrdersStatus,
    professionalServices,
    vendorOrders,
    vendorProducts,
    vendorsTable,
  } = useDashboardAnalytics();

  if (localStorage.getItem("role") === "customer") {
    return (
      <Menu>
        <div className="w-full h-full justify-center items-center">
          <h1>Customer Dashboard</h1>
        </div>
      </Menu>
    );
  }

  return (
    <Menu>
      <div className="heading-text app-text-color">
        <Translate>Business Analytics</Translate>
      </div>
      {userRole === "superadmin" ||
      userRole === "cityadmin" ||
      userRole === "logisticadmin" ? (
        <div className="my-5 flex flex-wrap w-full">
          <AnalyticCard
            heading="Total Earnings"
            number={`$${
              totalEarnings
                ? totalEarnings.reduce((acc, it) => acc + it.totalAmount, 0)
                : 0
            }
          `}
            icon={<CiShoppingCart size={50} color="green" />}
          />
          {userRole !== "logisticadmin" && (
            <AnalyticCard
              heading="Total Shops"
              number={totalVendors?.totalResults || 0}
              icon={<FaUserTie size={50} color="orange" />}
            />
          )}
          <AnalyticCard
            heading="Total Orders"
            number={totalEarnings?.length || 0}
            icon={<AiOutlineShoppingCart size={50} color="red" />}
          />
          {userRole !== "logisticadmin" && (
            <AnalyticCard
              heading="Total Products"
              number={totalProducts?.totalResults || 0}
              icon={<FaProductHunt size={50} />}
            />
          )}
          {userRole !== "logisticadmin" && (
            <AnalyticCard
              heading="Total Customer"
              number={totalCustomers?.totalResults || 0}
              icon={<BsPersonCheck size={50} />}
            />
          )}

          {/* <AnalyticCard
          heading="Total Drivers"
          number="123"
          icon={<BsPersonGear size={50} />}
        /> */}
        </div>
      ) : userRole === "professional" ? (
        <div className="my-5 flex flex-wrap w-full">
          <AnalyticCard
            heading="Pending Orders"
            number={
              professionalOrders?.results?.filter(
                (order) => order.statusByProfessional === "Pending"
              )?.length || 0
            }
            icon={<MdOutlinePending size={50} color="green" />}
          />
          <AnalyticCard
            heading="On Going Orders"
            number={
              professionalOrders?.results?.filter(
                (order) => order.statusByProfessional === "OrderOnTheWay"
              )?.length || 0
            }
            icon={<GrDeliver size={50} color="red" />}
          />

          <AnalyticCard
            heading="Total Orders"
            number={professionalOrders?.results?.length || 0}
            icon={<AiOutlineShoppingCart size={50} />}
          />
          <AnalyticCard
            heading="Completed Orders"
            number={
              professionalOrders?.results?.filter(
                (order) => order.statusByProfessional === "Delivered"
              )?.length || 0
            }
            icon={<FaCheck size={50} color="orange" />}
          />

          <AnalyticCard
            heading="Total Earnings"
            number={`$${
              professionalOrders
                ? professionalOrders?.results?.reduce(
                    (acc, it) => acc + it.totalAmount,
                    0
                  )
                : 0
            }
          `}
            icon={<CiShoppingCart size={50} />}
          />
          {/* <AnalyticCard
        heading="Total Drivers"
        number="123"
        icon={<BsPersonGear size={50} />}
      /> */}
        </div>
      ) : userRole === "vendor" ? (
        <div className="my-5 flex flex-wrap w-full">
          <AnalyticCard
            heading="Total Earnings"
            number={`$${
              vendorOrders
                ? vendorOrders?.results?.reduce(
                    (acc, it) => acc + it.totalAmount,
                    0
                  )
                : 0
            }
        `}
            icon={<CiShoppingCart size={50} color="green" />}
          />
          <AnalyticCard
            heading="Total Orders"
            number={vendorOrders?.totalResults || 0}
            icon={<AiOutlineShoppingCart size={50} color="red" />}
          />
          <AnalyticCard
            heading="Total Products"
            number={vendorProducts?.totalResults || 0}
            icon={<FaProductHunt size={50} />}
          />

          {/* <AnalyticCard
        heading="Total Drivers"
        number="123"
        icon={<BsPersonGear size={50} />}
      /> */}
        </div>
      ) : null}
      <div className="flex flex-col space-y-5">
        <div className="flex items-start space-x-5">
          <MultipleReportChart
            reportsData={
              userRole === "professional"
                ? professionalReportsData
                : userRole === "superadmin" ||
                  userRole === "cityadmin" ||
                  userRole === "vendor" ||
                  userRole === "logisticadmin"
                ? reportsData
                : []
            }
          />
          <UsersReportTable
            orderStatus={
              userRole === "professional"
                ? professionalOrdersStatus
                : userRole === "superadmin" ||
                  userRole === "cityadmin" ||
                  userRole === "vendor" ||
                  userRole === "logisticadmin"
                ? orderStatus
                : []
            }
          />
        </div>
        {userRole !== "logisticadmin" && (
          <div className="flex items-start space-x-10 ">
            {userRole !== "professional" && (
              <VendorsSelling
                topProducts={topProducts}
                vendorsTable={vendorsTable}
              />
            )}
            {userRole !== "vendor" && (
              <ProfessionalSelling
                professionalServices={professionalServices}
                topProfessionals={topProfessionals}
              />
            )}
          </div>
        )}
      </div>
    </Menu>
  );
};

export default Dashboard;
