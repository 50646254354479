import { Switch, TableCell, TableRow } from "@mui/material";
import React from "react";
import { MdDelete } from "react-icons/md";
import { RiEditCircleFill } from "react-icons/ri";
import { TiEye } from "react-icons/ti";
const label = { inputProps: { "aria-label": "Switch demo" } };

const DriverRow = ({ driver }) => {
  return (
    <TableRow>
      <TableCell>
        <div className="w-5 h-5 border border-black cursor-pointer"></div>
      </TableCell>

      <TableCell>
        <div className="w-12">
          <img
            src={driver.image}
            alt="user_profile"
            className="object-cover rounded-md w-full h-full"
          />
        </div>
      </TableCell>
      <TableCell>{driver.name}</TableCell>

      <TableCell>{driver.email}</TableCell>
      <TableCell>{driver.createdAt}</TableCell>
      <TableCell>
        <Switch {...label} checked={driver.availablity} color="success" />
      </TableCell>
      <TableCell>{driver.totalOrders}</TableCell>
      <TableCell>
        <div className="heading-text flex items-center space-x-2">
          <div className="text-gray-700 cursor-pointer">
            <TiEye />
          </div>
          <div className="text-green-700 cursor-pointer">
            <RiEditCircleFill />
          </div>
          <div>
            <MdDelete className="text-red-700 cursor-pointer" />
          </div>
        </div>
      </TableCell>
    </TableRow>
  );
};

export default DriverRow;
