import React, { useEffect, useState } from "react";
import Menu from "../../sideMenu/Menu";
import Translate from "../../util/Translate";
import Api from "../../util/axiosInstance";
import Toast from "../../util/CustomToast";
import { useNavigate } from "react-router-dom";

const CreateSubCategory = () => {
  const [inputValues, setInputValues] = useState({
    name: "",
    categoryId: "",
  });
  const [categories, setCategories] = useState([]);
  const Navigate = useNavigate();

  useEffect(() => {
    Api.get("/category").then((response) => {
      setCategories(response.data.results);
    });
  }, []);

  const handleInputChange = (e) => {
    setInputValues({ ...inputValues, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    Api.post("/sub-category", inputValues).then((response) => {
      console.log(response.data);
      setInputValues({
        name: "",
        categoryId: "",
      });
      Toast("success", "Sub Category Created Successfully");
      Navigate(-1);
    });
  };
  return (
    <Menu>
      <div>
        <div className="mb-5 heading-text">
          <Translate>Create Category</Translate>
        </div>
        <div className="w-full flex justify-center">
          <form
            className="w-2/3 flex flex-col space-y-20 my-10"
            onSubmit={handleSubmit}
          >
            <fieldset>
              <legend>
                <Translate>Category Details</Translate>
              </legend>
              <div className="px-5 my-3 flex flex-col space-y-10">
                <div className="input-group">
                  <label htmlFor="name">
                    <Translate>Name</Translate>
                  </label>
                  <input
                    type="text"
                    name="name"
                    value={inputValues.name}
                    onChange={handleInputChange}
                    required
                    minLength={3}
                    maxLength={20}
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="categoryId">
                    <Translate>Category</Translate>
                  </label>
                  <select
                    name="categoryId"
                    value={inputValues.categoryId}
                    onChange={handleInputChange}
                    required
                  >
                    <option value="">Select Category</option>
                    {categories.map((category) => (
                      <option key={category.id} value={category.id}>
                        {category.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </fieldset>
            <div className="flex justify-center">
              <button className="btn" type="submit">
                <Translate>Create</Translate>
              </button>
            </div>
          </form>
        </div>
      </div>
    </Menu>
  );
};

export default CreateSubCategory;
