import {
  Dialog,
  DialogPanel,
  DialogTitle,
  Transition,
  TransitionChild,
} from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import { useAppContext } from "../../../../hooks/context/tokenStore";
import Api from "../../../util/axiosInstance";
import Toast from "../../../util/CustomToast";
import LoadingSpinner from "../../../util/LoadingSpinner";
import Translate from "../../../util/Translate";
import { cloudinaryUpload } from "../../../../utils/cloudinaryUpload";

export default function ProfessionalChatOrder({
  open,
  close,
  isOpen,

  handleOrderSend,
}) {
  const { userData } = useAppContext();
  const [loading, setLoading] = useState(false);
  const [inputValues, setInputValues] = useState({
    title: "",
    price: 0,
    image: null,
  });

  const [step, setStep] = useState(0);

  const handleInputChange = (e) => {
    setInputValues({
      ...inputValues,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    // const formData = new FormData();
    // formData.append("title", inputValues.title);
    // formData.append("price", inputValues.price);
    // formData.append("image", inputValues.image);
    const image = await cloudinaryUpload(inputValues.image);
    console.log("🚀 ~ handleSubmit ~ image:", image);

    try {
      const result = await Api.post("/chat-service", {
        ...inputValues,
        professionalId: userData?.id,
        price: +inputValues.price,
        image: image,
      });
      setInputValues({
        title: "",
        price: 0,
        image: null,
      });
      const order = {
        serviceId: result.data.data.id,
        serviceTitle: result.data.data.title,
        serviceImage: result.data.data.image,
        servicePrice: result.data.data.price,
      };
      handleOrderSend(order);
    } catch (error) {
      console.log(error);
      Toast("error", "Failed to create product");
    }
    setLoading(false);
    close();
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={close}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    In Chat Order
                  </Dialog.Title>

                  <div>
                    <form
                      className="flex flex-col space-y-3"
                      onSubmit={handleSubmit}
                    >
                      <fieldset>
                        <legend>Service Details</legend>
                        <div className="create-form">
                          <div>
                            <label htmlFor="title">
                              <Translate>Title</Translate>
                            </label>
                            <input
                              type="text"
                              id="title"
                              name="title"
                              value={inputValues.title}
                              onChange={handleInputChange}
                            />
                          </div>
                          <div>
                            <label htmlFor="price">
                              <Translate>Price</Translate>
                            </label>
                            <input
                              type="number"
                              id="price"
                              name="price"
                              value={inputValues.price}
                              onChange={handleInputChange}
                            />
                          </div>
                          <div>
                            <label htmlFor="image">
                              <Translate>Image</Translate>
                            </label>
                            <input
                              type="file"
                              id="image"
                              name="image"
                              onChange={(e) =>
                                setInputValues({
                                  ...inputValues,
                                  image: e.target.files[0],
                                })
                              }
                            />
                          </div>
                        </div>
                      </fieldset>
                      <input type="submit" value="Send" className="btn" />
                    </form>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
