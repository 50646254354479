import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import UserRow from "./UserRow";
import Api from "../util/axiosInstance";
import LoadingSpinner from "../util/LoadingSpinner";
import LoadingAnimation from "../util/LoadingAnimation";

const User = {
  name: "John",
  image: "https://picsum.photos/800/800",
  email: "waleed@yopmail.com",
  createdAt: "2021-01-01",
  active: true,
};

const UsersList = ({ setIsLoading: setLoading, customers, setCustomers }) => {
  const handleDeleteCustomer = (id) => {
    setLoading(true);
    Api.delete(`/customer/${id}`)
      .then((response) => {
        setCustomers(customers.filter((customer) => customer.id !== id));
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleEditCustomer = (id) => {
    setLoading(true);
    const activeStatus = customers.find((customer) => customer.id === id);
    Api.patch(`/customer/${id}`, {
      active: !activeStatus.active,
    })
      .then((response) => {
        const updatedCustomers = customers.map((customer) => {
          if (customer.id === id) {
            customer.active = !customer.active;
          }
          return customer;
        });
        setCustomers(updatedCustomers);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  // if (loading) {
  //   <div className="absolute inset-0 z-50 flex justify-center items-center">
  //     <LoadingAnimation />
  //   </div>;
  // }

  return (
    <div className="w-full">
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>All</TableCell>
            <TableCell>Image</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>City</TableCell>
            <TableCell>Date</TableCell>
            <TableCell>Active</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {customers.map((customer) => (
            <UserRow
              key={customer.id}
              user={customer}
              handleDeleteCustomer={handleDeleteCustomer}
              handleEditCustomer={handleEditCustomer}
            />
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default UsersList;
