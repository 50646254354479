import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Translate from "../../util/Translate";
import Api from "../../util/axiosInstance";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../../../hooks/context/tokenStore";
import LoadingSpinner from "../../util/LoadingSpinner";
import Toast from "../../util/CustomToast";

const CustomerAdmin = () => {
  const [loading, setLoading] = useState(false);

  const [inputValues, setInputValues] = useState({
    email: "",
    password: "",
  });
  const { userData, setUserData } = useAppContext();
  const Navigate = useNavigate();
  const { t } = useTranslation();

  const handleInputChange = (e) => {
    setInputValues({ ...inputValues, [e.target.name]: e.target.value });
  };

  const handleLogin = (e) => {
    e.preventDefault();
    setLoading(true);
    Api.post("/user/login/customer", {
      email: inputValues.email,
      password: inputValues.password,
    })
      .then((res) => {
        console.log(res.data);
        localStorage.setItem("token", res.data.token);
        localStorage.setItem("role", "customer");

        setUserData(res.data.user);
        Navigate("/");
      })
      .catch((err) => {
        console.log(err);
        Toast("error", t("Invalid Credentials/Network Error"));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <div className="relative w-full h-screen flex justify-center items-center app-bg-color">
      <div
        className="absolute inset-0 w-screen h-screen"
        style={{
          backgroundImage: "url(/login-bg.jpg)",
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          filter: "blur(5px)",
          opacity: "0.6",
          zIndex: "0",
        }}
      ></div>
      <div className="flex flex-col justify-center items-center space-y-8 w-1/2 lg:w-1/4 z-10">
        <div className="py-2 px-10  rounded-md flex flex-col space-y-2 justify-center items-center">
          <div className="text-5xl app-text-color">Bipeers</div>
          <div>
            <Translate>Customer</Translate>
          </div>
        </div>
        <div className="p-5 shadow-lg w-full border">
          <form
            className="flex flex-col space-y-6 sub-heading-text"
            onSubmit={handleLogin}
          >
            <div className="self-center app-text-color">
              <Translate>LOGIN</Translate>
            </div>
            <input
              type="email"
              name="email"
              id="email"
              value={inputValues.email}
              onChange={handleInputChange}
              className="border-2 px-3 py-1 rounded-md"
              placeholder={t("Email Address")}
            />
            <input
              type="password"
              name="password"
              id="password"
              value={inputValues.password}
              onChange={handleInputChange}
              className="border-2 px-3 py-1 rounded-md"
              placeholder={t("Password")}
            />

            <input
              type="submit"
              value={t("LOGIN")}
              className="py-2 w-full text-center heading-bg-color bg-text-color rounded-md cursor-pointer"
            />
          </form>
        </div>
      </div>
    </div>
  );
};

export default CustomerAdmin;
