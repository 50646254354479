import React, { useEffect, useState } from "react";
import Menu from "../sideMenu/Menu";
import OrdersList from "./OrdersList";
import { URL } from "../util/authorization/authorizationURL";
import Api from "../util/axiosInstance";
import LoadingAnimation from "../util/LoadingAnimation";
import { useAppContext } from "../../hooks/context/tokenStore";

const Orders = () => {
  const [orders, setOrders] = useState([]);
  const [filter, setFilter] = useState("vendor");
  const userRole = localStorage.getItem("role");
  const [loading, setLoading] = useState(false);
  const { userData } = useAppContext();
  console.log("🚀 ~ Orders ~ userData:", userData, filter);

  useEffect(() => {
    const getOrders = async () => {
      let url = "";
      setLoading(true);
      if (
        userRole === "superadmin" ||
        userRole === "cityadmin" ||
        userRole === "logisticadmin"
      ) {
        let localFilter = "";

        if (userRole === "cityadmin" || userRole === "logisticadmin") {
          localFilter = `?cityId=${userData?.City?.id}`;
        }
        url = `${URL.order[userRole][filter]}${localFilter}`;
      } else {
        let localFilter = "";
        if (userRole === "vendor")
          localFilter = `?vendorId=${userData.id}`;
        if (userRole === "professional") {
          localFilter = `?professionalId=${userData?.id}`;
          setFilter("professional");
        }
        url = `${URL.order[userRole]}${localFilter}`;
      }
      Api.get(url)
        .then((res) => {
          setOrders(res.data.results);
          console.log(res.data.results);
        })
        .finally(() => {
          setLoading(false);
        });
    };
    getOrders();
  }, [userRole, filter]);

  return (
    <Menu>
      {loading ? (
        <div className=" flex justify-center min-h-[90vh] items-center m-auto">
          <LoadingAnimation />
        </div>
      ) : (
        <div className="flex flex-col space-y-10">
          <h1 className="sub-heading-text py-2 border-b-2 border-b-gray-300 w-full">
            Orders
          </h1>
          <div>
            <OrdersList
              filter={filter}
              orders={orders}
              setFilter={setFilter}
              setLoading={setLoading}
              setOrders={setOrders}
              userRole={userRole}
            />
          </div>
        </div>
      )}
    </Menu>
  );
};

export default Orders;
