import React from "react";
import Menu from "../sideMenu/Menu";
import { Link } from "react-router-dom";
import CategoryList from "./CategoryList";
import Translate from "../util/Translate";

const Categories = () => {
  return (
    <Menu>
      <div className="flex flex-col items-start space-y-5 ">
        <h1 className="sub-heading-text py-2 border-b-2 border-b-gray-300 w-full">
          <Translate>Categories</Translate>
        </h1>
        <div className="w-full bg-gray-200 flex justify-center items-center">
          <div className="py-2 px-4 bg-color app-text-color cursor-pointer rounded-md">
            <Translate>Categories List</Translate>
          </div>
          <Link
            className="py-2 px-4 hover:heading-bg-color hover:bg-text-color cursor-pointer rounded-md"
            to={"/category/create"}
          >
            <Translate>Create Category</Translate>
          </Link>
          <Link
            className="py-2 px-4 hover:heading-bg-color hover:bg-text-color cursor-pointer rounded-md"
            to={"/category/sub"}
          >
            <Translate>Sub Categories</Translate>
          </Link>
        </div>
        <CategoryList />
      </div>
    </Menu>
  );
};

export default Categories;
