import React, { useEffect, useState } from "react";
import Menu from "../sideMenu/Menu";
import Translate from "../util/Translate";
import { useAppContext } from "../../hooks/context/tokenStore";
import Api from "../util/axiosInstance";
import TagsInput from "react-tagsinput";
import { TiDeleteOutline } from "react-icons/ti";
import Toast from "../util/CustomToast";
import LoadingSpinner from "../util/LoadingSpinner";
import { Link } from "react-router-dom";
import { cloudinaryUpload } from "../../utils/cloudinaryUpload";

const VendorProfile = () => {
  const { userData } = useAppContext();
  const [loading, setLoading] = useState(false);
  const [cities, setCities] = useState();
  const [inputValues, setInputValues] = useState({
    name: "",
    email: "",
    cityId: "",
    cityArea: "",
    address: "",
    cityCommercialReference: "",
    cityShoppingCenter: "",
    whatsapp: "",
    phone: "",
    benefitForBipeers: [],
    keywords: [],
    photoLogo: "",
    photoBanner: "",
  });
  useEffect(() => {
    Api.get("/city").then((res) => {
      setCities(res.data.results);
    });
  }, []);

  useEffect(() => {
    Api.get(`/vendor/${userData.id}`).then((res) => {
      setInputValues({
        name: res.data.name,
        email: res.data.email,
        cityId: res.data.cityId,
        cityArea: res.data.cityArea,
        address: res.data.address,
        cityCommercialReference: res.data.cityCommercialReference,
        cityShoppingCenter: res.data.cityShoppingCenter,
        whatsapp: res.data.whatsapp,
        phone: res.data.phone,
        benefitForBipeers: res.data.benefitForBipeers,
        keywords: res.data.keywords,
        photoLogo: res.data.photoLogo,
        photoBanner: res.data.photoBanner,
      });
    });
  }, [userData]);

  const handleInputChange = (e) => {
    setInputValues({ ...inputValues, [e.target.name]: e.target.value });
  };
  const handleFileChange = async (e) => {
    console.log(e.target.files);
    if (!e.target.files?.length) return;
    const fileUpload = await cloudinaryUpload(e.target.files[0]);
    setInputValues({ ...inputValues, [e.target.name]: fileUpload });
  };
  const handleSubmitValues = async (e) => {
    e.preventDefault();
    setLoading(true);
    Api.patch(`/vendor/${userData.id}`, {
      ...inputValues,
    })
      .then((res) => {
        console.log(res.data);
        setInputValues({
          ...res.data.data,
        });
        Toast("success", "Profile Updated Successfully");
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <Menu>
      <div>
        <div className="mb-5 heading-text">
          <Translate>Profile</Translate>
        </div>
        <div className="flex justify-center items-center space-x-4">
          <Link to={"/user/resetpassword"} className="btn">
            Password Reset
          </Link>
          <Link to={"/user/sociallinks"} className="btn">
            Social Links
          </Link>
        </div>
        <div className="w-full flex justify-center">
          <form
            className="w-2/3 flex flex-col space-y-20 my-10"
            onSubmit={handleSubmitValues}
          >
            <fieldset>
              <legend>
                <Translate>Profile Details</Translate>
              </legend>
              <div className="create-form">
                <div>
                  <label htmlFor="name">
                    <Translate>Name</Translate>
                  </label>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    value={inputValues.name}
                    onChange={handleInputChange}
                  />
                </div>
                <div>
                  <label htmlFor="email">
                    <Translate>Email</Translate>
                  </label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    value={inputValues.email}
                    onChange={handleInputChange}
                  />
                </div>

                {/* city */}
                <div>
                  <label htmlFor="cityId">
                    <Translate>City</Translate>
                  </label>
                  <select
                    name="cityId"
                    id="cityId"
                    value={inputValues.cityId}
                    onChange={handleInputChange}
                  >
                    <option value="">Select City</option>
                    {cities &&
                      cities.map((city) => (
                        <option key={city.id} value={city.id}>
                          {city.name}
                        </option>
                      ))}
                  </select>
                </div>
                <div>
                  <label htmlFor="cityArea">
                    <Translate>City Area</Translate>
                  </label>
                  <input
                    type="text"
                    name="cityArea"
                    id="cityArea"
                    value={inputValues.cityArea}
                    onChange={handleInputChange}
                  />
                </div>
                {/* address */}
                <div>
                  <label htmlFor="address">
                    <Translate>Address</Translate>
                  </label>
                  <input
                    type="text"
                    name="address"
                    id="address"
                    value={inputValues.address}
                    onChange={handleInputChange}
                  />
                </div>
                {/* cityCommercialReference */}
                <div>
                  <label htmlFor="cityCommercialReference">
                    <Translate>City Commercial Reference</Translate>
                  </label>
                  <input
                    type="text"
                    name="cityCommercialReference"
                    id="cityCommercialReference"
                    value={inputValues.cityCommercialReference}
                    onChange={handleInputChange}
                  />
                </div>
                {/* cityShoppingCenter */}
                <div>
                  <label htmlFor="cityShoppingCenter">
                    <Translate>City Shopping Center</Translate>
                  </label>
                  <input
                    type="text"
                    name="cityShoppingCenter"
                    id="cityShoppingCenter"
                    value={inputValues.cityShoppingCenter}
                    onChange={handleInputChange}
                  />
                </div>
                <div>
                  <label htmlFor="whatsapp">
                    <Translate>Whatsapp</Translate>
                  </label>
                  <input
                    type="text"
                    name="whatsapp"
                    id="whatsapp"
                    value={inputValues.whatsapp}
                    onChange={handleInputChange}
                  />
                </div>
                <div>
                  <label htmlFor="phone">
                    <Translate>Phone</Translate>
                  </label>
                  <input
                    type="text"
                    name="phone"
                    id="phone"
                    value={inputValues.phone}
                    onChange={handleInputChange}
                  />
                </div>
                <div>
                  <label htmlFor="benefitForBipeers">
                    <Translate>Benefit For Bipeers</Translate>
                  </label>
                  {/* tags input */}
                  <TagsInput
                    value={inputValues.benefitForBipeers || []}
                    onChange={(benefitForBipeers) =>
                      setInputValues({ ...inputValues, benefitForBipeers })
                    }
                  />
                </div>
                <div>
                  <label htmlFor="keywords">
                    <Translate>Keywords</Translate>
                  </label>
                  {/* tags input */}
                  <TagsInput
                    value={inputValues.keywords || []}
                    onChange={(keywords) =>
                      setInputValues({ ...inputValues, keywords })
                    }
                  />
                </div>
              </div>
            </fieldset>

            <fieldset>
              <legend>
                <Translate>Profile Photos</Translate>
              </legend>
              <div className="flex h-40">
                {inputValues.photoLogo ? (
                  <div className="w-1/2 h-auto relative">
                    <img
                      src={inputValues.photoLogo}
                      alt="photoLogo"
                      className="w-full h-full object-contain"
                    />
                    <div
                      className="absolute -top-5 right-0 cursor-pointer"
                      onClick={() => {
                        setInputValues({ ...inputValues, photoLogo: "" });
                      }}
                    >
                      <TiDeleteOutline size={20} color="red" />
                    </div>
                  </div>
                ) : (
                  <div>
                    <label htmlFor="photoLogo">
                      <Translate>Photo Logo</Translate>
                    </label>
                    <input
                      type="file"
                      name="photoLogo"
                      id="photoLogo"
                      onChange={handleFileChange}
                    />
                  </div>
                )}
                {inputValues.photoBanner ? (
                  <div className="w-1/2 h-auto relative">
                    <img
                      src={inputValues.photoBanner}
                      alt="photoBanner"
                      className="w-full h-full object-contain"
                    />
                    <div
                      className="absolute -top-5 right-0 cursor-pointer"
                      onClick={() => {
                        setInputValues({ ...inputValues, photoBanner: "" });
                      }}
                    >
                      <TiDeleteOutline size={20} color="red" />
                    </div>
                  </div>
                ) : (
                  <div>
                    <label htmlFor="photoBanner">
                      <Translate>Photo Banner</Translate>
                    </label>
                    <input
                      type="file"
                      name="photoBanner"
                      id="photoBanner"
                      onChange={handleFileChange}
                    />
                  </div>
                )}
              </div>
            </fieldset>
            <div className="flex justify-center space-x-5">
              <button
                className="heading-bg-color bg-text-color px-5 py-2 rounded-md cursor-pointer"
                type="submit"
              >
                <Translate>Save</Translate>
              </button>
              <div className="heading-bg-color bg-text-color px-5 py-2 rounded-md cursor-pointer">
                <Translate>Back</Translate>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Menu>
  );
};

export default VendorProfile;
