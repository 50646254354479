import React, { useState } from "react";
import Menu from "../../sideMenu/Menu";
import CreateMainCategory from "./CreateMainCategory";
import CreateSubCategory from "./CreateSubCategory";
import Translate from "../../util/Translate";

const CreateTaxonomy = () => {
  const [selectedRole, setSelectedRole] = useState("");
  const [recheck, setRecheck] = useState(false);
  return (
    <Menu>
      <div className="flex flex-col space-y-10">
        <h1 className="sub-heading-text py-2 border-b-2 border-b-gray-300 w-full">
          <Translate>Create Taxonomy</Translate>
        </h1>
        <div className="flex flex-col space-y-2">
          <div className="app-text-color">
            <Translate>Select Taxonomy Role</Translate>
          </div>

          <div className="flex items-center space-x-5 sub-heading-text">
            <div
              className={`px-5 py-2 border cursor-pointer rounded-md ${
                selectedRole === "shop" ? "heading-bg-color bg-text-color" : ""
              }`}
              onClick={() => setSelectedRole("shop")}
            >
              <Translate>Shop</Translate>
            </div>
            <div
              className={`px-5 py-2 border cursor-pointer rounded-md ${
                selectedRole === "professional"
                  ? "heading-bg-color bg-text-color"
                  : ""
              }`}
              onClick={() => setSelectedRole("professional")}
            >
              <Translate>Professional</Translate>
            </div>
          </div>
        </div>
        {selectedRole && (
          <div className="flex w-full justify-around">
            <CreateMainCategory
              selectedRole={selectedRole}
              setRecheck={setRecheck}
            />
            <CreateSubCategory selectedRole={selectedRole} recheck={recheck} />
          </div>
        )}
      </div>
    </Menu>
  );
};

export default CreateTaxonomy;
