import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import Translate from "../util/Translate";

const vendors = [
  {
    id: 1,
    name: "Vendor 1",
    orders: 10,
    revenue: 1000,
  },
  {
    id: 2,
    name: "Vendor 2",
    orders: 20,
    revenue: 4000,
  },
  {
    id: 3,
    name: "Vendor 3",
    orders: 30,
    revenue: 9000,
  },
  {
    id: 4,
    name: "Vendor 4",
    orders: 40,
    revenue: 16000,
  },
  {
    id: 5,
    name: "Vendor 5",
    orders: 50,
    revenue: 25000,
  },
];

const VendorsTable = ({ vendorsTable }) => {
  console.log("🚀 ~ VendorsTable ~ vendorsTable:", vendorsTable);
  return (
    <div>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <Translate>Vendor</Translate>
            </TableCell>
            <TableCell>
              <Translate>Total Orders</Translate>
            </TableCell>
            {/* <TableCell>
              <Translate>Revenue</Translate>
            </TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {vendorsTable?.map((vendor) => (
            <TableRow key={vendor.id}>
              <TableCell>{vendor.name}</TableCell>
              <TableCell>{vendor.totalOrders}</TableCell>
              {/* <TableCell>{vendor.revenue}</TableCell> */}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default VendorsTable;
