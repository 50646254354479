import React, { useState } from "react";
import CustomModal from "./CustomModal";

const ConfirmationModal = ({ deleteFuction, id, isOpen, setIsOpen, name }) => {
  console.log("🚀 ~ ConfirmationModal ~ id:", id)
  const openModal = () => {
    setIsOpen(true);
  };
  const closeModal = () => {
    setIsOpen(false);
  };

  const handleDeleteOperation = () => {
    deleteFuction(id);
    closeModal();
  };

  return (
    <CustomModal
      title={"Confirmation"}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      openModal={openModal}
      closeModal={closeModal}
    >
      <div className="flex flex-col space-y-6 my-5">
        <p>
          Are you sure you want to delete
          {name && <b>"{name}"</b>}?
        </p>
        <div className="flex justify-end space-x-4">
          <button
            onClick={closeModal}
            className="py-2 px-4 bg-gray-300 text-gray-800 rounded-md"
          >
            Cancel
          </button>
          <button
            onClick={handleDeleteOperation}
            className="py-2 px-4 bg-red-500 text-white rounded-md"
          >
            Delete
          </button>
        </div>
      </div>
    </CustomModal>
  );
};

export default ConfirmationModal;
